define("shared/mixins/upload", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    growl: Ember.inject.service(),
    actions: {
      upload() {
        (0, _jquery.default)(this.element).find('INPUT[type=file]').click();
      }

    },

    change(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        let file = input.files[0];
        const reader = new FileReader();

        reader.onerror = err => {
          Ember.get(this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.onload = event2 => {
          const out = event2.target.result;
          this.send(Ember.get(this, 'uploadAction'), out);
          input.value = '';
        };

        reader.readAsText(file);
      }
    }

  });

  _exports.default = _default;
});