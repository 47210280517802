define("shared/components/metrics-action/component", ["exports", "ui/utils/constants", "shared/components/metrics-action/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOM = 'custom';
  const PERIODS = [{
    label: 'metricsAction.periods.custom',
    value: CUSTOM
  }, {
    label: 'metricsAction.periods.5m',
    value: 'now-5m',
    interval: '5s',
    istio: '2s'
  }, {
    label: 'metricsAction.periods.1h',
    value: 'now-1h',
    interval: '60s',
    istio: '10s'
  }, {
    label: 'metricsAction.periods.6h',
    value: 'now-6h',
    interval: '60s',
    istio: '30s'
  }, {
    label: 'metricsAction.periods.24h',
    value: 'now-24h',
    interval: '300s'
  }, {
    label: 'metricsAction.periods.7d',
    value: 'now-168h',
    interval: '1800s'
  }, {
    label: 'metricsAction.periods.30d',
    value: 'now-720h',
    interval: '3600s'
  }];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    prefs: Ember.inject.service(),
    layout: _template.default,
    classNames: 'mb-20',
    resourceType: 'cluster',
    dashboardName: null,
    allowDetail: true,
    istio: false,
    selected: 'now-1h',
    periods: PERIODS,

    init() {
      this._super(...arguments);

      const periodPref = Ember.get(this, `prefs.${_constants.default.PREFS.PERIOD}`);

      if (periodPref) {
        Ember.set(this, 'selected', periodPref);
      }

      Ember.run.next(() => {
        this.query();
      });
    },

    actions: {
      refresh() {
        this.query();
      },

      onOpen() {
        Ember.set(this, 'now', new Date().getTime());
      },

      fromDidChange(from) {
        if (Ember.get(from, 'length')) {
          Ember.set(this, 'from', Ember.get(from, 'firstObject').getTime());
        }
      },

      toDidChange(to) {
        if (Ember.get(to, 'length')) {
          Ember.set(this, 'to', Ember.get(to, 'firstObject').getTime());
        }
      },

      onTimePickerClose() {
        this.query(false);
      },

      toggle(detail) {
        if (!Ember.get(this, 'state.loading')) {
          Ember.set(this, 'state.detail', detail);
          this.query();
        }
      }

    },
    periodDidChange: Ember.observer('selected', function () {
      Ember.setProperties(this, {
        from: new Date().getTime() - 60000,
        to: new Date().getTime(),
        now: new Date().getTime()
      });

      if (Ember.get(this, 'selected') !== CUSTOM) {
        Ember.set(this, `prefs.${_constants.default.PREFS.PERIOD}`, Ember.get(this, 'selected'));
      }

      this.query();
    }),

    query(forceRefresh = true) {
      const period = Ember.get(this, 'selected');
      let from;
      let to;
      let interval;
      let isCustom;

      if (period !== CUSTOM) {
        const params = PERIODS.findBy('value', Ember.get(this, 'selected'));
        from = period;
        to = 'now';
        interval = Ember.get(params, 'istio') && Ember.get(this, 'istio') ? Ember.get(params, 'istio') : Ember.get(params, 'interval');
        isCustom = false;
      } else {
        from = Ember.get(this, 'from').toString();
        to = Ember.get(this, 'to').toString() || new Date().getTime().toString();
        let i = Math.round((to - from) / 120000);
        i = i > 1 ? i : 1;
        interval = `${i}s`;
        isCustom = true;
      }

      Ember.setProperties(Ember.get(this, 'state'), {
        from,
        to,
        interval,
        isCustom
      });

      if (period === CUSTOM) {
        if (!forceRefresh && Ember.get(this, 'preFrom') === from && Ember.get(this, 'preTo') === to) {
          return;
        } else {
          Ember.setProperties(this, {
            preFrom: from,
            preTo: to
          });
        }
      }

      this.queryAction();
    },

    queryAction() {
      throw new Error('queryAction action is required!');
    }

  });

  _exports.default = _default;
});