define("shared/components/schema/input-container/component", ["exports", "shared/components/schema/input-container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allContainers: Ember.inject.service(),
    layout: _template.default,
    selected: null,
    // Selected service ID
    exclude: null,
    // ID or array of IDs to exclude from list
    stack: null,
    // The current stack, to generate stack-relative names
    // For use as a catalog question
    field: null,
    // Read default from a schema resourceField
    value: null,

    // stackName/serviceName string output
    init() {
      this._super(...arguments);

      let def = this.get('field.default');

      if (def && !this.get('selected')) {
        let match = this.get('allContainers.list').findBy('name', def);
        this.set('selected', match || null);
      }
    },

    selectedChanged: Ember.observer('selected', function () {
      let id = this.get('selected');
      let str = null;

      if (id) {
        let container = this.get('allContainers').byId(id);

        if (container) {
          if (this.get('stack') && this.get('stack') === container.get('stack')) {
            str = container.get('name');
          } else {
            str = `${container.get('stack.name')}/${container.get('name')}`;
          }
        }
      }

      this.set('value', str);
    }),
    grouped: Ember.computed('allContainers.list.[]', 'canBalanceTo', 'canHaveContainers', 'exclude', function () {
      let list = this.get('allContainers.list');
      let exclude = this.get('exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        list = list.filter(x => !exclude.includes(x.id));
      }

      return this.get('allContainers').group(list);
    })
  });

  _exports.default = _default;
});