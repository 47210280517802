define("shared/components/schema/input-relative-service/component", ["exports", "shared/components/schema/input-relative-service/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOM = '__CUSTOM__';

  var _default = Ember.Component.extend({
    allWorkloads: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    stack: null,
    // The default stack
    value: null,
    // The [stack/]service string value
    exclude: null,
    // ID or array of IDs to exclude from list
    inputClass: 'form-control',
    obj: null,
    // The selected service object
    custom: false,

    init() {
      this._super(...arguments);

      let value = this.get('value');

      if (value) {
        let obj = this.get('allWorkloads').matching(value, this.get('stack'));

        if (obj) {
          this.setProperties({
            obj,
            custom: false
          });
        } else {
          this.set('custom', true);
        }
      }
    },

    actions: {
      standard() {
        if (!this.get('obj')) {
          this.set('value', null);
        }

        this.set('custom', false);
      }

    },
    valueChanged: Ember.observer('value', function () {
      let value = this.get('value');

      if (value === CUSTOM) {
        this.setProperties({
          value: '',
          custom: true,
          obj: null
        });
      } else if (value) {
        let obj = this.get('allWorkloads').matching(value, this.get('stack'));
        this.set('obj', obj);
      }
    }),
    list: Ember.computed('allWorkloads.list', 'exclude', 'grouped.[]', 'intl.locale', 'stack.{id,name}', function () {
      let stackId = this.get('stack.id');
      let list = this.get('allWorkloads.list').sortBy('combined');
      list.forEach(item => {
        if (item.obj.stackId === stackId) {
          Ember.set(item, 'value', item.name);
        } else {
          Ember.set(item, 'value', item.combined);
        }
      });
      list.push({
        group: null,
        value: CUSTOM,
        name: this.get('intl').t('schema.inputService.custom')
      });
      let exclude = this.get('exclude') || [];

      if (!Ember.isArray(exclude)) {
        exclude = [exclude];
      }

      if (exclude.get('length')) {
        list = list.filter(row => !exclude.includes(row.id));
      }

      return list;
    })
  });

  _exports.default = _default;
});