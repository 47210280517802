define("global-admin/security/roles/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model(params) {
      const store = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        policies: store.find('podSecurityPolicyTemplate'),
        roles: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(null, null),
        globalRoles: store.find('globalRole')
      }).then(res => {
        const {
          id,
          context = 'project'
        } = params;
        let role;

        if (id) {
          if (context === 'global') {
            role = res.globalRoles.findBy('id', id);
          } else {
            role = res.roles.findBy('id', id);
          }

          if (!role) {
            this.replaceWith('security.roles.index');
          }

          role = role.cloneForNew();
          Ember.set(role, 'context', context);
          delete role['builtin'];
          delete role['annotations'];
          delete role['labels'];
          delete role['links'];
        } else {
          if (context === 'global') {
            role = store.createRecord({
              type: 'globalRole',
              context
            });
          } else {
            role = store.createRecord({
              type: 'roleTemplate',
              name: '',
              rules: [],
              hidden: false,
              locked: false,
              context
            });
          }
        }

        Ember.set(res, 'role', role);
        return res;
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.setProperties(controller, {
        type: Ember.get(model, 'role.context')
      });
    },

    queryParams: {
      context: {
        refreshModel: true
      },
      id: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});