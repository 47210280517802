define("nodes/custom-drivers/cluster-drivers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }];

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    headers: HEADERS,
    refreshing: false,
    actions: {
      addNewDriver() {
        let newDriver = Ember.get(this, 'globalStore').createRecord({
          type: 'kontainerDriver',
          name: null,
          description: null,
          checksum: null,
          url: null,
          active: true
        });
        this.get('modalService').toggleModal('modal-edit-driver', newDriver);
      },

      refreshMetadata() {
        Ember.set(this, 'refreshing', true);
        Ember.get(this, 'globalStore').rawRequest({
          url: '/v3/kontainerdrivers?action=refresh',
          method: 'POST'
        }).catch(error => {
          Ember.get(this, 'growl').fromError(undefined, error.body.message);
        }).finally(() => Ember.set(this, 'refreshing', false));
      }

    },
    rows: Ember.computed('model.drivers.@each.{externalId,id,state,version}', 'model.drivers.content', function () {
      // possibly add some search here
      let drivers = Ember.get(this, 'model.drivers.content');
      return drivers;
    })
  });

  _exports.default = _default;
});