define("shared/components/radio-button/component", ["exports", "shared/components/radio-button/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'input',
    type: 'radio',
    disabled: false,
    attributeBindings: ['name', 'type', 'checked:checked', 'disabled:disabled'],
    checked: Ember.computed('value', 'selection', function () {
      return this.get('value') === this.get('selection');
    }),

    click() {
      this.set('selection', this.get('value'));
    }

  });

  _exports.default = _default;
});