define("shared/components/form-share-member/component", ["exports", "shared/components/form-share-member/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBERS_HEADERS = [{
    translationKey: 'formShareMember.table.headers.name',
    name: 'name',
    sort: ['userPrincipalId', 'groupPrincipalId']
  }, {
    translationKey: 'formShareMember.table.headers.accessType',
    name: 'accessType',
    sort: ['accessType']
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    membersHeaders: MEMBERS_HEADERS,
    sortBy: '',
    descending: false,
    resource: null,
    gotError: null,
    users: null,
    errors: null,
    editing: false,
    addPublicMember: false,

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, 'setupPublicMember');
    },

    actions: {
      addMember() {
        this.addAuthorizedPrincipal({
          type: 'member',
          accessType: 'read-only'
        });
      },

      sharePublic() {
        this.addAuthorizedPrincipal({
          type: 'member',
          accessType: 'read-only',
          groupPrincipalId: '*'
        });
      },

      checkboxToggled() {
        let {
          members = []
        } = this.resource;

        if (this.addPublicMember && members.findBy('groupPrincipalId', '*')) {
          this.removeMember(members.findBy('groupPrincipalId', '*'));
        } else {
          this.send('sharePublic');
        }
      }

    },
    membersRows: Ember.computed('resource.members.[]', function () {
      let {
        members = []
      } = this.resource;
      return (members || []).filter(member => Ember.get(member, 'groupPrincipalId') !== '*').sortBy('displayName');
    }),

    setupPublicMember() {
      let {
        members = []
      } = this.resource;

      if (!this.addPublicMember && (members || []).findBy('groupPrincipalId', '*')) {
        Ember.set(this, 'addPublicMember', true);
      }
    },

    addAuthorizedPrincipal() {
      throw new Error('add principal handler must be provided!!');
    },

    removeMember() {
      throw new Error('removeMember is a required action!');
    }

  });

  _exports.default = _default;
});