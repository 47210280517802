define("shared/utils/subscribe-global", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_subscribe.default, {
    updateClusterStore: false,
    updateProjectStore: false,

    init() {
      this._super(...arguments);

      Ember.set(this, 'endpoint', Ember.get(this, 'app.globalSubscribeEndpoint'));
    }

  });

  _exports.default = _default;
});