define("shared/components/cluster-driver/driver-tencenttke/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-tencenttke/template"], function (_exports, _clusterDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ENDPOINT = 'tencentcloudapi.com/';
  const CCS_ENDPOINT = 'api.qcloud.com/v2/index.php';
  const DATA_DISK = 'DATA_DISK';
  const SYSTEM_DISK = 'SYSTEM_DISK';
  const OS = [{
    label: 'Ubuntu Server 16.04.1 LTS 64bit',
    value: 'ubuntu16.04.1 LTSx86_64'
  }, {
    label: 'CentOS 7.2 64bit',
    value: 'centos7.2x86_64'
  }];
  const REGIONS = [{
    label: 'ap-guangzhou',
    value: 'ap-guangzhou'
  }, {
    label: 'ap-shanghai',
    value: 'ap-shanghai'
  }, {
    label: 'ap-beijing',
    value: 'ap-beijing'
  }, {
    label: 'ap-hongkong',
    value: 'ap-hongkong'
  }, {
    label: 'ap-singapore',
    value: 'ap-singapore'
  }, {
    label: 'ap-chengdu',
    value: 'ap-chengdu'
  }, {
    label: 'ap-mumbai',
    value: 'ap-mumbai'
  }, {
    label: 'ap-tokyo',
    value: 'ap-tokyo'
  }, {
    label: 'ap-bangkok',
    value: 'ap-bangkok'
  }, {
    label: 'na-siliconvalley',
    value: 'na-siliconvalley'
  }, {
    label: 'na-ashburn',
    value: 'na-ashburn'
  }, {
    label: 'eu-moscow',
    value: 'eu-moscow'
  }, {
    label: 'eu-frankfurt',
    value: 'eu-frankfurt'
  }];
  const VERSIONS = [{
    label: '1.10.5',
    value: '1.10.5'
  }, {
    label: '1.12.4',
    value: '1.12.4'
  }, {
    label: '1.14.3',
    value: '1.14.3'
  }, {
    label: '1.16.3',
    value: '1.16.3'
  }];
  const BAND_WIDTH = [{
    label: 'clusterNew.tencenttke.bandwidthType.hour',
    value: 'PayByHour'
  }, {
    label: 'clusterNew.tencenttke.bandwidthType.traffic',
    value: 'PayByTraffic'
  }];

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'tencentEngineConfig',
    step: 1,
    regionChoices: REGIONS,
    versionChoices: VERSIONS,
    osChoices: OS,
    bandWidthChoices: BAND_WIDTH,
    zoneChoices: null,
    vpcChoices: null,
    sgChoices: null,
    keyChoices: null,
    allSubnets: null,
    allInstances: null,
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments);

      let config = Ember.get(this, 'cluster.tencentEngineConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: 'tencentEngineConfig',
          clusterCidr: '172.16.0.0/16',
          clusterVersion: Ember.get(VERSIONS, 'lastObject.value'),
          region: 'ap-guangzhou',
          secretId: null,
          secretKey: null,
          zoneId: null,
          vpcId: null,
          subnetId: null,
          instanceType: 'S2.MEDIUM4',
          osName: 'ubuntu16.04.1 LTSx86_64',
          sgId: null,
          rootSize: 100,
          storageSize: 100,
          cvmType: 'PayByHour',
          wanIp: 1,
          isVpcGateway: 0,
          emptyCluster: false,
          bandwidthType: 'PayByHour',
          bandwidth: 10,
          keyId: null,
          nodeCount: 1
        });
        Ember.set(this, 'cluster.tencentEngineConfig', config);
      }
    },

    actions: {
      tencentLogin(cb) {
        Ember.setProperties(this, {
          'errors': null,
          'config.secretId': (Ember.get(this, 'config.secretId') || '').trim(),
          'config.secretKey': (Ember.get(this, 'config.secretKey') || '').trim()
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const secretId = Ember.get(this, 'config.secretId');
        const secretKey = Ember.get(this, 'config.secretKey');

        if (!secretId) {
          errors.push(intl.t('clusterNew.tencenttke.secretId.required'));
        }

        if (!secretKey) {
          errors.push(intl.t('clusterNew.tencenttke.secretKey.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        return Ember.RSVP.all([this.fetchVpcs(), this.fetchSubnets()]).then(() => {
          Ember.set(this, 'step', 2);
          cb(true);
        }).catch(() => {
          cb(false);
        });
      },

      loadNodeConfig(cb) {
        Ember.setProperties(this, {
          'errors': null
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const {
          clusterCidr,
          vpcId,
          nodeCount
        } = Ember.get(this, 'config');

        if (!clusterCidr) {
          errors.push(intl.t('clusterNew.tencenttke.cidr.required'));
        }

        if (!vpcId) {
          errors.push(intl.t('clusterNew.tencenttke.vpc.required'));
        }

        if (!nodeCount) {
          errors.push(intl.t('clusterNew.tencenttke.nodeCount.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        return this.checkCidr().then(res => {
          if (Ember.get(res, 'code') === 0) {
            Ember.RSVP.all([this.fetchZones(), this.fetchNodeTypes()]).then(() => {
              Ember.set(this, 'step', 3);
              cb(true);
            }).catch(() => {
              cb(false);
            });
          } else {
            const error = decodeURIComponent(Ember.get(res, 'message'));

            if (error) {
              Ember.set(this, 'errors', [error]);
            }

            cb(false);
          }
        }).catch(error => {
          Ember.set(this, 'errors', [error]);
          cb(false);
        });
      },

      loadInstanceConfig(cb) {
        Ember.setProperties(this, {
          'errors': null
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const {
          zoneId,
          subnetId
        } = Ember.get(this, 'config');

        if (!zoneId) {
          errors.push(intl.t('clusterNew.tencenttke.zone.required'));
        }

        if (!subnetId) {
          errors.push(intl.t('clusterNew.tencenttke.subnet.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        return Ember.RSVP.all([this.fetchSecurityGroups(), this.fetchKeyPairs(), this.fetchDiskConfigQuota()]).then(() => {
          Ember.set(this, 'step', 4);
          cb(true);
        }).catch(() => {
          cb(false);
        });
      },

      save(cb) {
        Ember.setProperties(this, {
          'errors': null
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const {
          sgId,
          keyId
        } = Ember.get(this, 'config');

        if (!sgId) {
          errors.push(intl.t('clusterNew.tencenttke.securityGroup.required'));
        }

        if (!keyId) {
          errors.push(intl.t('clusterNew.tencenttke.keyPair.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        this.send('driverSave', cb);
      }

    },
    clusterNameDidChange: Ember.observer('cluster.name', function () {
      Ember.set(this, 'config.clusterName', Ember.get(this, 'cluster.name'));
    }),
    subnetIdObserver: Ember.observer('selectedZone', 'allSubnets', 'config.vpcId', 'vpcChoices.[]', function () {
      if (!Ember.get(this, 'selectedZone') || !Ember.get(this, 'allSubnets')) {
        return;
      }

      const subnets = Ember.get(this, 'allSubnets').filter(subnet => Ember.get(subnet, 'vpcId') === Ember.get(this, 'config.vpcId') && Ember.get(subnet, 'zone') === Ember.get(this, 'selectedZone.label'));
      const subnetId = Ember.get(this, 'config.subnetId');

      if (Ember.get(this, 'isNew') && Ember.get(subnets, 'length')) {
        Ember.set(this, 'config.subnetId', Ember.get(subnets, 'firstObject.value'));
      } else {
        const found = subnets.findBy('value', subnetId);

        if (!found) {
          Ember.set(this, 'config.subnetId', null);
        }
      }
    }),
    subnetChoices: Ember.computed('allSubnets', 'config.vpcId', 'selectedZone.label', 'vpcChoices.[]', function () {
      if (!Ember.get(this, 'selectedZone') || !Ember.get(this, 'allSubnets')) {
        return;
      }

      const subnets = Ember.get(this, 'allSubnets').filter(subnet => Ember.get(subnet, 'vpcId') === Ember.get(this, 'config.vpcId') && Ember.get(subnet, 'zone') === Ember.get(this, 'selectedZone.label'));
      return subnets;
    }),
    instanceChoices: Ember.computed('allInstances', 'config.instanceType', 'selectedZone.label', function () {
      if (!Ember.get(this, 'selectedZone') || !Ember.get(this, 'allInstances')) {
        return;
      }

      const instances = Ember.get(this, 'allInstances').filterBy('zone', Ember.get(this, 'selectedZone.label'));
      const instanceType = Ember.get(this, 'config.instanceType');
      const found = instances.findBy('value', instanceType);

      if (!found) {
        Ember.set(this, 'config.instanceType', null);
      }

      return instances;
    }),
    selectedZone: Ember.computed('config.zoneId', 'zoneChoices', function () {
      const zoneChoices = Ember.get(this, 'zoneChoices') || [];
      return zoneChoices.findBy('value', Ember.get(this, 'config.zoneId'));
    }),
    storageDiskChoices: Ember.computed('diskConfigSet.[]', function () {
      return this.getDiskChoices(DATA_DISK);
    }),
    rootDiskChoices: Ember.computed('diskConfigSet.[]', function () {
      return this.getDiskChoices(SYSTEM_DISK);
    }),
    maxDataDiskSize: Ember.computed('config.storageType', function () {
      const {
        storageDiskChoices = []
      } = this;
      const disk = storageDiskChoices.findBy('value', Ember.get(this, 'config.storageType'));
      return Ember.get(disk, 'maxDiskSize');
    }),
    minDataDiskSize: Ember.computed('config.storageType', function () {
      const {
        storageDiskChoices = []
      } = this;
      const disk = storageDiskChoices.findBy('value', Ember.get(this, 'config.storageType'));
      return Ember.get(disk, 'minDiskSize');
    }),
    maxSystemDiskSize: Ember.computed('config.rootType', function () {
      const {
        rootDiskChoices = []
      } = this;
      const disk = rootDiskChoices.findBy('value', Ember.get(this, 'config.rootType'));
      return Ember.get(disk, 'maxDiskSize');
    }),
    minSystemDiskSize: Ember.computed('config.rootType', function () {
      const {
        rootDiskChoices = []
      } = this;
      const disk = rootDiskChoices.findBy('value', Ember.get(this, 'config.rootType'));
      return Ember.get(disk, 'minDiskSize');
    }),

    checkCidr() {
      if (Ember.get(this, 'isNew')) {
        return this.queryFromTencent('ccs', 'CheckClusterCIDR', CCS_ENDPOINT, {
          clusterCIDR: Ember.get(this, 'config.clusterCidr'),
          vpcId: Ember.get(this, 'config.vpcId')
        });
      } else {
        return Ember.RSVP.resolve({
          code: 0
        });
      }
    },

    queryFromTencent(product, action, endpoint = ENDPOINT, extraParams = {}) {
      const data = {
        Action: action,
        Nonce: Math.round(Math.random() * 65535),
        Region: Ember.get(this, 'config.region'),
        SecretId: Ember.get(this, 'config.secretId'),
        SignatureMethod: 'HmacSHA1',
        Timestamp: Math.round(Date.now() / 1000),
        Version: '2017-03-12',
        ...extraParams
      };
      let url = `${product}.${endpoint}?`;
      const params = [];
      Object.keys(data).sort().forEach(key => {
        params.push(`${key}=${data[key]}`);
      });
      url += params.join('&');
      url += `&Signature=${encodeURIComponent(AWS.util.crypto.hmac(Ember.get(this, 'config.secretKey'), `GET${url}`, 'base64', 'sha1'))}`;
      return Ember.get(this, 'globalStore').rawRequest({
        url: `/meta/proxy/https:/${url}`,
        method: 'GET'
      }).then(xhr => {
        const error = Ember.get(xhr, 'body.Response.Error.Message');

        if (error) {
          Ember.set(this, 'errors', [error]);
          return Ember.RSVP.reject();
        }

        return Ember.get(xhr, 'body.Response') || JSON.parse(Ember.get(xhr, 'body'));
      }).catch(xhr => {
        const error = Ember.get(xhr, 'body.Response.Error.Message') || JSON.stringify(xhr);
        Ember.set(this, 'errors', [error]);
        return Ember.RSVP.reject();
      });
    },

    fetchVpcs() {
      return this.queryFromTencent('vpc', 'DescribeVpcs').then(res => {
        Ember.set(this, 'vpcChoices', Ember.get(res, 'VpcSet').map(vpc => {
          return {
            label: Ember.get(vpc, 'VpcName'),
            value: Ember.get(vpc, 'VpcId')
          };
        }));

        if (!Ember.get(this, 'config.vpcId') && Ember.get(this, 'vpcChoices.length')) {
          Ember.set(this, 'config.vpcId', Ember.get(this, 'vpcChoices.firstObject.value'));
        }
      });
    },

    fetchSubnets() {
      return this.queryFromTencent('vpc', 'DescribeSubnets').then(res => {
        Ember.set(this, 'allSubnets', Ember.get(res, 'SubnetSet').map(subnet => {
          return {
            label: Ember.get(subnet, 'SubnetName'),
            value: Ember.get(subnet, 'SubnetId'),
            vpcId: Ember.get(subnet, 'VpcId'),
            zone: Ember.get(subnet, 'Zone')
          };
        }));
      });
    },

    fetchNodeTypes() {
      return this.queryFromTencent('cvm', 'DescribeInstanceTypeConfigs').then(res => {
        Ember.set(this, 'allInstances', Ember.get(res, 'InstanceTypeConfigSet').map(instance => {
          return {
            value: Ember.get(instance, 'InstanceType'),
            label: `${Ember.get(instance, 'InstanceType')} (CPU ${Ember.get(instance, 'CPU')} Memory ${Ember.get(instance, 'Memory')} GiB)`,
            group: Ember.get(instance, 'InstanceFamily'),
            zone: Ember.get(instance, 'Zone')
          };
        }));
      });
    },

    fetchSecurityGroups() {
      return this.queryFromTencent('vpc', 'DescribeSecurityGroups').then(res => {
        Ember.set(this, 'sgChoices', Ember.get(res, 'SecurityGroupSet').map(zone => {
          return {
            label: Ember.get(zone, 'SecurityGroupName'),
            value: Ember.get(zone, 'SecurityGroupId')
          };
        }));

        if (!Ember.get(this, 'config.sgId') && Ember.get(this, 'sgChoices.length')) {
          Ember.set(this, 'config.sgId', Ember.get(this, 'sgChoices.firstObject.value'));
        }
      });
    },

    fetchDiskConfigQuota() {
      return this.queryFromTencent('cbs', 'DescribeDiskConfigQuota', ENDPOINT, {
        InquiryType: 'INQUIRY_CVM_CONFIG',
        'Zones.0': ((Ember.get(this, 'zoneChoices') || []).findBy('value', Ember.get(this, 'config.zoneId')) || {}).label,
        'InstanceFamilies.0': (Ember.get(this, 'config.instanceType') || '').split('.').get('firstObject')
      }).then(res => {
        const diskConfigSet = Ember.get(res, 'DiskConfigSet').filter(d => d.DiskChargeType === 'POSTPAID_BY_HOUR');
        const dataDisks = diskConfigSet.filter(d => d.DiskUsage === DATA_DISK);
        const systemDisks = diskConfigSet.filter(d => d.DiskUsage === SYSTEM_DISK);

        if (Ember.get(this, 'isNew')) {
          Ember.setProperties(this, {
            'config.storageType': Ember.get(dataDisks, 'firstObject.DiskType'),
            'config.rootType': Ember.get(systemDisks, 'firstObject.DiskType')
          });
        }

        Ember.set(this, 'diskConfigSet', diskConfigSet);
      });
    },

    fetchKeyPairs() {
      return this.queryFromTencent('cvm', 'DescribeKeyPairs').then(res => {
        Ember.set(this, 'keyChoices', Ember.get(res, 'KeyPairSet').map(key => {
          return {
            label: Ember.get(key, 'KeyName'),
            value: Ember.get(key, 'KeyId')
          };
        }));

        if (!Ember.get(this, 'config.keyId') && Ember.get(this, 'keyChoices.length')) {
          Ember.set(this, 'config.keyId', Ember.get(this, 'keyChoices.firstObject.value'));
        }
      });
    },

    fetchZones() {
      return this.queryFromTencent('cvm', 'DescribeZones').then(res => {
        Ember.set(this, 'zoneChoices', Ember.get(res, 'ZoneSet').filterBy('ZoneState', 'AVAILABLE').map(zone => {
          return {
            label: Ember.get(zone, 'Zone'),
            value: Ember.get(zone, 'ZoneId')
          };
        }));

        if (!Ember.get(this, 'config.zoneId') && Ember.get(this, 'zoneChoices.length')) {
          Ember.set(this, 'config.zoneId', Ember.get(this, 'zoneChoices.firstObject.value'));
        }
      });
    },

    getDiskChoices(usage) {
      const {
        diskConfigSet = []
      } = this;
      return diskConfigSet.filter(d => d.DiskUsage === usage).map(d => {
        return {
          label: `clusterNew.tencenttke.disk.${d.DiskType}`,
          value: d.DiskType,
          maxDiskSize: d.MaxDiskSize,
          minDiskSize: d.MinDiskSize
        };
      });
    }

  });

  _exports.default = _default;
});