define("global-admin/security/accounts/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    hasRefreshProviderAccess: false,

    model() {
      return Ember.get(this, 'globalStore').findAll('user');
    },

    afterModel() {
      return this.globalStore.rawRequest({
        url: `users?limit=0`,
        method: 'GET'
      }).then(users => {
        if (Ember.get(users, 'body.actions.refreshauthprovideraccess')) {
          Ember.set(this, 'hasRefreshProviderAccess', true);
        }

        return;
      });
    },

    setupController(controller, model) {
      if (Ember.get(this, 'hasRefreshProviderAccess')) {
        controller.set('hasRefreshProviderAccess', true);
      }

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});