define("shared/pnap/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PNAP_API = 'api.phoenixnap.com';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),

    request(command) {
      let headers = {
        'Content-Type': 'application/json'
      };
      let url = `${Ember.get(this, 'app.proxyEndpoint')}/`;
      url += `${PNAP_API}/${command}`;
      return fetch(url, {
        headers
      }).then(res => {
        let json = res.json();
        return json;
      }).then(jsonObject => {
        return jsonObject;
      }).catch(err => {
        return JSON.parse(`{"message": "${err}", "validationErrors": ""}`);
      });
    }

  });

  _exports.default = _default;
});