define("global-admin/global-dns/providers/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model(params) {
      if (Ember.get(params, 'id')) {
        return this.globalStore.find('globaldnsprovider', params.id).then(resp => {
          if (resp) {
            return resp.clone();
          } else {
            return Ember.RSVP.reject('Global DNS Provider Not Found');
          }
        });
      } else {
        return this.initConfig(Ember.get(params, 'activeProvider') || 'route53');
      }
    },

    afterModel(model
    /* , transition */
    ) {
      let {
        members
      } = model;

      if (members) {
        return this.fetchMembers(model);
      }

      return model;
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (model && Ember.get(model, 'id')) {
        controller.set('mode', 'edit');
      }

      if (Ember.get(model, 'provider')) {
        controller.set('activeProvider', Ember.get(model, 'provider'));
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.setProperties(controller, {
          id: null,
          activeProvider: 'route53',
          mode: 'new'
        });
      }
    },

    queryParams: {
      id: {
        refreshModel: true
      },
      activeProvider: {
        refreshModel: true
      }
    },

    initConfig(configType = 'route53') {
      if (configType === 'route53') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'route53',
          route53ProviderConfig: this.globalStore.createRecord({
            type: 'route53ProviderConfig'
          })
        });
      } else if (configType === 'cloudflare') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'cloudflare',
          cloudflareProviderConfig: this.globalStore.createRecord({
            type: 'cloudflareProviderConfig'
          })
        });
      } else if (configType === 'alidns') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'alidns',
          alidnsProviderConfig: this.globalStore.createRecord({
            type: 'alidnsProviderConfig'
          })
        });
      }
    },

    fetchMembers(model) {
      let {
        members
      } = model;

      if (members) {
        const membersPromises = [];
        members.forEach(member => {
          if (Ember.get(member, 'userPrincipalId')) {
            membersPromises.push(this.globalStore.find('principal', member.userPrincipalId));
          } else if (Ember.get(member, 'groupPrincipalId')) {
            membersPromises.push(this.globalStore.find('principal', member.groupPrincipalId));
          }
        });
        return Ember.RSVP.all(membersPromises);
      }
    }

  });

  _exports.default = _default;
});