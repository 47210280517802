define("global-admin/security/accounts/groups/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    translationKey: 'accountsPage.groups.table.groupName',
    name: 'name',
    sort: ['name']
  }, {
    translationKey: 'accountsPage.groups.table.globalRole',
    name: 'globalRoleName',
    sort: ['globalRole.displayName']
  }];

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    sortBy: 'username',
    refreshing: false,
    hasRefreshProviderAccess: false,
    errors: null,
    headers: HEADERS,
    actions: {
      refreshAllTokens() {
        Ember.set(this, 'refreshing', true);
        this.globalStore.request({
          url: '/v3/users?action=refreshauthprovideraccess',
          method: 'POST',
          data: {}
        }).then(() => {
          const successTitle = this.intl.t('action.refreshAuthProviderAccess.allSuccess.title');
          const successMessage = this.intl.t('action.refreshAuthProviderAccess.allSuccess.message');
          this.growl.success(successTitle, successMessage);
        }).catch(err => {
          Ember.set(this, 'errors', [err.message]);
        }).finally(() => {
          Ember.set(this, 'refreshing', false);
        });
      }

    },
    parsedGroups: Ember.computed('model.globalRoleBindings.@each.{state,id,groupPrincipal}', 'model.groupPrincipals.@each.groupPrincipalId', function () {
      const {
        model: {
          globalRoleBindings,
          groupPrincipals
        }
      } = this; // Because we don't create a group when we associate a group with a GRB we have to individually fetch the groups from the auth provider
      // The list that is displayed on this page is a bit of a fudge and when you take an action on a "group" on this page the user will
      // actually take the action on the GRB. So just associate the GRB now and we can take action on this in the component

      const mutatedGroupPricipals = groupPrincipals.map(grp => {
        if (grp) {
          const filterdGrbs = globalRoleBindings.filterBy('groupPrincipalId', grp.id).filter(grb => {
            return _constants.default.REMOVEDISH_STATES.indexOf(grb.state) === -1;
          });
          const mappedGrbNamesIds = filterdGrbs.map(grb => ({
            groupRoleBindingName: grb.globalRole.displayName,
            groupRoleBindingId: grb.globalRoleId,
            globalRoleBinding: grb
          })).sortBy('groupRoleBindingName');

          if (filterdGrbs.length > 0) {
            Ember.setProperties(grp, {
              globalRoleBindings: filterdGrbs,
              mappedGroupRoleBindingNamesIds: mappedGrbNamesIds
            });
            return grp;
          }
        }
      });
      return mutatedGroupPricipals.compact();
    })
  });

  _exports.default = _default;
});