define("shared/session/service", ["exports", "ui/utils/browser-storage"], function (_exports, _browserStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_browserStorage.default, {
    backing: window.localStorage,
    app: Ember.inject.service(),

    // Multiple browser windows to the same URL will send 'storage' events
    // between each other when a setting changes.
    init() {
      this._super();

      $(window).on('storage', event => {
        // eslint-disable-line
        var key = event.originalEvent.key;
        var old = event.originalEvent.oldValue;
        var neu = event.originalEvent.newValue;

        if (old !== neu) {
          this.notifyPropertyChange(key); // @TODO-2.0
          // if ( key === C.SESSION.ACCOUNT_ID && old && neu && old !== neu )
          // {
          //  // If the active user changes, flee
          //  try {
          //    window.lc('application').send('logout');
          //  }
          //  catch (e) {
          //  }
          // }
        }
      });
    }

  });

  _exports.default = _default;
});