define("global-admin/components/new-edit-policy/component", ["exports", "ui/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_newOrEdit.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    model: null,
    readOnly: false,
    actions: {
      cancel() {
        this.goBack();
      },

      setLabels(labels) {
        let out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        Ember.set(this, 'model.labels', out);
      }

    },

    goBack() {
      this.get('router').transitionTo('global-admin.security.policies.index');
    },

    doneSaving() {
      this.goBack();
    }

  });

  _exports.default = _default;
});