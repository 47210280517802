define("shared/components/page-numbers/component", ["exports", "ember-cli-pagination/components/page-numbers", "shared/components/page-numbers/template"], function (_exports, _pageNumbers, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Component from '@ember/component';
  var _default = _pageNumbers.default.extend({
    layout: _template.default,

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});