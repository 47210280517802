define("global-admin/global-dns/entries/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    model(params) {
      let globaldns = null;
      const providers = this.globalStore.findAll('globaldnsprovider');
      const multiClusterApps = this.globalStore.findAll('multiclusterapp');
      const allProjects = this.scope.getAllProjects();
      const allClusters = this.scope.getAllClusters();

      if (Ember.get(params, 'id')) {
        return this.globalStore.find('globaldns', params.id).then(resp => {
          let match = resp;

          if (match) {
            globaldns = match.clone();
            return Ember.RSVP.hash({
              globaldns,
              providers,
              multiClusterApps,
              allProjects,
              allClusters
            });
          } else {
            return Ember.RSVP.reject('Global DNS Entry Not Found');
          }
        });
      } else {
        globaldns = this.globalStore.createRecord({
          type: 'globaldns',
          name: '',
          projectIds: []
        });
        return Ember.RSVP.hash({
          globaldns,
          providers,
          multiClusterApps,
          allProjects,
          allClusters
        });
      }
    },

    afterModel(model
    /* , transition */
    ) {
      let {
        providers
      } = model;
      let {
        members
      } = model.globaldns;

      if (Ember.get(providers, 'length') === 1 && !Ember.get(model, 'globaldns.providerId')) {
        this.initProviderId(model, providers);
      }

      if (members) {
        return this.fetchMembers(model);
      }

      return model;
    },

    setupController(controller, model) {
      let recordType = 'multi';
      let originalModel = null;
      let mode = 'new';

      if (Ember.get(model, 'globaldns.projectIds.length') > 0) {
        recordType = 'project';
      }

      if (Ember.get(model, 'globaldns.id')) {
        mode = 'edit';
        originalModel = Ember.get(model, 'globaldns').clone();
      }

      controller.setProperties({
        projectsToAddOnUpgrade: [],
        projectsToRemoveOnUpgrade: [],
        mode,
        originalModel,
        recordType
      });

      this._super(controller, model);
    },

    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.setProperties({
          projectsToAddOnUpgrade: [],
          projectsToRemoveOnUpgrade: [],
          originalModel: null,
          mode: 'new',
          recordType: 'multi'
        });
      }
    },

    initProviderId(model, providers) {
      Ember.set(model, 'globaldns.providerId', Ember.get(providers, 'firstObject.id'));
    },

    fetchMembers(model) {
      let {
        members
      } = model.globaldns;

      if (members) {
        const membersPromises = [];
        members.forEach(member => {
          if (Ember.get(member, 'userPrincipalId')) {
            membersPromises.push(this.globalStore.find('principal', member.userPrincipalId));
          } else if (Ember.get(member, 'groupPrincipalId')) {
            membersPromises.push(this.globalStore.find('principal', member.groupPrincipalId));
          }
        });
        return Ember.RSVP.all(membersPromises);
      }
    },

    queryParams: {
      id: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});