define("global-admin/clusters/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      const store = this.get('globalStore');
      return store.findAll('cluster').then(() =>
      /* resp */
      {
        const clusters = store.all('cluster');

        if (clusters.length > 0) {
          clusters.forEach(cluster => {
            cluster.store.findAll('etcdbackup');
          });
        }

        return {
          clusters
        };
      });
    }

  });

  _exports.default = _default;
});