define("shared/components/hover-dropdown/component", ["exports", "shared/components/hover-dropdown/template", "shared/utils/calculate-position", "jquery"], function (_exports, _template, _calculatePosition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    layout: _template.default,
    delay: 200,
    renderInPlace: true,
    publicDropdownApi: null,
    dropdownCloseDelay: 150,
    ddCloseDelayTimer: null,
    openDelay: Ember.computed.oneWay('delay'),
    closeDelay: Ember.computed.oneWay('delay'),

    init() {
      this._super(...arguments);

      this.router.on('routeWillChange', () =>
      /* transition */
      {
        if (!Ember.isEmpty(this.publicDropdownApi) && !Ember.isEmpty(Ember.get(this, 'publicDropdownApi.actions.close'))) {
          this.publicDropdownApi.actions.close();
          Ember.set(this, 'publicDropdownApi', null);
        }
      });
    },

    actions: {
      registerAPI(dd) {
        this.registerAPI(dd);
      },

      open(forContent, dropdown) {
        if (Ember.get(this, 'closeTimer') || this.isTransitioning()) {
          Ember.run.cancel(Ember.get(this, 'closeTimer'));
          Ember.set(this, 'closeTimer', null);
        } else {
          let openFn = () => {
            Ember.set(this, 'openTimer', null);
            this.openDropdown.call(this, forContent, dropdown);
          };

          let openDelay = Ember.get(this, 'openDelay');

          if (openDelay) {
            Ember.set(this, 'openTimer', Ember.run.later(openFn, openDelay));
          } else {
            openFn();
          }
        }
      },

      close(forContent, dropdown) {
        if (this.openTimer || this.isTransitioning()) {
          Ember.run.cancel(this.openTimer);
          Ember.set(this, 'openTimer', null);
        } else {
          let closeFn = () => {
            Ember.set(this, 'closeTimer', null);
            this.closeDropdown.call(this, forContent, dropdown);
          };

          let closeDelay = Ember.get(this, 'closeDelay');

          if (closeDelay) {
            Ember.set(this, 'closeTimer', Ember.run.later(closeFn, closeDelay));
          } else {
            closeFn();
          }
        }
      },

      prevent() {
        return false;
      },

      calculatePosition: _calculatePosition.default
    },

    openDropdown(forContent, dropdown) {
      if (forContent) {
        if (this.onBeforeOpen) {
          this.onBeforeOpen(dropdown);
        }
      }

      dropdown.actions.open();

      if (forContent) {
        if (this.onOpen) {
          this.onOpen(dropdown);
        }
      }
    },

    closeDropdown(forContent, dropdown, skipFocus = true) {
      if (forContent) {
        if (this.onBeforeClose) {
          this.onBeforeClose(dropdown);
        }
      } // signature - event, skipfocus


      dropdown.actions.close(null, skipFocus);

      if (forContent) {
        if (this.onClose) {
          this.onClose(dropdown);
        }
      }
    },

    isTransitioning() {
      if (this.router._routerMicrolib && this.router._routerMicrolib.activeTransition && this.router._routerMicrolib.activeTransition.isTransition) {
        return true;
      }

      return false;
    },

    registerAPI(publicDropdown) {
      Ember.set(this, 'publicDropdownApi', publicDropdown);
    },

    focusIn() {
      if (this.ddCloseDelayTimer) {
        clearTimeout(this.ddCloseDelayTimer);
      }
    },

    focusOut() {
      if (this.publicDropdownApi && this.publicDropdownApi.isOpen) {
        Ember.set(this, 'ddCloseDelayTimer', setTimeout(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'ddCloseDelayTimer', null);
          this.closeDropdown(null, this.publicDropdownApi);
        }, this.dropdownCloseDelay));
      }
    },

    keyUp(e) {
      const code = e.keyCode;
      const {
        publicDropdownApi
      } = this;

      if (!publicDropdownApi) {
        return;
      }

      let tabList = (0, _jquery.default)(`#ember-basic-dropdown-content-${publicDropdownApi.uniqueId} > LI > a:first-child`);
      let currentFocusIndex = tabList.index(e.target);

      switch (code) {
        case 27:
          {
            if (publicDropdownApi && publicDropdownApi.isOpen) {
              this.closeDropdown(null, publicDropdownApi, false);
            }

            break;
          }

        case 38:
          {
            // up
            let nextIndex = currentFocusIndex - 1;

            if (nextIndex >= tabList.length) {
              tabList.eq(tabList.length).focus();
            } else {
              tabList.eq(nextIndex).focus();
            }

            break;
          }

        case 40:
          {
            // down
            if (publicDropdownApi && publicDropdownApi.isOpen) {
              let nextIndex = currentFocusIndex + 1;

              if (nextIndex >= tabList.length) {
                tabList.eq(0).focus();
              } else {
                tabList.eq(nextIndex).focus();
              }
            } else if (publicDropdownApi && !publicDropdownApi.isOpen) {
              this.openDropdown(null, publicDropdownApi);
              Ember.run.later(() => {
                (0, _jquery.default)(`#ember-basic-dropdown-content-${publicDropdownApi.uniqueId} > LI > a:first-child`).first().focus();
              }, 10);
            }

            break;
          }

        default:
      }
    }

  });

  _exports.default = _default;
});