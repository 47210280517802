define("shared/mixins/catalog-upgrade", ["exports", "shared/mixins/upgrade-component", "ui/utils/parse-version"], function (_exports, _upgradeComponent, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PREFIX = 'cattle-global-data';
  const AVAILABLE = 'available';

  var _default = Ember.Mixin.create(_upgradeComponent.default, {
    scope: Ember.inject.service(),
    catalog: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    apps: null,
    latestVersion: null,
    templateId: null,
    templateVersion: null,
    checkUpdateStatus: Ember.on('init', Ember.observer('apps', function () {
      Ember.get(this, 'catalog').fetchTemplate(`${PREFIX}:${Ember.get(this, 'templateId')}`).then(template => {
        const toUpgrade = [];
        (Ember.get(this, 'apps') || []).forEach(app => {
          Ember.set(this, 'model', app);
          this.updateStatus();

          if (AVAILABLE === Ember.get(this, 'upgradeStatus')) {
            toUpgrade.push(app);
          }
        });
        Ember.set(this, 'toUpgrade', toUpgrade);

        if (template.labels) {
          Ember.set(this, 'templateLables', template.labels);
        }

        this.initAvailableVersions(template);
      });
    })),
    appVersion: Ember.computed('templateVersion', 'templateName', function () {
      const {
        templateVersion,
        templateName
      } = this;
      return `catalog://?catalog=system-library&template=${templateName}&version=${templateVersion}`;
    }),

    initAvailableVersions(template) {
      const apps = Ember.get(this, 'apps') || [];
      const links = Ember.get(template, 'versionLinks');
      const versions = Object.keys(links).filter(key => !!links[key]).map(key => ({
        label: key,
        value: key
      })).sort((a, b) => (0, _parseVersion.compare)(a.value, b.value));

      if (Ember.get(versions, 'length') === 0) {
        Ember.set(this, 'availableVersions', []);
        return;
      }

      if (Ember.get(apps, 'length')) {
        const currentVersion = Ember.get(apps, 'firstObject.externalIdInfo.version');
        const availableVersions = versions.filter(v => (0, _parseVersion.compare)(v.value, currentVersion) > 0);
        availableVersions.unshift({
          value: currentVersion,
          label: currentVersion
        });
        Ember.setProperties(this, {
          availableVersions,
          templateVersion: currentVersion
        });
      } else {
        Ember.setProperties(this, {
          availableVersions: versions,
          templateVersion: Ember.get(versions, 'lastObject.value')
        });
      }
    },

    upgradeAvailable: Ember.computed('toUpgrade', function () {
      const toUpgrade = Ember.get(this, 'toUpgrade') || [];
      return Ember.get(toUpgrade, 'length') > 0;
    }),
    actions: {
      upgrade() {
        const currentVersion = Ember.get(this, 'apps.firstObject.externalIdInfo.version');
        const templateVersion = Ember.get(this, 'templateVersion');

        if (!templateVersion || !currentVersion || templateVersion === currentVersion) {
          return;
        }

        Ember.set(this, 'availableVersions', Ember.get(this, 'availableVersions').slice(Ember.get(this, 'availableVersions').findIndex(v => v.value === templateVersion)));
        const requests = [];
        const apps = Ember.get(this, 'toUpgrade') || [];
        apps.forEach(app => {
          const externalInfo = Ember.get(app, 'externalIdInfo');
          requests.push(Ember.get(this, 'globalStore').rawRequest({
            url: `/v3/project/${Ember.get(app, 'projectId')}/apps/${Ember.get(app, 'id')}`,
            method: 'PUT',
            data: {
              projectId: Ember.get(app, 'projectId'),
              targetNamespace: Ember.get(app, 'targetNamespace'),
              externalId: Ember.get(app, 'externalId').replace(`version=${Ember.get(externalInfo, 'version')}`, `version=${templateVersion}`)
            }
          }));
        });
        Ember.set(this, 'toUpgrade', []);
        return Ember.RSVP.all(requests);
      }

    }
  });

  _exports.default = _default;
});