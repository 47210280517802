define("shared/k8s/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    app: Ember.inject.service(),
    kubernetesDashboard: Ember.computed('app.{clusterToken,kubernetesDashboard,projectToken}', 'scope.currentCluster.id', 'scope.currentProject.id', function () {
      let url = this.get('app.kubernetesDashboard').replace(this.get('app.projectToken'), this.get('scope.currentProject.id')).replace(this.get('app.clusterToken'), this.get('scope.currentCluster.id'));
      url += '#!/overview';
      return url;
    }),

    getInstanceToConnect() {
      let systemProject = this.get('scope.currentProject.cluster.systemProject');
      let inst;

      if (!systemProject) {
        return Ember.RSVP.reject('Unable to locate system environment');
      }

      return this.get('globalStore').rawRequest({
        url: systemProject.links.instances
      }).then(res => {
        inst = res.body.data.find(c => {
          return c.state === 'running' && c.labels && `${c.labels[_constants.default.LABEL.K8S_KUBECTL]}` === 'true';
        });

        if (inst) {
          return this.get('store').createRecord(inst);
        } else {
          return Ember.RSVP.reject('Unable to find running kubectl container');
        }
      });
    }

  });

  _exports.default = _default;
});