define("shared/components/modal-edit-cluster-template/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-edit-cluster-template/template", "shared/mixins/new-or-edit"], function (_exports, _modalBase, _template, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    modal: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    primaryResource: Ember.computed.alias('modal.modalOpts.model'),
    originalPrimaryResource: Ember.computed.alias('modal.modalOpts.model'),
    actions: {
      addAuthorizedPrincipal(member) {
        let {
          members = []
        } = this.primaryResource;

        if (!members) {
          members = [];
        }

        if (member) {
          members.pushObject(this.globalStore.createRecord(member));
        } else {
          members.pushObject(this.globalStore.createRecord({
            type: 'member'
          }));
        }

        Ember.set(this, 'primaryResource.members', members);
      },

      removeAuthorizedPrincipal(member) {
        let {
          members
        } = this.primaryResource;
        members.removeObject(member);
      },

      cancel() {
        this.modal.toggleModal();
      }

    },

    doneSaving() {
      this.modal.toggleModal();
    }

  });

  _exports.default = _default;
});