define("shared/components/textarea-autogrow/component", ["exports", "ui/utils/platform", "shared/mixins/intl-placeholder", "jquery"], function (_exports, _platform, _intlPlaceholder, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend(_intlPlaceholder.default, {
    intl: Ember.inject.service(),
    minHeight: 0,
    curHeight: null,
    maxHeight: 200,
    tagName: 'textarea',
    classNames: ['no-resize', 'no-ease'],
    attributeBindings: ['spellcheck'],
    classNameBindings: ['bg'],

    didInsertElement() {
      Ember.run.later(this, 'initHeights', 100);
    },

    changed: Ember.observer('value', function () {
      Ember.run.debounce(this, 'autoSize', 100);
    }),
    bg: Ember.computed('disabled', function () {
      if (Ember.get(this, 'disabled')) {
        return 'bg-disabled';
      }

      return '';
    }),
    isSmall: Ember.computed('isDestroyed', 'isDestroying', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      return (0, _jquery.default)().hasClass('input-sm');
    }),

    initHeights() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (this.get('minHeight') === 0) {
        this.set('minHeight', this.get('isSmall') ? 31 : 36);
      }

      Ember.run.next(() => {
        this.autoSize();
      });
      (0, _jquery.default)().on('paste', () => {
        Ember.run.later(this, 'autoSize', 100);
      });
    },

    autoSize() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let el = this.element;
      let $el = (0, _jquery.default)(el); // eslint-disable-line

      Ember.run(() => {
        $el.css('height', '1px');
        let border = parseInt($el.css('borderTopWidth'), 10) || 0 + parseInt($el.css('borderBottomWidth'), 10) || 0;
        let magic = (this.get('isSmall') ? -2 : 0) + (_platform.isGecko ? 1 : 2); // Sigh, but it's wrong without magic fudge

        let neu = Math.max(this.get('minHeight'), Math.min(el.scrollHeight + border + magic, this.get('maxHeight')));
        $el.css('overflowY', el.scrollHeight > neu ? 'auto' : 'hidden');
        $el.css('height', `${neu}px`);
        this.set('curHeight', neu);
      });
    }

  });

  _exports.default = _default;
});