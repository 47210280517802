define("shared/components/schema/input-pvc/component", ["exports", "shared/components/schema/input-pvc/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['form-control'],
    namespaceId: null,
    fiedl: null,
    value: null,
    pvcs: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'pvcs', Ember.get(this, 'store').all('persistentVolumeClaim'));
    },

    pvcChoices: Ember.computed('pvcs.@each.{name,state}', 'namespaceId', function () {
      return Ember.get(this, 'pvcs').filterBy('namespaceId', Ember.get(this, 'namespaceId')).map(v => {
        let label = Ember.get(v, 'displayName');
        const state = Ember.get(v, 'state');
        const disabled = false;

        if (disabled) {
          label += ` (${state})`;
        }

        return {
          label,
          disabled,
          value: Ember.get(v, 'name')
        };
      }).sortBy('label');
    })
  });

  _exports.default = _default;
});