define("shared/components/check-computed-override/component", ["exports", "shared/components/check-computed-override/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    applyClusterTemplate: null,
    clusterTemplateCreate: null,

    computedOverrideAllowed() {
      throw new Error('computedOverrideAllowed function required!');
    }

  });

  _exports.default = _default;
});