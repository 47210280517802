define("shared/components/storage-class/provisioner-vsphere-volume/component", ["exports", "shared/components/storage-class/provisioner-vsphere-volume/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELDS = [{
    id: 'diskformat',
    options: [{
      value: 'thin',
      label: 'thin',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.thin'
    }, {
      value: 'zeroedthick',
      label: 'zeroedthick',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.zeroedthick'
    }, {
      value: 'eagerzeroedthick',
      label: 'eagerzeroedthick',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.eagerzeroedthick'
    }]
  }, 'storagePolicyName', 'datastore', 'hostFailuresToTolerate', 'cachereservation', 'fstype'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'vsphere-volume',
    fields: FIELDS
  });

  _exports.default = _default;
});