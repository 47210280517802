define("shared/mixins/state-counts", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // defineStateCounts('arrangedInstances', 'instanceStates', 'instanceCountSort');
    defineStateCounts(inputKey, countsProperty, sortProperty) {
      // after a mixin is instantiated they seal the props which prevents us from pushing more objs into reservedKeys
      // BUT they dont freeze them so we can set it again. just clone it push the new values in and then set it
      var rkCln = this.get('reservedKeys').slice(0);
      rkCln.pushObjects([countsProperty, sortProperty]);
      this.set('reservedKeys', rkCln);
      Ember.defineProperty(this, countsProperty, Ember.computed(`${inputKey}.@each.displayState`, () => {
        let byName = [];
        let byColor = [];
        let good = 0;
        let notGood = 0;
        (this.get(inputKey) || []).sortBy('sortState').forEach(inst => {
          let color = inst.get('stateBackground');

          if (color === 'bg-muted') {
            color = 'bg-success';
          }

          if (color === 'bg-success') {
            good++;
          } else {
            notGood++;
          }

          let state = inst.get('displayState');
          let entry = byName.findBy('state', state);

          if (entry) {
            entry.count++;
          } else {
            entry = {
              state,
              color,
              count: 1
            };
            byName.push(entry);
          }

          entry = byColor.findBy('color', color);

          if (entry) {
            entry.count++;
          } else {
            entry = {
              color,
              count: 1
            };
            byColor.push(entry);
          }
        });
        return {
          byName,
          byColor,
          good,
          notGood
        };
      }));
      Ember.defineProperty(this, sortProperty, Ember.computed(countsProperty, `${inputKey}.[]`, () => {
        let colors = this.get(`${countsProperty}.byColor`);
        let success = (colors.findBy('bg-success') || {}).count + (colors.findBy('bg-muted') || {}).coun;
        let error = (colors.findBy('bg-error') || {}).count;
        let other = this.get(`${inputKey}.length`) - success - error;
        return _util.default.strPad(error, 6, '0') + _util.default.strPad(other, 6, '0') + _util.default.strPad(success, 6, '0');
      }));
    }

  });

  _exports.default = _default;
});