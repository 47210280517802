define("shared/components/storage-class/provisioner-azure-disk/component", ["exports", "shared/components/storage-class/provisioner-azure-disk/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KIND_OPTIONS = [{
    value: 'shared',
    translationKey: 'cruStorageClass.azure-disk.kind.shared'
  }, {
    value: 'dedicated',
    translationKey: 'cruStorageClass.azure-disk.kind.dedicated'
  }, {
    value: 'managed',
    translationKey: 'cruStorageClass.azure-disk.kind.managed'
  }];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    kindOptions: KIND_OPTIONS,
    skuName: null,
    location: null,
    storageAccount: null,
    storageaccounttype: null,
    kind: 'shared',
    provisioner: 'azure-disk',

    didReceiveAttrs() {
      const changes = {};
      let {
        parameters = {}
      } = this;
      const {
        storageaccounttype = '',
        kind = 'shared'
      } = parameters;
      changes['storageaccounttype'] = storageaccounttype;
      changes['kind'] = kind;
      Ember.setProperties(this, changes);
    },

    // registered in the StorageClassProvisioner mixin
    updateParams() {
      const {
        storageaccounttype,
        kind,
        out = {}
      } = this;

      if (storageaccounttype) {
        out['storageaccounttype'] = storageaccounttype;
      }

      if (kind) {
        out['kind'] = kind;
      }

      Ember.set(this, 'parameters', out);
    }

  });

  _exports.default = _default;
});