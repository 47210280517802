define("shared/components/copy-inline/component", ["exports", "ui/utils/platform", "shared/components/copy-inline/template"], function (_exports, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 1000;
  const DEFAULT_TEXT = 'copyToClipboard.tooltip';

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    model: null,

    /* Component Params*/
    buttonText: null,
    tooltipText: null,
    status: null,
    size: null,
    target: null,
    clipboardText: null,
    textChangedEvent: null,
    defaultText: DEFAULT_TEXT,

    init() {
      this._super(...arguments);

      this.set('model', new Object({
        tooltipText: this.get('defaultText')
      }));
    },

    actions: {
      alertSuccess() {
        let orig = this.get('model.tooltipText');
        this.setProperties({
          status: 'success',
          model: {
            tooltipText: 'copyToClipboard.copied'
          }
        });
        Ember.run.later(() => {
          this.setProperties({
            status: null,
            model: {
              tooltipText: orig
            }
          });
        }, DELAY);
      }

    },
    isSupported: Ember.computed('clipboardText.length', function () {
      return this.get('clipboardText.length') && (!_platform.isSafari || document.queryCommandSupported('copy'));
    }),

    mouseEnter() {
      this.set('model', new Object({
        tooltipText: this.get('defaultText')
      }));
    }

  });

  _exports.default = _default;
});