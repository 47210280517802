define("shared/mixins/endpoint-ports", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    displayEndpoints: Ember.computed('publicEndpoints.@each.{address,port,protocol}', function () {
      let parts = [];
      const endpoints = (Ember.get(this, 'publicEndpoints') || []).sort(_util.default.compareDisplayEndpoint);
      endpoints.forEach(endpoint => {
        if (!Ember.get(endpoint, 'isReady')) {
          return;
        }

        if (Ember.get(endpoint, 'isTcpish')) {
          parts.push(`${'<span>' + '<a target="_blank" rel="noreferrer nofollow noopener" href="'}${_util.default.escapeHtml(Ember.get(endpoint, 'linkEndpoint'))}">${_util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint'))}</a>` + `</span>`);
        } else {
          parts.push(`<span>${_util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint'))}</span>`);
        }
      });
      let pub = parts.join(', ');

      if (pub) {
        return pub.htmlSafe();
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});