define("ember-engines/-private/route-ext", [], function () {
  "use strict";

  /*
    Creates an aliased form of a method that properly resolves external routes.
  */
  function externalAlias(methodName) {
    return function _externalAliasMethod(routeName, ...args) {
      let externalRoute = Ember.getOwner(this)._getExternalRoute(routeName);

      let router = this._router || this.router;
      return router[methodName](externalRoute, ...args);
    };
  }

  Ember.Route.reopen({
    transitionToExternal: externalAlias('transitionTo'),
    replaceWithExternal: externalAlias('replaceWith')
  });
});