define("global-admin/security/accounts/new/controller", ["exports", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    name: 'active',
    sort: ['active'],
    translationKey: 'accountsPage.detail.table.headers.active',
    width: 125
  }, {
    name: 'name',
    sort: ['name'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }];

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    sortBy: 'name',
    globalRoleSave: null,
    canUserLogIn: null,
    globalRoleSaveError: false,
    password: '',
    headers: HEADERS,
    primaryResource: Ember.computed.alias('model.account'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'doSave', this._doSave.bind(this));
    },

    actions: {
      cancel() {
        this.transitionToRoute('security.accounts.users');
      },

      setGRError() {
        Ember.set(this, 'globalRoleSaveError', true);
      },

      setGlobalRoleSave(fn) {
        Ember.set(this, 'globalRoleSave', fn);
      },

      setValidateGlobalRoles(fn) {
        Ember.set(this, 'canUserLogIn', fn);
      }

    },
    validateDescription: Ember.computed(function () {
      return Ember.get(this, 'settings').get(_constants.default.SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    }),
    users: Ember.computed('model.users.@each.{id,state}', function () {
      const users = Ember.get(this, 'model.users');
      return users;
    }),
    roles: Ember.computed('model.globalRoles.[]', function () {
      return Ember.get(this, 'model.globalRoles').map(grb => {
        return {
          name: Ember.get(grb, 'name'),
          active: false,
          globalId: Ember.get(grb, 'id')
        };
      });
    }),
    doesExist: Ember.computed('users.@each.{username,id,state}', 'model.account.username', function () {
      let users = Ember.get(this, 'users');
      let account = Ember.get(this, 'model.account');

      if (users.findBy('username', account.get('username'))) {
        return true;
      }

      return false;
    }),

    validate() {
      var errors = [];

      if (this.canUserLogIn && !this.canUserLogIn()) {
        errors.push(this.intl.t('formGlobalRoles.loginError', {
          type: this.intl.t('generic.user')
        }));
      }

      if ((Ember.get(this, 'model.account.username') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.usernameReq'));
      }

      if (this.doesExist && !this.globalRoleSaveError) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.usernameInExists'));
      }

      if ((Ember.get(this, 'password') || '').trim().length === 0 && !this.globalRoleSaveError) {
        errors.push(Ember.get(this, 'intl').t('accountsPage.new.errors.pwReq'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      } else {
        Ember.set(this, 'errors', null);
      }

      return true;
    },

    willSave() {
      Ember.set(this, 'model.account.password', Ember.get(this, 'password'));
      Ember.set(this, 'errors', null);
      var ok = this.validate();
      return ok;
    },

    didSave() {
      return this.globalRoleSave();
    },

    doneSaving() {
      this.transitionToRoute('security.accounts.users');
    },

    _doSave(opt) {
      if (Ember.get(this, 'doesExist') && this.globalRoleSaveError) {
        // we shouldn't hit this since I changed the password to be a static var instead of directly on th model.account.password and set it on save BUT if the password was cleared we don't have to reset it
        if ((Ember.get(this, 'password') || '').trim().length === 0) {
          return Ember.RSVP.resolve(Ember.get(this, 'primaryResource'));
        } else {
          // else we should make sure there password is ALWAYS set to what they expect
          return Ember.get(this, 'primaryResource').save(opt).then(newData => {
            this.mergeResult(newData);
            return this.primaryResource.doAction('setpassword', {
              newPassword: this.password
            });
          });
        }
      } else {
        return Ember.get(this, 'primaryResource').save(opt).then(newData => {
          return this.mergeResult(newData);
        });
      }
    }

  });

  _exports.default = _default;
});