define("ember-engines/-private/engine-ext", ["ember-engines/components/link-to-component", "ember-engines/components/link-to-external"], function (_linkToComponent, _linkToExternal) {
  "use strict";

  Ember.Engine.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);

      if (!(this instanceof Ember.Application)) {
        registry.register('component:link-to', _linkToComponent.default);
        registry.register('component:link-to-external', _linkToExternal.default);
      }

      return registry;
    }

  });
});