define("global-admin/application/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),

    model() {
      return Ember.get(this, 'scope').startSwitchToGlobal(false);
    },

    setupController()
    /* controller, model*/
    {
      this._super(...arguments);

      Ember.get(this, 'scope').finishSwitchToGlobal();
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('error', null);
      }
    }

  });

  _exports.default = _default;
});