define("shared/growl/service", ["exports", "ui/utils/errors", "jgrowl", "shared/utils/util"], function (_exports, _errors, _jgrowl, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.initjGrowlDefaults();
    },

    initjGrowlDefaults() {
      let {
        defaults
      } = $.jGrowl;
      Ember.setProperties(defaults, {
        pool: 6,
        closeTemplate: '<i class="icon icon-x"></i>',
        closerTemplate: `<div><button type="button" class="btn bg-info btn-sm btn-block">Dismiss All Notifications</button></div>`
      });
    },

    close() {
      $("div.jGrowl").jGrowl("close"); // eslint-disable-line
    },

    raw(title, body, opt) {
      opt = opt || {};

      if (title) {
        opt.header = title;
      }

      return $.jGrowl((0, _util.escapeHtml)(body), opt);
    },

    success(title, body) {
      this.raw(title, body, {
        theme: 'success'
      });
    },

    message(title, body) {
      this.raw(title, body, {
        theme: 'message'
      });
    },

    error(title, body) {
      this.raw(title, body, {
        sticky: true,
        theme: 'error'
      });
    },

    fromError(title, err) {
      if (!err && typeof title === 'object') {
        err = title;
        title = 'Error';
      }

      var body = _errors.default.stringify(err);

      this.error(title, body);
    }

  });

  _exports.default = _default;
});