define("nodes/components/driver-amazonec2/component", ["exports", "jquery", "shared/mixins/node-driver", "nodes/components/driver-amazonec2/template", "shared/utils/amazon", "shared/utils/util"], function (_exports, _jquery, _nodeDriver, _template, _amazon, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let RANCHER_GROUP = 'rancher-nodes';

  var _default = Ember.Component.extend(_nodeDriver.default, {
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    driverName: 'amazonec2',
    clients: null,
    allSubnets: null,
    allSecurityGroups: null,
    selectedSecurityGroup: null,
    defaultSecurityGroup: null,
    allKmsKeys: null,
    defaultSecurityGroupName: RANCHER_GROUP,
    whichSecurityGroup: 'default',
    instanceTypes: _amazon.INSTANCE_TYPES,
    regionChoices: _amazon.REGIONS,
    step: 1,
    tags: null,
    editing: false,
    loadFailedKmsKeys: false,
    config: Ember.computed.alias('model.amazonec2Config'),
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        allKmsKeys: [],
        allSubnets: [],
        clients: {}
      });
      let cur = Ember.get(this, 'config.securityGroup');

      if (cur === '') {
        // TODO 2.0 should this be null 403 Vince/Wes/Daishan
        Ember.setProperties(this, {
          whichSecurityGroup: 'default',
          selectedSecurityGroup: null
        });
      } else {
        Ember.setProperties(this, {
          whichSecurityGroup: 'custom',
          selectedSecurityGroup: cur
        });
      }

      const tagsString = Ember.get(this, 'config.tags');

      if (tagsString) {
        const array = tagsString.split(',');
        const tags = {};

        for (let i = 0; i < array.length - 1; i = i + 2) {
          tags[array[i]] = array[i + 1];
        }

        Ember.set(this, 'tags', tags);
      }

      if (Ember.isEmpty(Ember.get(this, 'config.httpEndpoint'))) {
        Ember.set(this, 'config.httpEndpoint', 'enabled');
      }

      if (Ember.isEmpty(Ember.get(this, 'config.httpTokens'))) {
        Ember.set(this, 'config.httpTokens', 'optional');
      }
    },

    willDestroyElement() {
      Ember.setProperties(this, {
        step: 1,
        machineId: null,
        clients: null,
        allSubnets: null,
        allSecurityGroups: null,
        whichSecurityGroup: 'default'
      });
    },

    actions: {
      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'primaryResource.cloudCredentialId', Ember.get(cred, 'id'));
          this.send('awsLogin');
        }
      },

      async awsLogin(cb) {
        let self = this;
        Ember.set(this, 'errors', null);
        let subnets = [];
        let rName = Ember.get(this, 'config.region');

        if (rName.includes('me-')) {
          Ember.set(this, 'config.instanceType', 't3.medium');
        }

        const auth = {
          accessKeyId: (0, _util.randomStr)(),
          secretAccessKey: (0, _util.randomStr)(),
          region: rName,
          httpOptions: {
            cloudCredentialId: Ember.get(this, 'model.cloudCredentialId')
          }
        };
        await this.loadKMSKeys(auth); // have to have something in there before we describe the request even though we are going to replace with the actual cred id

        let ec2 = new AWS.EC2(auth);
        let vpcNames = {};
        let vpcTags = {};
        ec2.describeVpcs({}, (err, vpcs) => {
          if (err) {
            let errors = Ember.get(self, 'errors') || [];
            errors.pushObject(err);
            Ember.set(this, 'errors', errors);

            if (cb && typeof cb === 'function') {
              cb();
            }

            return;
          }

          vpcs.Vpcs.forEach(vpc => {
            vpcNames[vpc.VpcId] = (0, _amazon.nameFromResource)(vpc, 'VpcId');
            vpcTags[vpc.VpcId] = (0, _amazon.tagsFromResource)(vpc);
          });
          ec2.describeSubnets({}, (err, data) => {
            if (err) {
              let errors = Ember.get(self, 'errors') || [];
              errors.pushObject(err);
              Ember.set(this, 'errors', errors);

              if (cb && typeof cb === 'function') {
                cb();
              }

              return;
            }

            Ember.set(this, `clients.${rName}`, ec2);
            data.Subnets.forEach(subnet => {
              if ((subnet.State || '').toLowerCase() !== 'available') {
                return;
              }

              subnets.pushObject(Ember.Object.create({
                subnetName: (0, _amazon.nameFromResource)(subnet, 'SubnetId'),
                subnetId: subnet.SubnetId,
                subnetTags: (0, _amazon.tagsFromResource)(subnet),
                vpcName: vpcNames[subnet.VpcId] || subnet.VpcId,
                vpcId: subnet.VpcId,
                vpcTags: vpcTags[subnet.VpcId] || [],
                zone: subnet.AvailabilityZone,
                region: rName
              }));
            });
            Ember.setProperties(this, {
              'allSubnets': subnets,
              'step': 2
            });

            if (cb && typeof cb === 'function') {
              cb();
            }
          });
        });
      },

      selectSubnet(cb) {
        Ember.set(this, 'errors', null);

        if (!Ember.get(this, 'selectedZone')) {
          Ember.set(this, 'errors', ['Select an Availability Zone']);

          if (cb && typeof cb === 'function') {
            cb();
          }

          return;
        }

        if (!Ember.get(this, 'selectedSubnet')) {
          Ember.set(this, 'errors', ['Select a VPC or Subnet']);

          if (cb && typeof cb === 'function') {
            cb();
          }

          return;
        }

        let ec2 = Ember.get(this, `clients.${Ember.get(this, 'config.region')}`);
        let filter = {
          Name: 'vpc-id',
          Values: [Ember.get(this, 'config.vpcId')]
        };
        ec2.describeSecurityGroups({
          Filters: [filter]
        }, (err, data) => {
          if (err) {
            Ember.set(this, 'errors', [err]);

            if (cb && typeof cb === 'function') {
              cb();
            }

            return;
          }

          let groups = [];
          data.SecurityGroups.forEach(group => {
            let tags = {}; // Skip launch-wizard groups

            if ((group.GroupName || '').match(/^launch-wizard-.*$/)) {
              return;
            }

            (group.Tags || []).forEach(tag => {
              tags[tag.Key] = tag.Value;
            });
            let obj = {
              id: group.GroupId,
              name: group.GroupName,
              description: group.Description
            };
            groups.push(obj);
          });
          Ember.setProperties(this, {
            allSecurityGroups: groups,
            step: 3
          });
        });
      },

      multiSecurityGroupSelect() {
        let options = Array.prototype.slice.call((0, _jquery.default)('.existing-security-groups')[0], 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'selectedSecurityGroup', selectedOptions);
      },

      selectSecurityGroup(cb) {
        Ember.set(this, 'errors', null);

        if (Ember.get(this, 'isCustomSecurityGroup')) {
          Ember.set(this, 'config.securityGroup', Ember.get(this, 'selectedSecurityGroup'));
        } else {
          Ember.set(this, 'config.securityGroup', '');
        }

        Ember.setProperties(this, {
          step: 4
        });

        if (cb && typeof cb === 'function') {
          cb();
        }
      }

    },
    resetKms: Ember.observer('config.encryptEbsVolume', function () {
      if (!Ember.get(this, 'config.encryptEbsVolume')) {
        Ember.set(this, 'config.kmsKey', null);
      }
    }),
    tagsDidChange: Ember.observer('tags', function () {
      const array = [];
      const tags = Ember.get(this, 'tags') || {};
      Object.keys(tags).forEach(key => {
        array.push(key);
        array.push(tags[key]);
      });
      Ember.set(this, 'config.tags', array.join(','));
    }),
    stepDidChange: Ember.observer('context.step', function () {
      Ember.run.scheduleOnce('afterRender', this, this.setScroll);
    }),
    selectedZone: Ember.computed('config.{region,zone}', {
      get() {
        let config = Ember.get(this, 'config');

        if (Ember.get(config, 'region') && Ember.get(config, 'zone')) {
          return Ember.get(config, 'region') + Ember.get(config, 'zone');
        } else {
          return null;
        }
      },

      set(key, val) {
        let config = Ember.get(this, 'config');
        Ember.setProperties(config, {
          region: val.substr(0, val.length - 1),
          zone: val.substr(val.length - 1)
        });
        let selectedSubnet = Ember.get(this, 'selectedSubnet');

        if (Ember.get(this, 'subnetChoices').filterBy('value', selectedSubnet).length === 0) {
          Ember.setProperties(config, {
            region: val.substr(0, val.length - 1),
            zone: val.substr(val.length - 1),
            vpcId: null,
            subnetId: null
          });
        }

        if (Ember.get(config, 'region') && Ember.get(config, 'zone')) {
          return Ember.get(config, 'region') + Ember.get(config, 'zone');
        } else {
          return null;
        }
      }

    }),
    zoneChoices: Ember.computed('allSubnets.@each.zone', 'config.region', 'config.zone.length', function () {
      const choices = (Ember.get(this, 'allSubnets') || []).map(subnet => {
        return Ember.get(subnet, 'zone');
      }).sort().uniq();

      if (choices.length) {
        if (Ember.get(this, 'config.zone.length')) {
          Ember.set(this, 'selectedZone', `${Ember.get(this, 'config.region')}${Ember.get(this, 'config.zone')}`);
        } else {
          Ember.set(this, 'selectedZone', choices[0]);
        }
      }

      return choices;
    }),
    subnetChoices: Ember.computed('selectedZone', 'allSubnets.@each.{subnetId,vpcId,zone}', function () {
      let out = [];
      let seenVpcs = [];
      (Ember.get(this, 'allSubnets') || []).filterBy('zone', Ember.get(this, 'selectedZone')).forEach(subnet => {
        let vpcName = Ember.get(subnet, 'vpcName');
        let vpcId = Ember.get(subnet, 'vpcId');
        let vpcTags = Ember.get(subnet, 'vpcTags');
        let subnetId = Ember.get(subnet, 'subnetId');
        let subnetName = Ember.get(subnet, 'subnetName');
        let subnetTags = Ember.get(subnet, 'subnetTags');

        if (seenVpcs.indexOf(vpcId) === -1) {
          seenVpcs.pushObject(vpcId);
          out.pushObject({
            sortKey: vpcId,
            label: vpcName,
            value: vpcId,
            isVpc: true,
            tags: vpcTags
          });
        }

        out.pushObject({
          sortKey: `${vpcId} ${subnetName}`,
          label: subnetName,
          value: subnetId,
          isVpc: false,
          tags: subnetTags
        });
      });
      return out.sortBy('sortKey');
    }),
    selectedSubnet: Ember.computed('config.{subnetId,vpcId}', {
      set(key, val) {
        let config = Ember.get(this, 'config');

        if (arguments.length > 1) {
          if (val && val.length) {
            if (val.indexOf('vpc-') === 0) {
              Ember.setProperties(config, {
                vpcId: val,
                subnetId: null
              });
            } else {
              let subnet = this.subnetById(val);
              Ember.setProperties(config, {
                vpcId: subnet.vpcId,
                subnetId: subnet.subnetId
              });
            }
          } else {
            Ember.setProperties(config, {
              vpcId: null,
              subnetId: null
            });
          }
        }

        return Ember.get(config, 'subnetId') || Ember.get(config, 'vpcId');
      },

      get() {
        let config = Ember.get(this, 'config');
        return Ember.get(config, 'subnetId') || Ember.get(config, 'vpcId');
      }

    }),

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: 'amazonec2Config',
        region: 'us-west-2',
        instanceType: 't2.medium',
        securityGroup: '',
        zone: 'a',
        rootSize: '16'
      });
      Ember.set(this, 'model.amazonec2Config', config);
    },

    validate() {
      let errors = [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    subnetById(id) {
      return (Ember.get(this, 'allSubnets') || []).filterBy('subnetId', id)[0];
    },

    async loadKMSKeys(auth) {
      try {
        const kmsKeys = await this.listKMSKeys(auth);
        return Ember.RSVP.resolve(Ember.set(this, 'allKmsKeys', kmsKeys));
      } catch (err) {
        // console.warn('Unable to load KMS Keys.', err); // eslint-disable-line
        // node template owners MAY not have access to KMS keys via IAM so dont kill everything because they dont
        // its not required
        Ember.set(this, 'loadFailedKmsKeys', true);
        return Ember.RSVP.resolve();
      }
    },

    listKMSKeys(auth) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const KMS = new AWS.KMS(auth);
        KMS.listKeys({}, (err, data) => {
          if (err) {
            // console.warn(err, err.stack);
            return reject(err);
          }

          return resolve(data.Keys);
        });
      });
    },

    setScroll() {
      document.body.scrollTop = document.body.scrollHeight;
    }

  });

  _exports.default = _default;
});