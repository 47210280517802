define("shared/access/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    settings: Ember.inject.service(),
    session: Ember.inject.service(),
    shibbolethAuth: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    app: Ember.inject.service(),
    me: null,
    userCode: null,
    firstLogin: null,
    // These are set by authenticated/route
    // Is access control enabled
    enabled: true,
    // @TODO-2.0 remove this, always enabled
    // What kind of access control
    provider: null,
    // @TODO-2.0 remove this, and how do i check?
    providers: null,
    principal: null,
    // Are you an admin
    admin: Ember.computed.alias('me.hasAdmin'),
    mustChangePassword: Ember.computed.alias('me.mustChangePassword'),
    // The identity from the session isn't an actual identity model...
    identity: null,
    identityObsvr: Ember.on('init', Ember.observer(`session.${_constants.default.SESSION.IDENTITY}`, function () {
      var obj = this.get(`session.${_constants.default.SESSION.IDENTITY}`) || {};
      obj.type = 'identity';
      this.set('identity', this.get('globalStore').createRecord(obj));
    })),

    testAuth() {
      // make a call to api base because it is authenticated
      return this.get('globalStore').rawRequest({
        url: ''
      }).then(() =>
      /* xhr*/
      {
        // Auth token still good
        return Ember.RSVP.resolve('Auth Succeeded');
      }, () =>
      /* err */
      {
        // Auth token expired
        return Ember.RSVP.reject('Auth Failed');
      });
    },

    detect() {
      const globalStore = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        pl: globalStore.request({
          url: `settings/${_constants.default.SETTING.PL}`
        }),
        firstSetting: globalStore.request({
          url: `settings/${_constants.default.SETTING.FIRST_LOGIN}`
        }),
        providers: globalStore.request({
          url: '/v3-public/authProviders'
        })
      }).then(({
        providers,
        pl,
        firstSetting
      }) => {
        if (providers && Ember.get(providers, 'length')) {
          Ember.set(this, 'providers', providers);

          if (Ember.get(providers, 'length') === 1) {
            Ember.set(this, 'provider', Ember.get(providers, 'firstObject.id'));
          }
        } else {
          Ember.set(this, 'providers', []);
        }

        if (pl) {
          Ember.get(this, 'settings').notifyPropertyChange(_constants.default.SETTING.PL);
        }

        Ember.set(this, 'firstLogin', `${firstSetting.value}` === 'true');
        return this.loadMe();
      }).catch(err => {
        Ember.run.next(() => {
          Ember.set(this, 'app.initError', err && err.message ? err : {
            message: 'No response received'
          });
        });
        Ember.set(this, 'providers', []);
      });
    },

    loadMe() {
      const globalStore = Ember.get(this, 'globalStore');
      return globalStore.request({
        url: 'users?me=true'
      }).then(users => {
        const me = Ember.get(users, 'firstObject');
        Ember.set(this, 'me', me);
        return me;
      }).catch(err => {
        return err;
      });
    },

    shibbolethConfigured(token) {
      let rv = false;

      if ((token.authProvider || '') === 'shibbolethconfig' && token.userIdentity) {
        rv = true;
      }

      return rv;
    },

    login(providerId, body) {
      body.description = _constants.default.SESSION.DESCRIPTION;
      body.responseType = 'cookie';
      body.ttl = _constants.default.SESSION.TTL;
      body.labels = {
        'ui-session': 'true'
      };
      let url;
      let provider = (Ember.get(this, 'providers') || []).findBy('id', providerId);

      if (provider) {
        url = provider.actionLinks.login;
      } else {
        return Ember.RSVP.reject({
          type: 'error',
          message: 'Provider config not found'
        });
      }

      console.log('Logging into', url);
      let req = this.get('globalStore').rawRequest({
        method: 'POST',
        url,
        data: body
      }).then(() => {
        return this.loadMe().catch(res => {
          let err;

          try {
            err = res.body;
          } catch (e) {
            console.log('Error loading user', e, res);
            err = {
              type: 'error',
              message: 'Error loading user'
            };
          }

          return Ember.RSVP.reject(err);
        });
      }).catch(res => {
        let err;

        try {
          err = res.body;
        } catch (e) {
          console.log('Error logging in', e, res);
          err = {
            type: 'error',
            message: 'Error logging in'
          };
        }

        return Ember.RSVP.reject(err);
      });
      return req;
    },

    clearToken() {
      return this.get('globalStore').rawRequest({
        url: 'tokens?action=logout',
        method: 'POST'
      }).then(() => {
        return true;
      });
    },

    clearSessionKeys(all) {
      if (all === true) {
        this.get('session').clear();
      } else {
        var values = {};

        _constants.default.TOKEN_TO_SESSION_KEYS.forEach(key => {
          values[key] = undefined;
        });

        this.get('session').setProperties(values);
      }

      this.get('cookies').remove(_constants.default.COOKIE.TOKEN);
    },

    allows(resource, permission, scope) {
      // console.log('rbac-allows',resource,permission,scope);
      if (!resource) {
        // console.log('rbac-result 1 false');
        return false;
      }

      scope = scope ? scope : 'global';
      permission = permission ? permission : 'list';

      if (!Ember.isArray(resource)) {
        resource = [resource];
      }

      const store = Ember.get(this, `${scope}Store`);

      if (!store) {
        // console.log('rbac-result 2 false');
        return false;
      }

      if (permission === 'list') {
        // console.log('rbac-result 3',!!resource.some(r => store.canList(r)));
        return resource.some(r => store.canList(r));
      } else if (permission === 'create') {
        // console.log('rbac-result 4',!!resource.some(r => store.canCreate(r)));
        return resource.some(r => store.canCreate(r));
      } // console.log('rbac-result 5 false');


      return false;
    }

  });

  _exports.default = _default;
});