define("shared/mixins/view-new-edit", ["exports", "shared/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EDIT = _exports.NEW = _exports.VIEW = void 0;
  const VIEW = 'view';
  _exports.VIEW = VIEW;
  const NEW = 'new';
  _exports.NEW = NEW;
  const EDIT = 'edit';
  _exports.EDIT = EDIT;

  var _default = Ember.Mixin.create(_newOrEdit.default, {
    intl: Ember.inject.service(),
    titleKey: null,
    inlineEdit: false,
    isView: Ember.computed.equal('mode', VIEW),
    isNew: Ember.computed.equal('mode', NEW),
    isEdit: Ember.computed.equal('mode', EDIT),
    notView: Ember.computed.or('isNew', 'isEdit'),
    editing: Ember.computed.alias('notView'),
    actions: {
      inlineEdit() {
        Ember.set(this, 'mode', EDIT);
        Ember.set(this, 'inlineEdit', true);
      },

      viewEditCancel() {
        if (Ember.get(this, 'inlineEdit')) {
          Ember.set(this, 'inlineEdit', false);
          Ember.set(this, 'mode', VIEW);
        } else {
          if (this.cancel) {
            this.cancel();
          }
        }
      }

    },
    isClone: Ember.computed('mode', 'primaryResource.{id,name}', function () {
      const pr = Ember.get(this, 'primaryResource');
      const created = pr.hasOwnProperty('created');
      const tpof = typeof pr.created;
      let isClone = false;

      if (created && (tpof !== null || tpof !== undefined)) {
        isClone = true;
      }

      return isClone;
    }),
    title: Ember.computed('mode', 'originalModel.displayName', 'primaryResource.displayName', 'titleKey', function () {
      const prefix = Ember.get(this, 'titleKey');
      const mode = Ember.get(this, 'mode');
      const intl = Ember.get(this, 'intl');
      let name = Ember.get(this, 'originalModel.displayName') || Ember.get(this, 'primaryResource.displayName') || '';
      return intl.t(`${prefix}.${mode}`, {
        name
      });
    }),

    doneSaving() {
      if (this.done) {
        this.done();
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});