define("shared/components/form-ingress-backends/component", ["exports", "shared/components/form-ingress-backends/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    ingress: null,
    rule: null,
    isDefault: null,
    editing: true,
    pathArray: null,

    init() {
      this._super(...arguments);

      this.initPathArray();
    },

    didInsertElement() {
      if (Ember.get(this, 'editing') && Ember.get(this, 'pathArray.length') === 0) {
        this.send('addPath', 'workload');
      }
    },

    actions: {
      addPath(backendType) {
        Ember.get(this, 'pathArray').pushObject({
          backendType,
          targetPort: '',
          serviceId: '',
          path: ''
        });
      },

      removePath(path) {
        Ember.get(this, 'pathArray').removeObject(path);
      }

    },
    pathsChanged: Ember.observer('pathArray.@each.{path,backendType,targetPort,serviceId}', 'isDefault', function () {
      if (Ember.get(this, 'isDefault')) {
        this.setDefaultBackend();
      } else {
        this.setPaths();
      }
    }),
    servicesDidChange: Ember.observer('pathArray.@each.{service}', function () {
      const pathArray = Ember.get(this, 'pathArray');
      pathArray.forEach(path => {
        const backendType = Ember.get(path, 'backendType');
        const targetPort = Ember.get(path, 'targetPort');
        const availablePorts = Ember.get(path, 'service.availablePorts') || [];
        const hasPorts = Ember.get(path, 'service.availablePorts.length') > 0;

        if (backendType === 'service' && hasPorts && !availablePorts.find(p => p.port === targetPort)) {
          Ember.set(path, 'targetPort', Ember.get(path, 'service.availablePorts.firstObject.port'));
        }
      });
    }),
    hasServiceTargets: Ember.computed('pathArray.@each.backendType', function () {
      return !!Ember.get(this, 'pathArray').findBy('backendType', 'service');
    }),

    initPathArray() {
      const pathArray = [];
      const paths = Ember.get(this, 'rule.paths') || [];
      paths.forEach(path => {
        if (Ember.get(path, 'serviceId')) {
          pathArray.pushObject(Ember.get(this, 'store').createRecord({
            type: 'httpingresspath',
            backendType: 'service',
            targetPort: `${Ember.get(path, 'targetPort') || ''}`,
            serviceId: Ember.get(path, 'serviceId').replace('/', ':'),
            path: Ember.get(path, 'path')
          }));
        } else if (Ember.get(path, 'workloadIds')) {
          Ember.get(path, 'workloadIds').forEach(workload => {
            pathArray.pushObject({
              backendType: 'workload',
              targetPort: Ember.get(path, 'targetPort'),
              serviceId: workload,
              path: Ember.get(path, 'path')
            });
          });
        }
      });
      Ember.set(this, 'pathArray', pathArray);
    },

    setDefaultBackend() {
      const pathArray = Ember.get(this, 'pathArray');
      let defaultBackend = {
        workloadIds: []
      };
      pathArray.forEach(path => {
        const backendType = Ember.get(path, 'backendType');
        const serviceId = Ember.get(path, 'serviceId');
        const targetPort = Ember.get(path, 'targetPort');

        if (backendType === 'service') {
          defaultBackend.serviceId = serviceId;
        } else if (backendType === 'workload') {
          defaultBackend.workloadIds.pushObject(serviceId);
        }

        defaultBackend.targetPort = targetPort;
      });
      Ember.set(this, 'ingress.defaultBackend', defaultBackend);
    },

    setPaths() {
      const pathArray = Ember.get(this, 'pathArray');
      const paths = [];
      pathArray.forEach(item => {
        const backendType = Ember.get(item, 'backendType');
        const path = Ember.get(item, 'path');
        const serviceId = Ember.get(item, 'serviceId');
        const targetPort = Ember.get(item, 'targetPort');

        if (backendType === 'service') {
          paths.pushObject({
            path,
            serviceId,
            targetPort
          });
        } else if (backendType === 'workload') {
          paths.pushObject({
            path,
            workloadIds: [serviceId],
            targetPort
          });
        }
      });
      Ember.set(this, 'rule.paths', paths);
    }

  });

  _exports.default = _default;
});