define("global-admin/mixins/saml-auth", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    saml: Ember.inject.service(),
    errors: null,
    redirectUrl: null,
    saving: false,
    saved: false,
    testing: false,
    disableAuth: true,
    urlError: null,
    urlWarning: false,
    urlInvalid: false,
    protocol: 'https://',
    authConfig: Ember.computed.alias('model.authConfig'),
    providerNamePath: null,
    providerName: null,

    init() {
      this._super(...arguments);

      let serverUrl = this.settings.get(_constants.default.SETTING.SERVER_URL);

      if (this.authConfig && Ember.isEmpty(this.authConfig.rancherApiHost)) {
        Ember.set(this, 'authConfig.rancherApiHost', serverUrl);
      }
    },

    didChange: Ember.observer('authConfig.rancherApiHost', function () {
      if (!Ember.get(this, 'authConfig.rancherApiHost')) {
        Ember.set(this, 'authConfig.rancherApiHost', Ember.get(this, 'model.serverUrl.value'));
      }
    }),
    actions: {
      authTest(cb) {
        this.send('clearError');
        let errors = [];
        let model = Ember.get(this, 'authConfig');
        const am = Ember.get(model, 'accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am
        });

        if (model.id === 'shibboleth' && !Ember.isEmpty(model.openLdapConfig)) {
          model = this.removeOpenLdapConfigIfDefault(model);
        }

        if (model.rancherApiHost && model.rancherApiHost === 'https://' || model.rancherApiHost === 'http://') {
          errors.push(this.intl.t('validation.required', {
            key: 'Rancher API Host'
          }));
        }

        errors = [...errors, ...model.validationErrors()];

        if (errors.get('length')) {
          Ember.setProperties(this, {
            errors,
            testing: false
          });
          Ember.set(model, 'enabled', false);
          cb(false);
        } else {
          Ember.set(this, 'testing', true);
          let allowedPrincipals = [];

          if (Ember.get(this, 'editing')) {
            allowedPrincipals = Ember.get(model, 'allowedPrincipalIds');
          }

          Ember.setProperties(model, {
            'enabled': false,
            // It should already be, but just in case..
            'allowedPrincipalIds': allowedPrincipals
          });
          model.save().then(() => {
            model.doAction('testAndEnable', {
              finalRedirectUrl: `${window.location.origin}/verify-auth?config=${Ember.get(this, 'providerName')}`
            }).then(resp => {
              Ember.get(this, 'saml').test(resp, popupResults => {
                if (popupResults.type === 'error') {
                  this.set('errors', [popupResults.message]);
                  cb(false);
                } else {
                  this.send('waitAndRefresh');
                }
              });
            }).catch(err => {
              this.set('errors', [err]);
              cb(false);
            });
          }).catch(err => {
            this.set('errors', [err]);
            cb(false);
          });
        }
      }

    },
    apiHost: Ember.computed('authConfig.rancherApiHost', {
      get() {
        let host = Ember.get(this, 'authConfig.rancherApiHost');

        if (host.length > 0) {
          host = host.slice(8, host.length);
        }

        return host;
      },

      set(key, value) {
        let host = '';

        if (host.indexOf(Ember.get(this, 'protocol'))) {
          host = `${Ember.get(this, 'protocol')}${value}`;
        } else {
          host = value;
        }

        Ember.set(this, 'authConfig.rancherApiHost', host);
        return value;
      }

    }),
    canEditConfig: Ember.computed('isEnabled', 'editing', function () {
      const {
        isEnabled = false,
        editing = false
      } = this;

      if (!isEnabled || editing) {
        return true;
      }

      return false;
    }),
    numUsers: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'providerName', 'wasRestricted', function () {
      let type = `PROJECT.TYPE_${Ember.get(this, 'providerName').capitalize()}_USER`;
      type = Ember.get(_constants.default, type);
      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    }),
    numOrgs: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'providerName', 'wasRestricted', function () {
      let type = `PROJECT.TYPE_${Ember.get(this, 'providerName').capitalize()}_GROUP`;
      type = Ember.get(_constants.default, type);
      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    }),

    removeOpenLdapConfigIfDefault(authConfig) {
      let openLdapConfig = authConfig.openLdapConfig;
      let defaultLdapFields = JSON.stringify(this.globalStore.createRecord({
        type: 'ldapFields'
      }));
      let stringedAuthConfig = JSON.stringify(openLdapConfig);

      if (defaultLdapFields === stringedAuthConfig) {
        delete authConfig.openLdapConfig;
      }

      return authConfig;
    }

  });

  _exports.default = _default;
});