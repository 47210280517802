define("shared/tooltip/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 250;

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    mouseLeaveTimer: null,
    requireClick: false,
    tooltipOpts: null,
    openedViaContextClick: false,
    childOpened: false,

    startTimer() {
      this.set('mouseLeaveTimer', Ember.run.later(() => {
        this.hide();
      }, DELAY));
    },

    cancelTimer() {
      Ember.run.cancel(this.get('mouseLeaveTimer'));
    },

    hide() {
      if (!Ember.get(this, 'childOpened')) {
        this.set('tooltipOpts', null);
      }
    },

    leave() {
      if (!this.get('requireClick')) {
        this.startTimer();
      }
    }

  });

  _exports.default = _default;
});