define("shared/security-scan-config/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CONFIG_MAP_FILE_KEY = 'config.json';
  const CONFIG_MAP_NAMESPACE_ID = 'security-scan';
  const CONFIG_MAP_NAME = 'security-scan-cfg';
  const CONFIG_MAP_ID = `${CONFIG_MAP_NAMESPACE_ID}:${CONFIG_MAP_NAME}`;

  var _default = Ember.Service.extend({
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    app: Ember.inject.service(),
    FILE_KEY: CONFIG_MAP_FILE_KEY,
    report: null,
    configMaps: [],

    setReport(report) {
      Ember.set(this, 'report', report);
    },

    async loadAsyncConfigMap(cluster) {
      Ember.set(this, 'cluster', cluster);
      const systemProject = Ember.get(cluster, 'systemProject');
      const configMapsAsync = systemProject && systemProject.hasLink('configMaps') && Ember.get(cluster, 'state') === 'active' ? systemProject.followLink('configMaps') : [];
      const configMaps = await configMapsAsync;
      Ember.set(this, 'configMaps', [...configMaps.content]);
      return configMaps;
    },

    defaultValue: Ember.computed('report.version', function () {
      return {
        skip: {
          [Ember.get(this, 'report.version')]: []
        }
      };
    }),
    defaultData: Ember.computed('defaultValue', function () {
      return {
        [CONFIG_MAP_FILE_KEY]: JSON.stringify(Ember.get(this, 'defaultValue'))
      };
    }),
    securityScanConfig: Ember.computed('configMaps', 'configMaps.[]', function () {
      return Ember.get(this, 'configMaps').findBy('id', 'security-scan:security-scan-cfg');
    }),
    parsedSecurityScanConfig: Ember.computed('defaultValue', 'loadedTrigger', 'securityScanConfig.data.[]', function () {
      try {
        return JSON.parse(Ember.get(this, 'securityScanConfig.data')[CONFIG_MAP_FILE_KEY]);
      } catch (error) {
        return Ember.get(this, 'defaultValue');
      }
    }),

    validateSecurityScanConfig() {
      try {
        const data = Ember.get(this, `securityScanConfig.data`);

        if (!data) {
          return;
        }

        const configFile = data[CONFIG_MAP_FILE_KEY];

        if (!configFile) {
          return;
        }

        const parsed = JSON.parse(configFile);
        const version = Ember.get(this, 'report.version');

        if (!version) {
          return;
        }

        if (parsed.skip[version] && !Array.isArray(parsed.skip[version])) {
          throw new Error("Security Scan Config didin't contain the 'skip' array.");
        }
      } catch (error) {
        this.growl.fromError(this.intl.t('cis.scan.detail.error.parseConfig'), error.message);
        throw error;
      }
    },

    skipList: Ember.computed('report.version', 'securityScanConfig.data.[]', function () {
      const defaultValue = [];

      try {
        const securityScanConfig = Ember.get(this, 'securityScanConfig');

        if (!securityScanConfig) {
          return [];
        }

        const version = Ember.get(this, 'report.version');
        const skip = Ember.get(this, `parsedSecurityScanConfig.skip`)[version];
        return Array.isArray(skip) ? skip : defaultValue;
      } catch {
        return defaultValue;
      }
    }),

    async editSecurityScanConfig(newValue) {
      const securityScanConfig = await Promise.resolve(Ember.get(this, 'securityScanConfig') || this.createAndSaveDefaultConfigMap());
      Ember.set(securityScanConfig, 'data', newValue);
      securityScanConfig.save();
    },

    async createAndSaveDefaultConfigMap() {
      try {
        const configMaps = Ember.get(this, 'configMaps');
        const systemProjectLink = Ember.get(this, 'scope.currentCluster.systemProject.links.self');
        const creationUrl = `${systemProjectLink}/configmap`;
        const recordLink = `${systemProjectLink}/configMaps/${CONFIG_MAP_ID}`;
        const configRecord = Ember.get(this, 'projectStore').createRecord({
          type: 'configMap',
          id: CONFIG_MAP_ID,
          namespaceId: CONFIG_MAP_NAMESPACE_ID,
          name: CONFIG_MAP_NAME,
          data: Ember.get(this, 'defaultData'),
          links: {}
        });
        configMaps.pushObject(configRecord);
        await configRecord.save({
          url: creationUrl,
          method: 'POST'
        }); // We have to set this link after .save instead of before because .save will attempt to
        // use the self link to save the record and saving the record isn't setting the self link.

        Ember.set(configRecord, 'links.self', recordLink);
        return configRecord;
      } catch (error) {
        this.growl.fromError(this.intl.t('cis.scan.detail.error.createDefault'), error.message);
      }
    },

    editSkipList(newValue) {
      const version = Ember.get(this, 'report.version');
      const existingSkip = Ember.get(this, 'parsedSecurityScanConfig.skip') || {};
      const newSkipListObject = {
        skip: { ...existingSkip,
          [version]: newValue
        }
      };
      const newConfig = {
        [Ember.get(this, 'FILE_KEY')]: JSON.stringify(newSkipListObject)
      };
      this.editSecurityScanConfig(newConfig);
    }

  });

  _exports.default = _default;
});