define("nodes/node-templates/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.state',
    width: 100
  }, {
    name: 'name',
    sort: ['name', 'id'],
    translationKey: 'nodeTemplatesPage.table.name'
  }, {
    name: 'owner',
    sort: ['creator.displayName', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.owner',
    width: 150
  }, {
    name: 'provider',
    sort: ['displayProvider', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.provider',
    width: 150
  }, {
    name: 'location',
    sort: ['displayLocation', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.location',
    width: 150
  }, {
    name: 'size',
    sort: ['displaySize', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.size',
    width: 150
  }];

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    sortBy: 'name',
    headers: HEADERS,
    extraSearchFields: ['displayUserLabelStrings', 'displayTaintsStrings'],
    actions: {
      newTemplate() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template');
      }

    },
    filteredNodeTemplates: Ember.computed('model.nodeTemplates.@each.{id,name,state,displayLocation,displaySize}', function () {
      return Ember.get(this, 'model.nodeTemplates').filterBy('displayName');
    })
  });

  _exports.default = _default;
});