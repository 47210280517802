define("shared/user-language/service", ["exports", "shared/utils/constants", "@rancher/ember-api-store/utils/ajax-promise", "ui/utils/load-script"], function (_exports, _constants, _ajaxPromise, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @@TODO@@ - 10-27-17 - move to addon
  const RTL_LANGUAGES = ['fa-ir'];

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),
    session: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    locales: Ember.computed.alias('app.locales'),
    growl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),
    app: Ember.inject.service(),
    loadedLocales: null,
    bootstrap: Ember.on('init', function () {
      this.set('loadedLocales', []);
    }),

    initUnauthed() {
      let lang = _constants.default.LANGUAGE.DEFAULT;
      const fromSession = this.get(`session.${_constants.default.SESSION.LANGUAGE}`);
      const fromCookie = this.get('cookies').get(_constants.default.COOKIE.LANG);

      if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      return this.sideLoadLanguage(lang);
    },

    initLanguage(save = false) {
      let lang = _constants.default.LANGUAGE.DEFAULT;
      const session = this.get('session');
      const fromLogin = session.get(_constants.default.SESSION.LOGIN_LANGUAGE);
      const fromPrefs = this.get(`prefs.${_constants.default.PREFS.LANGUAGE}`); // get language from user prefs

      const fromSession = session.get(_constants.default.SESSION.LANGUAGE); // get local language

      const fromCookie = this.get('cookies').get(_constants.default.COOKIE.LANG); // get language from cookie

      if (fromLogin) {
        lang = fromLogin;

        if (save) {
          session.set(_constants.default.SESSION.LOGIN_LANGUAGE, undefined);
        }
      } else if (fromPrefs) {
        lang = fromPrefs;
      } else if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      lang = this.normalizeLang(lang);
      this.setLanguage(lang, save);
      return this.sideLoadLanguage(lang);
    },

    normalizeLang(lang) {
      return lang.toLowerCase();
    },

    getLocale() {
      return this.get('intl.locale')[0];
    },

    setLanguage(lang, savePref = true) {
      // Don't save 'none', so you can't get stuck in it across reloads
      if (lang === 'none') {
        return Ember.RSVP.resolve();
      }

      let session = this.get('session');
      lang = lang || session.get(_constants.default.SESSION.LANGUAGE);
      session.set(_constants.default.SESSION.LANGUAGE, lang);

      if (savePref && this.get('access.principal')) {
        return this.set(`prefs.${_constants.default.PREFS.LANGUAGE}`, lang);
      } else {
        return Ember.RSVP.resolve();
      }
    },

    sideLoadLanguage(language) {
      let version = this.get('settings.uiVersion');
      let loadedLocales = this.get('loadedLocales');

      if (loadedLocales.includes(language)) {
        this.get('intl').setLocale(language);
        this.setLanguage(language, false);
        this.get('userTheme').writeStyleNode();
        return Ember.RSVP.resolve();
      } else {
        return (0, _ajaxPromise.ajaxPromise)({
          url: `${this.get('app.baseAssets')}translations/${language}.json?${version}`,
          method: 'GET',
          dataType: 'json'
        }).then(resp => {
          let promise;

          if (this.get('app.needIntlPolyfill')) {
            promise = (0, _loadScript.loadScript)(`${this.get('app.baseAssets')}assets/intl/locales/${language.toLowerCase()}.js?${version}`);
          } else {
            promise = Ember.RSVP.resolve();
          }

          return promise.then(() => {
            loadedLocales.push(language);
            this.get('intl').addTranslations(language, resp.xhr.responseJSON);
            this.get('intl').setLocale(language);
            this.setLanguage(language, false);
            this.get('userTheme').writeStyleNode();
            return Ember.RSVP.resolve();
          });
        }).catch(err => {
          this.get('growl').fromError(`Error loading language: ${language}`, err);

          if (language !== _constants.default.LANGUAGE.DEFAULT) {
            return this.sideLoadLanguage(_constants.default.LANGUAGE.DEFAULT);
          } // Why would we resolve in a catch? The application route beforeModel initializes the language
          // we don't want to break the app if the translations have had some kind of error
          // a growl notification will be sufficient as the app can load without translations
          // it won't be very usable but its better then an error page.


          return Ember.RSVP.resolve();
        });
      }
    },

    getAvailableTranslations() {
      return this.get('intl').getLocalesByTranslations();
    },

    isRtl(lang) {
      return RTL_LANGUAGES.includes(lang.toLowerCase());
    }

  });

  _exports.default = _default;
});