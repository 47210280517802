define("shared/components/settings-overridable-banner/component", ["exports", "shared/components/settings-overridable-banner/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    classNameBindings: ['show::hide'],
    show: false,
    backgroundColor: null,
    textColor: null,

    didReceiveAttrs() {
      const {
        model = {}
      } = this;
      const {
        background,
        textColor,
        text: bannerText
      } = model;

      if (background) {
        (0, _jquery.default)(this.element).css({
          'background-color': background
        });
      }

      if (textColor) {
        (0, _jquery.default)(this.element).css({
          color: textColor
        });
      }

      if (bannerText) {
        Ember.set(this, 'bannerText', bannerText);
      }
    },

    bannerText: Ember.computed('model.text', function () {
      const {
        model = {}
      } = this;
      const {
        text = ''
      } = model;
      return Ember.String.htmlSafe(text);
    })
  });

  _exports.default = _default;
});