define("nodes/components/driver-digitalocean/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-digitalocean/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_IMAGES = ['rancheros', 'centos-7-x64', 'coreos-alpha', 'coreos-beta', 'coreos-stable', 'debian-8-x64', 'debian-9-x64', 'fedora-27-x64', 'fedora-28-x64', 'ubuntu-14-04-x64', 'ubuntu-16-04-x64', 'ubuntu-18-04-x64', 'ubuntu-20-04-x64'];

  var _default = Ember.Component.extend(_nodeDriver.default, {
    app: Ember.inject.service(),
    digitalOcean: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    driverName: 'digitalocean',
    regionChoices: null,
    model: null,
    step: 1,
    sizeChoices: null,
    imageChoices: null,
    tags: null,
    config: Ember.computed.alias('primaryResource.digitaloceanConfig'),

    init() {
      this._super(...arguments);

      this.initTags();
    },

    actions: {
      finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'primaryResource.cloudCredentialId', Ember.get(cred, 'id'));
          this.send('getData');
        }
      },

      getData(cb) {
        const auth = {
          type: 'cloud',
          token: Ember.get(this, 'primaryResource.cloudCredentialId')
        };
        let promises = {
          regions: this.digitalOcean.request(auth, 'regions'),
          images: this.digitalOcean.request(auth, 'images', {
            params: {
              type: 'distribution'
            }
          }),
          sizes: this.digitalOcean.request(auth, 'sizes')
        };
        Ember.RSVP.hash(promises).then(hash => {
          let filteredRegions = hash.regions.regions.filter(region => {
            return region.available && region.features.indexOf('metadata') >= 0;
          }).sortBy('name');
          let filteredSizes = hash.sizes.sizes.map(size => {
            size.memoryGb = size.memory / 1024;
            size.highMem = size.slug.indexOf('m-') >= 0;
            return size;
          }).filter(size => {
            return size.available;
          }).sortBy('highMem', 'memory');
          let filteredImages = hash.images.images.filter(image => {
            // 64-bit only
            return !(image.name || '').match(/x32$/);
          }).map(image => {
            image.disabled = VALID_IMAGES.indexOf(image.slug) === -1;
            return image;
          });
          filteredImages = filteredImages.sortBy('distribution', 'name');
          Ember.setProperties(this, {
            regionChoices: filteredRegions,
            sizeChoices: filteredSizes,
            imageChoices: filteredImages,
            step: 2,
            errors: null
          });

          if (this.hidePicker) {
            this.hidePicker();
          }
        }, err => {
          let errors = Ember.get(this, 'errors') || [];
          errors.push(`${err.statusText}: ${err.body.message}`);
          Ember.setProperties(this, {
            errors
          });

          if (cb && typeof cb === 'function') {
            cb();
          }
        });
      }

    },
    imageChanged: Ember.observer('config.image', function () {
      const image = Ember.get(this, 'config.image');

      if (image === 'rancheros') {
        Ember.set(this, 'config.sshUser', 'rancher');
      } else if (image.startsWith('coreos')) {
        Ember.set(this, 'config.sshUser', 'core');
      } else {
        Ember.set(this, 'config.sshUser', 'root');
      }
    }),
    tagsDidChange: Ember.observer('tags', function () {
      Ember.set(this, 'config.tags', Ember.get(this, 'tags').join(','));
    }),
    filteredSizeChoices: Ember.computed('config.region', 'regionChoices', 'sizeChoices', function () {
      let region = Ember.get(this, 'regionChoices').findBy('slug', Ember.get(this, 'config.region'));
      let sizes = Ember.get(this, 'sizeChoices');
      let out = sizes.filter(size => {
        return region.sizes.indexOf(size.slug) >= 0;
      });
      return out;
    }),

    initTags() {
      const tags = Ember.get(this, 'config.tags');

      if (tags) {
        Ember.set(this, 'tags', tags.split(','));
      }
    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: 'digitaloceanConfig',
        size: 's-2vcpu-2gb',
        region: 'nyc3',
        image: 'ubuntu-18-04-x64',
        sshUser: 'root'
      });
      const primaryResource = Ember.get(this, 'primaryResource');
      Ember.set(primaryResource, 'digitaloceanConfig', config);
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});