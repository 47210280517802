define("global-admin/components/form-google-auth/component", ["exports", "global-admin/components/form-google-auth/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    buttonDisabled: Ember.computed('authConfig.hostname', 'authConfig.adminEmail', 'authConfig.serviceAccountCredential', 'authConfig.oauthCredential', function () {
      const authConfig = Ember.get(this, 'authConfig');
      const isHostnameEmpty = Ember.isEmpty(authConfig.get('hostname'));
      const isAdminEmailEmpty = Ember.isEmpty(authConfig.get('adminEmail'));

      if (!Ember.get(this, 'authConfig.oauthCredential') || !Ember.get(this, 'authConfig.serviceAccountCredential') || isHostnameEmpty || isAdminEmailEmpty) {
        return true;
      }

      return false;
    }),
    destinationUrl: Ember.computed(() => {
      return `${window.location.origin}/`;
    }),
    destinationDomain: Ember.computed(() => {
      return `${window.location.hostname}`;
    }),
    redirectURI: Ember.computed(() => {
      return `${window.location.origin}/verify-auth`;
    }),
    authorizedJavascriptOrigin: Ember.computed(() => {
      return `${window.location.origin}`;
    })
  });

  _exports.default = _default;
});