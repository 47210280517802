define("shared/components/link-to-cluster-driver/component", ["exports", "shared/components/link-to-cluster-driver/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    layout: _template.default,
    classNames: ['link-to-cluster', 'mb-20', 'mt-10', 'col', 'span-3', 'col-inline', 'nav-box-item', 'driver'],
    classNameBindings: ['clusterName'],
    clusterName: '',
    genericIcon: false,
    linkHovered: false,
    actions: {
      removeRecent(e) {
        e.stopPropagation();
        e.preventDefault();
        this.removeRecent(this.clusterName);
      }

    },

    mouseEnter() {
      if (!this.element.classList.contains('active')) {
        Ember.set(this, 'linkHovered', true);
      }
    },

    mouseLeave() {
      Ember.set(this, 'linkHovered', false);
    },

    removeRecent() {
      throw new Error('removeRecent action is required!');
    }

  });

  _exports.default = _default;
});