define("shared/components/input-number/component", ["exports", "ui/utils/platform", "shared/components/input-number/template"], function (_exports, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sanitize(val) {
    val = `${val}`.trim().replace(/[^0-9.-]/g, '');
    val = val.substr(0, 1) + val.substr(1).replace('-', '');
    let idx = val.indexOf('.');

    if (idx >= 0) {
      let idx2 = val.indexOf('.', idx + 1);

      if (idx2 >= 0) {
        val = val.substr(0, idx2);
      }
    }

    if (idx === 0) {
      val = `0${val}`;
    }

    return val;
  }

  var _default = Ember.TextField.extend({
    layout: _template.default,
    attributeBindings: ['pattern', 'inputmode'],
    pattern: '[0-9]*(\.[0-9]*)?',
    inputmode: 'numeric',
    type: Ember.computed(() => {
      return _platform.isMobile ? 'number' : 'text';
    }),

    _elementValueDidChange() {
      let val = this.element.value;
      let cur = val;
      val = sanitize(val);
      let num = parseFloat(val);
      let max = parseFloat(this.get('max'));

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = `${max}`;
      }

      if (cur !== val) {
        this.element.value = val;
      }

      this.set('value', val);
    },

    focusOut() {
      this._super(...arguments);

      let val = this.element.value;
      let cur = val;
      val = sanitize(val);
      let num = parseFloat(val);
      let min = parseFloat(this.get('min'));

      if (!isNaN(num) && !isNaN(min) && num < min) {
        val = `${min}`;
      }

      if (cur !== val) {
        this.element.value = val;
        this.set('value', val);
      }
    }

  });

  _exports.default = _default;
});