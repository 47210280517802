define("shared/components/cru-cluster-template/component", ["exports", "shared/components/cru-cluster-template/template", "shared/mixins/view-new-edit", "shared/mixins/child-hook", "ui/utils/errors", "shared/utils/constants"], function (_exports, _template, _viewNewEdit, _childHook, _errors, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    globalStore: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    classNames: ['horizontal-form', 'container-fluid'],
    clusterTemplate: null,
    clusterTemplateRevision: null,
    clusterTemplateRevisionId: null,
    errors: null,
    originalCluster: null,
    memberErrors: null,
    nodePoolErrors: null,
    setDefaultTemplateRevision: false,
    mode: 'new',
    model: Ember.computed.alias('clusterTemplate'),
    cluster: Ember.computed.alias('clusterTemplateRevision.clusterConfig'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'originalCluster', Ember.get(this, 'clusterTemplateRevision.clusterConfig').clone());
    },

    actions: {
      updateFromYaml(newOpts) {
        this.cluster.replaceWith(newOpts);
      },

      addQuestion() {
        const {
          clusterTemplateRevision
        } = this;
        let question = this.globalStore.createRecord({
          type: 'question'
        });
        Ember.set(question, 'type', 'string');

        if (clusterTemplateRevision.questions) {
          clusterTemplateRevision.questions.pushObject(question);
        } else {
          Ember.set(clusterTemplateRevision, 'questions', [question]);
        }
      },

      removeQuestion(question) {
        const {
          clusterTemplateRevision
        } = this;
        clusterTemplateRevision.questions.removeObject(question);
      },

      addAuthorizedPrincipal(member) {
        let {
          members = []
        } = this.primaryResource;

        if (!members) {
          members = [];
        }

        if (member) {
          members.pushObject(this.globalStore.createRecord(member));
        } else {
          members.pushObject(this.globalStore.createRecord({
            type: 'member'
          }));
        }

        Ember.set(this, 'primaryResource.members', members);
      },

      removeMember(member) {
        let {
          members
        } = this.primaryResource;
        members.removeObject(member);
      },

      cancel() {
        this.cancel();
      },

      done() {
        this.done();
      }

    },

    willSave() {
      Ember.set(this, 'clusterTemplateRevision.clusterTemplateId', '__TEMPID__');
      this.validate(); // Make sure we add a question for the weave network configuration

      if (this.clusterTemplateRevision && this.clusterTemplateRevision.questions) {
        const allowNetworkOverride = this.clusterTemplateRevision.questions.find(q => q.variable === _constants.default.NETWORK_QUESTIONS.PLUGIN);

        if (!!allowNetworkOverride) {
          let question = this.clusterTemplateRevision.questions.find(q => q.variable === _constants.default.NETWORK_QUESTIONS.WEAVE_PASSWORD);

          if (!question) {
            question = this.globalStore.createRecord({
              type: 'question',
              variable: _constants.default.NETWORK_QUESTIONS.WEAVE_PASSWORD,
              priamryResource: this.clusterTemplateRevision.clusterConfig
            });
            this.clusterTemplateRevision.questions.push(question);
          }

          Ember.setProperties(question, {
            type: 'string',
            default: '',
            hideQuestion: true,
            isBuiltIn: true
          });
        }
      }

      return this._super(...arguments);
    },

    doneSaving(neu) {
      if (neu) {
        const {
          clusterTemplateRevision
        } = this;
        Ember.set(clusterTemplateRevision, 'clusterTemplateId', neu.id);
        return this.clusterTemplateRevision.save().then(ctr => {
          if (this.setDefaultTemplateRevision) {
            Ember.set(neu, 'defaultRevisionId', Ember.get(ctr, 'id'));
            return neu.save().then(() => {
              return this.send('done');
            }).catch(err => {
              if (err) {
                let body = _errors.default.stringify(err);

                Ember.set(this, 'errors', [body]);
              } else {
                Ember.set(this, 'errors', null);
              }

              return false;
            });
          }

          return this.send('done');
        }).catch(err => {
          return Ember.RSVP.reject(err);
        });
      } else {
        return Ember.RSVP.reject();
      }
    }

  });

  _exports.default = _default;
});