define("global-admin/catalog/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    catalog: Ember.inject.service(),
    togglingHelmIncubator: false,
    togglingHelmStable: false,
    togglingLibrary: false,
    actions: {
      add() {
        const record = Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          kind: 'helm',
          branch: 'master'
        });
        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: record,
          scope: 'global'
        });
      }

    }
  });

  _exports.default = _default;
});