define("shared/mixins/store-tweaks", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cookies: Ember.inject.service(),
    defaultPageSize: -1,
    removeAfterDelete: false,

    get headers() {
      let out = {
        [_constants.default.HEADER.ACTIONS]: _constants.default.HEADER.ACTIONS_VALUE,
        [_constants.default.HEADER.NO_CHALLENGE]: _constants.default.HEADER.NO_CHALLENGE_VALUE
      };
      let csrf = this.get(`cookies.${_constants.default.COOKIE.CSRF}`);

      if (csrf) {
        out[_constants.default.HEADER.CSRF] = csrf;
      }

      return out;
    }

  });

  _exports.default = _default;
});