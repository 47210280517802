define("shared/components/input-custom-answers/component", ["exports", "ui/utils/platform", "shared/components/input-custom-answers/template", "js-yaml", "shared/mixins/input-answers", "shared/utils/convert-yaml"], function (_exports, _platform, _template, _jsYaml, _inputAnswers, _convertYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputAnswers.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    pasteOrUpload: false,
    accept: '.yml, .yaml',
    app: null,
    isMultiClusterApp: false,
    intialAnswerMap: null,
    valuesYaml: Ember.computed.alias('selectedTemplate.valuesYaml'),

    init() {
      this._super(...arguments);

      this.initPasteOrUpload();
    },

    // shared actions exist in mixin
    actions: {
      updateAnswers(answers) {
        const {
          isMultiClusterApp
        } = this;

        if (isMultiClusterApp) {
          Ember.set(this, 'app.answers.firstObject.values', answers);
        } else {
          Ember.set(this, 'app.answers', answers);
        }
      }

    },
    applicationAnswers: Ember.computed('app.answers', 'app.answers.firstObject.values', function () {
      const answers = Ember.isArray(Ember.get(this, 'app.answers')) ? Ember.get(this, 'app.answers.firstObject.values') : Ember.get(this, 'app.answers');
      return answers;
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    pastedAnswers: Ember.computed('app.answers.firstObject.valuesYaml', 'app.valuesYaml', 'applicationAnswers', 'pasteOrUpload', {
      get()
      /* key */
      {
        let valuesYaml;
        const {
          isMultiClusterApp
        } = this;

        if (isMultiClusterApp) {
          valuesYaml = Ember.get(this, 'app.answers.firstObject.valuesYaml') || '';
        } else {
          valuesYaml = Ember.get(this, 'app.valuesYaml') || '';
        }

        let yaml;

        if (valuesYaml) {
          yaml = valuesYaml;
        } else {
          const input = {};
          let questions = Ember.get(this, 'applicationAnswers');
          Object.keys(questions).forEach(q => {
            if (questions[q] !== undefined && questions[q] !== null) {
              input[q] = questions[q];
            } else {
              input[q.variable] = '';
            }
          });
          yaml = (0, _convertYaml.default)(input);
        }

        Ember.set(this, 'valuesYaml', yaml);
        return yaml;
      },

      set(key, value) {
        try {
          _jsYaml.default.safeLoad(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', [`YAML Parse Error: ${err.snippet} - ${err.message}`]);
          return value;
        }

        Ember.setProperties(this, {
          yamlErrors: [],
          valuesYaml: value
        });
        return value;
      }

    }),

    change(event) {
      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      const input = event.target;

      if (input.files && input.files[0]) {
        let file = input.files[0];
        const reader = new FileReader();

        reader.onerror = err => {
          Ember.get(this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.onload = event2 => {
          const out = event2.target.result;
          Ember.set(this, 'pastedAnswers', out);
          input.value = '';
        };

        reader.readAsText(file);
      }
    },

    initPasteOrUpload() {
      const {
        isMultiClusterApp
      } = this;

      if (isMultiClusterApp) {
        if (Ember.get(this, 'app.answers.firstObject.valuesYaml')) {
          Ember.set(this, 'pasteOrUpload', true);
        }
      } else {
        if (Ember.get(this, 'app.valuesYaml')) {
          Ember.set(this, 'pasteOrUpload', true);
        }
      }
    }

  });

  _exports.default = _default;
});