define("nodes/components/modal-edit-node-template/component", ["exports", "shared/mixins/modal-base", "nodes/components/modal-edit-node-template/template", "shared/utils/load-script", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _modalBase, _template, _loadScript, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _preload.default, {
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    driver: null,
    onAdd: null,
    loading: true,
    editing: false,
    cloning: false,
    allNodeDrivers: null,
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    customDrivers: null,
    rejectedDriverIds: null,
    showPicker: Ember.computed.empty('model.id'),

    didReceiveAttrs() {
      const opts = Ember.get(this, 'modalService.modalOpts') || {};
      const originalModel = Ember.get(opts, 'nodeTemplate');
      let {
        driver
      } = this;
      let override = Ember.get(opts, 'driver');
      let newModel = null;
      let editing = false;
      let cloning = false;

      if (Ember.get(opts, 'edit')) {
        newModel = originalModel.clone();
        editing = true;
      } else if (Ember.get(opts, 'clone')) {
        newModel = originalModel.cloneForNew();
        editing = true;
        cloning = true;
      }

      if (originalModel) {
        Ember.setProperties(this, {
          model: newModel,
          originalModel,
          editing,
          cloning
        });
      } else {
        Ember.set(this, 'model', Ember.get(this, 'globalStore').createRecord({
          type: 'nodeTemplate'
        }));
      }

      if (override) {
        driver = override;
        Ember.set(this, 'showPicker', false);
      }

      Ember.set(this, 'onAdd', Ember.get(opts, 'onAdd')); // need to reload the schemas to fetch any new custom node template schemas so they are available for the availableDrivers

      return this.loadSchemas('globalStore').then(() => {
        return Ember.get(this, 'globalStore').findAll('nodeDriver').then(allNodeDrivers => {
          Ember.set(this, 'allNodeDrivers', allNodeDrivers);
          const active = Ember.get(this, 'availableDrivers');

          if (!driver || !active.findBy('name', driver)) {
            driver = Ember.get(active, 'firstObject.name');
          }

          Ember.set(this, 'driver', driver);
          return this.loadCustomUi().then(() => {
            Ember.set(this, 'loading', false);
          });
        });
      });
    },

    actions: {
      switchDriver(name) {
        Ember.set(this, 'driver', name);
      },

      saved() {
        const fn = Ember.get(this, 'onAdd');
        const model = Ember.get(this, 'model');

        if (fn) {
          fn(model);
        }
      },

      hidePicker() {
        this.set('showPicker', false);
      }

    },
    availableDrivers: Ember.computed('allNodeDrivers.@each.state', 'rejectedDriverIds.[]', function () {
      const out = [];
      const rejectedDriverIds = Ember.get(this, 'rejectedDriverIds') || [];
      const drivers = Ember.get(this, 'allNodeDrivers').filter(driver => {
        if (Ember.get(driver, 'state') === 'active' && !rejectedDriverIds.includes(Ember.get(driver, 'id'))) {
          return driver;
        }
      }).sortBy('name');
      drivers.forEach(driver => {
        const configName = `${Ember.get(driver, 'name')}Config`;
        const driverSchema = Ember.get(this, 'globalStore').getById('schema', configName.toLowerCase());

        if (driverSchema) {
          out.push(driver);
        }
      });
      return out;
    }),
    availableDriversGroups: Ember.computed('availableDrivers.@each.state', function () {
      const {
        availableDrivers
      } = this;
      const group = [];
      let groupIndex = 0;
      availableDrivers.forEach((item, index) => {
        if (index % 5 === 0) {
          group.push([item]);
          groupIndex++;
        } else {
          group[groupIndex - 1].push(item);
        }
      });
      return group;
    }),
    driverObj: Ember.computed('availableDrivers', 'driver', function () {
      return Ember.get(this, 'availableDrivers').filterBy('name', Ember.get(this, 'driver'))[0];
    }),
    uiFieldHints: Ember.computed('driverObj.annotations.[]', function () {
      const uiFieldHints = Ember.get(this, 'driverObj.annotations')[_constants.default.LABEL.UI_HINTS] || null;

      if (uiFieldHints) {
        return JSON.parse(uiFieldHints);
      } else {
        return {};
      }
    }),

    // Loads the custom UI CSS/JS for drivers that have a uiUrl,
    loadCustomUi() {
      const promises = [];
      let customDrivers = Ember.get(this, 'customDrivers') || [];
      Ember.get(this, 'availableDrivers').forEach(driver => {
        const name = Ember.get(driver, 'name');
        const uiUrl = Ember.get(driver, 'uiUrl');

        if (uiUrl) {
          if (!customDrivers.includes(name)) {
            customDrivers.pushObject(name);
          }

          const jsUrl = (0, _loadScript.proxifyUrl)(driver.uiUrl, this.get('app.proxyEndpoint'));
          const cssUrl = (0, _loadScript.proxifyUrl)(driver.uiUrl.replace(/\.js$/, '.css'), this.get('app.proxyEndpoint'));
          promises.push((0, _loadScript.loadScript)(jsUrl, `driver-ui-js-${name}`));
          promises.push((0, _loadScript.loadStylesheet)(cssUrl, `driver-ui-css-${name}`));
        }
      });
      Ember.set(this, 'customDrivers', customDrivers);
      return Ember.RSVP.allSettled(promises).then(results => {
        let rejectedResutls = results.filterBy('state', 'rejected');

        if (rejectedResutls.length >= 1) {
          let rejectedIds = rejectedResutls.map(rej => {
            return (Ember.get(rej, 'reason.srcElement.id') || '').split('-').get('lastObject');
          }).compact().uniq();
          Ember.set(this, 'rejectedDriverIds', rejectedIds);
          rejectedIds.forEach(reject => {
            this.growl.fromError(this.intl.t('nodeDriver.externalError', {
              driverName: reject
            }));
          });
        }

        Ember.RSVP.resolve();
      });
    }

  });

  _exports.default = _default;
});