define("shared/components/cluster-templates-table/component", ["exports", "shared/components/cluster-templates-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    type: 'string',
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 125
  }, {
    name: 'displayName',
    translationKey: 'clusterTemplatesPage.table.name',
    searchField: 'displayName',
    sort: ['displayName', 'name', 'id']
  }, {
    name: 'defaultRevisionId',
    translationKey: 'clusterTemplatesPage.headers.defaultRevisionId',
    searchField: ['defaultRevisionId'],
    sort: ['defaultRevisionId', 'name', 'id'],
    width: 175,
    classNames: 'text-center'
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created', 'name', 'id'],
    searchField: false,
    translationKey: 'clusterTemplatesPage.headers.created',
    width: 175
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    clusterTemplates: null,
    clusterTemplateRevisions: null,
    searchText: null,
    descending: false,
    suffix: true,
    sortBy: 'displayName',
    headers: HEADERS,
    extraSearchFields: ['clusterTemplate.displayName'],
    rows: Ember.computed('clusterTemplateRevisions.@each.{id,state,enabled}', function () {
      const {
        clusterTemplateRevisions = []
      } = this;
      return clusterTemplateRevisions.sortBy('displayName');
    }),
    clusterTemplatesWithoutRevisionsRows: Ember.computed('clusterTemplateRevisions.each.{clusterTemplate,enabled}', 'clusterTemplates.@each.{defaultRevisionId,revisionsCount,revisions,enabled}', function () {
      const {
        clusterTemplates = []
      } = this;
      return clusterTemplates.filter(ct => {
        return Ember.get(ct, 'enabled') && Ember.get(ct, 'revisionsCount') === 0;
      }).sortBy('displayName');
    })
  });

  _exports.default = _default;
});