define("global-admin/multi-cluster-apps/catalog/launch/route", ["exports", "shared/utils/util", "ui/utils/constants"], function (_exports, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    store: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    parentRoute: 'multi-cluster-apps.catalog',

    model(params
    /* , transition*/
    ) {
      var store = Ember.get(this, 'globalStore');
      var dependencies = {
        projects: this.scope.getAllProjects(),
        clusters: this.scope.getAllClusters()
      };

      if (params.appId) {
        dependencies.app = store.find('multiclusterapp', params.appId);
        dependencies.app.then(appData => {
          const getCurrentVersion = app => {
            const templateVersionId = app.templateVersionId;
            const splitId = templateVersionId.split('-');
            const currentVersion = splitId[splitId.length - 1];
            return currentVersion;
          };

          const currentVersion = getCurrentVersion(appData); // If an app ID is given, the current app version will be used in the
          // multi-cluster app launch route.

          dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate(`${params.template}-${params.upgrade}`, true, currentVersion);
          dependencies.tpl = Ember.get(this, 'catalog').fetchTemplate(params.template, false, currentVersion);
        }).catch(err => {
          throw new Error(err);
        });
      } else {
        // If an app ID is not given, the current app version will not be used
        // in the multi-cluster app launch route.
        if (params.upgrade) {
          dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate(`${params.template}-${params.upgrade}`, true);
        }

        dependencies.tpl = Ember.get(this, 'catalog').fetchTemplate(params.template);
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(results => {
        var app = Ember.get(results, 'app');
        var def = Ember.get(results, 'tpl.defaultVersion');
        let kind = 'helm';
        let neuApp = null;
        var links;
        let catalogTemplateUrl = null; // get diff versions

        links = results.tpl.versionLinks;

        if (app && params.appId && !params.upgrade) {
          def = Ember.get(app, 'externalIdInfo.version');
        }

        if (!links[def]) {
          def = Ember.get(results, 'tpl.latestVersion');
        }

        catalogTemplateUrl = links[def];
        return this.catalog.fetchByUrl(catalogTemplateUrl).then(() =>
        /* catalogTemplate */
        {
          var verArr = Object.keys(links).filter(key => !!links[key]).map(key => ({
            version: key,
            sortVersion: key,
            link: links[key]
          }));

          if (results.app) {
            if (Ember.get(params, 'clone')) {
              neuApp = results.app.cloneForNew();
              Ember.set(neuApp, 'name', this.dedupeName(results.app.name));

              if (neuApp.targets.length > 0) {
                let neuTargets = [];
                neuApp.targets.forEach(t => {
                  neuTargets.pushObject(t.cloneForNew());
                });
                Ember.set(neuApp, 'targets', neuTargets);
              }
            } else {
              neuApp = results.app.clone();
            }
          } else {
            let defaultAnswwer = store.createRecord({
              clusterId: null,
              projectId: null,
              type: 'answer',
              values: []
            });
            neuApp = store.createRecord({
              type: 'multiclusterapp',
              answers: [defaultAnswwer],
              catalogId: results.tpl.catalogId,
              targets: [],
              members: [],
              roles: ['project-member']
            });
          }

          let catalogTemplateUrlKey = def;

          if (neuApp.id) {
            const currentAppVersion = Ember.get(neuApp, 'externalIdInfo.version');
            const currentVersion = verArr.filter(ver => ver.version === currentAppVersion);

            if (currentVersion.length === 0) {
              verArr.unshift({
                link: Ember.get(verArr, 'firstObject.link').substring(0, Ember.get(verArr, 'firstObject.link.length') - Ember.get(verArr, 'firstObject.version.length')) + currentAppVersion,
                sortVersion: currentAppVersion,
                version: `${currentAppVersion} (current)`
              });
            } else {
              currentVersion.forEach(ver => {
                Ember.set(ver, 'version', `${ver.version} (current)`);
              });

              if (!params.upgrade) {
                catalogTemplateUrlKey = currentAppVersion;
              }
            }
          }

          if (results.tpl && results.tpl.id === _constants.default.STORAGE.LONGHORN_CATALOG_TEMPLATE_ID) {
            Ember.set(neuApp, 'name', _constants.default.STORAGE.LONGHORN_CATALOG_ITEM_DEFAULT_NAME);
          }

          return {
            catalogTemplateUrl: links[catalogTemplateUrlKey],
            // catalogTemplateUrl gets qp's added and this needs with out
            clusters: results.clusters,
            isClone: Ember.get(params, 'clone') ? true : false,
            multiClusterApp: neuApp,
            projects: results.projects,
            tpl: results.tpl,
            tplKind: kind,
            upgradeTemplate: results.upgrade,
            versionLinks: links,
            versionsArray: verArr
          };
        });
      });
    },

    afterModel(model
    /* , transition */
    ) {
      if (Ember.get(model, 'multiClusterApp.id') && Ember.get(model, 'multiClusterApp.members.length')) {
        const membersPromises = [];
        Ember.get(model, 'multiClusterApp.members').forEach(member => {
          let id = Ember.get(member, 'userPrincipalId') || Ember.get(member, 'groupPrincipalId');
          membersPromises.push(this.globalStore.find('principal', id));
        });
        return Ember.RSVP.all(membersPromises).catch(() =>
        /* err */
        {
          // we fail here when we can't look up a principal (e.g. logged in as local but its an external auth provider principal)
          return;
        });
      }

      return;
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          catalog: null,
          template: null,
          upgrade: null,
          clone: null
        });
      }
    },

    dedupeName(name) {
      const suffix = (0, _util.randomStr)(5, 5, 'novowels');
      let newName = null;
      newName = `${name}-${suffix}`;
      return newName;
    }

  });

  _exports.default = _default;
});