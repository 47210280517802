define("shared/components/resource-event-list/component", ["exports", "shared/components/resource-event-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NON_NAMESPACED_RESOURCES = ['PersistentVolume'];
  const CLUSTER_ONLY = ['namespace', 'object'];
  const HEADERS = [{
    name: 'namespace',
    sort: ['involvedObject.namespace'],
    translationKey: 'resourceEventList.table.namespace',
    width: 120
  }, {
    name: 'type',
    sort: ['type'],
    translationKey: 'resourceEventList.table.type',
    width: 100
  }, {
    name: 'reason',
    sort: ['reason'],
    translationKey: 'resourceEventList.table.reason',
    width: 200
  }, {
    name: 'object',
    sort: ['involvedObject.name'],
    translationKey: 'resourceEventList.table.object',
    width: 200
  }, {
    name: 'message',
    sort: ['message'],
    translationKey: 'resourceEventList.table.message'
  }, {
    name: 'lastTimestamp',
    sort: ['lastTimestamp'],
    translationKey: 'resourceEventList.table.lastUpdate',
    width: 200
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    events: null,
    sortBy: 'lastTimestamp',
    descending: true,
    name: null,
    namespaceId: null,
    resourceType: null,
    expanded: false,
    timeOutAnchor: null,
    loading: false,
    clusterEvents: false,

    init() {
      this._super(...arguments);

      this.expanedDidChange();
    },

    willDestroyElement() {
      this.clearTimeOut();

      this._super();
    },

    expanedDidChange: Ember.observer('expanded', 'expandAll', function () {
      if (Ember.get(this, 'expanded') || Ember.get(this, 'expandAll')) {
        Ember.set(this, 'loading', true);
        this.fetchEvents();
      } else {
        this.clearTimeOut();
      }
    }),
    headers: Ember.computed('clusterEvents', function () {
      return Ember.get(this, 'clusterEvents') ? HEADERS : HEADERS.filter(h => CLUSTER_ONLY.indexOf(h.name) === -1);
    }),

    fetchEvents() {
      let url = `/k8s/clusters/${Ember.get(this, 'scope.currentCluster.id')}/api/v1/`;

      if (Ember.get(this, 'clusterEvents')) {
        url += 'events';
      } else {
        const query = `fieldSelector=involvedObject.name=${Ember.get(this, 'name')},involvedObject.kind=${Ember.get(this, 'kind').capitalize()}`;

        if (NON_NAMESPACED_RESOURCES.indexOf(Ember.get(this, 'kind')) === -1) {
          url += `namespaces/${Ember.get(this, 'namespaceId')}/`;
        }

        url += `events?${query}`;
      }

      Ember.get(this, 'globalStore').rawRequest({
        url,
        method: 'GET'
      }).then(xhr => {
        Ember.set(this, 'events', xhr.body.items);
        Ember.set(this, 'loading', false);
        const timeOutAnchor = setTimeout(() => {
          this.fetchEvents();
        }, 10000);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      });
    },

    clearTimeOut() {
      const timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    }

  });

  _exports.default = _default;
});