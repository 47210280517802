define("shared/pipeline-github/service", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    redirect: null,
    // Set by app/services/access
    hostname: null,
    scheme: null,
    clientId: null,
    oauth: Ember.inject.service(),
    redirectURL: Ember.computed(() => {
      return `${window.location.origin}/verify-auth`;
    }),

    getAuthorizeUrl(githubAuthUrl) {
      var redirect = Ember.get(this, 'redirectURL');
      redirect = redirect.split('#')[0];

      var url = _util.default.addQueryParams(githubAuthUrl, {
        state: this.oauth.encodeState(this.oauth.generateState('github')),
        redirect_uri: redirect
      });

      Ember.set(this, 'redirect', redirect);
      return url;
    },

    authorizeTest(githubAuthUrl, cb, neverReject = false) {
      var responded = false;

      window.onAuthTest = function (err, code) {
        if (!responded) {
          responded = true;
          cb(err, code);
        }
      };

      var popup = window.open(this.getAuthorizeUrl(githubAuthUrl), 'rancherAuth', _util.default.popupWindowOptions());
      var timer = setInterval(() => {
        if (!popup || popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;

            if (neverReject) {
              cb();
            } else {
              cb({
                type: 'error',
                message: 'Access was not authorized'
              });
            }
          }
        }
      }, 500);
    }

  });

  _exports.default = _default;
});