define("shared/components/scheduling-toleration/component", ["exports", "shared/components/scheduling-toleration/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPERATOR = 'Equal';
  const DEFAULT_EFFECT = 'NoSchedule';

  var _default = Ember.Component.extend({
    layout: _template.default,
    tolerate: null,
    editing: true,
    title: null,
    tolerationArray: null,

    init() {
      this._super(...arguments);

      this.initTolerationArray();
    },

    actions: {
      addToleration() {
        Ember.get(this, 'tolerationArray').pushObject({
          key: '',
          operator: DEFAULT_OPERATOR,
          value: '',
          effect: DEFAULT_EFFECT,
          tolerationSeconds: ''
        });
      },

      removeToleration(rule) {
        Ember.get(this, 'tolerationArray').removeObject(rule);
      }

    },
    inputChanged: Ember.observer('tolerationArray.@each.{key,value,operator,effect,tolerationSeconds}', function () {
      Ember.set(this, 'tolerate', Ember.get(this, 'tolerationArray').filter(t => this.isTolerationValid(t)).map(t => this.convertToleration(t)));
    }),

    initTolerationArray() {
      const tolerate = Ember.get(this, 'tolerate') || [];
      Ember.set(this, 'tolerationArray', tolerate);
    },

    isTolerationValid(toleration) {
      if (toleration.operator === 'Equal') {
        return toleration.key && toleration.value;
      } else if (toleration.operator === 'Exists') {
        return true;
      } else {
        return toleration.effect;
      }
    },

    convertToleration(toleration) {
      const result = {};
      Object.keys(toleration).forEach(key => {
        if (toleration[key]) {
          result[key] = toleration[key];
        }
      });

      if (result.effect !== 'NoExecute') {
        delete result['tolerationSeconds'];
      }

      if (result.tolerationSeconds) {
        result.tolerationSeconds = parseInt(result.tolerationSeconds, 10);
      }

      if (result.operator === 'Exists') {
        delete result['value'];
      }

      return result;
    },

    operatorChoices: _constants.default.SCHED_TOLERATION_OPERATOR,
    effectChoices: _constants.default.SCHED_TOLERATION_EFFECT
  });

  _exports.default = _default;
});