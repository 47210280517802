define("shared/components/link-to-as-attrs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is a link-to where models (path components) and query-params can be set as attribtues instead of positional params
  var _default = Ember.LinkComponent.extend({
    attributeBindings: ['role', 'aria-haspopup', 'aria-expanded'],

    didReceiveAttrs() {
      Ember.set(this, 'params', [Ember.get(this, 'attrs.ourRoute.value'), ...(Ember.get(this, 'attrs.ctx.value') || [])]);

      if (Ember.get(this, 'attrs.qp.value')) {
        Ember.get(this, 'params').pushObject(Ember.get(this, 'attrs.qp.value'));
      }

      this._super(...arguments);
    },

    'current-when': Ember.computed('onlyCurrentWhen.length', 'qualifiedRouteName', function () {
      let base = Ember.get(this, 'qualifiedRouteName');

      if (Ember.get(this, 'onlyCurrentWhen.length')) {
        return Ember.get(this, 'onlyCurrentWhen').concat(base).join(' ');
      }

      return;
    }),
    queryParams: Ember.computed('attrs.qp.value', function () {
      return {
        isQueryParams: true,
        values: Ember.get(this, 'attrs.qp.value') || {}
      };
    })
  });

  _exports.default = _default;
});