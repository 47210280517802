define("global-admin/clusters/new/select/controller", ["exports", "shared/utils/load-script", "jquery"], function (_exports, _loadScript, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    disabledAddCluster: false,
    clusterTemplateRevisions: Ember.computed.alias('model.clusterTemplateRevisions'),

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this.setupClickHandler);
    },

    reloadSchema: Ember.observer('needReloadSchema', function () {
      if (!this.reloadingSchema && this.needReloadSchema) {
        Ember.set(this, 'reloadingSchema', true);
        this.globalStore.findAll('schema', {
          url: '/v3/schemas',
          forceReload: true
        }).then(() => {
          Ember.setProperties(this, {
            schemaReloaded: true,
            reloadingSchema: false
          });
        });
      }
    }),
    kontainerDrivers: Ember.computed('model.kontainerDrivers.@each.{id,state}', function () {
      const build_in_ui = ['tencentkubernetesengine', 'huaweicontainercloudengine', 'oraclecontainerengine', 'linodekubernetesengine'];
      const nope = ['import', 'rancherkubernetesengine'];
      const kDrivers = Ember.get(this, 'model.kontainerDrivers') || [];
      const builtIn = kDrivers.filter(d => d.state === 'active' && (d.builtIn || build_in_ui.indexOf(d.id) > -1) && !nope.includes(d.id));
      const custom = kDrivers.filter(d => d.state === 'active' && !d.builtIn && d.hasUi);
      return {
        builtIn,
        custom
      };
    }),
    providerChoices: Ember.computed('app.proxyEndpoint', 'intl.locale', 'kontainerDrivers.[]', 'model.nodeDrivers.{id,state}', 'schemaReloaded', function () {
      const {
        kontainerDrivers,
        intl
      } = this;
      const {
        builtIn,
        custom
      } = kontainerDrivers;
      let out = [{
        name: 'googlegke',
        driver: 'googlegke',
        kontainerId: 'googlekubernetesengine'
      }, {
        displayName: 'Google GKE',
        driver: 'gke',
        name: 'googlegkev2',
        nodePool: false,
        nodeWhich: 'gke',
        preSave: false,
        postSave: true
      }, {
        name: 'amazoneks',
        driver: 'amazoneks',
        kontainerId: 'amazonelasticcontainerservice'
      }, // No driver entry exists for this since it is not a kontainerEngine driver
      {
        displayName: 'Amazon EKS',
        driver: 'eks',
        name: 'amazoneksv2',
        nodePool: false,
        nodeWhich: 'eks',
        preSave: false
      }, {
        name: 'azureaks',
        driver: 'azureaks',
        kontainerId: 'azurekubernetesservice'
      }, {
        name: 'tencenttke',
        driver: 'tencenttke',
        kontainerId: 'tencentkubernetesengine'
      }, {
        name: 'huaweicce',
        driver: 'huaweicce',
        kontainerId: 'huaweicontainercloudengine'
      }, {
        name: 'oracleoke',
        driver: 'oracleoke',
        kontainerId: 'oraclecontainerengine'
      }, {
        name: 'linodelke',
        driver: 'linodelke',
        kontainerId: 'linodekubernetesengine'
      }];
      out = out.filter(o => builtIn.findBy('id', o.kontainerId) || o.name === 'amazoneksv2' || o.name === 'googlegkev2');

      if (custom.length > 0) {
        custom.forEach(c => {
          const {
            name
          } = c;
          const configName = `${name}EngineConfig`;
          const driverEngineSchema = this.globalStore.getById('schema', configName.toLowerCase());

          if (driverEngineSchema) {
            let {
              displayName,
              name: driver,
              id: kontainerId,
              name,
              genericIcon = true
            } = c;
            out.pushObject({
              displayName,
              driver,
              kontainerId,
              name,
              genericIcon
            });
          } else {
            Ember.set(this, 'needReloadSchema', true);
          }
        });
      }

      Ember.get(this, 'model.nodeDrivers').filterBy('state', 'active').sortBy('name').forEach(driver => {
        const {
          name,
          hasUi,
          hasBuiltinIconOnly: hasIcon,
          uiUrl
        } = driver;
        const configName = `${name}Config`;
        const driverSchema = this.globalStore.getById('schema', configName.toLowerCase());

        if (uiUrl) {
          const cssUrl = (0, _loadScript.proxifyUrl)(uiUrl.replace(/\.js$/, '.css'), Ember.get(this, 'app.proxyEndpoint'));
          (0, _loadScript.loadStylesheet)(cssUrl, `driver-ui-css-${name}`);
        }

        if (driverSchema) {
          out.push({
            name,
            driver: 'rke',
            genericIcon: !hasUi && !hasIcon,
            nodeComponent: hasUi ? name : 'generic',
            nodeWhich: name
          });
        } else {
          Ember.set(this, 'needReloadSchema', true);
        }
      }), out.push({
        name: 'custom',
        driver: 'rke',
        nodeWhich: 'custom',
        preSave: true
      });
      out.push({
        name: 'import',
        driver: 'import',
        preSave: true
      });
      out.forEach(driver => {
        const key = `clusterNew.${driver.name}.label`;

        if (!Ember.get(driver, 'displayName') && intl.exists(key)) {
          Ember.set(driver, 'displayName', intl.t(key));
        }
      });
      out.sortBy('name');
      return out;
    }),
    providerGroups: Ember.computed('providerChoices.@each.{name,driver,nodeComponent,nodeWhich,preSave}', function () {
      const choices = Ember.get(this, 'providerChoices');
      const rkeGroup = [];
      const cloudGroup = [];
      const customGroup = [];
      const importGroup = [];
      choices.forEach(item => {
        if (Ember.get(item, 'driver') === 'rke' && Ember.get(item, 'name') !== 'custom') {
          rkeGroup.pushObject(item);
        } else if (Ember.get(item, 'driver') === 'import' && Ember.get(item, 'name') !== 'custom') {
          importGroup.pushObject(item);
        } else if (Ember.get(item, 'name') === 'custom') {
          customGroup.pushObject(item);
        } else {
          cloudGroup.pushObject(item);
        }
      });
      return {
        cloudGroup: cloudGroup.sortBy('name'),
        customGroup: customGroup.sortBy('name'),
        importGroup: importGroup.sortBy('name'),
        rkeGroup: rkeGroup.sortBy('name')
      };
    }),

    setupClickHandler() {
      (0, _jquery.default)('BODY').on('click', '#k3s-info-external-link', e => {
        e.stopPropigation();
      });
    }

  });

  _exports.default = _default;
});