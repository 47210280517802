define("shared/components/cru-master-auth-network/component", ["exports", "shared/components/cru-master-auth-network/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    editing: false,
    isNew: true,
    config: null,
    clusterConfig: null,
    privateClusterChanged: Ember.observer('clusterConfig.privateClusterConfig.enablePrivateEndpoint', function () {
      const config = Ember.get(this, 'config') || {
        enabled: false
      };
      const clusterConfig = Ember.get(this, 'clusterConfig') || {
        privateClusterConfig: {
          enablePrivateEndpoint: false
        }
      };

      if (clusterConfig.privateClusterConfig.enablePrivateEndpoint && !config.enabled) {
        Ember.set(this, 'config.enabled', true);
      }
    })
  });

  _exports.default = _default;
});