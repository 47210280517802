define("shared/digital-ocean/service", ["exports", "shared/utils/util", "@rancher/ember-api-store/utils/fetch"], function (_exports, _util, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DIGITALOCEAN_API = 'api.digitalocean.com/v2';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),

    request(auth = {}, command, opt = {}, out) {
      let url = `${Ember.get(this, 'app.proxyEndpoint')}/`;
      let token = Ember.get(auth, 'token');
      let headers = {
        'Accept': 'application/json'
      };

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', `Bearer credID=${token} passwordField=accessToken`);
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', `Bearer ${token}`);
      }

      if (opt.url) {
        url += opt.url.replace(/^http[s]?\/\//, '');
      } else {
        url += `${DIGITALOCEAN_API}/${command}`;
        url = (0, _util.addQueryParam)(url, 'per_page', opt.per_page || 100);
        url = (0, _util.addQueryParams)(url, opt.params || {});
      }

      return (0, _fetch.default)(url, {
        headers
      }).then(res => {
        let body = res.body;

        if (out) {
          out[command].pushObjects(body[command]);
        } else {
          out = body;
        } // De-paging


        if (body && body.links && body.links.pages && body.links.pages.next) {
          opt.url = body.links.pages.next;
          return this.apiRequest(command, opt, out).then(() => {
            return out;
          });
        } else {
          return out;
        }
      }).catch(err => {
        return Ember.RSVP.reject(err);
      });
    }

  });

  _exports.default = _default;
});