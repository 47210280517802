define("shared/utils/subscribe-cluster", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_subscribe.default, {
    updateProjectStore: false,
    scope: Ember.inject.service(),
    watchState: true,
    watchStateOf: Ember.computed.alias('scope.pendingCluster'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'endpoint', Ember.get(this, 'app.clusterSubscribeEndpoint'));
    },

    validate() {
      const socket = Ember.get(this, 'subscribeSocket');
      const metadata = socket.getMetadata();
      const socketClusterId = Ember.get(metadata, 'clusterId');
      const currentClusterId = Ember.get(this, 'scope.currentCluster.id');

      if (!currentClusterId || currentClusterId === socketClusterId) {
        return true;
      } else {
        console.error(`${this.label} Subscribe ignoring message, current=${currentClusterId} socket=${socketClusterId} ${this.forStr()}`);
        return false;
      }
    }

  });

  _exports.default = _default;
});