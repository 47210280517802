define("shared/components/form-versions/component", ["exports", "shared/components/form-versions/template", "shared/utils/constants", "shared/utils/parse-version", "shared/utils/sort", "semver"], function (_exports, _template, _constants, _parseVersion, _sort, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    versionChoices: null,
    versions: null,
    initialVersion: null,
    disabled: false,
    value: null,
    mode: 'new',
    showNotAllowed: false,
    supportedVersionsRange: null,
    editing: Ember.computed.equal('mode', 'edit'),
    isView: Ember.computed.equal('mode', 'view'),
    defaultK8sVersionRange: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE}`),
    defaultK8sVersion: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_RKE_K8S_DEFAULT}`),
    shouldRecomputInitalValues: Ember.on('init', Ember.observer('cluster.clusterTemplateRevisionId', function () {
      Ember.run.scheduleOnce('afterRender', this, 'setupVersions');
    })),
    isRke: Ember.computed('cluster', function () {
      const {
        cluster
      } = this;

      if (Ember.get(cluster, 'rancherKubernetesEngineConfig')) {
        return true;
      }

      return false;
    }),
    k8sVersionOverride: Ember.computed('clusterTemplateQuestions.@each.variable', function () {
      return (this.clusterTemplateQuestions || []).findBy('variable', 'rancherKubernetesEngineConfig.kubernetesVersion') || {};
    }),
    getLabelSuffixKey: Ember.computed('showNotAllowed', function () {
      let {
        applyClusterTemplate = false,
        clusterTemplateCreate = false,
        showNotAllowed
      } = this;
      let suffix = showNotAllowed ? 'formVersions.notallowed' : 'formVersions.unsupported'; // if we're not consuming or creating a cluster template we should use the default translation for the label

      if (!applyClusterTemplate && !clusterTemplateCreate) {
        suffix = 'formVersions.unsupported';
      }

      return suffix;
    }),

    initVersions() {
      let {
        versions,
        editing,
        initialVersion,
        defaultK8sVersion,
        k8sVersionOverride,
        intl,
        getLabelSuffixKey: suffix
      } = this;
      let clonedVersions = versions.slice();

      if (Ember.isEmpty(initialVersion)) {
        initialVersion = defaultK8sVersion;
      }

      if (editing && !clonedVersions.includes(initialVersion)) {
        clonedVersions.unshift(initialVersion);
      }

      let initialWithoutX = initialVersion.endsWith('.x') ? initialVersion.replace(/x$/, '0') : initialVersion;
      let maxVersion = this.getMaxVersion(initialVersion, clonedVersions);
      clonedVersions = [...(0, _sort.sortVersions)(clonedVersions.filter(v => v.endsWith('.x'))).reverse(), ...(0, _sort.sortVersions)(clonedVersions.filter(v => !v.endsWith('.x'))).reverse()];
      let mappedVersions = clonedVersions.map(v => {
        let label = this.parseLabelFromVersion(v);
        let disabled = false;
        let experimental = false;
        const version = (0, _parseVersion.coerceVersion)(v);
        let versionSatisfies = (0, _parseVersion.satisfies)(version, this.supportedVersionsRange);

        if (versionSatisfies) {
          if (editing && isCurrentVersionLessThanInitial()) {
            disabled = true;
            label = `${label} ${intl.t('formVersions.downgrade')}`;
          }
        } else {
          if ((0, _semver.gt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            if (Ember.isEmpty(k8sVersionOverride.satisfies)) {
              if (!(0, _parseVersion.satisfies)((0, _parseVersion.coerceVersion)(v), '>=1.16 <1.17')) {
                experimental = true;
                label = `${label} ${intl.t('formVersions.experimental')}`;
              }
            } else {
              disabled = true;
              label = `${label} ${intl.t(suffix)}`;
            }
          } else if ((0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            disabled = true;
            label = `${label} ${intl.t(suffix)}`;
          }
        }

        function isCurrentVersionLessThanInitial() {
          if ((0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(initialWithoutX)) && (0, _semver.minor)(version) <= (0, _semver.minor)((0, _parseVersion.coerceVersion)(initialWithoutX))) {
            return true;
          }

          return false;
        }

        return {
          disabled,
          experimental,
          label,
          value: v
        };
      });
      Ember.set(this, 'versionChoices', mappedVersions);
      this.initValue(initialWithoutX, maxVersion);
    },

    initValue(initialVersion, maxVersion) {
      let {
        editing,
        value,
        k8sVersionOverride,
        applyClusterTemplate,
        intl,
        versionChoices: mappedVersions
      } = this;
      let valueIsPatchVersion = false;
      let initialVersionDoesSatisfy = true;

      if (Ember.isEmpty(value)) {
        value = initialVersion;
      }

      if (value.endsWith('.x')) {
        valueIsPatchVersion = true;
      }

      if (!Ember.isEmpty(k8sVersionOverride.satisfies) && !(0, _parseVersion.satisfies)(initialVersion, k8sVersionOverride.satisfies)) {
        initialVersionDoesSatisfy = false;
      }

      if (editing) {
        if (applyClusterTemplate) {
          if (initialVersionDoesSatisfy) {
            value = initialVersion;
          } else {
            value = maxVersion;
          }
        }
      } else {
        if (applyClusterTemplate && !mappedVersions.findBy('value', value)) {
          value = maxVersion;
        } else {
          if (valueIsPatchVersion) {
            value = intl.t('formVersions.dotx', {
              minor: value.replace(/\.x$/, '')
            });
          }
        }
      }

      Ember.set(this, 'value', value);
    },

    getMaxVersion(initialVersion, versions) {
      let {
        applyClusterTemplate,
        defaultK8sVersionRange,
        supportedVersionsRange,
        k8sVersionOverride
      } = this;
      let maxVersion = (0, _parseVersion.maxSatisfying)(versions, defaultK8sVersionRange);

      if (applyClusterTemplate) {
        if (Ember.isEmpty(k8sVersionOverride.satisfies) && initialVersion.endsWith('.x')) {
          // the template creator lets them override this but the initial version is a dot x so we should choose the biggest version in the .x range
          maxVersion = (0, _parseVersion.maxSatisfying)(versions, initialVersion);
        } else {
          if (k8sVersionOverride.satisfies) {
            supportedVersionsRange = Ember.set(this, 'supportedVersionsRange', k8sVersionOverride.satisfies);
          }

          maxVersion = (0, _parseVersion.maxSatisfying)(versions, supportedVersionsRange);
        }
      }

      return maxVersion;
    },

    parseLabelFromVersion(version) {
      let {
        intl
      } = this;
      let label = version;

      if (!label.startsWith('v')) {
        label = `v${label}`;
      }

      if (label.endsWith('.x')) {
        label = intl.t('formVersions.dotx', {
          minor: label.replace(/\.x$/, '')
        });
      }

      return label;
    },

    setupVersions() {
      // we should set this everytime. we can potentially override it based on
      // satisfies overrides, so if we change templates while launching
      // a cluster from a satisfies to a non-satisfies override we should set
      // it back to the system setting
      Ember.set(this, 'supportedVersionsRange', Ember.get(this, `settings.${_constants.default.SETTING.VERSION_K8S_SUPPORTED_RANGE}`));
      this.initVersions();
    }

  });

  _exports.default = _default;
});