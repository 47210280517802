define("global-admin/security/cloud-credentials/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model()
    /* params */
    {
      return this.globalStore.findAll('cloudcredential');
    }

  });

  _exports.default = _default;
});