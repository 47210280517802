define("shared/components/cru-cluster/component", ["exports", "jquery", "shared/mixins/child-hook", "shared/mixins/view-new-edit", "shared/utils/load-script", "shared/components/cru-cluster/template"], function (_exports, _jquery, _childHook, _viewNewEdit, _loadScript, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBER_CONFIG = {
    type: 'clusterRoleTemplateBinding'
  };
  const BUILD_IN_UI = ['tencentkubernetesengine', 'huaweicontainercloudengine', 'oraclecontainerengine', 'linodekubernetesengine'];
  const V2_DRIVERS = ['amazoneksv2', 'googlegkev2'];

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    step: 1,
    initialProvider: null,
    memberConfig: MEMBER_CONFIG,
    newCluster: false,
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    applyClusterTemplate: false,
    routeLoading: false,
    isPostSave: false,
    showClassicLauncher: false,
    nodePoolErrors: null,
    clusterTemplateRevisionId: null,
    cluster: Ember.computed.alias('model.cluster'),
    originalCluster: Ember.computed.alias('model.originalCluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    clusterState: Ember.computed.alias('originalCluster.state'),
    isCustom: Ember.computed.equal('driverInfo.nodeWhich', 'custom'),
    isK3sCluster: Ember.computed.equal('model.cluster.driver', 'k3s'),
    isRke2Cluster: Ember.computed.equal('model.cluster.driver', 'rke2'),

    init() {
      this._super(...arguments); // On edit pass in initialProvider, for create just set provider directly


      const initialProvider = Ember.get(this, 'initialProvider');

      if (this.cookies.get('classicClusterLaunch')) {
        Ember.set(this, 'showClassicLauncher', true);
      } else {
        Ember.set(this, 'showClassicLauncher', false);
      }

      if (initialProvider) {
        Ember.set(this, 'provider', initialProvider);
      }

      if (Ember.isEmpty(Ember.get(this, 'cluster.id'))) {
        Ember.set(this, 'newCluster', true);
      }

      this.router.on('routeWillChange', () =>
      /* transition */
      {
        if (!this.isDestroyed || !this.isDestroying) {
          Ember.set(this, 'routeLoading', true);
        }
      });
      this.router.on('routeDidChange', () =>
      /* transition */
      {
        if (!this.isDestroyed || !this.isDestroying) {
          Ember.set(this, 'routeLoading', false);
        }
      });
    },

    actions: {
      updateFromYaml(newOpts) {
        let {
          primaryResource
        } = this;

        let _cachedName;
        /**
         * If a user switches to the yaml entry view before they put a name in the cluster name input
         * and then they put a name in the input rather that the yaml, when they click save we overwrite
         * the name with the empty (or remove if not included) value.
         * Ensure the name they've entered in the UI is used. This will not affect name entry in yaml.
         */


        if (primaryResource.name) {
          _cachedName = primaryResource.name;
        }

        if (this.isEdit) {
          primaryResource.merge(newOpts);
        } else {
          primaryResource.replaceWith(newOpts);
        }

        if (Ember.isEmpty(primaryResource.name) && _cachedName) {
          Ember.set(primaryResource, 'name', _cachedName);
        }
      },

      clickNext() {
        (0, _jquery.default)('BUTTON[type="submit"]').click();
      },

      close(saved) {
        if (this.close) {
          Ember.set(this, 'isPostSave', false);
          this.close(saved);
        }
      },

      setNodePoolErrors(errors) {
        Ember.set(this, 'nodePoolErrors', errors);
      }

    },
    reloadSchema: Ember.observer('needReloadSchema', function () {
      if (!Ember.get(this, 'reloadingSchema') && Ember.get(this, 'needReloadSchema')) {
        Ember.set(this, 'reloadingSchema', true);
        Ember.get(this, 'globalStore').findAll('schema', {
          url: '/v3/schemas',
          forceReload: true
        }).then(() => {
          Ember.setProperties(this, {
            schemaReloaded: true,
            reloadingSchema: false
          });
        });
      }
    }),
    agentEnvVars: Ember.computed('cluster.agentEnvVars.[]', {
      get() {
        var _this$cluster;

        if (!((_this$cluster = this.cluster) === null || _this$cluster === void 0 ? void 0 : _this$cluster.agentEnvVars)) {
          // if undefined two way binding doesn't seem to work
          Ember.set(this, 'cluster.agentEnvVars', []);
        }

        return Ember.get(this, 'cluster.agentEnvVars') || [];
      },

      set(_key, value) {
        Ember.set(this, 'cluster.agentEnvVars', value);
        return value;
      }

    }),
    isEksClusterPending: Ember.computed('clusterState', 'provider', function () {
      const {
        clusterState,
        provider
      } = this;

      if (['pending', 'waiting'].includes(clusterState) && provider === 'amazoneksv2') {
        return true;
      }

      return false;
    }),
    kontainerDrivers: Ember.computed('model.kontainerDrivers.@each.{id,state}', function () {
      let nope = ['import', 'rancherkubernetesengine'];
      let kDrivers = Ember.get(this, 'model.kontainerDrivers') || [];
      let builtIn = kDrivers.filter(d => d.state === 'active' && (d.builtIn || BUILD_IN_UI.indexOf(d.id) > -1) && !nope.includes(d.id));
      let custom = kDrivers.filter(d => d.state === 'active' && !d.builtIn && d.hasUi);
      return {
        builtIn,
        custom
      };
    }),
    providerChoices: Ember.computed('app.proxyEndpoint', 'cluster.rancherKubernetesEngineConfig', 'intl.locale', 'isEdit', 'kontainerDrivers.[]', 'model.nodeDrivers', 'nodeDrivers.[]', 'schemaReloaded', function () {
      const {
        kontainerDrivers,
        intl
      } = this;
      const {
        builtIn,
        custom
      } = kontainerDrivers;
      let out = [{
        name: 'googlegke',
        driver: 'googlegke',
        kontainerId: 'googlekubernetesengine'
      }, {
        displayName: 'Google GKE',
        driver: 'gke',
        name: 'googlegkev2',
        nodePool: false,
        nodeWhich: 'gke',
        preSave: false,
        postSave: true
      }, {
        name: 'amazoneks',
        driver: 'amazoneks',
        kontainerId: 'amazonelasticcontainerservice'
      }, {
        displayName: 'Amazon EKS',
        driver: 'eks',
        name: 'amazoneksv2',
        nodePool: false,
        nodeWhich: 'eks',
        preSave: false,
        postSave: true
      }, {
        name: 'azureaks',
        driver: 'azureaks',
        kontainerId: 'azurekubernetesservice'
      }, {
        name: 'tencenttke',
        driver: 'tencenttke',
        kontainerId: 'tencentkubernetesengine'
      }, {
        name: 'huaweicce',
        driver: 'huaweicce',
        kontainerId: 'huaweicontainercloudengine'
      }, {
        name: 'oracleoke',
        driver: 'oracleoke',
        kontainerId: 'oraclecontainerengine'
      }, {
        name: 'linodelke',
        driver: 'linodelke',
        kontainerId: 'linodekubernetesengine'
      }];
      out = out.filter(o => builtIn.findBy('id', o.kontainerId) || V2_DRIVERS.includes(o.name));

      if (custom.length > 0) {
        custom.forEach(c => {
          const {
            name
          } = c;
          const configName = `${name}EngineConfig`; // need the hyph name

          const driverEngineSchema = Ember.get(this, 'globalStore').getById('schema', configName.toLowerCase());

          if (driverEngineSchema) {
            out.pushObject({
              displayName: Ember.get(c, 'displayName'),
              driver: Ember.get(c, 'name'),
              kontainerId: Ember.get(c, 'id'),
              name: Ember.get(c, 'name'),
              genericIcon: true // @TODO should have a way for drivers to provide an icon

            });
          } else {
            Ember.set(this, 'needReloadSchema', true);
          }
        });
      }

      Ember.get(this, 'model.nodeDrivers').filterBy('state', 'active').sortBy('name').forEach(driver => {
        const name = Ember.get(driver, 'name');
        const hasUi = Ember.get(driver, 'hasUi');
        const hasIcon = Ember.get(driver, 'hasBuiltinIconOnly');
        const uiUrl = Ember.get(driver, 'uiUrl');
        const configName = `${name}Config`;
        const driverSchema = Ember.get(this, 'globalStore').getById('schema', configName.toLowerCase());

        if (uiUrl) {
          const cssUrl = (0, _loadScript.proxifyUrl)(uiUrl.replace(/\.js$/, '.css'), Ember.get(this, 'app.proxyEndpoint'));
          (0, _loadScript.loadStylesheet)(cssUrl, `driver-ui-css-${name}`);
        }

        if (driverSchema) {
          out.push({
            name,
            driver: 'rke',
            genericIcon: !hasUi && !hasIcon,
            nodeComponent: hasUi ? name : 'generic',
            nodeWhich: name,
            nodePool: true
          });
        } else {
          Ember.set(this, 'needReloadSchema', true);
        }
      }), out.push({
        name: 'custom',
        driver: 'rke',
        nodeWhich: 'custom',
        nodePool: true,
        preSave: true,
        postSave: true
      });
      out.push({
        name: 'import',
        driver: 'import',
        preSave: true,
        postSave: true
      });
      out.push({
        name: 'imported',
        driver: 'import',
        preSave: true,
        postSave: true
      });
      out.push({
        name: 'importeks',
        driver: 'import-eks',
        preSave: false,
        postSave: true
      });
      out.push({
        name: 'importgke',
        driver: 'import-gke',
        preSave: false,
        postSave: true
      });
      out.push({
        name: 'k3s',
        driver: 'import',
        preSave: true
      });
      out.push({
        name: 'rke2',
        driver: 'import',
        preSave: true
      });
      out.forEach(driver => {
        const key = `clusterNew.${driver.name}.label`;

        if (!Ember.get(driver, 'displayName') && intl.exists(key)) {
          Ember.set(driver, 'displayName', intl.t(key));
        }
      });

      if (Ember.get(this, 'isEdit') && Ember.get(this, 'cluster.rancherKubernetesEngineConfig')) {
        out = out.filterBy('driver', 'rke');
      }

      out.sortBy('name');
      return out;
    }),
    driverInfo: Ember.computed('provider', 'providerChoices', 'router.currentRoute.queryParams', function () {
      let name = Ember.get(this, 'provider');
      const {
        router
      } = this;
      const importProvider = Ember.get(router, 'currentRoute.queryParams.importProvider');

      if (name === 'import') {
        if (Ember.isEmpty(importProvider)) {
          return null;
        } else if (importProvider === 'eks') {
          name = 'importeks';
        } else if (importProvider === 'gke') {
          name = 'importgke';
        }
      }

      const choices = Ember.get(this, 'providerChoices');
      const entry = choices.findBy('name', name);

      if (entry) {
        return {
          name: entry.name,
          displayName: entry.displayName,
          driverComponent: `cluster-driver/driver-${entry.driver}`,
          nodeWhich: entry.nodeWhich,
          preSave: !!entry.preSave,
          postSave: !!entry.postSave,
          nodePool: entry.nodePool || false
        };
      }

      return {
        name: 'unknown',
        displayName: 'unknown',
        driverComponent: null,
        nodeWhich: 'unknown',
        preSave: false,
        nodePool: false
      };
    }),
    isImportedOther: Ember.computed('isK3sCluster', 'provider', 'router.currentRoute.queryParams.importProvider', function () {
      // This is a special case because k3s is treated as a special cluster and no longer looks imported by the time it's actve
      if (Ember.get(this, 'isK3sCluster')) {
        return true;
      }

      const name = Ember.get(this, 'provider');
      const importProvider = Ember.get(this, 'router.currentRoute.queryParams.importProvider');
      return name === 'import' && (!importProvider || importProvider === 'other');
    }),
    showDriverComponent: Ember.computed('routeLoading', 'provider', 'router.currentRoute.queryParams', function () {
      const {
        driverInfo,
        provider,
        routeLoading,
        router
      } = this;
      const importProvider = Ember.get(router, 'currentRoute.queryParams.importProvider');

      if (routeLoading || Ember.isEmpty(driverInfo)) {
        return false;
      }

      if (provider === 'import') {
        if (!Ember.isEmpty(importProvider)) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    }),

    doSave(opt) {
      opt = opt || {};
      opt.qp = {
        '_replace': 'true'
      };
      return this._super(opt);
    },

    didSave() {
      const originalCluster = Ember.get(this, 'cluster');
      return originalCluster.waitForCondition('InitialRolesPopulated').then(() => {
        return this.applyHooks().then(() => {
          const clone = originalCluster.clone();
          Ember.setProperties(this, {
            cluster: clone,
            originalCluster
          });
          return clone;
        });
      });
    },

    doneSaving(saved) {
      if (Ember.get(this, 'step') === 1 && Ember.get(this, 'driverInfo.preSave') && !Ember.get(this, 'driverInfo.postSave')) {
        var _this$originalCluster, _this$originalCluster2;

        const skipK3sImport = !Ember.isEmpty(saved === null || saved === void 0 ? void 0 : saved.provider) && saved.provider === 'k3s' && Ember.isEmpty(saved === null || saved === void 0 ? void 0 : saved.k3sConfig);

        if (skipK3sImport && ((_this$originalCluster = this.originalCluster) === null || _this$originalCluster === void 0 ? void 0 : _this$originalCluster.state) !== 'pending') {
          if (this.close) {
            this.close(saved);
          }
        } else if (((_this$originalCluster2 = this.originalCluster) === null || _this$originalCluster2 === void 0 ? void 0 : _this$originalCluster2.state) === 'pending') {
          Ember.setProperties(this, {
            step: 2,
            initialProvider: Ember.get(this, 'provider')
          });
        } else {
          if (this.close) {
            this.close(saved);
          }
        }
      } else if (Ember.get(this, 'driverInfo.postSave')) {
        Ember.setProperties(this, {
          initialProvider: Ember.get(this, 'provider'),
          isPostSave: true,
          step: 2
        });
      } else {
        if (this.close) {
          this.close(saved);
        }
      }
    },

    errorSaving()
    /* err */
    {
      if (this.applyClusterTemplate && this.primaryResource._cachedConfig) {
        let {
          localClusterAuthEndpoint,
          rancherKubernetesEngineConfig,
          enableNetworkPolicy,
          defaultClusterRoleForProjectMembers,
          defaultPodSecurityPolicyTemplateId
        } = this.primaryResource._cachedConfig;
        Ember.setProperties(this.primaryResource, {
          localClusterAuthEndpoint,
          rancherKubernetesEngineConfig,
          enableNetworkPolicy,
          defaultClusterRoleForProjectMembers,
          defaultPodSecurityPolicyTemplateId
        });
      }
    }

  });

  _exports.default = _default;
});