define("shared/scope/service", ["exports", "shared/utils/subscribe-global", "shared/utils/subscribe-cluster", "shared/utils/subscribe-project"], function (_exports, _subscribeGlobal, _subscribeCluster, _subscribeProject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    cookies: Ember.inject.service(),
    app: Ember.inject.service(),
    currentProject: null,
    currentCluster: null,
    currentPageScope: 'none',
    docsBase: Ember.computed.alias('settings.docsBase'),
    allProjects: null,
    allClusters: null,

    init() {
      const globalStore = Ember.get(this, 'globalStore');

      this._super(...arguments);

      Ember.setProperties(this, {
        allClusters: globalStore.all('cluster'),
        allProjects: globalStore.all('project')
      });
      this.initSubscribe();
    },

    // Subscribe
    subscribeGlobal: null,
    subscribeCluster: null,
    subscribeProject: null,

    initSubscribe() {
      const deps = {
        app: Ember.get(this, 'app'),
        store: Ember.get(this, 'store'),
        clusterStore: Ember.get(this, 'clusterStore'),
        globalStore: Ember.get(this, 'globalStore'),
        intl: Ember.get(this, 'intl'),
        growl: Ember.get(this, 'growl'),
        scope: this
      };

      const g = _subscribeGlobal.default.create(deps);

      const c = _subscribeCluster.default.create(deps);

      const p = _subscribeProject.default.create(deps);

      g.set('label', 'Global');
      g.set('label', 'Cluster');
      p.set('label', 'Project');
      Ember.setProperties(this, {
        subscribeGlobal: g,
        subscribeCluster: c,
        subscribeProject: p
      });
    },

    // End: Subscribe
    startSwitchToNothing() {
      this._setPageScope('global');

      return Ember.get(this, 'subscribeGlobal').disconnect().then(() => {
        return this._startSwitchTo(null, null).then(() => {
          Ember.get(this, 'globalStore').reset();
        });
      });
    },

    finishSwitchToNothing() {
      this._finishSwitchTo(null, null);
    },

    startSwitchToGlobal(connect = true) {
      this._setPageScope('global');

      if (connect) {
        Ember.get(this, 'subscribeGlobal').connect(false);
      }

      return this._startSwitchTo(null, null, connect);
    },

    finishSwitchToGlobal() {
      return this._finishSwitchTo(null, null);
    },

    startSwitchToCluster(cluster, connect = true) {
      this._setPageScope('cluster');

      return this._startSwitchTo(cluster, null, connect);
    },

    finishSwitchToCluster(cluster) {
      return this._finishSwitchTo(cluster, null);
    },

    startSwitchToProject(project, connect = true) {
      this._setPageScope('project');

      return this._startSwitchTo(Ember.get(project, 'cluster'), project, connect);
    },

    finishSwitchToProject(project) {
      return this._finishSwitchTo(Ember.get(project, 'cluster'), project);
    },

    _setPageScope(scope) {
      Ember.set(this, 'currentPageScope', scope);
    },

    _startSwitchTo(cluster, project, connect = true) {
      const clusterOld = Ember.get(this, 'currentCluster');
      const clusterSubscribe = Ember.get(this, 'subscribeCluster');
      const clusterStore = Ember.get(this, 'clusterStore');
      const clusterId = cluster && Ember.get(cluster, 'id') || null;
      let clusterReset = false;
      const projectOld = Ember.get(this, 'currentProject');
      const projectSubscribe = Ember.get(this, 'subscribeProject');
      const projectStore = Ember.get(this, 'store');
      const projectId = project && Ember.get(project, 'id') || null;
      let projectReset = false;
      const cleanupPromises = [];
      Ember.set(this, 'pendingCluster', cluster);
      Ember.set(this, 'pendingProject', project);

      if (cluster !== clusterOld) {
        if (cluster) {
          Ember.set(clusterStore, 'baseUrl', `${Ember.get(this, 'app.apiEndpoint')}/clusters/${clusterId}`);
        }

        cleanupPromises.push(clusterSubscribe.disconnect());
        clusterReset = true;
      }

      if (!Ember.get(clusterSubscribe, 'wasConnected')) {
        clusterReset = true;
      }

      if (project !== projectOld) {
        if (project) {
          Ember.set(projectStore, 'baseUrl', `${Ember.get(this, 'app.apiEndpoint')}/projects/${projectId}`);
        }

        cleanupPromises.push(projectSubscribe.disconnect());
        projectReset = true;
      }

      return Ember.RSVP.all(cleanupPromises).then(() => {
        if (clusterReset) {
          clusterStore.reset();

          if (cluster && connect) {
            clusterSubscribe.connect(true, clusterId, projectId);
          }
        }

        if (projectReset) {
          projectStore.reset();

          if (project && connect) {
            projectSubscribe.connect(true, clusterId, projectId);
          }
        }
      });
    },

    _finishSwitchTo(cluster, project) {
      return new Ember.RSVP.Promise(resolve => {
        Ember.setProperties(this, {
          currentCluster: cluster,
          currentProject: project
        });
        resolve();
        return;
      });
    },

    getAllProjects(moreOpt = {}) {
      let opt = {
        url: 'projects'
      }; // This is called in authenticated/route before schemas are loaded

      Object.assign(opt, moreOpt);
      return Ember.get(this, 'globalStore').findAll('project', opt);
    },

    getAllClusters(moreOpt = {}) {
      let opt = {
        url: 'clusters'
      }; // This is called in authenticated/route before schemas are loaded

      Object.assign(opt, moreOpt);
      return Ember.get(this, 'globalStore').findAll('cluster', opt);
    },

    dashboardBase: Ember.computed('app.environment', 'currentCluster.id', function () {
      let link;

      if (Ember.get(this, 'app.environment') === 'development') {
        link = 'https://localhost:8005/';
      } else {
        link = '/dashboard/';
      }

      return link;
    }),
    dashboardLink: Ember.computed('dashboardBase', 'currentCluster.id', function () {
      const cluster = Ember.get(this, 'currentCluster');

      if (!cluster || !cluster.isReady) {
        // Only in ready/active clusters
        return;
      }

      let link = Ember.get(this, 'dashboardBase');

      if (link) {
        return `${link}c/${escape(cluster.id)}`;
      }

      return;
    })
  });

  _exports.default = _default;
});