define("global-admin/components/form-volume-policy/component", ["exports", "global-admin/components/form-volume-policy/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const choices = _constants.default.VOLUME_POLICIES;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    basicPolicies: null,
    readOnly: false,
    volumeChoices: null,
    statusClass: null,
    status: null,

    init() {
      this._super(...arguments);

      this.initVolume();
    },

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    actions: {
      modifyVolumes(type, select) {
        let options = Array.prototype.slice.call(select.target.options, 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        this.set('model.volumes', selectedOptions);
      }

    },

    initVolume() {
      this.set('model.volumes', this.get('model.volumes') || []);
      this.set('volumeChoices', choices);
    }

  });

  _exports.default = _default;
});