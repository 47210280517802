define("shared/store-reset/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    store: Ember.inject.service(),
    app: Ember.inject.service(),

    reset() {
      // Forget all the things
      console.log('Store Reset');
      this.get('globalStore').reset();
      this.get('clusterStore').reset();
      this.get('store').reset();
    }

  });

  _exports.default = _default;
});