define("nodes/components/driver-pnap/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-pnap/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRIVER = 'pnap';
  const CONFIG = 'pnapConfig';

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    pnap: Ember.inject.service(),
    layout: _template.default,
    driverName: DRIVER,
    model: null,
    allProducts: [],
    config: Ember.computed.alias(`model.${CONFIG}`),
    actions: {
      finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }

    },
    type: Ember.computed('allProducts', 'config.{serverLocation,serverType}', function () {
      let products = this.allProducts;

      if (Array.isArray(products)) {
        let serverLocation = this.config.serverLocation;
        let serverType = this.config.serverType;

        for (const prod of products) {
          for (const plan of prod.plans) {
            if (prod.productCode === serverType && plan.location === serverLocation) {
              return prod;
            }
          }
        }
      }

      return products.message;
    }),
    locationChoices: Ember.computed('allProducts', function () {
      let products = this.allProducts;
      let result = [];

      if (Array.isArray(products)) {
        let map = new Map();

        for (const prod of products) {
          for (const plan of prod.plans) {
            if (!map.has(plan.location)) {
              map.set(plan.location, true);
              result.push({
                value: plan.location
              });
            }
          }
        }
      } else {
        result.push({
          value: products.message
        });
      }

      return result;
    }),
    osChoices: Ember.computed(async function () {
      let osList = await this.pnap.request('rancher-node-driver/options');
      let oses = osList.operatingSystems;
      let result = [];

      if (Array.isArray(oses)) {
        let map = new Map();

        for (const os of oses) {
          if (!map.has(os)) {
            map.set(os, true);
            result.push({
              value: os
            });
          }
        }
      } else {
        result.push({
          value: osList.message
        });
      }

      return result;
    }),
    typeChoices: Ember.computed('allProducts', 'config.{serverLocation,serverType}', function () {
      let products = this.allProducts;
      let result = [];

      if (Array.isArray(products)) {
        let map = new Map();
        let serverLocation = this.config.serverLocation;

        for (const prod of products) {
          for (const plan of prod.plans) {
            if (!map.has(prod.productCode) && plan.location === serverLocation) {
              map.set(prod.productCode, true);
              result.push({
                value: prod.productCode,
                label: `${prod.productCode} - $${plan.price}/hour`
              });
            }
          }
        }

        if (!map.has(this.config.serverType) && products.length > 0) {
          this.config.serverType = result[0].value;
          Ember.notifyPropertyChange(this.config, 'serverType');
        }
      } else {
        result.push({
          value: products.message
        });
      }

      return result;
    }),

    populateProducts() {
      this.pnap.request('billing/v1/products?productCategory=SERVER').then(resp => {
        Ember.set(this, 'allProducts', resp);
      });
    },

    bootstrap() {
      let config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        serverLocation: 'PHX',
        serverType: 's1.c1.medium',
        serverOs: 'ubuntu/bionic',
        serverHostname: 'host'
      });
      Ember.set(this, `model.${CONFIG}`, config);
      this.populateProducts();
    },

    validate() {
      this._super();

      let errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});