define("global-admin/security/accounts/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      const store = Ember.get(this, 'globalStore');
      var account = store.createRecord({
        type: 'user'
      });
      return Ember.RSVP.hash({
        users: store.findAll('user'),
        globalRoles: store.findAll('globalrole')
      }).then(hash => {
        return Ember.Object.create({
          account,
          users: hash.users,
          globalRoles: hash.globalRoles
        });
      });
    },

    resetController(controller, isExisting
    /* , transition*/
    ) {
      if (isExisting) {
        Ember.setProperties(controller, {
          canUserLogIn: null,
          errors: null,
          globalRoleSaveError: false,
          password: ''
        });
      }
    }

  });

  _exports.default = _default;
});