define("nodes/custom-drivers/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect(model, transition) {
      if (transition.targetName === 'nodes.custom-drivers.index') {
        this.replaceWith('custom-drivers.cluster-drivers');
      }
    }

  });

  _exports.default = _default;
});