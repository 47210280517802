define("global-admin/clusters/new/launch/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    queryParams: ['clusterTemplateRevision', 'importProvider'],
    clusterTemplateRevision: null,
    importProvider: null,
    cluster: Ember.computed.alias('model.cluster'),
    actions: {
      close() {
        this.transitionToRoute('clusters.index');
      },

      clusterDriverErrorAndTransition() {
        this.transitionToRoute('globalAdmin.clusters.index');
        this.growl.fromError('That provider is no longer active. Please choose a different cluster provider or reactivate the previous cluster provider.');
      }

    }
  });

  _exports.default = _default;
});