define("shared/components/modal-istio-yaml/component", ["exports", "json2yaml", "shared/mixins/modal-base", "shared/utils/fetch-yaml", "shared/components/modal-istio-yaml/template"], function (_exports, _json2yaml, _modalBase, _fetchYaml, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VIRTUAL_SERVICES = 'virtualservices';
  const SERVICE_ENTRIES = 'serviceentries';
  const DESTINATION_RULES = 'destinationrules';
  const GATEWAYS = 'gateways';
  const ENVOY_FILTERS = 'envoyfilters';
  const APP_ID = 'io.cattle.field/appId';
  const OPTIONS = [VIRTUAL_SERVICES, DESTINATION_RULES, GATEWAYS, SERVICE_ENTRIES, ENVOY_FILTERS];

  var _default = Ember.Component.extend(_modalBase.default, {
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    layout: _template.default,
    loading: null,
    selectedType: VIRTUAL_SERVICES,
    options: OPTIONS,
    name: Ember.computed.alias('modalService.modalOpts.name'),
    appId: Ember.computed.alias('modalService.modalOpts.appId'),
    namespace: Ember.computed.alias('modalService.modalOpts.namespace'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'options', OPTIONS.map(opt => {
        return {
          label: opt,
          value: opt
        };
      }));
    },

    selectedTypeDidChange: Ember.on('init', Ember.observer('selectedType', function () {
      this.loadIstioResources(Ember.get(this, 'selectedType'));
    })),
    filename: Ember.computed('selectedType', function () {
      return `${Ember.get(this, 'selectedType')}.yaml`;
    }),

    loadIstioResources(type) {
      const appId = Ember.get(this, 'appId');
      const namespace = Ember.get(this, 'namespace');
      const yamlLink = `/k8s/clusters/${Ember.get(this, 'scope.currentCluster.id')}/apis/networking.istio.io/v1alpha3/namespaces/${namespace}/${type}`;
      Ember.set(this, 'loading', true);
      return (0, _fetchYaml.default)(yamlLink, false).then(res => {
        const out = [];
        const data = JSON.parse(res) || {};
        (Ember.get(data, 'items') || []).forEach(item => {
          const labels = Ember.get(item, 'metadata.labels') || {};

          if (labels[APP_ID] === appId) {
            out.push(item);
          }
        });
        let yaml = out.length > 0 ? _json2yaml.default.stringify(out) : '';
        Ember.set(this, 'yaml', yaml);
      }).catch(error => {
        Ember.get(this, 'growl').fromError(Ember.get(error, 'message') || Ember.get(error, 'xhr.responseJSON.message'));
      }).finally(() => {
        Ember.set(this, 'loading', false);
      });
    }

  });

  _exports.default = _default;
});