define("shared/components/answer-override-row/component", ["exports", "shared/components/answer-override-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    tagName: '',
    addSubrowQuestions: null,
    selectedTemplateModel: null,
    removeAnswerOverride: null,
    answer: null,
    ownedSubquestions: null,
    subquestionAnswers: null,
    answerOverrides: null,
    actions: {
      removeOverride(answer) {
        if (this.subquestionAnswers) {
          this.removeSubquestionsAndSend(this.subquestionAnswers);
        }

        Ember.run.next(() => {
          Ember.get(this, 'removeAnswerOverride')(answer);
        });
      }

    },
    scopeChanged: Ember.observer('answer.scope', function () {
      const subquestionAnswers = this.subquestionAnswers || [];
      const {
        answer
      } = this;

      if (subquestionAnswers.length > 0) {
        subquestionAnswers.forEach(sq => Ember.set(sq, 'scope', Ember.get(answer, 'scope')));
      }
    }),
    allProjectsAndClusters: Ember.computed('scope.allProjects.[]', 'scope.allClusters.[]', 'primaryResource.targets.@each.projectId', function () {
      let out = [];
      Ember.get(this, 'scope.allClusters').forEach(c => {
        out.pushObject({
          name: this.intl.t('newMultiClusterApp.overrides.dropdown.allProjects', {
            clusterName: c.name
          }),
          value: c.id,
          group: this.intl.t('newMultiClusterApp.overrides.dropdown.clusterGroup', {
            clusterName: c.name
          }),
          isCluster: true
        });
        c.get('projects').forEach(p => {
          out.pushObject({
            name: p.name,
            value: p.id,
            group: this.intl.t('newMultiClusterApp.overrides.dropdown.clusterGroup', {
              clusterName: c.name
            }),
            isProject: true
          });
        });
      });
      return out;
    }),
    allQuestions: Ember.computed('answer.answer', 'primaryResource.answers.firstObject.values', 'selectedTemplateModel.questions.[]', 'subquestionAnswers.length', function () {
      let allQuestions = Ember.get(this, 'selectedTemplateModel.questions');
      const {
        answer
      } = this;
      let questionMatch;

      if (!allQuestions) {
        let questionAnswers = Ember.get(this, 'primaryResource.answers.firstObject.values');
        allQuestions = Object.keys(questionAnswers).map(q => {
          return {
            label: q,
            variable: q
          };
        });
      }

      questionMatch = allQuestions.findBy('variable', Ember.get(answer, 'question'));
      let nueQuestions = [];
      allQuestions.forEach(q => {
        if (questionMatch && questionMatch.variable === q.variable) {
          if (q.showSubquestionIf && q.subquestions) {
            let answerMatchesSubQuestionIf = false;

            if (answer.answer && answer.answer.toString) {
              answerMatchesSubQuestionIf = answer.answer.toString() === q.showSubquestionIf;
            }

            if (answerMatchesSubQuestionIf) {
              this.buildSubquestions(q.subquestions);
            } else {
              if (this.subquestionAnswers && this.subquestionAnswers.length > 0) {
                this.removeSubquestionsAndSend(this.subquestionAnswers);
              }
            }
          }
        }

        nueQuestions.pushObject(q);
      });
      return nueQuestions;
    }),

    buildSubquestions(subQuestions) {
      let subquestionAnswers = [];
      subQuestions.forEach(sq => {
        let subQuestionAnswerValue = sq.default;
        let nueOverride = {
          scope: Ember.get(this, 'answer.scope'),
          question: sq,
          answer: subQuestionAnswerValue,
          isSubQuestion: true
        };

        if (Ember.get(this, 'answerOverrides')) {
          let match = Ember.get(this, 'answerOverrides').filterBy('scope', Ember.get(this, 'answer.scope')).findBy('question', Ember.get(sq, 'variable'));

          if (match) {
            Ember.set(nueOverride, 'answer', Ember.get(match, 'answer'));
          }
        }

        subquestionAnswers.pushObject(nueOverride);
      });
      Ember.run.next(() => {
        Ember.set(this, 'subquestionAnswers', subquestionAnswers);
        Ember.get(this, 'addSubrowQuestions')(subquestionAnswers);
      });
    },

    removeSubquestionsAndSend(answers) {
      let removed = [].concat(answers);
      Ember.set(this, 'subquestionAnswers', null);
      Ember.run.next(() => {
        Ember.get(this, 'removeSubrowQuestions')(removed);
      });
    }

  });

  _exports.default = _default;
});