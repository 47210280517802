define("ember-engines/components/link-to-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    didReceiveAttrs() {
      this._super(...arguments);

      const owner = Ember.getOwner(this);

      if (owner.mountPoint) {
        // https://emberjs.github.io/rfcs/0459-angle-bracket-built-in-components.html
        const routeKey = 'targetRouteName' in this ? 'targetRouteName' : 'route';
        const routeName = Ember.get(this, routeKey);

        const externalRoute = owner._getExternalRoute(routeName);

        Ember.set(this, routeKey, externalRoute);
      }
    }

  });

  _exports.default = _default;
});