define("shared/components/form-key-value/component", ["exports", "shared/mixins/upload", "shared/components/form-key-value/template", "jquery", "shared/utils/util"], function (_exports, _upload, _template, _jquery, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function applyLinesIntoArray(lines, ary) {
    lines.forEach(line => {
      line = line.trim();

      if (!line) {
        return;
      }

      let idx = line.indexOf('=');

      if (idx === -1) {
        idx = line.indexOf(': ');
      }

      let key = '';
      let val = '';

      if (idx > 0) {
        key = line.substr(0, idx).trim();
        val = line.substr(idx + 1).trim();
      } else {
        key = line.trim();
        val = '';
      }

      var existing = ary.filterBy('key', key)[0];

      if (existing) {
        Ember.set(existing, 'value', val);
      } else {
        ary.pushObject({
          key,
          value: val
        });
      }
    });
  }

  function removeEmptyEntries(ary, allowEmptyValue = false) {
    // Clean up empty user entries
    let toRemove = [];
    ary.forEach(item => {
      if (!Ember.get(item, 'key') && (!Ember.get(item, 'value') || !allowEmptyValue)) {
        toRemove.push(item);
      }
    });
    ary.removeObjects(toRemove);
  }

  function isMultiline(val) {
    let lines = val.split(/\r?\n/);
    return lines.length > 1;
  }

  var _default = Ember.Component.extend(_upload.default, {
    layout: _template.default,
    // Inputs
    initialStr: null,
    initialMap: null,
    initialArray: null,
    requiredIfAny: null,
    readonlyArray: null,
    keyContent: null,
    valueContent: null,
    ary: null,
    allowEmptyValue: false,
    allowAdd: true,
    allowUpload: false,
    allowRemove: true,
    allowEditKey: true,
    addInitialEmptyRow: false,
    allowMultilineValue: true,
    base64Value: false,
    showNoneLabel: true,
    concealValue: false,
    editing: true,
    trimWhenMultiLines: true,
    kvSeparator: '=',
    separators: ['=', ': '],
    addActionLabel: 'formKeyValue.addAction',
    keyLabel: 'formKeyValue.key.label',
    valueLabel: 'formKeyValue.value.label',
    keyPlaceholder: 'formKeyValue.key.placeholder',
    valuePlaceholder: 'formKeyValue.value.placeholder',
    uploadAction: 'pastedValues',

    init() {
      this._super(...arguments);

      var ary = [];
      var map = Ember.get(this, 'initialMap');
      const readonlyArray = Ember.get(this, 'readonlyArray') || [];

      if (map) {
        Object.keys(map).forEach(key => {
          ary.push({
            key,
            value: map[key],
            editable: readonlyArray.indexOf(key) === -1
          });
        });
      } else if (Ember.get(this, 'initialStr')) {
        let lines = Ember.get(this, 'initialStr').split(',');
        let required = Ember.get(this, 'requiredIfAny');
        applyLinesIntoArray(lines, ary, Ember.get(this, 'kvSeparator'));
        removeEmptyEntries(ary, Ember.get(this, 'allowEmptyValue'));

        if (required) {
          Object.keys(required).forEach(key => {
            let line = ary.findBy('key', key);
            line.editable = false;
          });
        }
      } else if (Ember.get(this, 'initialArray')) {
        Ember.get(this, 'initialArray').forEach(line => {
          ary.push({
            key: line.key,
            value: line.value
          });
        });
      }

      if (Ember.get(this, 'base64Value')) {
        ary.forEach(entry => {
          const decoded = AWS.util.base64.decode(entry.value).toString();

          if (typeof decoded === 'string' && !(0, _util.asciiLike)(decoded)) {
            Ember.set(entry, 'binary', true);
          } else {
            entry.value = decoded;
          }
        });
      }

      Ember.set(this, 'ary', ary);

      if (!ary.length && Ember.get(this, 'addInitialEmptyRow')) {
        this.send('add');
      }
    },

    // groupMap: null,
    actions: {
      add() {
        let ary = Ember.get(this, 'ary');
        let required = Ember.get(this, 'requiredIfAny');

        if (required && !Ember.get(ary, 'length')) {
          Object.keys(required).forEach(k => {
            ary.pushObject({
              key: k,
              value: required[k],
              editable: false
            });
          });
        }

        ary.pushObject({
          key: '',
          value: ''
        });
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          let elem = (0, _jquery.default)(this.element).find('INPUT.key').last();

          if (elem && !Ember.get(this, 'keyContent')) {
            elem.focus();
          }
        });
      },

      remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      },

      pastedValues(str) {
        let ary = Ember.get(this, 'ary');
        str = str.trim();
        let lines = str.split(/\r?\n/);
        applyLinesIntoArray(lines, ary);
        removeEmptyEntries(ary, Ember.get(this, 'allowEmptyValue'));
      }

    },
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,value}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),

    fireChanged() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let map = {};
      let str = '';
      let arr = [];
      Ember.get(this, 'ary').forEach(row => {
        var k = Ember.get(row, 'key').trim();
        var v;

        if (Ember.get(row, 'value') !== undefined && Ember.get(row, 'value') !== null) {
          v = `${Ember.get(row, 'value')}`;

          if (isMultiline(v)) {
            if (this.trimWhenMultiLines) {
              v = v.trim();
            }
          } else {
            v = v.trim();
          }
        }

        if (Ember.get(this, 'base64Value') && !Ember.get(row, 'binary')) {
          v = AWS.util.base64.encode(v);
        }

        if (k && (v || Ember.get(this, 'allowEmptyValue'))) {
          map[k] = v;
          str += (str ? ', ' : '') + k + (v ? Ember.get(this, 'kvSeparator') + v : '');
          arr.push({
            key: k,
            value: v
          });
        }
      });
      Ember.run.next(() => {
        if (this.changed) {
          this.changed(map);
        }

        if (this.changedStr) {
          this.changedStr(str);
        }

        if (this.changedArray) {
          this.changedArray(arr);
        }
      });
    }

  });

  _exports.default = _default;
});