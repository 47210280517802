define("shared/components/form-members-catalog-access/component", ["exports", "shared/components/form-members-catalog-access/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBERS_HEADERS = [{
    translationKey: 'newMultiClusterApp.members.table.name',
    name: 'name',
    sort: ['userPrincipalId', 'groupPrincipalId']
  }, {
    translationKey: 'newMultiClusterApp.members.table.type',
    name: 'type',
    sort: ['displayType']
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    membersHeaders: MEMBERS_HEADERS,
    sortBy: '',
    descending: false,
    excludeMember: false,
    resource: null,
    gotError: null,
    removeMember: null,
    searchOnlyGroups: false,
    includeLocal: false,
    actions: {
      addPrincipal(principal) {
        if (principal && this.addAuthorizedPrincipal) {
          this.addAuthorizedPrincipal(principal);
        }
      }

    },

    addAuthorizedPrincipal() {
      throw new Error('add principal handler must be provided!!');
    }

  });

  _exports.default = _default;
});