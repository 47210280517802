define("shared/components/principal-search/component", ["exports", "ui/utils/constants", "ember-concurrency", "shared/components/searchable-select/component", "ui/utils/platform", "jquery"], function (_exports, _constants, _emberConcurrency, _component, _platform, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_MS = 250;

  var _default = _component.default.extend({
    globalStore: Ember.inject.service(),
    classNames: 'principal-search',
    errors: null,
    _principals: null,
    useLabel: null,
    showDropdownArrow: false,
    clientSideFiltering: false,
    loading: false,
    focused: false,
    selectExactOnBlur: true,
    includeLocal: true,
    sendAfterLoad: false,
    searchOnlyGroups: false,
    content: Ember.computed.alias('filteredPrincipals'),
    value: Ember.computed.alias('filter'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'allUsers', Ember.get(this, 'globalStore').all('user'));
    },

    didInsertElement() {
      // Explicitly not calling super here to not show until there's content this._super(...arguments);
      (0, _jquery.default)(this.element).find('input').on('focus', () => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'focused', true);
        const term = Ember.get(this, 'value');

        if (term) {
          Ember.set(this, '_principals', []);
          this.search.perform(term);
          this.send('show');
        }
      });
      (0, _jquery.default)(this.element).find('input').on('blur', () => {
        Ember.run.later(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'focused', false);

          if (Ember.get(this, 'selectExactOnBlur')) {
            this.scheduleSend();
          }

          this.send('hide');
        }, 250);
      });
    },

    actions: {
      search(term, e) {
        const kc = e.keyCode;
        this.send('show');

        if (kc === _constants.default.KEY.CR || kc === _constants.default.KEY.LF) {
          this.scheduleSend();
          return;
        }

        var isAlpha = k => {
          return !Ember.get(this, 'metas').includes(k) && !(0, _platform.isAlternate)(k) && !(0, _platform.isRange)(k) && !(0, _platform.isMore)(k);
        };

        if (isAlpha(kc)) {
          Ember.set(this, 'principal', null);
          this.add();
          this.search.perform(term);
        }
      },

      show() {
        if (Ember.get(this, 'showOptions') === true) {
          return;
        }

        const toBottom = (0, _jquery.default)('body').height() - (0, _jquery.default)(this.element).offset().top - 60; // eslint-disable-line

        Ember.setProperties(this, {
          maxHeight: toBottom < Ember.get(this, 'maxHeight') ? toBottom : Ember.get(this, 'maxHeight'),
          showOptions: true
        });
      },

      hide() {
        Ember.setProperties(this, {
          filter: Ember.get(this, 'displayLabel'),
          showOptions: false,
          '$activeTarget': null
        });
      }

    },
    filteredPrincipals: Ember.computed('_principals.@each.{id,state}', function () {
      return (Ember.get(this, '_principals') || []).map(principal => {
        // console.log({label: get(principal, 'displayName') || get(principal, 'loginName') || get(principal, 'name'), value: get(principal, 'id'), provider: get(principal, 'provider'),});
        return {
          label: Ember.get(principal, 'displayName') || Ember.get(principal, 'loginName') || Ember.get(principal, 'name'),
          value: Ember.get(principal, 'id'),
          provider: Ember.get(principal, 'provider'),
          type: Ember.get(principal, 'principalType'),
          principal
        };
      });
    }),
    externalChanged: Ember.on('init', Ember.observer('external', function () {
      let principal = Ember.get(this, 'external');

      if (principal) {
        Ember.setProperties(this, {
          readOnly: true,
          optionValuePath: 'label'
        });
        this.setSelect({
          label: Ember.get(principal, 'displayName') || Ember.get(principal, 'loginName') || Ember.get(principal, 'name'),
          value: Ember.get(principal, 'id'),
          provider: Ember.get(principal, 'provider'),
          type: Ember.get(principal, 'principalType')
        });
      }
    })),
    metas: Ember.computed(() => {
      return Object.keys(_constants.default.KEY).map(k => _constants.default.KEY[k]);
    }),
    displayLabel: Ember.computed('interContent.[]', 'intl', 'localizedLabel', 'optionLabelPath', 'optionValuePath', 'prompt', 'value', function () {
      const value = Ember.get(this, 'value');

      if (!value) {
        return null;
      }

      const vp = Ember.get(this, 'optionValuePath');
      const lp = Ember.get(this, 'optionLabelPath');
      const selectedItem = Ember.get(this, 'interContent').filterBy(vp, value).get('firstObject');

      if (selectedItem) {
        let label = Ember.get(selectedItem, lp);

        if (Ember.get(this, 'localizedLabel')) {
          label = Ember.get(this, 'intl').t(label);
        }

        return label;
      }

      return value;
    }),
    showMessage: Ember.computed('filtered.[]', 'value', function () {
      if (!Ember.get(this, 'value')) {
        return false;
      }

      return Ember.get(this, 'filtered.length') === 0;
    }),

    scheduleSend() {
      if (Ember.get(this, 'loading')) {
        Ember.set(this, 'sendExactAfterSearch', true);
      } else {
        Ember.set(this, 'sendExactAfterSearch', false);
        this.sendSelectExact();
      }
    },

    sendSelectExact() {
      const value = Ember.get(this, 'value');
      const match = Ember.get(this, 'filteredPrincipals').findBy('label', value);
      let principal = null;

      if (match) {
        principal = match.principal;
      } else {
        Ember.set(this, 'value', '');
      }

      this.selectExact(principal);
      this.send('hide');
    },

    setSelect(item) {
      const gp = Ember.get(this, 'optionGroupPath');
      const vp = Ember.get(this, 'optionValuePath');
      Ember.set(this, 'value', Ember.get(item, vp));

      if (gp && Ember.get(item, gp)) {
        Ember.set(this, 'group', Ember.get(item, gp));
      }

      Ember.set(this, 'filter', Ember.get(this, 'displayLabel'));
      Ember.set(this, 'principal', item);
      this.add();
      this.send('hide');
    },

    search: (0, _emberConcurrency.task)(function* (term) {
      if (Ember.isBlank(term)) {
        Ember.setProperties(this, {
          '_principals': [],
          loading: false
        });
        return;
      } // Pause here for DEBOUNCE_MS milliseconds. Because this
      // task is `restartable`, if the principal starts typing again,
      // the current search will be canceled at this point and
      // start over from the beginning. This is the
      // ember-concurrency way of debouncing a task.


      Ember.set(this, 'loading', true);
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      let xhr = yield this.goSearch.perform(term);
      let neu = [];

      if (xhr.status >= 200 && xhr.status <= 299 && xhr.body && typeof xhr.body === 'object' && xhr.body.data) {
        neu = xhr.body.data;
      }

      if (Ember.get(this, 'includeLocal')) {
        let normalizedTerm = term.toLowerCase().trim();
        let foundIds = {};
        neu.forEach(x => {
          foundIds[x.id] = true;
        });
        let local = Ember.get(this, 'allUsers');
        local = local.filter(x => {
          if ((x.name || '').toLowerCase().trim().startsWith(normalizedTerm) || (x.username || '').toLowerCase().trim().startsWith(normalizedTerm)) {
            for (let i = 0; i < x.principalIds.length; i++) {
              if (foundIds[x.principalIds[i]]) {
                return false;
              }
            }

            return true;
          }

          return false;
        });
        const globalStore = Ember.get(this, 'globalStore');
        local = local.map(x => {
          return globalStore.getById('principal', x.principalIds[0]);
        });
        local = local.filter(x => !!x);
        neu.addObjects(local);
      }

      Ember.set(this, '_principals', neu);
      return xhr;
    }).restartable(),
    goSearch: (0, _emberConcurrency.task)(function* (term) {
      const {
        globalStore
      } = this;
      const data = {
        name: term
      };

      if (this.searchOnlyGroups) {
        Ember.set(data, 'principalType', 'group');
      }

      try {
        return yield globalStore.rawRequest({
          url: 'principals?action=search',
          method: 'POST',
          data
        });
      } catch (xhr) {
        Ember.set(this, 'errors', [`${xhr.status}: ${xhr.statusText}`]);
      } finally {
        Ember.set(this, 'loading', false);

        if (Ember.get(this, 'sendExactAfterSearch')) {
          this.scheduleSend();
        }
      }
    }),

    add() {
      throw new Error('add action is required!');
    },

    selectExact() {
      throw new Error('selectExact action is required!');
    }

  });

  _exports.default = _default;
});