define("global-admin/global-dns/providers/new/controller", ["exports", "shared/mixins/view-new-edit"], function (_exports, _viewNewEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DNS_PROVIDERS = ['route53', 'alidns', 'cloudflare'];

  var _default = Ember.Controller.extend(_viewNewEdit.default, {
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    queryParams: ['id', 'activeProvider'],
    memberAccessTypes: ['owner', 'read-only'],
    id: null,
    activeProvider: 'route53',
    mode: 'new',
    saveDisabled: false,
    config: Ember.computed.alias('model'),
    primaryResource: Ember.computed.alias('config'),
    actions: {
      switchProvider(provider) {
        Ember.set(this, 'activeProvider', provider);
      },

      cancel() {
        this.router.transitionTo('global-admin.global-dns.providers.index');
      },

      addAuthorizedPrincipal(principal) {
        if (principal) {
          let {
            members = []
          } = this.model;

          if (!members) {
            members = [];
          }

          Ember.set(principal, 'accessType', 'owner');
          members.pushObject(this.globalStore.createRecord(principal));
          Ember.set(this, 'model.members', members);
        }
      },

      removeMember(member) {
        let {
          members
        } = this.model;
        members.removeObject(member);
      }

    },
    additionalOptions: Ember.computed('activeProvider', 'config.route53.additionalOptions', 'config.cloudflare.additionalOptions', 'config.alidns.additionalOptions', {
      get() {
        const {
          activeProvider,
          config
        } = this;
        const providerConfig = Ember.get(config, `${activeProvider}ProviderConfig`);
        return Ember.get(providerConfig, 'additionalOptions') || {};
      },

      set(key, value) {
        Ember.set(this, `config.${this.activeProvider}ProviderConfig.additionalOptions`, value);
        return value;
      }

    }),
    availableProviders: Ember.computed('activeProvider', 'isEdit', function () {
      if (Ember.get(this, 'isEdit')) {
        return [{
          name: Ember.get(this, 'activeProvider')
        }];
      } else {
        return DNS_PROVIDERS.map(p => {
          return {
            name: p
          };
        });
      }
    }),

    validate() {
      const providerConfig = Ember.get(this, `config.${this.activeProvider}ProviderConfig`);
      const {
        mode
      } = this;

      if (mode === 'edit' && providerConfig && providerConfig.hasOwnProperty('secretKey')) {
        if (providerConfig.secretKey === '' || providerConfig.secretKey === null) {
          delete providerConfig.secretKey;
        }
      }

      return this._super(...arguments);
    },

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});