define("shared/utils/subscribe-project", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_subscribe.default, {
    scope: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'endpoint', Ember.get(this, 'app.projectSubscribeEndpoint'));
    },

    validate() {
      const socket = Ember.get(this, 'subscribeSocket');
      const metadata = socket.getMetadata();
      const socketProjectId = Ember.get(metadata, 'projectId');
      const currentProjectId = Ember.get(this, 'scope.currentProject.id');

      if (!currentProjectId || currentProjectId === socketProjectId) {
        return true;
      } else {
        console.error(`${this.label} Subscribe ignoring message, current=${currentProjectId} socket=${socketProjectId} ${this.forStr()}`);
        return false;
      }
    }

  });

  _exports.default = _default;
});