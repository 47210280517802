define("shared/mixins/subscribe", ["exports", "ui/utils/socket", "ui/utils/constants", "ui/utils/queue"], function (_exports, _socket, _constants, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    label: '',
    endpoint: null,
    intl: null,
    growl: null,
    scope: null,
    store: null,
    watchState: false,
    watchStateOf: null,
    globalStore: null,
    clusterStore: null,
    updateProjectStore: true,
    updateGlobalStore: true,
    updateClusterStore: true,
    validate: null,
    subscribeSocket: null,
    reconnect: true,
    connected: false,
    queue: null,
    queueTimer: null,
    warningShown: false,
    wasConnected: false,
    disconnectedTimer: null,

    init() {
      this._super(...arguments);

      let queue = new _queue.default();
      Ember.set(this, 'queue', queue);

      var socket = _socket.default.create();

      socket.on('message', event => {
        Ember.run.schedule('actions', this, function () {
          // Fail-safe: make sure the message is for the current project/cluster
          if (this.validate && !this.validate()) {
            this.connect();
            return;
          }

          if (!event || !event.data) {
            return;
          }

          try {
            var d = JSON.parse(event.data);
          } catch (e) {
            console.error(`Error parsing ${this.label} change event:`, e);
            return;
          }

          switch (d.name) {
            case 'resource.change':
            case 'resource.remove':
              queue.enqueue(d); // console.log(`${this.label} Change event`, queue.getLength(), 'in queue');

              break;

            case 'logout':
              this.send('logout', null);
              break;

            case 'ping':
              this.subscribePing(d);
              break;
          }
        });
      });
      socket.on('connected', (tries, after) => {
        this.subscribeConnected(tries, after);
      });
      socket.on('frameTimeout', () => {
        this.showDisconnectedWarning();
      });
      socket.on('disconnected', () => {
        this.subscribeDisconnected(Ember.get(this, 'tries'));
      });
      Ember.set(this, 'subscribeSocket', socket);
    },

    processQueue() {
      let queue = Ember.get(this, 'queue');

      if (!queue.getLength()) {
        return;
      }

      const projectStore = Ember.get(this, 'store');
      const clusterStore = Ember.get(this, 'clusterStore');
      const globalStore = Ember.get(this, 'globalStore'); // let count = 0;

      let event = queue.dequeue();
      let projectId, type, forceRemove;
      Ember.beginPropertyChanges();

      while (event) {
        if (!event.data) {
          event = queue.dequeue();
          continue;
        }

        projectId = Ember.get(event.data, 'projectId');
        type = Ember.get(event.data, 'type');
        forceRemove = event.name === 'resource.remove'; // console.log(this.label, (forceRemove ? 'Remove' : 'Change'), type +':'+ event.data.id,  projectId);

        if (Ember.get(this, 'updateProjectStore') && projectId && checkStoreHasType(projectStore, type)) {
          // console.log('  Update project store', type, event.data.id, projectId);
          updateStore(projectStore, event.data, forceRemove);
        }

        if (Ember.get(this, 'updateClusterStore') && checkStoreHasType(clusterStore, type)) {
          // console.log('  Update cluster store', type, event.data.id);
          updateStore(clusterStore, event.data, forceRemove);
        }

        if (Ember.get(this, 'updateGlobalStore') && checkStoreHasType(globalStore, type)) {
          // console.log('  Update global store', type, event.data.id);
          updateStore(globalStore, event.data, forceRemove);
        } // count++;


        event = queue.dequeue();
      }

      Ember.endPropertyChanges(); // console.log(`Processed ${count} ${this.label} change events`);

      function checkStoreHasType(store, type) {
        return Ember.run.next(() => store.hasType(type));
      }

      function updateStore(store, data, forceRemove = false) {
        // Update the state to removed before we remove it from store
        if (forceRemove) {
          data.state = 'removed';
        } // Typeify adds or updates the store entry


        const resource = store._typeify(data);

        if (resource) {
          // Remove from store if the resource is removed
          if (forceRemove || _constants.default.REMOVEDISH_STATES.includes(resource.state)) {
            const type = Ember.get(resource, 'type');

            store._remove(type, resource);
          }
        }
      }
    },

    connect(force = true, clusterId, projectId) {
      if (Ember.get(this, 'watchState')) {
        const state = Ember.get(this, 'watchStateOf.relevantState');

        if (state !== 'active') {
          console.log(`${this.label} Subscribe not connecting because state isn't active (${state})`);
          return;
        }
      }

      if (Ember.get(this, 'connected') && !force) {
        return;
      }

      const socket = Ember.get(this, 'subscribeSocket');
      projectId = projectId || Ember.get(this, 'scope.pendingProject.id');
      clusterId = clusterId || Ember.get(this, 'scope.pendingCluster.id');
      const url = `ws://${window.location.host}${Ember.get(this, 'endpoint').replace(Ember.get(this, 'app.projectToken'), projectId).replace(Ember.get(this, 'app.clusterToken'), clusterId)}`;
      Ember.set(this, 'reconnect', true);
      socket.setProperties({
        url,
        autoReconnect: true
      });
      socket.reconnect({
        projectId,
        clusterId
      });
    },

    disconnect(cb) {
      return new Ember.RSVP.Promise((resolve
      /* , reject*/
      ) => {
        Ember.setProperties(this, {
          reconnect: false,
          tries: 0
        });
        var socket = Ember.get(this, 'subscribeSocket');

        if (!socket || socket._state === 'disconnected') {
          if (cb) {
            cb();
          }

          resolve();
          return;
        }

        console.log(`${this.label} Subscribe disconnect ${this.forStr()}`);
        socket.set('tries', 0);
        socket.disconnect(() => {
          if (cb) {
            cb();
          }

          resolve();
        });
      });
    },

    forStr() {
      let out = '';
      let socket = Ember.get(this, 'subscribeSocket');

      if (socket) {
        out = `(sockId=${socket.getId()})`;
      }

      return out;
    },

    // WebSocket connected
    subscribeConnected(tries, msec) {
      Ember.set(this, 'queueTimer', setInterval(this.processQueue.bind(this), 1000));
      Ember.set(this, 'connected', true);
      Ember.set(this, 'wasConnected', true);

      if (Ember.get(this, 'warningShown')) {
        Ember.get(this, 'growl').close();
        Ember.set(this, 'warningShown', false);
      }

      Ember.run.cancel(Ember.get(this, 'disconnectedTimer'));
      let msg = `${this.label} Subscribe connected ${this.forStr()}`;

      if (tries > 0) {
        msg += ` (after ${tries} ${tries === 1 ? 'try' : 'tries'}`;

        if (msec) {
          msg += `, ${msec / 1000} sec`;
        }

        msg += ')';
      }

      console.log(msg);
    },

    // WebSocket disconnected (unexpectedly)
    subscribeDisconnected() {
      Ember.set(this, 'connected', false);
      Ember.get(this, 'queue').clear();
      clearInterval(Ember.get(this, 'queueTimer'));
      console.log(`${this.label} Subscribe disconnected ${this.forStr()}`);

      if (Ember.get(this, 'reconnect')) {
        this.showDisconnectedWarning();
      }
    },

    subscribePing() {// This is annoying now with 3 connections alternating messages
      // console.log('Subscribe ping ' + this.forStr());
    },

    showDisconnectedWarning() {
      // if ( !get(this, 'warningShown') && get(this, 'wasConnected') ) {
      if (Ember.get(this, 'wasConnected')) {
        //      const intl = get(this,'intl');
        // @TODO-2.0      get(this,'growl').error(intl.t('growl.webSocket.connecting.title'), intl.t('growl.webSocket.connecting.disconnectedWarning'));
        // set(this, 'warningShown', true);
        Ember.set(this, 'disconnectedTimer', Ember.run.later(this, () => {
          if (window.navigator.onLine) {
            window.location.reload();
          } else {
            window.ononline = function () {
              window.location.reload();
            };
          }
        }, _constants.default.WEBSOCKET.SUBSCRIBE_DISCONNECTED_TIMEOUT));
      }
    }

  });

  _exports.default = _default;
});