define("global-admin/components/account-group-row/component", ["exports", "global-admin/components/account-group-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    model: null,
    isLocal: null,
    groupRoleBinding: null,
    tagName: 'TR',
    classNames: 'main-row',
    groupPrincipal: Ember.computed.alias('model'),
    globalRoleBindings: Ember.computed.alias('model.globalRoleBindings'),
    mappedGroupRoleBindingNamesIds: Ember.computed.alias('model.mappedGroupRoleBindingNamesIds'),

    init() {
      this._super(...arguments);

      if (!Ember.isEmpty(this.model.globalRoleBindings)) {
        if (Ember.isEmpty(this.groupRoleBinding)) {
          Ember.set(this, 'groupRoleBinding', this.globalStore.createRecord({
            type: 'groupGlobalRoleBindings'
          }));
        }

        Ember.setProperties(this, {
          'groupRoleBinding.groupPrincipalId': this.groupPrincipal.id,
          'groupRoleBinding.groupPrincipalName': this.groupPrincipal.displayName,
          'groupRoleBinding.globalRoleBindingIds': this.mappedGroupRoleBindingNamesIds.mapBy('groupRoleBindingId'),
          'groupRoleBinding.mappedGroupRoleBindingNamesIds': this.mappedGroupRoleBindingNamesIds
        });
      }
    }

  });

  _exports.default = _default;
});