define("shared/components/form-project-targets/component", ["exports", "shared/components/form-project-targets/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    translationKey: 'newMultiClusterApp.targets.cluster',
    name: 'clusterName',
    sort: ['clusterName']
  }, {
    translationKey: 'newMultiClusterApp.targets.project',
    name: 'projectName',
    sort: ['projectName']
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    classNames: ['row'],
    headers: HEADERS,
    projects: null,
    readOnly: false,
    fullWidth: false,
    targets: null,
    sortBy: 'clusterName',
    searchText: '',
    appTargets: Ember.computed('targets.@each.projectId', function () {
      return Ember.get(this, 'targets') || [];
    }),
    allProjectsGroupedByCluster: Ember.computed('projects.[]', 'targets.@each.projectId', function () {
      return Ember.get(this, 'projects').filter(p => Ember.get(p, 'cluster')).map(p => {
        const clusterDisplayName = Ember.get(p, 'cluster.displayName');
        const out = {
          name: Ember.get(p, 'displayName'),
          value: Ember.get(p, 'id'),
          cluster: clusterDisplayName
        };

        if (Ember.get(this, 'targets').findBy('projectId', p.id)) {
          Ember.set(out, 'disabled', true);
        } else {
          if (!out.disabled) {
            Ember.set(out, 'disabled', false);
          }
        }

        return out;
      }).filter(c => !c.disabled); // searchable-select doesn't use the disable flag
    })
  });

  _exports.default = _default;
});