define("@rancher/ember-api-store/index", ["exports", "@rancher/ember-api-store/version"], function (_exports, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EmberApiStore = Ember.Namespace.create({
    VERSION: _version.default
  });

  if (Ember.libraries) {
    Ember.libraries.registerCoreLibrary('Ember API Store', EmberApiStore.VERSION);
  }

  var _default = EmberApiStore;
  _exports.default = _default;
});