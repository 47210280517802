define("shared/helpers/date-from-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateFromNow = dateFromNow;
  _exports.default = void 0;

  function dateFromNow(params) {
    let d = (0, _moment.default)(params[0]);
    let str = `<span title="${d.format('llll')}">${d.fromNow()}</span>`;
    return Ember.String.htmlSafe(str);
  }

  var _default = Ember.Helper.helper(dateFromNow);

  _exports.default = _default;
});