define("global-admin/security/accounts/groups/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    hasRefreshProviderAccess: false,

    beforeModel() {
      return this.globalStore.findAll('globalrolebinding');
    },

    model() {
      const globalRoleBindings = this.globalStore.all('globalrolebinding').filterBy('groupPrincipalId');
      const promises = [];
      const uniqGroupPrincipalIds = globalRoleBindings.mapBy('groupPrincipalId').uniq();
      uniqGroupPrincipalIds.forEach(grb => {
        promises.push(this.globalStore.find('principal', grb));
      });
      return Ember.RSVP.allSettled(promises).then(resp => {
        const groupPrincipals = resp.filterBy('state', 'fulfilled').mapBy('value');
        return {
          globalRoleBindings,
          groupPrincipals
        };
      });
    },

    afterModel() {
      return this.globalStore.rawRequest({
        url: `users?limit=0`,
        method: 'GET'
      }).then(users => {
        if (Ember.get(users, 'body.actions.refreshauthprovideraccess')) {
          Ember.set(this, 'hasRefreshProviderAccess', true);
        }

        return;
      });
    },

    setupController(controller, model) {
      if (Ember.get(this, 'hasRefreshProviderAccess')) {
        controller.set('hasRefreshProviderAccess', true);
      }

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});