define("shared/components/accordion-list-item/component", ["exports", "shared/components/accordion-list-item/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classForStatus = classForStatus;
  _exports.default = _exports.STATUS_INTL_KEY = _exports.STATUS = void 0;
  const NONE = 'none';
  const INCOMPLETE = 'incomplete';
  const ERROR = 'error';
  const NOTCONFIGURED = 'notConfigured';
  const CONFIGURED = 'configured';
  const COUNTCONFIGURED = 'countConfigured';
  const STANDARD = 'standard';
  const SPECIFIC = 'specific';
  const CUSTOM = 'custom';
  const RULE = 'rule';
  const ANY = 'any';
  const STATUS = {
    NONE,
    INCOMPLETE,
    ERROR,
    NOTCONFIGURED,
    CONFIGURED,
    COUNTCONFIGURED,
    STANDARD,
    CUSTOM,
    SPECIFIC,
    RULE,
    ANY
  };
  _exports.STATUS = STATUS;
  const STATUS_INTL_KEY = 'accordionRow.status';
  _exports.STATUS_INTL_KEY = STATUS_INTL_KEY;

  function classForStatus(status) {
    switch (status) {
      case NONE:
      case NOTCONFIGURED:
      case STANDARD:
      case ANY:
        return 'text-muted';

      case INCOMPLETE:
      case ERROR:
        return 'text-error';

      default:
        return 'text-success';
    }
  }

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion'],
    name: null,
    title: null,
    detail: null,
    status: null,
    statusClass: null,
    showStatue: true,
    intent: null,
    showExpand: true,
    expandOnInit: false,
    expanded: false,
    expandAll: false,
    everExpanded: false,

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('render', this, this.setupExpandOnInit);
    },

    actions: {
      doExpand() {
        if (Ember.get(this, 'showExpand')) {
          this.expand(this);
        }
      },

      goToGrafana() {
        window.open(Ember.get(this, 'grafanaUrl'), '_blank');
      }

    },
    expdObserver: Ember.on('init', Ember.observer('expanded', function () {
      if (Ember.get(this, 'expanded') && !Ember.get(this, 'intent')) {
        if (!Ember.get(this, 'everExpanded')) {
          Ember.run.next(() => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            Ember.set(this, 'everExpanded', true);
          });
        }

        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'intent', Ember.get(this, 'componentName'));
        });
      }
    })),
    expandAllObserver: Ember.on('init', Ember.observer('expandAll', function () {
      var ea = Ember.get(this, 'expandAll');

      if (ea) {
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'expanded', true);
        });
      } else {
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'expanded', false);
        });
      }
    })),

    expand() {},

    setupExpandOnInit() {
      let eoi = Ember.get(this, 'expandOnInit');

      if (eoi) {
        if (!Ember.get(this, 'everExpanded')) {
          Ember.run.next(() => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            Ember.set(this, 'everExpanded', true);
          });
        }

        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.run.next(() => {
            Ember.set(this, 'expanded', eoi);
          });
        });
      }

      if ((0, _jquery.default)('.accordion-detail-text a').length) {
        (0, _jquery.default)('.accordion-detail-text a').attr('onclick', 'event.stopPropagation();');
      }
    }

  });

  _exports.default = _default;
});