define("global-admin/security/authentication/freeipa/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    resourceType: 'freeipaconfig',

    model() {
      const gs = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        freeipaConfig: gs.find('authconfig', 'freeipa', {
          forceReload: true
        }),
        principals: gs.all('principal')
      });
    },

    setupController(controller, model) {
      controller.setProperties({
        model,
        confirmDisable: false,
        testing: false,
        organizations: this.get('session.orgs') || [],
        errors: null
      });
    },

    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }

  });

  _exports.default = _default;
});