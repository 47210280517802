define("shared/components/schema/input-certificate/component", ["exports", "shared/components/schema/input-certificate/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    field: null,
    value: null,
    choices: null,
    loading: true,
    default: Ember.computed.alias('field.default'),

    init() {
      this._super(...arguments);

      this.get('store').findAll('certificate').then(choices => {
        var def = this.get('default');

        if (this.get('value') === undefined) {
          if (def) {
            var match = choices.filterBy('name', def);

            if (match.length > 0) {
              this.set('value', def);
            } else {
              this.set('value', null);
            }
          } else {
            this.set('value', null);
          }
        }

        this.setProperties({
          loading: false,
          choices
        });
      });
    }

  });

  _exports.default = _default;
});