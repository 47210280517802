define("shared/components/node-group-row/component", ["exports", "semver", "shared/utils/amazon", "ui/models/cluster", "shared/components/node-group-row/template"], function (_exports, _semver, _amazon, _cluster, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    classNames: ['row', 'mb-20'],
    instanceTypes: _amazon.INSTANCE_TYPES,
    defaultNodeGroupConfig: _cluster.DEFAULT_NODE_GROUP_CONFIG,
    clusterConfig: null,
    keyPairs: null,
    mode: null,
    model: null,
    nodeGroupsVersionCollection: null,
    originalCluster: null,
    versions: null,
    launchTemplates: null,
    allSelectedTemplateVersions: null,
    clusterSaving: false,
    clusterSaved: false,
    nameIsEditable: true,
    showNodeUpgradePreventionReason: false,
    upgradeVersion: false,
    refreshResourceInstanceTags: true,
    // simply used for reinit'ing the resource instance tags key value component rather than add weird logic to recomput to the component
    editing: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments);

      if (!this.launchTemplates) {
        Ember.set(this, 'launchTemplates', []);
      }

      if (this.editing) {
        if (!Ember.isEmpty(this.model.nodegroupName)) {
          Ember.set(this, 'nameIsEditable', false);
        }
      }
    },

    actions: {
      setTags(section) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'model.tags', section);
      },

      setLabels(section) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'model.labels', section);
      },

      setResourceTags(section) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'resourceTags', section);
      }

    },
    instanceType: Ember.computed('defaultNodeGroupConfig.instanceType', 'isNoLaunchTemplate', 'model.instanceType', 'selectedTemplateVersionInfo.LaunchTemplateData.InstanceType', 'selectedTemplateVersionInfo.LaunchTemplateData.InstanceMarketOptions.MarketType', {
      get() {
        var _this$model;

        let instanceType = this === null || this === void 0 ? void 0 : (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.instanceType;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return instanceType;
        } else if (selectedTemplateVersionInfo) {
          var _LaunchTemplateData$I;

          const LaunchTemplateData = selectedTemplateVersionInfo === null || selectedTemplateVersionInfo === void 0 ? void 0 : selectedTemplateVersionInfo.LaunchTemplateData;
          const MarketType = LaunchTemplateData === null || LaunchTemplateData === void 0 ? void 0 : (_LaunchTemplateData$I = LaunchTemplateData.InstanceMarketOptions) === null || _LaunchTemplateData$I === void 0 ? void 0 : _LaunchTemplateData$I.MarketType;
          const InstanceType = (LaunchTemplateData === null || LaunchTemplateData === void 0 ? void 0 : LaunchTemplateData.InstanceType) ? LaunchTemplateData === null || LaunchTemplateData === void 0 ? void 0 : LaunchTemplateData.InstanceType : this.defaultNodeGroupConfig.instanceType;

          if (!Ember.isEmpty(MarketType) && MarketType === 'spot') {
            return null;
          }

          return InstanceType;
        }

        return instanceType;
      },

      set(_key, instanceType) {
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.instanceType', instanceType);
        } else if (selectedTemplateVersionInfo) {
          const {
            LaunchTemplateData: {
              InstanceMarketOptions: {
                MarketType
              }
            }
          } = selectedTemplateVersionInfo;

          if (!Ember.isEmpty(MarketType) && MarketType === 'spot') {
            Ember.set(this, 'model.instanceType', instanceType);
          }
        }

        return instanceType;
      }

    }),
    imageId: Ember.computed('defaultNodeGroupConfig.imageId', 'isNoLaunchTemplate', 'model.imageId', 'selectedTemplateVersionInfo.LaunchTemplateData.ImageId', {
      get() {
        var _this$model2;

        let imageId = this === null || this === void 0 ? void 0 : (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.imageId;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return imageId;
        } else if (selectedTemplateVersionInfo) {
          const {
            LaunchTemplateData: {
              ImageId = this.defaultNodeGroupConfig.imageId
            }
          } = selectedTemplateVersionInfo;
          return ImageId !== null && ImageId !== void 0 ? ImageId : this.defaultNodeGroupConfig.imageId;
        }

        return imageId;
      },

      set(_key, imageId) {
        const {
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.imageId', imageId);
        }

        return imageId;
      }

    }),
    diskSize: Ember.computed('defaultNodeGroupConfig.diskSize', 'isNoLaunchTemplate', 'model.diskSize', 'selectedTemplateVersionInfo.LaunchTemplateData.BlockDeviceMappings.[]', {
      get() {
        var _this$model3;

        let diskSize = this === null || this === void 0 ? void 0 : (_this$model3 = this.model) === null || _this$model3 === void 0 ? void 0 : _this$model3.diskSize;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return diskSize;
        } else if (selectedTemplateVersionInfo) {
          var _selectedTemplateVers, _selectedTemplateVers2, _selectedTemplateVers3, _blockDeviceMappings$, _blockDeviceMappings$2;

          const blockDeviceMappings = (_selectedTemplateVers = selectedTemplateVersionInfo === null || selectedTemplateVersionInfo === void 0 ? void 0 : (_selectedTemplateVers2 = selectedTemplateVersionInfo.LaunchTemplateData) === null || _selectedTemplateVers2 === void 0 ? void 0 : (_selectedTemplateVers3 = _selectedTemplateVers2.BlockDeviceMappings) === null || _selectedTemplateVers3 === void 0 ? void 0 : _selectedTemplateVers3.firstObject) !== null && _selectedTemplateVers !== void 0 ? _selectedTemplateVers : {};
          const DiskSize = (_blockDeviceMappings$ = blockDeviceMappings === null || blockDeviceMappings === void 0 ? void 0 : (_blockDeviceMappings$2 = blockDeviceMappings.Ebs) === null || _blockDeviceMappings$2 === void 0 ? void 0 : _blockDeviceMappings$2.VolumeSize) !== null && _blockDeviceMappings$ !== void 0 ? _blockDeviceMappings$ : this.defaultNodeGroupConfig.diskSize;
          return DiskSize !== null && DiskSize !== void 0 ? DiskSize : this.defaultNodeGroupConfig.diskSize;
        }

        return diskSize;
      },

      set(_key, diskSize) {
        const {
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.diskSize', diskSize);
        }

        return diskSize;
      }

    }),
    ec2SshKey: Ember.computed('defaultNodeGroupConfig.ec2SshKey', 'isNoLaunchTemplate', 'model.ec2SshKey', 'selectedTemplateVersionInfo.LaunchTemplateData.KeyName', {
      get() {
        var _this$model4;

        let ec2SshKey = this === null || this === void 0 ? void 0 : (_this$model4 = this.model) === null || _this$model4 === void 0 ? void 0 : _this$model4.ec2SshKey;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return ec2SshKey;
        } else if (selectedTemplateVersionInfo) {
          const {
            LaunchTemplateData: {
              KeyName = this.defaultNodeGroupConfig.ec2SshKey
            }
          } = selectedTemplateVersionInfo;
          return KeyName !== null && KeyName !== void 0 ? KeyName : this.defaultNodeGroupConfig.ec2SshKey;
        }

        return ec2SshKey;
      },

      set(_key, ec2SshKey) {
        const {
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.ec2SshKey', ec2SshKey);
        }

        return ec2SshKey;
      }

    }),
    userData: Ember.computed('defaultNodeGroupConfig.userData', 'isNoLaunchTemplate', 'model.userData', 'selectedTemplateVersionInfo.LaunchTemplateData.UserData', {
      get() {
        var _this$model5;

        let userData = this === null || this === void 0 ? void 0 : (_this$model5 = this.model) === null || _this$model5 === void 0 ? void 0 : _this$model5.userData;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return userData;
        } else if (selectedTemplateVersionInfo) {
          var _UserData;

          let {
            LaunchTemplateData: {
              UserData = this.defaultNodeGroupConfig.userData
            }
          } = selectedTemplateVersionInfo;

          try {
            UserData = atob(UserData);
          } catch (_err) {}

          return (_UserData = UserData) !== null && _UserData !== void 0 ? _UserData : this.defaultNodeGroupConfig.userData;
        }

        return userData;
      },

      set(_key, userData) {
        const {
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.userData', userData);
        }

        return userData;
      }

    }),
    resourceTags: Ember.computed('defaultNodeGroupConfig.resourceTags', 'isNoLaunchTemplate', 'model.resourceTags', 'selectedTemplateVersionInfo.LaunchTemplateData.TagSpecifications.[]', {
      get() {
        var _this$model6;

        let resourceTags = this === null || this === void 0 ? void 0 : (_this$model6 = this.model) === null || _this$model6 === void 0 ? void 0 : _this$model6.resourceTags;
        const {
          selectedTemplateVersionInfo,
          isNoLaunchTemplate
        } = this;

        if (isNoLaunchTemplate) {
          return resourceTags;
        } else if (selectedTemplateVersionInfo) {
          var _selectedTemplateVers4, _selectedTemplateVers5, _resourceInstanceTags;

          const resourceInstanceTags = ((_selectedTemplateVers4 = selectedTemplateVersionInfo === null || selectedTemplateVersionInfo === void 0 ? void 0 : (_selectedTemplateVers5 = selectedTemplateVersionInfo.LaunchTemplateData) === null || _selectedTemplateVers5 === void 0 ? void 0 : _selectedTemplateVers5.TagSpecifications) !== null && _selectedTemplateVers4 !== void 0 ? _selectedTemplateVers4 : []).findBy('ResourceType', 'instance');
          const resourceTags = {};
          ((_resourceInstanceTags = resourceInstanceTags === null || resourceInstanceTags === void 0 ? void 0 : resourceInstanceTags.Tags) !== null && _resourceInstanceTags !== void 0 ? _resourceInstanceTags : []).forEach(tag => Ember.set(resourceTags, Ember.get(tag, 'Key'), Ember.get(tag, 'Value')));
          return resourceTags;
        }

        return resourceTags;
      },

      set(_key, resourceTags) {
        const {
          isNoLaunchTemplate,
          isRancherLaunchTemplate
        } = this;

        if (isNoLaunchTemplate || isRancherLaunchTemplate) {
          Ember.set(this, 'model.resourceTags', resourceTags);
        }

        return resourceTags;
      }

    }),
    isRancherLaunchTemplate: Ember.computed('model.{launchTemplate,nodegroupName}', 'originalCluster.eksStatus.managedLaunchTemplateID', function () {
      const {
        originalCluster,
        model
      } = this;
      const {
        launchTemplate
      } = model;
      const eksStatus = Ember.get(originalCluster !== null && originalCluster !== void 0 ? originalCluster : {}, 'eksStatus') || {};
      const {
        managedLaunchTemplateID = null,
        managedLaunchTemplateVersions = {}
      } = eksStatus;
      const matchedManagedVersion = Ember.get(managedLaunchTemplateVersions !== null && managedLaunchTemplateVersions !== void 0 ? managedLaunchTemplateVersions : {}, this.model.nodegroupName);

      if (Ember.isEmpty(launchTemplate) && !Ember.isEmpty(managedLaunchTemplateID) && !Ember.isEmpty(matchedManagedVersion)) {
        return true;
      }

      return false;
    }),
    isUserLaunchTemplate: Ember.computed('model.launchTemplate', 'originalCluster.eksStatus.managedLaunchTemplateID', function () {
      const {
        model
      } = this;
      const {
        launchTemplate
      } = model;

      if (!Ember.isEmpty(launchTemplate) && !Ember.isEmpty(launchTemplate === null || launchTemplate === void 0 ? void 0 : launchTemplate.id) && !Ember.isEmpty(launchTemplate === null || launchTemplate === void 0 ? void 0 : launchTemplate.version)) {
        return true;
      }

      return false;
    }),
    isNoLaunchTemplate: Ember.computed('isRancherLaunchTemplate', 'isUserLaunchTemplate', function () {
      return !this.isRancherLaunchTemplate && !this.isUserLaunchTemplate;
    }),
    filteredLaunchTemplates: Ember.computed('launchTemplates.[]', function () {
      const {
        launchTemplates
      } = this;

      if (Ember.isEmpty(launchTemplates)) {
        return [];
      }

      return launchTemplates.filter(({
        LaunchTemplateName
      }) => !LaunchTemplateName.includes('rancher-managed-lt')).sortBy('LaunchTemplateName');
    }),
    selectedTemplateVersionInfo: Ember.computed('model.launchTemplate.{id,version}', 'allSelectedTemplateVersions.[]', function () {
      const {
        model,
        allSelectedTemplateVersions
      } = this;
      const version = Ember.get(model, 'launchTemplate.version');

      if (Ember.isEmpty(model.launchTemplate) || Ember.isEmpty(version)) {
        return null;
      }

      const match = (allSelectedTemplateVersions || []).findBy('VersionNumber', parseInt(version, 10));

      if (match) {
        return match;
      }

      return null;
    }),
    selectedLaunchTemplateVersion: Ember.computed('model.launchTemplate.version', 'model.launchTemplate.id', {
      get() {
        return Ember.get(this, 'model.launchTemplate.version') ? Ember.get(this, 'model.launchTemplate.version').toString() : null;
      },

      set(_key, value) {
        Ember.set(this, 'model.launchTemplate.version', parseInt(value, 10));
        this.loadTemplateVersionInfo();
        return value;
      }

    }),
    selectedLaunchTemplateVersions: Ember.computed('model.launchTemplate.{id,name,version}', 'launchTemplates', function () {
      const {
        model,
        launchTemplates
      } = this;
      const {
        launchTemplate
      } = model;

      if (Ember.isEmpty(launchTemplate) || Ember.isEmpty(Ember.get(launchTemplate, 'id'))) {
        return [];
      }

      const match = launchTemplates.findBy('LaunchTemplateId', launchTemplate.id);

      if (match) {
        // this lets us create a range of values 1...XX because the launch template only gives us the 1st and latest numbers but we want all for the version select
        // ++ver -> zero based array so we need to +1 that value to match a non-zero based version number system
        return Array.from(Array(match.LatestVersionNumber).keys()).map(ver => ({
          label: `${++ver}`
        }));
      }

      return [];
    }),
    selectedLaunchTemplate: Ember.computed('model.launchTemplate', 'filteredLaunchTemplates.[]', {
      get() {
        var _Ember$get;

        const launchTemplate = (_Ember$get = Ember.get(this, 'model.launchTemplate')) !== null && _Ember$get !== void 0 ? _Ember$get : false;

        if (launchTemplate) {
          const out = this.filteredLaunchTemplates.findBy('LaunchTemplateId', launchTemplate.id);
          return Ember.isEmpty(out) ? null : out;
        }

        return null;
      },

      set(_key, launchTemplateId) {
        const launchTemplate = this.filteredLaunchTemplates.findBy('LaunchTemplateId', launchTemplateId);
        const {
          LaunchTemplateId: id,
          LaunchTemplateName: name,
          DefaultVersionNumber: version
        } = launchTemplate !== null && launchTemplate !== void 0 ? launchTemplate : {};

        if (Ember.isEmpty(launchTemplate)) {
          Ember.set(this, 'model.launchTemplate', null);
          Ember.setProperties(this, {
            diskSize: this.defaultNodeGroupConfig.diskSize,
            ec2SshKey: this.defaultNodeGroupConfig.ec2SshKey,
            imageId: this.defaultNodeGroupConfig.imageId,
            instanceType: this.defaultNodeGroupConfig.instanceType,
            requestSpotInstances: this.defaultNodeGroupConfig.requestSpotInstances,
            resourceTags: this.defaultNodeGroupConfig.resourceTags,
            userData: this.defaultNodeGroupConfig.userData
          });
        } else {
          Ember.set(this, 'model.launchTemplate', {
            id,
            name
          });
          Ember.set(this, 'selectedLaunchTemplateVersion', version);
        }

        return launchTemplateId;
      }

    }),
    creating: Ember.computed('mode', function () {
      const {
        mode,
        originalCluster,
        model: {
          nodegroupName
        }
      } = this;

      if (mode === 'new') {
        return true;
      }

      const upstreamSpec = Ember.get(originalCluster, 'eksStatus.upstreamSpec');
      const nodeGroups = upstreamSpec ? Ember.get(upstreamSpec, 'nodeGroups') : [];

      if (nodegroupName && nodeGroups.length >= 1) {
        if (nodeGroups.findBy('nodegroupName', nodegroupName)) {
          return false;
        }
      }

      return true;
    }),
    originalClusterVersion: Ember.computed('originalCluster.eksConfig.kubernetesVersion', 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion', function () {
      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.eksConfig.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.eksConfig.kubernetesVersion');
      }

      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion');
      }

      return '';
    }),
    upgradeAvailable: Ember.computed('clusterConfig.kubernetesVersion', 'mode', 'model.version', 'originalClusterVersion', 'showNodeUpgradePreventionReason', function () {
      const originalClusterVersion = Ember.get(this, 'originalClusterVersion');
      const clusterVersion = Ember.get(this, 'clusterConfig.kubernetesVersion');
      const nodeVersion = Ember.get(this, 'model.version');
      const mode = Ember.get(this, 'mode');
      const initalClusterMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(clusterVersion)), 10);
      const initalNodeMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(nodeVersion)), 10);
      const diff = initalClusterMinorVersion - initalNodeMinorVersion;

      if (mode === 'edit') {
        // we must upgrade the cluster first
        if (originalClusterVersion !== clusterVersion) {
          Ember.set(this, 'showNodeUpgradePreventionReason', true);
          return false;
        }
      }

      if (diff === 0 && Ember.get(this, 'showNodeUpgradePreventionReason')) {
        Ember.set(this, 'showNodeUpgradePreventionReason', false);
      }

      return diff === 1;
    }),
    showGPUWarning: Ember.computed('model.launchTemplate.{id,version}', 'selectedTemplateVersionInfo', function () {
      const {
        model,
        selectedTemplateVersionInfo
      } = this;
      const ltId = Ember.get(model, 'launchTemplate.id');
      const ltVersion = Ember.get(model, 'launchTemplate.version');
      const imageId = selectedTemplateVersionInfo ? Ember.get(selectedTemplateVersionInfo, 'LaunchTemplateData.ImageId') : undefined;
      return ltId && ltVersion && selectedTemplateVersionInfo && imageId;
    }),
    requestedSpotInstances: Ember.on('init', Ember.observer('model.requestSpotInstances', 'selectedTemplateVersionInfo.LaunchTemplateData.InstanceMarketOptions.MarketType', function () {
      const {
        model
      } = this;

      if (Ember.get(model, 'requestSpotInstances')) {
        Ember.set(this, 'instanceType', null);
      } else if (!Ember.get(model, 'requestSpotInstances') && Ember.get(model, 'instanceType') === null) {
        Ember.set(this, 'instanceType', 't3.medium');
      }
    })),
    clusterVersionDidChange: Ember.on('init', Ember.observer('clusterConfig.kubernetesVersion', function () {
      const {
        clusterConfig,
        editing
      } = this;

      if (Ember.get(clusterConfig, 'kubernetesVersion') && !editing) {
        Ember.set(this, 'model.version', clusterConfig.kubernetesVersion);
      }
    })),
    shouldUpgradeVersion: Ember.on('init', Ember.observer('upgradeVersion', function () {
      const {
        upgradeVersion
      } = this;
      const clusterVersion = Ember.get(this, 'clusterConfig.kubernetesVersion');
      const nodeVersion = Ember.get(this, 'model.version');

      if (upgradeVersion && clusterVersion !== nodeVersion) {
        Ember.set(this, 'model.version', clusterVersion);
      }
    })),

    async loadTemplateVersionInfo() {
      if (!this.clusterSaving && !this.clusterSaved) {
        const {
          launchTemplate = {}
        } = this.model;
        let defaults = { ...this.defaultNodeGroupConfig
        }; // in this case we dont want the defaults for nodegroup items

        delete defaults.nodegroupName;
        delete defaults.maxSize;
        delete defaults.minSize;
        delete defaults.desiredSize;

        if (Ember.isEmpty(launchTemplate)) {
          Ember.set(this, 'refreshResourceInstanceTags', false);
          Ember.run.next(() => {
            Ember.setProperties(this.model, defaults);
            Ember.set(this, 'refreshResourceInstanceTags', true);
          });
        } else if (!Ember.isEmpty(Ember.get(launchTemplate, 'id'))) {
          try {
            var _Ember$get2, _Ember$get3, _Ember$get4, _Ember$get5;

            const versions = await this.listTemplateVersions();
            const match = versions.findBy('VersionNumber', parseInt(launchTemplate.version, 10)); // newselect doesn't handle numbers as values very well

            const {
              LaunchTemplateData: launchTemplateData
            } = match;
            const overrides = {
              imageId: (_Ember$get2 = Ember.get(launchTemplateData, 'ImageId')) !== null && _Ember$get2 !== void 0 ? _Ember$get2 : null,
              instanceType: (_Ember$get3 = Ember.get(launchTemplateData, 'InstanceType')) !== null && _Ember$get3 !== void 0 ? _Ember$get3 : this.defaultNodeGroupConfig.instanceType,
              diskSize: (_Ember$get4 = Ember.get(launchTemplateData, 'BlockDeviceMappings.firstObject.Ebs.VolumeSize')) !== null && _Ember$get4 !== void 0 ? _Ember$get4 : this.defaultNodeGroupConfig.diskSize,
              ec2SshKey: (_Ember$get5 = Ember.get(launchTemplateData, 'KeyName')) !== null && _Ember$get5 !== void 0 ? _Ember$get5 : null,
              userData: Ember.isEmpty(Ember.get(launchTemplateData, 'UserData')) ? null : atob(Ember.get(launchTemplateData, 'UserData'))
            };
            defaults = Object.assign({}, defaults, overrides);

            if (Ember.get(launchTemplateData, 'InstanceMarketOptions.MarketType') && Ember.get(launchTemplateData, 'InstanceMarketOptions.MarketType') === 'spot') {
              Ember.set(defaults, 'requestSpotInstances', true);
              Ember.set(this, 'instanceType', null);
            }

            if (!Ember.isEmpty(Ember.get(launchTemplateData, 'TagSpecifications'))) {
              const resourceInstanceTags = Ember.get(launchTemplateData, 'TagSpecifications').findBy('ResourceType', 'instance');

              if (!Ember.isEmpty(resourceInstanceTags) && !Ember.isEmpty(Ember.get(resourceInstanceTags, 'Tags'))) {
                Ember.set(defaults, 'resourceTags', {});
                resourceInstanceTags.Tags.forEach(tag => Ember.set(defaults.resourceTags, Ember.get(tag, 'Key'), Ember.get(tag, 'Value')));
              }
            }

            Ember.set(this, 'refreshResourceInstanceTags', false);
            Ember.run.next(() => {
              Ember.setProperties(this.model, defaults);
              Ember.set(this, 'allSelectedTemplateVersions', versions);
              Ember.set(this, 'refreshResourceInstanceTags', true);
            });
          } catch (err) {}
        }
      }
    },

    listTemplateVersions() {
      const {
        launchTemplate
      } = this.model;
      const match = this.launchTemplates.findBy('LaunchTemplateId', launchTemplate.id);
      return new Ember.RSVP.Promise((resolve, reject) => {
        const ec2 = new AWS.EC2(this.authCreds());
        const maxVersion = match.LatestVersionNumber;
        ec2.describeLaunchTemplateVersions({
          LaunchTemplateId: launchTemplate.id,
          MaxVersion: maxVersion.toString(),
          MinVersion: '1'
        }, (err, data) => {
          if (err) {
            reject(err);
          }

          resolve(data.LaunchTemplateVersions);
        });
      });
    },

    removeNodeGroup() {
      throw new Error('remove node group action is required!');
    }

  });

  _exports.default = _default;
});