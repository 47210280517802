define("shared/components/metrics-graph/component", ["exports", "shared/components/metrics-graph/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    rows: null,
    graphs: null,
    loading: null,
    noGraphs: null,
    noDataLabel: 'metricsAction.noData',
    currentGraphs: null,
    graphsDidChange: Ember.observer('graphs', function () {
      let out = [];
      const graphs = Ember.get(this, 'graphs') || [];
      const newGrahps = graphs.map(graph => Ember.get(graph, 'graph.title') || '').join(',');
      const changed = newGrahps !== Ember.get(this, 'currentGraphs');
      Ember.set(this, 'currentGraphs', newGrahps);

      if (changed) {
        graphs.forEach((graph, index) => {
          if (index % 3 === 0) {
            out.pushObject([graph]);
          } else {
            Ember.get(out, 'lastObject').pushObject(graph);
          }
        });
        Ember.set(this, 'rows', out);
      } else {
        let rowIndex = -1;
        const currentRows = Ember.get(this, 'rows') || [];
        graphs.forEach((graph, index) => {
          let colIndex = index % 3;

          if (colIndex === 0) {
            rowIndex++;
          }

          let row = currentRows.objectAt(rowIndex) || [];
          Ember.set(row.objectAt(colIndex), 'series', Ember.get(graph, 'series'));
        });
      }
    })
  });

  _exports.default = _default;
});