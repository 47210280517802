define("global-admin/security/accounts/new-group/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      return this.globalStore.findAll('globalrole').then(resp => {
        const {
          globalRoles
        } = resp;
        return {
          globalRoleBinding: this.globalStore.createRecord({
            type: 'globalrolebinding'
          }),
          globalRoles
        };
      });
    }

  });

  _exports.default = _default;
});