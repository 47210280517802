define("shared/components/cluster-driver/driver-amazoneks/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-amazoneks/template", "shared/utils/amazon", "jquery", "semver"], function (_exports, _clusterDriver, _template, _amazon, _jquery, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RANCHER_GROUP = 'rancher-nodes';

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'amazonElasticContainerServiceConfig',
    instanceTypes: _amazon.INSTANCE_TYPES,
    regionChoices: _amazon.EKS_REGIONS,
    step: 1,
    serviceRoles: null,
    securityGroups: null,
    whichSecurityGroup: 'default',
    defaultSecurityGroupName: RANCHER_GROUP,
    errors: null,
    serviceRoleMode: 'default',
    vpcSubnetMode: 'default',
    allSecurityGroups: null,
    allKeyPairs: null,
    selectedServiceRole: null,
    selectedGroupedDetails: null,
    kubernetesVersionContent: _amazon.EKS_VERSIONS,
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),
    editing: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        clients: {},
        allSubnets: []
      });
      let config = Ember.get(this, 'cluster.amazonElasticContainerServiceConfig');

      if (!config) {
        config = Ember.get(this, 'globalStore').createRecord({
          type: 'amazonElasticContainerServiceConfig',
          accessKey: null,
          secretKey: null,
          region: 'us-west-2',
          instanceType: 'm5.large',
          desiredNodes: 1,
          minimumNodes: 1,
          maximumNodes: 1,
          kubernetesVersion: this.kubernetesVersionContent.firstObject
        });
        Ember.set(this, 'cluster.amazonElasticContainerServiceConfig', config);
      } else {
        if (this.mode === 'edit') {
          Ember.setProperties(this, {
            vpcSubnetMode: 'custom',
            serviceRoleMode: 'custom'
          });

          if (Ember.get(this, 'cluster.hasSessionToken')) {
            Ember.set(this, 'cluster.amazonElasticContainerServiceConfig.accessKey', null);
          }
        }
      }
    },

    willDestroyElement() {
      Ember.setProperties(this, {
        step: 1,
        clients: null,
        allSubnets: null
      });
    },

    actions: {
      multiSecurityGroupSelect() {
        let options = Array.prototype.slice.call((0, _jquery.default)('.existing-security-groups')[0], 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.securityGroups', selectedOptions);
      },

      multiSubnetGroupSelect() {
        let options = Array.prototype.slice.call((0, _jquery.default)('.existing-subnet-groups')[0], 0);
        let selectedOptions = [];
        options.filterBy('selected', true).forEach(cap => {
          return selectedOptions.push(cap.value);
        });
        Ember.set(this, 'config.subnets', selectedOptions);
      },

      awsLogin(cb) {
        this.listRoles(this.authCreds()).then(roles => {
          let eksRoles = [];
          eksRoles = roles.filter(role => {
            //
            let policy = JSON.parse(decodeURIComponent(Ember.get(role, 'AssumeRolePolicyDocument')));
            let statement = Ember.get(policy, 'Statement');
            let isEksRole = false;
            statement.forEach(doc => {
              let principal = Ember.get(doc, 'Principal');

              if (principal) {
                let service = Ember.get(principal, 'Service');

                if (service && (service.includes('eks.amazonaws') || service.includes('EKS')) && !eksRoles.findBy('RoleId', Ember.get(role, 'RoleId'))) {
                  // console.log(service.includes('eks'), service.includes('EKS'), eksRoles.findBy('RoleId', get(role, 'RoleId')), role)
                  isEksRole = true;
                } else if (Ember.get(principal, 'EKS')) {
                  // console.log(get(principal, 'EKS'), role);
                  isEksRole = true;
                } else {
                  isEksRole = false;
                }
              }
            });

            if (isEksRole) {
              return role;
            }
          });
          Ember.set(this, 'serviceRoles', eksRoles);
          return this.loadKeyPairs(this.authCreds()).then(() =>
          /* keyPairs */
          {
            if (this.mode === 'edit') {
              Ember.set(this, 'step', 6);
            } else {
              Ember.set(this, 'step', 2);
            }

            cb();
          }).catch(err => {
            Ember.get(this, 'errors').pushObject(err);
            cb(false, err);
          });
        }).catch(err => {
          Ember.get(this, 'errors').pushObject(err);
          cb(false, err);
        });
      },

      loadVPS(cb) {
        if (Ember.get(this, 'selectedServiceRole')) {
          Ember.set(this, 'config.serviceRole', Ember.get(this, 'selectedServiceRole'));
        }

        this.loadVpcs(this.authCreds()).then(() => {
          Ember.set(this, 'step', 3);
          cb();
        }).catch(err => {
          Ember.get(this, 'errors').pushObject(err);
          cb(false, err);
        });
      },

      setVPCS(cb) {
        if (Ember.get(this, 'vpcSubnetMode') === 'custom') {
          this.loadSubnets(this.authCreds()).then(() => {
            Ember.set(this, 'step', 4);
            cb();
          }).catch(err => {
            Ember.get(this, 'errors').pushObject(err);
            cb(false, err);
          });
        } else {
          Ember.set(this, 'step', 6);
        }
      },

      setSubnets(cb) {
        this.loadSecurityGroups(this.authCreds()).then(() => {
          Ember.set(this, 'step', 5);
          cb();
        }).catch(err => {
          Ember.get(this, 'errors').pushObject(err);
          cb(false, err);
        });
      }

    },
    publicIpChanged: Ember.observer('step', 'config.associateWorkerNodePublicIp', function () {
      if (!Ember.get(this, 'config.associateWorkerNodePublicIp')) {
        Ember.set(this, 'vpcSubnetMode', 'custom');
      }
    }),
    desiredNodesChanged: Ember.observer('config.desiredNodes', function () {
      const desiredNodes = Ember.get(this, 'config.desiredNodes');
      const config = Ember.get(this, 'config');
      Ember.setProperties(config, {
        minimumNodes: desiredNodes,
        maximumNodes: desiredNodes
      });
    }),
    vpcSubnetModeDidChange: Ember.observer('step', 'vpcSubnetMode', function () {
      const {
        vpcSubnetMode: mode,
        editing,
        step
      } = this;

      if (!editing && mode === 'custom') {
        const vpc = Ember.get(this, 'filteredVpcs.firstObject.id');

        if (step <= 3) {
          if (vpc) {
            Ember.set(this, 'config.virtualNetwork', vpc);
          }
        }
      }
    }),
    serviceRoleModeDidChange: Ember.observer('serviceRoleMode', function () {
      const mode = Ember.get(this, 'serviceRoleMode');

      if (mode === 'custom') {
        const role = Ember.get(this, 'serviceRoles.firstObject.RoleName');

        if (role) {
          Ember.set(this, 'selectedServiceRole', role);
        }
      } else {
        Ember.set(this, 'selectedServiceRole', null);
      }
    }),
    vpcsChanged: Ember.observer('config.virtualNetwork', 'vpcSubnetMode', function () {
      if (!this.primaryResource.isTransitioning) {
        const vnet = Ember.get(this, 'config.virtualNetwork');
        const subnets = Ember.get(this, 'config.subnets');
        const mode = Ember.get(this, 'vpcSubnetMode');
        const hasInitializedValues = vnet || subnets;

        if (vnet && mode === 'custom') {
          this.loadSubnets(this.authCreds()).catch(err => {
            Ember.get(this, 'errors').pushObject(err);
          }); // We check for initialized values here because as part of
          // the saving process this observer gets triggered with
          // uninitialized values. This was causing a save to switch
          // the step to step 3 rather than remaining on the last
          // page until the saving was complete.
        } else if (mode === 'default' && hasInitializedValues) {
          Ember.setProperties(Ember.get(this, 'config'), {
            virtualNetwork: null,
            subnets: []
          });
          Ember.set(this, 'step', 3);
        }
      }
    }),
    versionChoices: Ember.computed('editing', 'versions', function () {
      const {
        config: {
          kubernetesVersion: initialVersion
        },
        intl,
        kubernetesVersionContent,
        mode
      } = this;
      const versionChoices = this.versionChoiceService.parseCloudProviderVersionChoices(kubernetesVersionContent.slice(), initialVersion, mode); // only EKS and edit - user can only upgrade a single minor version at a time

      if (this.editing) {
        const initalMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(initialVersion)), 10);
        versionChoices.forEach(vc => {
          const vcMinorV = parseInt((0, _semver.minor)((0, _semver.coerce)(vc.value)), 10);
          const diff = vcMinorV - initalMinorVersion;

          if (diff > 1) {
            Ember.setProperties(vc, {
              disabled: true,
              label: `${vc.label} ${intl.t('formVersions.eks.label')}`
            });
          }
        });
      }

      return versionChoices;
    }),
    filteredKeyPairs: Ember.computed('allKeyPairs', function () {
      return Ember.get(this, 'allKeyPairs').sortBy('KeyName');
    }),
    filteredSubnets: Ember.computed('allSubnets', 'config.virtualNetwork', function () {
      return Ember.get(this, 'allSubnets').filterBy('VpcId', Ember.get(this, 'config.virtualNetwork')).map(subnet => {
        return {
          subnetName: (0, _amazon.nameFromResource)(subnet, 'SubnetId'),
          subnetId: subnet.SubnetId
        };
      }).sortBy('subnetName');
    }),
    filteredVpcs: Ember.computed('allVpcs', function () {
      return (Ember.get(this, 'allVpcs') || []).filterBy('State', 'available').map(vpc => {
        return {
          id: Ember.get(vpc, 'VpcId'),
          label: `${Ember.get(vpc, 'VpcId')} (${Ember.get(vpc, 'CidrBlock')})`
        };
      }).sortBy('label');
    }),
    filteredSecurityGroups: Ember.computed('allSecurityGroups', 'config.virtualNetwork', function () {
      return Ember.get(this, 'allSecurityGroups').filterBy('VpcId', Ember.get(this, 'config.virtualNetwork')).sortBy('GroupName');
    }),
    readableServiceRole: Ember.computed('config.serviceRole', 'serviceRoles', function () {
      const roles = Ember.get(this, 'serviceRoles');
      const selectedRole = Ember.get(this, 'config.serviceRole');
      const match = roles.findBy('RoleName', selectedRole);
      return match && match.RoleName ? Ember.get(match, 'RoleName') : this.intl.t('nodeDriver.amazoneks.role.noneSelected');
    }),
    canSaveVPC: Ember.computed('vpcSubnetMode', 'selectedGroupedDetails', 'config.virtualNetwork', 'config.subnets.[]', function () {
      const mode = Ember.get(this, 'vpcSubnetMode');
      const config = Ember.get(this, 'config');
      let disabled = true;

      if (mode === 'default' || Ember.get(config, 'virtualNetwork')) {
        disabled = false;
      }

      return disabled;
    }),
    canSaveSG: Ember.computed('config.securityGroups.[]', function () {
      const sg = Ember.get(this, 'config.securityGroups');
      let disabled = true;

      if (sg && sg.length > 0) {
        disabled = false;
      }

      return disabled;
    }),

    authCreds() {
      const config = Ember.get(this, 'config');
      let {
        accessKey,
        secretKey,
        region,
        sessionToken
      } = config;
      accessKey = (accessKey || '').trim();
      secretKey = (secretKey || '').trim();
      Ember.setProperties(this, {
        'errors': [],
        'config.accessKey': accessKey,
        'config.secretKey': secretKey
      });
      const auth = {
        region,
        accessKeyId: accessKey,
        secretAccessKey: secretKey
      };

      if (sessionToken) {
        let token = sessionToken.trim();
        Ember.set(auth, 'sessionToken', token);
        Ember.set(this, 'config.sessionToken', token);
      }

      return auth;
    },

    loadVpcs(auth) {
      return this.listVPCs(auth).then(resp => {
        let {
          vpcs
        } = resp;
        let def = vpcs.findBy('IsDefault');

        if (def && def.VpcId) {
          Ember.set(this, 'config.virtualNetwork', Ember.get(def, 'VpcId'));
        }

        return Ember.RSVP.resolve(Ember.set(this, 'allVpcs', vpcs));
      });
    },

    loadSubnets(auth) {
      return this.listSubnets(auth).then(resp => {
        return Ember.RSVP.resolve(Ember.set(this, 'allSubnets', resp));
      });
    },

    loadSecurityGroups(auth) {
      return this.listSecurityGroups(auth).then(resp => {
        return Ember.RSVP.resolve(Ember.set(this, 'allSecurityGroups', resp));
      });
    },

    loadKeyPairs(auth) {
      return this.listKeyPairs(auth).then(resp => {
        return Ember.RSVP.resolve(Ember.set(this, 'allKeyPairs', resp));
      });
    },

    listKeyPairs(auth) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const ec2 = new AWS.EC2(auth);
        ec2.describeKeyPairs({}, (err, data) => {
          if (err) {
            console.log(err, err.stack);
            reject(err);
          }

          resolve(data.KeyPairs);
        });
      });
    },

    listRoles(auth) {
      // TODO There is no IAM endpoint in cn-northwest-1 region. We need to use cn-north-1 for now. So users chould be able to create EKS cluster in cn-northwest-1.
      const {
        region
      } = auth || {};

      if (region === 'cn-northwest-1') {
        auth.region = 'cn-north-1';
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        const IAM = new AWS.IAM(auth);
        IAM.listRoles({}, (err, data) => {
          if (err) {
            console.log(err, err.stack);
            reject(err);
          }

          resolve(data.Roles);
        });
      });
    },

    listVPCs(auth) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const ec2 = new AWS.EC2(auth);
        const vpcNames = {};
        const vpcTags = {};
        ec2.describeVpcs({}, (err, vpcs) => {
          if (err) {
            return reject(err);
          }

          vpcs.Vpcs.forEach(vpc => {
            vpcNames[vpc.VpcId] = (0, _amazon.nameFromResource)(vpc, 'VpcId');
            vpcTags[vpc.VpcId] = (0, _amazon.tagsFromResource)(vpc);
          });
          return resolve({
            vpcNames,
            vpcTags,
            vpcs: vpcs.Vpcs
          });
        });
      });
    },

    listSubnets(auth) {
      const ec2 = new AWS.EC2(auth);
      const rName = Ember.get(this, 'config.region');
      let subnets = [];
      return new Ember.RSVP.Promise((resolve, reject) => {
        ec2.describeSubnets({}, (err, data) => {
          if (err) {
            reject(err);
          }

          Ember.set(this, `clients.${rName}`, ec2);
          subnets = data.Subnets;
          resolve(subnets);
        });
      });
    },

    listSecurityGroups(auth) {
      const ec2 = new AWS.EC2(auth);
      return new Ember.RSVP.Promise((resolve, reject) => {
        ec2.describeSecurityGroups({}, (err, data) => {
          if (err) {
            reject(err);
          }

          resolve(data.SecurityGroups);
        });
      });
    },

    willSave() {
      // temporary measure put in place for rancher/rancher#24652
      const {
        config: {
          subnets
        }
      } = this;

      if (Ember.isEmpty(subnets)) {
        Ember.set(this, 'config.subnets', []);
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});