define("shared/mixins/modal-base", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['modal-container'],
    modalService: Ember.inject.service('modal'),
    modalOpts: Ember.computed.alias('modalService.modalOpts'),

    keyUp(e) {
      if (e.which === _constants.default.KEY.ESCAPE && this.escToClose()) {
        this.get('modalService').toggleModal();
      }
    },

    escToClose() {
      var modalService = this.get('modalService');

      if (modalService.get('modalVisible') && modalService.get('modalOpts.escToClose')) {
        return true;
      } else {
        return false;
      }
    },

    actions: {
      close() {
        this.get('modalService').toggleModal();
      },

      cancel() {
        this.get('modalService').toggleModal();
      }

    }
  });

  _exports.default = _default;
});