define("shared/components/cluster-driver/driver-import-eks/component", ["exports", "shared/mixins/cluster-driver", "shared/utils/amazon", "shared/utils/util", "shared/components/cluster-driver/driver-import-eks/template"], function (_exports, _clusterDriver, _amazon, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'eksConfig',
    step: 1,
    loading: false,
    nodeForInfo: null,
    loadingClusters: false,
    regionChoices: _amazon.EKS_REGIONS,
    errors: null,
    otherErrors: null,
    clusterErrors: null,
    selectedCred: null,
    isPostSave: false,
    isEdit: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    allErrors: Ember.computed.union('errors', 'otherErrors', 'clusterErrors'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        errors: [],
        clusterErrors: [],
        otherErrors: []
      });

      if (this.isEdit) {
        const cloudCredId = Ember.get(this, 'model.cluster.eksConfig.amazonCredentialSecret');
        const cloudCred = (this.model.cloudCredentials || []).find(cc => cc.id === cloudCredId);

        if (!Ember.isEmpty(cloudCred)) {
          Ember.run.next(() => {
            this.send('finishAndSelectCloudCredential', cloudCred);
          });
        }
      } else {
        this.bootstrapEksV2Cluster();
      }
    },

    actions: {
      finishAndSelectCloudCredential(cred) {
        if (Ember.isEmpty(cred)) {
          Ember.set(this, 'config.amazonCredentialSecret', null);
          Ember.set(this, 'selectedCred', null);
        } else {
          Ember.set(this, 'config.amazonCredentialSecret', cred.id);
          Ember.set(this, 'selectedCred', cred);
          this.send('fetchEksResources');
        }
      },

      async fetchEksResources(cb) {
        const errors = [];
        let step = 2;
        let allClusters;
        Ember.set(this, 'loadingClusters', true);

        try {
          const authCreds = this.authCreds();
          allClusters = await this.listEksClusters(authCreds);
          Ember.setProperties(this, {
            allClusters: (allClusters || []).map(c => {
              return {
                label: c,
                value: c
              };
            }),
            step
          });

          if (cb) {
            cb();
          }
        } catch (err) {
          errors.pushObject(`Failed to load Clusters from Amazon: ${err.message}`); // EKS List Clusters API fails sometimes to list this, user cnn input a cluster name though so dont fail

          Ember.setProperties(this, {
            loadFailedAllClusters: true,
            errors
          });

          if (cb) {
            cb(false, err);
          }
        } finally {
          Ember.setProperties(this, {
            loadingClusters: false,
            step
          });
        }
      }

    },
    regionOrCredentialChanged: Ember.on('init', Ember.observer('config.region', 'config.amazonCredentialSecret', function () {
      const {
        config: {
          region,
          amazonCredentialSecret
        },
        loadingClusters,
        errors
      } = this;

      if (errors && errors.length >= 1) {
        Ember.setProperties(this, {
          loadFailedAllClusters: false,
          errors: []
        });
      }

      if (!Ember.isEmpty(region) && !Ember.isEmpty(amazonCredentialSecret) && !loadingClusters) {
        this.send('fetchEksResources');
      }
    })),
    cloudCredentials: Ember.computed('model.cloudCredentials.[]', function () {
      const {
        model: {
          cloudCredentials
        }
      } = this;
      return cloudCredentials.filter(cc => !Ember.isEmpty(Ember.get(cc, 'amazonec2credentialConfig')));
    }),
    disableImport: Ember.computed('step', 'config.{amazonCredentialSecret,displayName}', function () {
      const {
        step,
        config: {
          amazonCredentialSecret,
          displayName
        }
      } = this;

      if (step <= 2 && !Ember.isEmpty(amazonCredentialSecret) && !Ember.isEmpty(displayName)) {
        return false;
      }

      return true;
    }),

    doneSaving() {
      const {
        isPostSave,
        model: {
          cluster: {
            eksConfig = {},
            eksStatus = {}
          }
        }
      } = this;
      const privateAccess = !Ember.get(eksConfig, 'publicAccess') && Ember.get(eksConfig, 'privateAccess') || Ember.get(eksStatus, 'upstreamSpec.privateAccess') || false;

      if (isPostSave && privateAccess) {
        Ember.set(this, 'step', 3);
        return;
      }

      if (this.close) {
        this.close();
      }
    },

    bootstrapEksV2Cluster() {
      const eksConfig = this.globalStore.createRecord({
        displayName: '',
        imported: true,
        region: 'us-west-2',
        type: 'eksclusterconfigspec'
      });
      Ember.set(this, 'model.cluster.eksConfig', eksConfig);
      Ember.set(this, 'config', eksConfig);
    },

    listEksClusters(auth) {
      return new Promise((resolve, reject) => {
        const eks = new AWS.EKS(auth);
        eks.listClusters({}, (err, data) => {
          if (err) {
            console.log(err, err.stack);
            return reject(err);
          }

          return resolve(data.clusters);
        });
      });
    },

    authCreds() {
      let {
        config: {
          region,
          amazonCredentialSecret
        }
      } = this;
      const auth = {
        accessKeyId: (0, _util.randomStr)(),
        secretAccessKey: (0, _util.randomStr)(),
        region,
        httpOptions: {
          cloudCredentialId: amazonCredentialSecret
        }
      };
      return auth;
    }

  });

  _exports.default = _default;
});