define("shared/istio/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    useNewKialiUrl: false,
    _cached: false,

    checkKialiUiEndpoint(clusterId, force = false) {
      if (this._cached && !force) {
        return Ember.RSVP.resolve({
          useNewKialiUrl: this.useNewKialiUrl
        });
      }

      const {
        globalStore
      } = this;
      const preIsitio142BreakingKialiUrl = globalStore.rawRequest({
        url: `/k8s/clusters/${clusterId}/api/v1/namespaces/istio-system/services/http:kiali-http:80/proxy/`,
        method: 'GET'
      });
      const postIsitio142BreakingKialiUrl = globalStore.rawRequest({
        url: `/k8s/clusters/${clusterId}/api/v1/namespaces/istio-system/services/http:kiali:20001/proxy/`,
        method: 'GET'
      }); // project member may not have access to the system project to get the version info
      // so test both links (istio <=1.4.2(rancher catalog version 0.1.2) uses -http:80)

      return Ember.RSVP.hashSettled({
        preIsitio142BreakingKialiUrl,
        postIsitio142BreakingKialiUrl
      }).then(resp => {
        Ember.set(this, '_cached', true);

        if (resp.preIsitio142BreakingKialiUrl.state === 'fulfilled') {
          Ember.set(this, 'useNewKialiUrl', false);
        } else if (resp.postIsitio142BreakingKialiUrl.state === 'fulfilled') {
          Ember.set(this, 'useNewKialiUrl', true);
        } else {
          Ember.set(this, 'useNewKialiUrl', false);
        }

        return Ember.RSVP.resolve({
          useNewKialiUrl: this.useNewKialiUrl
        });
      }).catch(() => {
        Ember.set(this, '_cached', false);
        return Ember.RSVP.reject({
          useNewKialiUrl: false
        });
      });
    }

  });

  _exports.default = _default;
});