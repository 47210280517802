define("shared/components/input-text-file/component", ["exports", "ui/utils/platform", "shared/components/input-text-file/template", "jquery"], function (_exports, _platform, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    label: null,
    namePlaceholder: null,
    nameRequired: false,
    name: null,
    value: null,
    placeholder: null,
    accept: 'text/*',
    multiple: false,
    minHeight: 0,
    maxHeight: 200,
    inputName: false,
    canChangeName: true,
    canUpload: true,
    showUploadLabel: true,
    concealValue: false,
    tagName: ['div'],
    shouldChangeName: true,
    actions: {
      click() {
        (0, _jquery.default)(this.element).find('INPUT[type=file]').click();
      },

      wantsChange() {
        Ember.set(this, 'shouldChangeName', true);
      }

    },
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),

    change(event) {
      var input = event.target;

      if (!input.files || !input.files.length) {
        return;
      }

      if (Ember.get(this, 'canChangeName')) {
        const firstName = input.files[0].name;

        if (Ember.get(this, 'multiple')) {
          const ext = firstName.replace(/.*\./, '');
          Ember.set(this, 'name', `multiple.${ext}`);
        } else {
          Ember.set(this, 'name', firstName);
        }

        Ember.set(this, 'shouldChangeName', false);
      }

      const promises = [];
      let file;

      for (let i = 0; i < input.files.length; i++) {
        file = input.files[i];
        promises.push(new Ember.RSVP.Promise((resolve, reject) => {
          var reader = new FileReader();

          reader.onload = res => {
            var out = res.target.result;
            resolve(out);
          };

          reader.onerror = err => {
            Ember.get(this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
            reject(err);
          };

          reader.readAsText(file);
        }));
      }

      Ember.RSVP.all(promises).then(res => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        let value = res.join('\n');
        Ember.set(this, 'value', value);

        if (value) {
          if (this.fileChosen) {
            this.fileChosen();
          }
        }
      }).finally(() => {
        input.value = '';
      });
    }

  });

  _exports.default = _default;
});