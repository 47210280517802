define("shared/mixins/sticky-table-header", ["exports", "jquery", "shared/mixins/throttled-resize"], function (_exports, _jquery, _throttledResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const tableProps = {
    actionsHeight: 60,
    fixedHeaderHeight: 40
  };

  var _default = Ember.Mixin.create(_throttledResize.default, {
    stickyHeader: true,
    subRow: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.buildTableWidths();

      if (this.get('showHeader')) {
        (0, _jquery.default)(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header').css('width', (0, _jquery.default)(this.element).find('> table').outerWidth());
      }

      if (!this.get('stickyHeader')) {
        return;
      }

      this.set('_boundScroll', this._scroll.bind(this));
      (0, _jquery.default)(window).on('scroll', this.get('_boundScroll'));
    },

    _boundScroll: null,

    _scroll() {
      Ember.run.throttle(() => {
        this.updateHeaders();
      }, 30);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (!this.get('stickyHeader')) {
        return;
      }

      (0, _jquery.default)(window).off('scroll', this.get('_boundScroll'));
    },

    onResize() {
      this._super(...arguments);

      this.buildTableWidths();
    },

    buildTableWidths() {
      if ((0, _jquery.default)(this.element).is(':visible')) {
        let ths = (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header > th');
        let $fixHdr = (0, _jquery.default)(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header');
        (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header-placeholder > th').each((idx, th) => {
          (0, _jquery.default)(ths[idx]).attr('width', (0, _jquery.default)(th).outerWidth());
        });

        if (this.get('showHeader')) {
          $fixHdr.css({
            'width': (0, _jquery.default)(this.element).find('> table').width()
          });

          if ($fixHdr.is(':visible')) {
            (0, _jquery.default)(this.element).find('.search-group').show(1, 'linear');
          }
        }
      }
    },

    tearDownTableWidths() {
      if (!this.get('stickyHeader')) {
        return;
      }

      (0, _jquery.default)(this.element).find('> table > thead > tr.fixed-header > th').each((idx, td) => {
        (0, _jquery.default)(td).removeAttr('width');
      });
    },

    positionHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      let elem = (0, _jquery.default)(this.element);
      let $table = elem.find('> table');
      let $actionRow = $table.find('> thead > .fixed-header-actions');
      let $fixedHeader = $table.find('> thead > tr.fixed-header');
      let showHeader = this.get('showHeader');
      let fudge = this.get('subRow') ? 100 : 0;

      if (showHeader) {
        $actionRow.css({
          'position': 'fixed',
          'top': fudge,
          'height': `${tableProps.actionsHeight}px`
        });
      }

      $fixedHeader.css({
        'position': 'fixed',
        'top': `${showHeader && this.get('bulkActions') ? fudge + tableProps.actionsHeight : 0}px`,
        'height': `${tableProps.fixedHeaderHeight}px`
      });
      elem.css({
        'padding-top': `${tableProps.actionsHeight + tableProps.fixedHeaderHeight}px`
      });
    },

    removePositions() {
      if (!this.get('stickyHeader')) {
        return;
      }

      let elem = (0, _jquery.default)(this.element);
      let $table = elem.find('> table');
      let $actionRow = $table.find('> thead > .fixed-header-actions');
      let $fixedHeader = $table.find('> thead > tr.fixed-header');

      if (this.get('showHeader')) {
        $actionRow.css({
          'position': 'relative',
          'top': ''
        });
      }

      $fixedHeader.css({
        'position': '',
        'top': ''
      });
      elem.css({
        'padding-top': ''
      });
      this.buildTableWidths();
    },

    updateHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      let elem = (0, _jquery.default)(this.element);
      let $table = elem.find('> table');
      let $floatingHeader = $table.find('> thead > tr.fixed-header');
      let $scrollTop = (0, _jquery.default)(window).scrollTop();
      let containerBottom = $table.height() + $table.offset().top;
      let offset = elem.find('> table > thead > tr').offset().top - parseInt(elem.css('padding-top'), 10);

      if ($scrollTop < containerBottom && $scrollTop > offset) {
        if ($floatingHeader.css('position') !== 'fixed') {
          this.buildTableWidths();
          this.positionHeaders();
        }
      } else {
        if ($floatingHeader.css('position') === 'fixed') {
          this.tearDownTableWidths();
          this.removePositions();
        }
      }
    }

  });

  _exports.default = _default;
});