define("global-admin/components/modal-revert-setting/component", ["exports", "shared/mixins/modal-base", "global-admin/components/modal-revert-setting/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'medium-modal'],
    primaryResource: Ember.computed.alias('modalOpts.setting'),
    setting: Ember.computed.alias('primaryResource'),
    kind: Ember.computed.alias('modalOpts.kind'),
    actions: {
      save(btnCb) {
        let pr = Ember.get(this, 'primaryResource');
        Ember.set(pr, 'value', Ember.get(pr, 'default') || '');
        pr.save().then(() => {
          btnCb(true);
          this.send('done');
        }).catch(err => {
          btnCb(err);
        });
      },

      done() {
        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});