define("shared/utils/fetch-yaml", ["exports", "@rancher/ember-api-store/utils/ajax-promise"], function (_exports, _ajaxPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchYaml;

  function fetchYaml(yamlLink, yamlOnly = true) {
    const params = {
      method: 'GET',
      url: yamlLink
    };

    if (yamlOnly) {
      params['headers'] = {
        Accept: 'application/yaml'
      };
    }

    return (0, _ajaxPromise.ajaxPromise)(params).then(data => {
      return data.xhr.responseText;
    });
  }
});