define("shared/mixins/catalog-app", ["exports", "ui/utils/parse-version", "shared/utils/evaluate", "ui/utils/constants"], function (_exports, _parseVersion, _evaluate, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    previewTabDidChange: Ember.observer('previewTab', 'previewOpen', function () {
      const files = Ember.get(this, 'selectedTemplateModel.filesAsArray') || [];
      const previewTab = Ember.get(this, 'previewTab');
      const found = files.findBy('name', previewTab);

      if (!found) {
        return;
      }

      Ember.set(this, 'decoding', true);
      Ember.run.next(() => {
        if (!found.decoded) {
          Ember.setProperties(found, {
            body: AWS.util.base64.decode(found.body).toString(),
            decoded: true
          });
        }

        Ember.setProperties(this, {
          selectedFileContetnt: found.body,
          decoding: false
        });
      });
    }),
    templateChanged: Ember.observer('editable.selectedTemplateUrl', 'templateResource.defaultVersion', function () {
      const {
        editable: {
          selectedTemplateUrl = ''
        }
      } = this;

      if (!Ember.isEmpty(selectedTemplateUrl)) {
        this.getTemplate.perform();
      }
    }),
    isLonghorn: Ember.computed('templateResource.id', function () {
      const {
        templateResource
      } = this;

      if (templateResource && templateResource.id === 'cattle-global-data:library-longhorn') {
        return true;
      }

      return false;
    }),
    filenames: Ember.computed('selectedTemplateModel', 'selectedTemplateModel.filesAsArray.[]', function () {
      const files = Ember.get(this, 'selectedTemplateModel.filesAsArray').map(file => ({
        label: file.name,
        value: file.name
      }));
      files.addObject({
        label: 'answers.yaml',
        value: 'answers'
      });
      return files.sortBy('label');
    }),
    sortedVersions: Ember.computed('defaultUrl', 'intl', 'showDefaultVersionOption', 'templateResource.defaultVersion', 'versionsArray', function () {
      const out = Ember.get(this, 'versionsArray').sort((a, b) => {
        if (a.sortVersion && b.sortVersion) {
          return (0, _parseVersion.compare)(a.sortVersion, b.sortVersion);
        } else {
          return (0, _parseVersion.compare)(a.version, b.version);
        }
      });
      const def = Ember.get(this, 'templateResource.defaultVersion');

      if (Ember.get(this, 'showDefaultVersionOption') && Ember.get(this, 'defaultUrl')) {
        out.unshift({
          version: Ember.get(this, 'intl').t('newCatalog.version.default', {
            version: def
          }),
          link: 'default'
        });
      }

      return out;
    }),
    defaultUrl: Ember.computed('templateResource.defaultVersion', 'versionLinks', function () {
      const defaultVersion = Ember.get(this, 'templateResource.defaultVersion');
      const versionLinks = Ember.get(this, 'versionLinks');

      if (defaultVersion && versionLinks && versionLinks[defaultVersion]) {
        return versionLinks[defaultVersion];
      }

      return null;
    }),
    answers: Ember.computed('selectedTemplateModel.allQuestions.@each.{answer,variable}', 'selectedTemplateModel.customAnswers', function () {
      const out = {};
      const allQuestions = Ember.get(this, 'selectedTemplateModel.allQuestions') || [];
      const filteredQuestions = allQuestions.filter(q => (0, _evaluate.evaluate)(q, allQuestions));
      filteredQuestions.forEach(item => {
        out[item.variable] = (0, _evaluate.stringifyAnswer)(item.answer);
      });
      const customAnswers = Ember.get(this, 'selectedTemplateModel.customAnswers') || {};
      Object.keys(customAnswers).forEach(key => {
        out[key] = (0, _evaluate.stringifyAnswer)(customAnswers[key]);
      });
      return out;
    }),
    newExternalId: Ember.computed('selectedTemplateModel.id', function () {
      return `${_constants.default.EXTERNAL_ID.KIND_CATALOG}${_constants.default.EXTERNAL_ID.KIND_SEPARATOR}${Ember.get(this, 'selectedTemplateModel.id')}`;
    }),

    updateReadme() {
      const model = Ember.get(this, 'selectedTemplateModel');
      Ember.setProperties(this, {
        readmeContent: null,
        appReadmeContent: null,
        noAppReadme: false
      });

      if (model && model.hasLink('readme')) {
        model.followLink('readme').then(response => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'readmeContent', response);
        });
      }

      if (model && model.hasLink('app-readme')) {
        Ember.set(this, 'noAppReadme', false);
        model.followLink('app-readme').then(response => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'appReadmeContent', response);

          if (!response) {
            Ember.set(this, 'noAppReadme', true);
          }
        });
      } else {
        Ember.set(this, 'noAppReadme', true);
      }
    }

  });

  _exports.default = _default;
});