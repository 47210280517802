define("global-admin/multi-cluster-apps/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    catalog: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),

    model() {
      return this.globalStore.findAll('multiclusterapp').then(resp => ({
        apps: resp
      }));
    },

    afterModel(model
    /* , transition */
    ) {
      let promises = [];
      Ember.get(model, 'apps').forEach(app => {
        promises.push(this.catalog.fetchTemplate(Ember.get(app, 'templateVersionId'), true));
      }); // we don't have to retrun the promise here because this data is really needed for the upgrade data. because we dont have it doesn't mean we should crash the page.

      Ember.RSVP.allSettled(promises).then(() => {
        this.catalog.fetchMultiClusterAppTemplates(Ember.get(model, 'apps'));
      });
      return;
    }

  });

  _exports.default = _default;
});