define("global-admin/security/authentication/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      cancel() {
        this.transitionTo('security.authentication');
      }

    }
  });

  _exports.default = _default;
});