define("shared/components/schema/input-secret/component", ["exports", "shared/utils/util", "shared/components/schema/input-secret/template"], function (_exports, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    type: 'secret',
    namespace: null,
    selectClass: 'form-control',
    valueKey: 'name',
    // What to set the value as.. 'name' or 'id'
    // For use as a catalog question
    field: null,
    // Read default from a schema resourceField
    value: null,
    // name or id output string
    selected: null,
    // Selected secret ID
    projectSecrets: null,
    namespaceSecrets: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'projectSecrets', Ember.get(this, 'store').all('secret').filterBy('type', Ember.get(this, 'type')));
      Ember.set(this, 'namespaceSecrets', Ember.get(this, 'store').all('namespacedSecret').filterBy('type', `namespaced${(0, _util.ucFirst)(Ember.get(this, 'type'))}`));
      let def = Ember.get(this, 'value') || Ember.get(this, 'field.default');

      if (def && !Ember.get(this, 'selected')) {
        var exact;
        Ember.get(this, 'projectSecrets').forEach(secret => {
          if (def === Ember.get(secret, Ember.get(this, 'valueKey'))) {
            exact = Ember.get(secret, 'id');
          }
        });
        const namespaceId = Ember.get(this, 'namespace.id');

        if (!exact && namespaceId) {
          Ember.get(this, 'namespaceSecrets').forEach(secret => {
            if (def === Ember.get(secret, Ember.get(this, 'valueKey')) && Ember.get(secret, 'namespaceId') === namespaceId) {
              exact = Ember.get(secret, 'id');
            }
          });
        }

        Ember.run.next(() => {
          Ember.set(this, 'selected', exact || null);
        });
      }
    },

    selectedChanged: Ember.observer('selected', function () {
      let id = Ember.get(this, 'selected');
      let str = null;

      if (id) {
        let secret = Ember.get(this, 'projectSecrets').findBy('id', id) || Ember.get(this, 'namespaceSecrets').findBy('id', id);

        if (secret) {
          Ember.set(this, 'selectedSecret', secret);
          str = Ember.get(secret, Ember.get(this, 'valueKey'));
        } else {
          Ember.set(this, 'selectedSecret', null);
        }
      }

      Ember.set(this, 'value', str);
    }),
    filtered: Ember.computed('exclude', 'namespace.id', 'namespaceSecrets.[]', 'projectSecrets.[]', function () {
      const intl = Ember.get(this, 'intl');
      let out = Ember.get(this, 'projectSecrets').map(secret => {
        return {
          label: Ember.get(secret, 'name'),
          value: Ember.get(secret, 'id'),
          group: intl.t('generic.project')
        };
      });
      const namespaceId = Ember.get(this, 'namespace.id');

      if (namespaceId) {
        Ember.get(this, 'namespaceSecrets').filterBy('namespaceId', namespaceId).forEach(secret => {
          out.push({
            label: Ember.get(secret, 'name'),
            value: Ember.get(secret, 'id'),
            group: intl.t('generic.namespace')
          });
        });
      }

      let exclude = Ember.get(this, 'exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        out = out.filter(x => !exclude.includes(x.value));
      }

      return out.sortBy('group', 'label');
    })
  });

  _exports.default = _default;
});