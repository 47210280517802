define("global-admin/security/authentication/github/controller", ["exports", "ui/utils/constants", "global-admin/mixins/authentication"], function (_exports, _constants, _authentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, {
    oauth: Ember.inject.service(),
    endpoint: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,
    saved: false,
    saving: false,
    haveToken: false,
    organizations: null,
    isEnterprise: false,
    secure: true,
    protocolChoices: [{
      label: 'https:// -- Requires a cert from a public CA',
      value: 'https://'
    }, {
      label: 'http://',
      value: 'http://'
    }],
    authConfig: Ember.computed.alias('model.githubConfig'),
    scheme: Ember.computed.alias('authConfig.scheme'),
    isEnabled: Ember.computed.alias('authConfig.enabled'),
    actions: {
      save() {
        this.send('clearError');
        Ember.set(this, 'saving', true);
        const authConfig = Ember.get(this, 'authConfig');
        const am = Ember.get(authConfig, 'accessMode') || 'restricted';
        Ember.setProperties(authConfig, {
          'clientId': (authConfig.get('clientId') || '').trim(),
          'clientSecret': (authConfig.get('clientSecret') || '').trim(),
          'enabled': false,
          // It should already be, but just in case..
          'accessMode': am,
          'tls': true,
          'allowedPrincipalIds': []
        });
        Ember.setProperties(Ember.get(this, 'oauth'), {
          hostname: authConfig.get('hostname'),
          scheme: authConfig.get('scheme'),
          clientId: authConfig.get('clientId')
        });
        Ember.set(this, '_boundSucceed', this.authenticationApplied.bind(this));
        Ember.get(this, 'oauth').test(authConfig, Ember.get(this, '_boundSucceed'));
      }

    },
    enterpriseDidChange: Ember.observer('isEnterprise', 'authConfig.hostname', 'secure', function () {
      Ember.run.once(this, 'updateEnterprise');
    }),
    createDisabled: Ember.computed('authConfig.{clientId,clientSecret,hostname}', 'testing', 'isEnterprise', 'haveToken', function () {
      if (!Ember.get(this, 'haveToken')) {
        return true;
      }

      if (Ember.get(this, 'isEnterprise') && !Ember.get(this, 'authConfig.hostname')) {
        return true;
      }

      if (Ember.get(this, 'testing')) {
        return true;
      }

      return false;
    }),
    providerName: Ember.computed('authConfig.hostname', function () {
      if (Ember.get(this, 'authConfig.hostname') && Ember.get(this, 'authConfig.hostname') !== 'github.com') {
        return 'authPage.github.enterprise';
      } else {
        return 'authPage.github.standard';
      }
    }),
    numUsers: Ember.computed('authConfig.allowedPrincipalIds', 'authConfig.allowedPrincipals.@each.externalIdType', 'wasRestricted', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_GITHUB_USER)).get('length');
    }),
    numOrgs: Ember.computed('authConfig.allowedPrincipalIds', 'authConfig.allowedPrincipals.@each.externalIdType', 'wasRestricted', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_GITHUB_ORG)).get('length');
    }),
    destinationUrl: Ember.computed(() => {
      return `${window.location.origin}/`;
    }),

    updateEnterprise() {
      if (Ember.get(this, 'isEnterprise')) {
        var hostname = Ember.get(this, 'authConfig.hostname') || '';
        var match = hostname.match(/^http(s)?:\/\//i);

        if (match) {
          Ember.setProperties(this, {
            secure: (match[1] || '').toLowerCase() === 's',
            'authConfig.hostname': hostname = hostname.substr(match[0].length).replace(/\/.*$/, '')
          });
        }
      } else {
        Ember.setProperties(this, {
          secure: true,
          'authConfig.hostname': 'github.com'
        });
      }

      Ember.set(this, 'scheme', Ember.get(this, 'secure') ? 'https://' : 'http://');
    },

    authenticationApplied(err) {
      Ember.set(this, 'saving', false);

      if (err) {
        Ember.set(this, 'isEnabled', false);
        this.send('gotError', err);
        return;
      }

      this.send('clearError');
    }

  });

  _exports.default = _default;
});