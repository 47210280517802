define("ember-intl/-private/formatters/format-number", ["exports", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  /**
   * @private
   * @hide
   */
  class FormatNumber extends _base.default {
    get options() {
      return Ember.A(['locale', 'format', 'localeMatcher', 'style', 'currency', 'currencyDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits']);
    }

    constructor() {
      super();
      this.createNativeFormatter = (0, _fastMemoize.default)((locales, options) => {
        return new Intl.NumberFormat(locales, options);
      });
    }

    format(value, options, ctx) {
      return this._format(value, this.readOptions(options), undefined, ctx);
    }

  }

  _exports.default = FormatNumber;
});