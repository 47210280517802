define("global-admin/cluster-templates/new-revision/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterTemplates: Ember.inject.service(),

    model(params) {
      return this.globalStore.find('clustertemplate', params.template_id).then(template => {
        return this.globalStore.findAll('clustertemplaterevision').then(revisions => {
          let tempRevision = null;
          let tempId = null;

          if (template.defaultRevisionId) {
            if (params.revision) {
              tempRevision = revisions.findBy('id', params.revision).cloneForNew();
              tempId = params.revision;
            } else {
              tempRevision = revisions.findBy('id', template.defaultRevisionId).cloneForNew();
              tempId = template.defaultRevisionId;
            }

            if (Ember.isEmpty(tempRevision.clusterConfig.rancherKubernetesEngineConfig)) {
              Ember.set(tempRevision, 'clusterConfig.rancherKubernetesEngineConfig', this.globalStore.createRecord({
                type: 'rancherKubernetesEngineConfig'
              }));
            }

            return Ember.RSVP.hash({
              clusterTemplate: template,
              clusterTemplateRevision: tempRevision,
              psps: this.globalStore.findAll('podSecurityPolicyTemplate'),
              clusterTemplateRevisionId: tempId
            });
          } else {
            if (params.revision) {
              tempRevision = revisions.findBy('id', params.revision).cloneForNew();
              tempId = params.revision;
            } else {
              tempRevision = Ember.get(revisions, 'firstObject').cloneForNew();
              tempId = Ember.get(revisions, 'firstObject').defaultRevisionId;
            }

            if (Ember.isEmpty(tempRevision.clusterConfig.rancherKubernetesEngineConfig)) {
              Ember.set(tempRevision, 'clusterConfig.rancherKubernetesEngineConfig', this.globalStore.createRecord({
                type: 'rancherKubernetesEngineConfig'
              }));
            }

            return Ember.RSVP.hash({
              clusterTemplate: template,
              clusterTemplateRevision: tempRevision,
              psps: this.globalStore.findAll('podSecurityPolicyTemplate'),
              clusterTemplateRevisionId: tempId
            });
          }
        });
      });
    },

    afterModel() {
      return this.clusterTemplates.fetchQuestionsSchema();
    },

    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('revision', null);
      }
    },

    queryParams: {
      revision: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});