define("global-admin/cluster-templates/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    clusterTemplates: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        clusterTemplate: this.globalStore.createRecord({
          type: 'clustertemplate'
        }),
        clusterTemplateRevision: this.globalStore.createRecord({
          name: 'v1',
          type: 'clusterTemplateRevision',
          enabled: true,
          clusterConfig: this.globalStore.createRecord({
            type: 'clusterSpecBase',
            rancherKubernetesEngineConfig: this.globalStore.createRecord({
              type: 'rancherKubernetesEngineConfig'
            })
          })
        }),
        psps: this.globalStore.findAll('podSecurityPolicyTemplate'),
        users: this.globalStore.findAll('user')
      });
    },

    afterModel(model, transition) {
      if (transition.from && transition.from.name === 'global-admin.clusters.index') {
        this.controllerFor(this.routeName).set('parentRoute', transition.from.name);
      }

      return this.clusterTemplates.fetchQuestionsSchema();
    }

  });

  _exports.default = _default;
});