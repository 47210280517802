define("shared/components/cluster-driver/driver-oracleoke/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-oracleoke/template", "shared/utils/oci", "shared/utils/parse-version"], function (_exports, _clusterDriver, _template, _oci, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const vcnIdMap = {
    quick: 'Quick Create'
  };
  const subnetAccessMap = {
    public: 'Public',
    private: 'Private'
  };

  var _default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'okeEngineConfig',
    instanceConfig: '',
    step: 1,
    lanChanged: null,
    refresh: false,
    vcnCreationMode: 'none',
    vpcs: null,
    subnets: null,
    eipIds: null,
    nodeFlavors: null,
    keypairs: null,
    availableZones: null,
    authRegionChoices: _oci.OCI_REGIONS,
    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments);

      let config = Ember.get(this, 'cluster.okeEngineConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: 'okeEngineConfig',
          secretKey: '',
          clusterName: '',
          vcnCidr: '10.0.0.0/16',
          kubernetesVersion: '',
          region: 'us-phoenix-1',
          nodeShape: 'VM.Standard2.1',
          nodeImage: '',
          vcn: '',
          securityListId: '',
          subnetAccess: 'public',
          flexOcpus: 0,
          memory: 0,
          quantityPerSubnet: 1,
          quantityOfNodeSubnets: 1
        });
        Ember.set(this, 'cluster.okeEngineConfig', config);
      } // init cpu and memory


      const {
        cpu,
        memory
      } = Ember.get(this, 'config');

      if (cpu && memory) {
        Ember.set(this, 'instanceConfig', `${Ember.get(this, 'config.cpu')}/${Ember.get(this, 'config.memory')}`);
      }
    },

    actions: {
      authenticateOCI(cb) {
        const store = Ember.get(this, 'globalStore');
        const data = {
          tenancyOCID: Ember.get(this, 'cluster.okeEngineConfig.tenancyId'),
          userOCID: Ember.get(this, 'cluster.okeEngineConfig.userOcid'),
          region: Ember.get(this, 'cluster.okeEngineConfig.region'),
          fingerprint: Ember.get(this, 'cluster.okeEngineConfig.fingerprint'),
          privateKey: Ember.get(this, 'cluster.okeEngineConfig.privateKeyContents'),
          privateKeyPassphrase: Ember.get(this, 'cluster.okeEngineConfig.privateKeyPassphrase'),
          compartmentOCID: Ember.get(this, 'cluster.okeEngineConfig.compartmentId')
        };
        const ociRequest = {
          okeVersions: store.rawRequest({
            url: '/meta/oci/okeVersions',
            method: 'POST',
            data
          })
        };
        return Ember.RSVP.hash(ociRequest).then(resp => {
          const {
            okeVersions
          } = resp;
          Ember.setProperties(this, {
            okeVersions: (Ember.get(okeVersions, 'body') || []).sort((a, b) => (0, _parseVersion.compare)(a, b) < 0),
            // latest version first
            errors: []
          }); // latest version by default

          Ember.set(this, 'config.kubernetesVersion', Ember.get(this, 'okeVersions')[0] || null);
          Ember.set(this, 'step', 2);
          cb(true);
        }).catch(xhr => {
          const err = xhr.body.message || xhr.body.code || xhr.body.error;
          Ember.setProperties(this, {
            errors: [err]
          });
          cb(false, [err]);
        });
      },

      loadNodeConfig(cb) {
        const store = Ember.get(this, 'globalStore');
        const data = {
          tenancyOCID: Ember.get(this, 'cluster.okeEngineConfig.tenancyId'),
          userOCID: Ember.get(this, 'cluster.okeEngineConfig.userOcid'),
          region: Ember.get(this, 'cluster.okeEngineConfig.region'),
          fingerprint: Ember.get(this, 'cluster.okeEngineConfig.fingerprint'),
          privateKey: Ember.get(this, 'cluster.okeEngineConfig.privateKeyContents'),
          privateKeyPassphrase: Ember.get(this, 'cluster.okeEngineConfig.privateKeyPassphrase'),
          compartmentOCID: Ember.get(this, 'cluster.okeEngineConfig.compartmentId')
        };
        const ociRequest = {
          availabilityDomains: store.rawRequest({
            url: '/meta/oci/availabilityDomains',
            method: 'POST',
            data
          }),
          nodeShapes: store.rawRequest({
            url: '/meta/oci/nodeShapes',
            method: 'POST',
            data
          }),
          nodeImages: store.rawRequest({
            url: '/meta/oci/nodeOkeImages',
            method: 'POST',
            data
          })
        };
        return Ember.RSVP.hash(ociRequest).then(resp => {
          const {
            availabilityDomains,
            nodeShapes,
            nodeImages
          } = resp;
          Ember.setProperties(this, {
            availabilityDomais: Ember.get(availabilityDomains, 'body') || [],
            nodeShapes: (Ember.get(nodeShapes, 'body') || []).reverse(),
            nodeImages: Ember.get(nodeImages, 'body') || [],
            errors: []
          });
          Ember.set(this, 'step', 3);
          cb(true);
        }).catch(xhr => {
          const err = xhr.body.message || xhr.body.code || xhr.body.error;
          Ember.setProperties(this, {
            errors: [err]
          });
          cb(false, [err]);
        });
      },

      // TODO implement loadInstanceConfig
      loadInstanceConfig(cb) {
        Ember.set(this, 'errors', null);
        Ember.set(this, 'step', 4);
        cb(true);
      },

      upgradeCluster(cb) {
        Ember.setProperties(this, {
          'errors': null
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const quantityPerSubnet = Ember.get(this, 'config.quantityPerSubnet');
        const kubernetesVersion = Ember.get(this, 'config.kubernetesVersion');

        if (!quantityPerSubnet) {
          errors.push(intl.t('clusterNew.oke.quantityPerSubnet.required'));
        } else {
          const maxNodeCount = Ember.get(this, 'config.maxNodeCount');

          if (!/^\d+$/.test(quantityPerSubnet) || parseInt(quantityPerSubnet, 10) < 0 || parseInt(quantityPerSubnet, 10) > maxNodeCount) {
            errors.push(intl.t('clusterNew.oke.quantityPerSubnet.error', {
              max: maxNodeCount
            }));
          }
        }

        if (!kubernetesVersion) {
          errors.push(intl.t('clusterNew.oke.version.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        this.send('driverSave', cb);
      },

      save(cb) {
        Ember.setProperties(this, {
          'errors': null,
          'otherErrors': null,
          'clusterErrors': null
        });
        const errors = Ember.get(this, 'errors') || [];

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb(false);
          return;
        }

        if (!this.validate()) {
          cb(false);
          return;
        }

        if (Ember.get(this, 'config.vcnCompartmentId') === '') {
          Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.compartmentId'));
        }

        if (Ember.get(this, 'config.subnetAccess') === 'public') {
          Ember.set(this, 'config.enablePrivateNodes', false);
        } else {
          Ember.set(this, 'config.enablePrivateNodes', true);
        }

        this.send('driverSave', cb);
      },

      cancel() {
        Ember.get(this, 'router').transitionTo('global-admin.clusters.index');
      },

      cpuAndMemoryChanged(item) {
        Ember.setProperties(this, {
          'config.cpu': item.raw.cpuCount,
          'config.memory': item.raw.memoryCapacityInGB
        });
      }

    },
    maxNodeCount: Ember.computed('clusterQuota.slave', () => {
      return 256;
    }),
    vcnChoices: Object.entries(vcnIdMap).map(e => ({
      label: e[1],
      value: e[0]
    })),
    selectedVCN: Ember.computed('config.vcnId', function () {
      const vcnId = Ember.get(this, 'config.vcnId');
      return vcnId && vcnIdMap[vcnId];
    }),
    subnetAccessChoices: Object.entries(subnetAccessMap).map(e => ({
      label: e[1],
      value: e[0]
    })),
    selectedSubnetAccess: Ember.computed('config.subnetAccess', function () {
      const subnetAccess = Ember.get(this, 'config.subnetAccess');
      return subnetAccess && subnetAccessMap[subnetAccess];
    }),
    canAuthenticate: Ember.computed('config.tenancyId', 'config.region', 'config.userOcid', 'config.fingerprint', 'config.privateKeyContents', function () {
      return Ember.get(this, 'config.tenancyId') && Ember.get(this, 'config.region') && Ember.get(this, 'config.userOcid') && Ember.get(this, 'config.fingerprint') && Ember.get(this, 'config.privateKeyContents') ? false : true;
    }),
    canAddK8sVersion: Ember.computed('config.kubernetesVersion', 'config.compartmentId', function () {
      return !(Ember.get(this, 'config.compartmentId') && Ember.get(this, 'config.kubernetesVersion'));
    }),
    canSaveVCN: Ember.computed('vcnCreationMode', 'config.vcnName', 'config.loadBalancerSubnetName1', 'config.loadBalancerSubnetName2', 'config.subnetAccess', 'config.vcnCidr', function () {
      const mode = Ember.get(this, 'vcnCreationMode');

      if (mode === 'Quick') {
        return false;
      } else if (mode === 'Existing') {
        // Driver will use the same compartment as the cluster if not set.
        return Ember.get(this, 'config.vcnName') && Ember.get(this, 'config.loadBalancerSubnetName1') ? false : true;
      } else if (mode === 'Custom') {
        return Ember.get(this, 'config.subnetAccess') && Ember.get(this, 'config.vcnCidr') ? false : true;
      }

      return true;
    }),
    canCreateCluster: Ember.computed('config.nodeShape', 'config.nodeImage', function () {
      return !(Ember.get(this, 'config.nodeShape') && Ember.get(this, 'config.nodeImage'));
    }),
    isFlex: Ember.computed('config.nodeShape', function () {
      return Ember.get(this, 'config.nodeShape').includes('Flex');
    }),

    // Add custom validation beyond what can be done from the config API schema
    validate() {
      // Get generic API validation errors
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'cluster.name')) {
        errors.push('Name is required');
      }

      const tenancyId = Ember.get(this, 'config.tenancyId');

      if (!tenancyId.startsWith('ocid1.tenancy')) {
        errors.push('A valid tenancy OCID is required');
      }

      const compartmentId = Ember.get(this, 'config.compartmentId');

      if (!compartmentId.startsWith('ocid1.compartment')) {
        errors.push('A valid compartment OCID is required');
      }

      const userOcid = Ember.get(this, 'config.userOcid');

      if (!userOcid.startsWith('ocid1.user')) {
        errors.push('A valid user OCID is required');
      } // TODO Add more specific errors
      // Set the array of errors for display,
      // and return true if saving should continue.


      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        Ember.set(this, 'errors', null);
        return true;
      }
    },

    willSave() {
      if (Ember.get(this, 'mode') === 'new') {
        if (Ember.get(this, 'config.vcnCompartmentId') === '') {
          Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.compartmentId'));
        }

        if (Ember.get(this, 'config.subnetAccess') === 'public') {
          Ember.set(this, 'config.enablePrivateNodes', false);
        } else {
          Ember.set(this, 'config.enablePrivateNodes', true);
        }
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});