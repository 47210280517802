define("global-admin/global-dns/providers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    translationKey: 'generic.state',
    name: 'state',
    width: 100
  }, {
    translationKey: 'globalDnsPage.providersPage.table.name',
    name: 'name',
    sort: ['name', 'id']
  }, {
    translationKey: 'globalDnsPage.providersPage.table.rootDomain',
    name: 'rootDomain',
    sort: ['rootDomain', 'name', 'id']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    sort: ['created'],
    searchField: false,
    classNames: 'pr-20',
    width: 200
  }];

  var _default = Ember.Controller.extend({
    sortBy: 'name',
    searchText: '',
    headers: HEADERS,
    rows: Ember.computed('model.[]', function () {
      return Ember.get(this, 'model');
    })
  });

  _exports.default = _default;
});