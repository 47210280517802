define("global-admin/components/cru-group-account/component", ["exports", "global-admin/components/cru-group-account/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    name: 'active',
    sort: ['active'],
    translationKey: 'accountsPage.detail.table.headers.active',
    width: 125
  }, {
    name: 'name',
    sort: ['name'],
    translationKey: 'accountsPage.detail.table.headers.role'
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    sortBy: 'name',
    mode: 'new',
    globalRoleSave: null,
    canUserLogIn: null,
    groupPrincipalId: null,
    headers: HEADERS,

    init() {
      this._super(...arguments);

      if (this.model.principal && this.mode === 'edit') {
        Ember.set(this, 'groupPrincipalId', this.model.principal.id);
      }
    },

    actions: {
      addGroup(group) {
        if (group) {
          Ember.set(this, 'groupPrincipalId', group.id);
        }
      },

      save(cb) {
        if (!this.validate()) {
          return cb(false);
        }

        return this.globalRoleSave().then(() => {
          cb(true);
          this.doneSaving();
        });
      },

      cancel() {
        this.router.transitionTo('global-admin.security.accounts.groups');
      },

      setGlobalRoleSave(fn) {
        Ember.set(this, 'globalRoleSave', fn);
      },

      setValidateGlobalRoles(fn) {
        Ember.set(this, 'canUserLogIn', fn);
      }

    },
    roles: Ember.computed('model.globalRoles.[]', function () {
      return (this.model.globalRoles || []).map(grb => {
        return {
          name: Ember.get(grb, 'name'),
          active: false,
          globalId: Ember.get(grb, 'id')
        };
      });
    }),

    validate() {
      var errors = [];

      if (!this.groupPrincipalId) {
        errors.push(this.intl.t('accountsPage.newGroup.errors.group'));
      }

      if (this.canUserLogIn && !this.canUserLogIn()) {
        errors.push(this.intl.t('formGlobalRoles.loginError', {
          type: this.intl.t('generic.group')
        }));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      Ember.set(this, 'errors', null);
      return true;
    },

    doneSaving() {
      this.router.transitionTo('global-admin.security.accounts.groups');
    }

  });

  _exports.default = _default;
});