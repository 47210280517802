define("shared/utils/text-width", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWidth;
  let canvas;

  function getWidth(str, fontStyle) {
    if (!canvas) {
      canvas = document.createElement('canvas');
    }

    const ctx = canvas.getContext('2d');
    ctx.font = fontStyle;
    return Math.ceil(ctx.measureText(str).width);
  }
});