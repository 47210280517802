define("shared/components/form-network-config/component", ["exports", "shared/components/form-network-config/template", "shared/utils/util", "semver", "shared/utils/parse-version", "shared/utils/constants"], function (_exports, _template, _util, _semver, _parseVersion, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NETWORKCHOICES = [{
    label: 'clusterNew.rke.network.flannel',
    value: 'flannel'
  }, {
    label: 'clusterNew.rke.network.calico',
    value: 'calico'
  }, {
    label: 'clusterNew.rke.network.canal',
    value: 'canal'
  }, {
    label: 'clusterNew.rke.network.weave',
    value: 'weave'
  }];
  const {
    BACKEND_PORT,
    BACKEND_VNI,
    CALICO,
    CANAL,
    DEFAULT_BACKEND_TYPE,
    FLANNEL,
    VXLAN,
    WEAVE
  } = _constants.default.NETWORK_CONFIG_DEFAULTS;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    networkContent: NETWORKCHOICES,
    mode: 'new',
    isCustom: false,
    config: null,
    enableNetworkPolicy: null,
    clusterTemplateRevision: null,
    applyClusterTemplate: null,
    windowsSupportOverrideAvailable: false,
    isEdit: Ember.computed.equal('mode', 'edit'),

    init() {
      this._super(...arguments);

      this.setFlannelLables();
    },

    windowsSupportAvailableDidChange: Ember.observer('windowsSupportAvailable', function () {
      if (!Ember.get(this, 'windowsSupportAvailable')) {
        Ember.set(this, 'cluster.windowsPreferedCluster', false);
      }
    }),
    windowsSupportChange: Ember.observer('cluster.windowsPreferedCluster', function () {
      if (this.mode === 'edit') {
        return;
      }

      const windowsSupport = Ember.get(this, 'cluster.windowsPreferedCluster');
      const config = Ember.get(this, 'config');

      if (!config) {
        return;
      }

      if (windowsSupport) {
        Ember.set(config, 'network.options.flannel_backend_type', VXLAN);
      } else {
        Ember.set(config, 'network.options.flannel_backend_type', DEFAULT_BACKEND_TYPE);
      }
    }),
    flannelBackendDidChange: Ember.observer('cluster.windowsPreferedCluster', 'config.network.plugin', 'config.network.options.flannel_backend_type', function () {
      const config = Ember.get(this, 'config');

      if (config) {
        const plugin = Ember.get(config, 'network.plugin');
        const flannelBackend = Ember.get(config, 'network.options.flannel_backend_type') ? Ember.get(config, 'network.options.flannel_backend_type') : '';
        const windowsSupport = Ember.get(this, 'cluster.windowsPreferedCluster');

        if (flannelBackend === VXLAN && plugin === FLANNEL && windowsSupport) {
          Ember.setProperties(config, {
            'network.options.flannel_backend_port': BACKEND_PORT,
            'network.options.flannel_backend_vni': BACKEND_VNI
          });
        } else {
          const options = Ember.get(config, 'network.options');

          if (options) {
            delete options['flannel_backend_port'];
            delete options['flannel_backend_vni'];
          }
        }
      }
    }),
    networkPluginDidChange: Ember.observer('config.network.plugin', function () {
      let plugin = Ember.get(this, 'config.network.plugin');

      if (plugin) {
        if (plugin === FLANNEL) {
          Ember.set(this, 'enableNetworkPolicy', false);
        }

        if (plugin === WEAVE) {
          Ember.set(this, 'config.network.weaveNetworkProvider', this.globalStore.createRecord({
            type: 'weaveNetworkProvider',
            password: (0, _util.randomStr)(16, 16, 'password')
          }));
        } else if (plugin !== WEAVE && Ember.get(this, 'config.network.weaveNetworkProvider.password')) {
          Ember.set(this, 'config.network.weaveNetworkProvider', null);
        }
      }
    }),
    windowsSupportAvailable: Ember.computed('config.network.plugin', 'config.kubernetesVersion', function () {
      const plugin = Ember.get(this, 'config.network.plugin');
      const kubernetesVersion = Ember.get(this, 'config.kubernetesVersion');
      return plugin === FLANNEL && (0, _semver.gte)((0, _parseVersion.coerceVersion)(kubernetesVersion), 'v1.15.3');
    }),
    projectNetworkIsolationAvailable: Ember.computed('config.network.plugin', function () {
      const plugin = Ember.get(this, 'config.network.plugin');
      const networkPolicySafe = [CANAL, CALICO, WEAVE];
      return networkPolicySafe.includes(plugin);
    }),

    setFlannelLables() {
      let flannel = this.networkContent.findBy('value', 'flannel');

      if (Ember.get(this, 'isCustom')) {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannelCustom');
      } else {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannel');
      }
    }

  });

  _exports.default = _default;
});