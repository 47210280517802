define("shared/components/cru-private-cluster/component", ["exports", "shared/components/cru-private-cluster/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_PRIVATE_CONFIG = {
    enablePrivateEndpoint: false,
    enablePrivateNodes: false,
    masterIpv4CidrBlock: null
  };

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    config: null,
    mode: 'new',
    isNew: true,
    editing: false,
    defaultConfig: DEFAULT_PRIVATE_CONFIG,
    enablePrivateNodesChanged: Ember.on('init', Ember.observer('config.enablePrivateNodes', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const {
        config
      } = this;
      const {
        enablePrivateEndpoint,
        masterIpv4CidrBlock
      } = this.defaultConfig;

      if (this.isNew && !(config === null || config === void 0 ? void 0 : config.enablePrivateNodes)) {
        Ember.run.next(this, () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (!config.enablePrivateNodes) {
            Ember.set(this, 'config.enablePrivateEndpoint', enablePrivateEndpoint);
          }

          if (!Ember.isEmpty(config.masterIpv4CidrBlock)) {
            Ember.set(this, 'config.masterIpv4CidrBlock', masterIpv4CidrBlock);
          }
        });
      }
    }))
  });

  _exports.default = _default;
});