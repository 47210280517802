define("global-admin/clusters/new/select/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),

    model() {
      return this.modelFor('clusters.new');
    },

    setupController(controller, model) {
      this._super(controller, model);

      let {
        me: {
          hasAdmin: globalAdmin = false
        }
      } = this.access;
      let {
        clusterTemplates = []
      } = model;
      let {
        clusterTemplateEnforcement = false
      } = this.settings; // setting is string value

      if (clusterTemplateEnforcement === 'true') {
        clusterTemplateEnforcement = true;
      } else {
        clusterTemplateEnforcement = false;
      }

      if (!globalAdmin && clusterTemplateEnforcement) {
        if (clusterTemplates.length <= 0) {
          controller.set('disabledAddCluster', true);
        }
      }
    }

  });

  _exports.default = _default;
});