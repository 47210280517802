define("ember-engines/-private/controller-ext", [], function () {
  "use strict";

  Ember.Controller.reopen({
    /*
      Creates an aliased form of a method that properly resolves external routes.
    */
    transitionToExternalRoute(routeName, ...args) {
      let externalRoute = Ember.getOwner(this)._getExternalRoute(routeName);

      let target = this.target;
      let method = target.transitionToRoute || target.transitionTo;
      return method.apply(target, [externalRoute, ...args]);
    }

  });
});