define("shared/components/modal-root/component", ["exports", "jquery", "shared/components/modal-root/template"], function (_exports, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    tagName: 'div',
    classNames: ['modal-overlay'],
    classNameBindings: ['modalVisible:modal-open:modal-closed'],
    modalType: Ember.computed.alias('modalService.modalType'),
    modalVisible: Ember.computed.alias('modalService.modalVisible'),

    click(e) {
      if (this.get('modalService.closeWithOutsideClick') && (0, _jquery.default)(e.target).hasClass('modal-open')) {
        this.get('modalService').toggleModal();
      }
    }

  });

  _exports.default = _default;
});