define("shared/components/custom-command/component", ["exports", "shared/components/custom-command/template", "shared/mixins/manage-labels", "@rancher/ember-api-store/utils/validate", "shared/utils/util"], function (_exports, _template, _manageLabels, _validate, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    address: null,
    internalAddress: null,
    nodeName: null,
    labels: null,
    token: null,
    nodeNameErrors: null,
    isLinux: true,
    etcd: false,
    controlplane: false,
    worker: true,
    commandAdvanced: false,

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        labels: {},
        nodeNameErrors: [],
        taints: []
      });
    },

    actions: {
      setTaints(taints) {
        Ember.set(this, 'taints', taints);
      },

      setLabels(labels) {
        Ember.set(this, 'labels', labels);
        var out = {};
        labels.forEach(row => {
          out[row.key] = row.value;
        });
        this.set('labels', out);
      },

      expand(item) {
        item.toggleProperty('expanded');
      }

    },
    isAddressValid: Ember.computed('address.length', function () {
      const {
        address
      } = this;
      return Ember.isEmpty(address) || (0, _util.validateEndpoint)(Ember.get(this, 'address'));
    }),
    isInternalAddressValid: Ember.computed('internalAddress.length', function () {
      const {
        internalAddress
      } = this;
      return Ember.isEmpty(internalAddress) || (0, _util.validateEndpoint)(Ember.get(this, 'internalAddress'));
    }),
    isNodeNameValid: Ember.computed('nodeName', function () {
      const nodeName = (Ember.get(this, 'nodeName') || '').toLowerCase();

      if (Ember.get(nodeName, 'length') === 0) {
        return true;
      } else {
        const errors = (0, _validate.validateHostname)(nodeName, 'Node Name', Ember.get(this, 'intl'), {
          restricted: true
        });
        Ember.set(this, 'nodeNameErrors', errors);
        return errors.length === 0;
      }
    }),
    command: Ember.computed('taints', 'labels', 'token.{nodeCommand,windowsNodeCommand}', 'etcd', 'controlplane', 'worker', 'address', 'internalAddress', 'nodeName', 'isLinux', function () {
      let out = Ember.get(this, 'token.nodeCommand');

      if (!out) {
        return;
      }

      const {
        address,
        internalAddress,
        isLinux,
        labels,
        nodeName,
        taints
      } = this;
      const roles = ['etcd', 'controlplane', 'worker'];
      const windowsSelected = !isLinux;
      const windowsCmdPostfix = ` | iex}"`;

      if (windowsSelected) {
        Ember.run.next(() => {
          Ember.setProperties(this, {
            etcd: false,
            controlplane: false
          });
        });
        out = (Ember.get(this, 'token.windowsNodeCommand') || '').replace('--isolation hyperv ', '').replace(windowsCmdPostfix, '');
      }

      if (nodeName) {
        out += ` --node-name ${nodeName.toLowerCase()}`;
      }

      if (address) {
        out += ` --address ${address}`;
      }

      if (internalAddress) {
        out += ` --internal-address ${internalAddress}`;
      }

      for (let i = 0, k; i < roles.length; i++) {
        k = roles[i];

        if (Ember.get(this, k)) {
          out += ` --${k}`;
        }
      }

      Object.keys(labels).forEach(key => {
        out += ` --label ${key}=${labels[key]}`;
      });
      taints.forEach(taint => {
        out += ` --taints ${Ember.get(taint, 'key')}=${Ember.get(taint, 'value')}:${Ember.get(taint, 'effect')}`;
      });

      if (windowsSelected) {
        out += windowsCmdPostfix;
      }

      return out;
    })
  });

  _exports.default = _default;
});