define("global-admin/security/policies/index/controller", ["exports", "ui/mixins/filter-state"], function (_exports, _filterState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headers = [{
    translationKey: 'podSecurityPoliciesPage.index.table.name',
    name: 'name',
    sort: ['name']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    sort: ['created'],
    searchField: false,
    classNames: 'text-right pr-20',
    width: '200'
  }];

  var _default = Ember.Controller.extend(_filterState.default, {
    sortBy: 'name',
    headers,
    searchText: ''
  });

  _exports.default = _default;
});