define("shared/components/outside-click/component", ["exports", "shared/components/outside-click/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['outside-click'],
    onOutsideClick: null,
    layout: _template.default,
    isOutside: false,

    init() {
      this._super(...arguments);

      this._boundHandleDown = this.handleDown.bind(this);
      this._boundHandleUp = this.handleUp.bind(this);
    },

    didInsertElement() {
      this._super(...arguments);

      document.addEventListener('mousedown', this._boundHandleDown, true);
      document.addEventListener('mouseup', this._boundHandleUp, true);
    },

    willDestroyElement() {
      this._super(...arguments);

      document.removeEventListener('mousedown', this._boundHandleDown, true);
      document.removeEventListener('mouseup', this._boundHandleUp, true);
    },

    handleDown(e) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!this.element.contains(e.target)) {
        this.set('isOutside', true);
      }
    },

    handleUp(e) {
      if (this.get('isOutside')) {
        if (this.onOutsideClick) {
          this.onOutsideClick(e);
        }
      }

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      this.set('isOutside', false);
    }

  });

  _exports.default = _default;
});