define("shared/components/modal-resize-pvc/component", ["exports", "shared/components/modal-resize-pvc/template", "shared/mixins/modal-base", "shared/utils/parse-unit"], function (_exports, _template, _modalBase, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    model: null,

    didReceiveAttrs() {
      const model = Ember.get(this, 'modalOpts.model').clone();
      Ember.set(this, 'model', model);
    },

    actions: {
      save(cb) {
        Ember.get(this, 'model').save().then(() => {
          this.send('cancel');
        }).finally(() => {
          cb();
        });
      }

    },
    storage: Ember.computed('model.resources.requests.storage', {
      get() {
        const capacity = Ember.get(this, 'model.resources.requests.storage');
        const bytes = (0, _parseUnit.parseSi)(capacity);
        const gib = bytes / 1024 ** 3;
        return gib;
      },

      set(key, value) {
        const {
          resources
        } = this.model;
        Ember.set(resources, 'requests.storage', `${value}Gi`);
        return value;
      }

    })
  });

  _exports.default = _default;
});