define("ember-model-validator/initializers/model-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    let application = arguments[1] || arguments[0];
    let validatorDefaultLocale = application.get('validatorDefaultLocale');

    if (validatorDefaultLocale) {
      application.register('validator:locale', validatorDefaultLocale, {
        instantiate: false
      });
    }
  }

  var _default = {
    name: 'model-locale',
    initialize
  };
  _exports.default = _default;
});