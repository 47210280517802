define("ember-intl/services/intl", ["exports", "@ember-intl/intl-relativeformat", "@ember-intl/intl-messageformat", "ember-intl/-private/formatters", "ember-intl/-private/is-array-equal", "ember-intl/-private/normalize-locale", "ember-intl/utils/links", "ember-intl/hydrate", "ember-intl/utils/get-dom"], function (_exports, _intlRelativeformat, _intlMessageformat, _formatters, _isArrayEqual, _normalizeLocale, _links, _hydrate, _getDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /** @private **/
    _locale: null,

    /** @private **/
    _adapter: null,

    /** @public **/
    formats: null,

    /** @private **/
    _timer: null,

    /** @public **/
    locale: Ember.computed({
      set(_, localeName) {
        const proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);

        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          this._locale = proposed;
          Ember.run.cancel(this._timer);
          this._timer = Ember.run.next(() => {
            this.trigger('localeChanged');
            this.updateDocumentLanguage(this._locale);
          });
        }

        return this._locale;
      },

      get() {
        return this._locale;
      }

    }),

    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: Ember.computed.readOnly('locale.0'),

    /** @public **/
    formatRelative: formatter('relative'),

    /** @public **/
    formatMessage: formatter('message'),

    /** @public **/
    formatNumber: formatter('number'),

    /** @public **/
    formatTime: formatter('time'),

    /** @public **/
    formatDate: formatter('date'),

    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: Ember.computed.readOnly('_adapter.locales'),

    /** @public **/
    init() {
      this._super(...arguments);

      if (typeof Intl === 'undefined') {
        (false && Ember.warn(`[ember-intl] Intl API was not found.\nSee: ${_links.default.polyfill}`, false, {
          id: 'ember-intl-undefined-intljs'
        }));
      }

      const initialLocale = Ember.get(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = Ember.getOwner(this);
      this._adapter = this._owner.lookup('ember-intl@adapter:default');
      this._formatters = {
        message: new _formatters.FormatMessage(),
        relative: new _formatters.FormatRelative(),
        number: new _formatters.FormatNumber(),
        time: new _formatters.FormatTime(),
        date: new _formatters.FormatDate()
      };

      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }

      (0, _hydrate.default)(this, this._owner);
    },

    willDestroy() {
      this._super(...arguments);

      Ember.run.cancel(this._timer);
    },

    /** @public **/
    lookup(key, localeName, options = {}) {
      const localeNames = this.localeWithDefault(localeName);
      let translation;

      for (let i = 0; i < localeNames.length; i++) {
        translation = this._adapter.lookup(localeNames[i], key);

        if (translation !== undefined) {
          break;
        }
      }

      if (!options.resilient && translation === undefined) {
        const missingMessage = this._owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames, options);
      }

      return translation;
    },

    /** @public **/
    t(key, options = {}) {
      let defaults = [key];
      let msg;

      if (options.default) {
        defaults = defaults.concat(options.default);
      }

      while (!msg && defaults.length) {
        msg = this.lookup(defaults.shift(), options.locale, Ember.assign({}, options, {
          resilient: defaults.length > 0
        }));
      }
      /* Avoids passing msg to intl-messageformat if it is not a string */


      if (typeof msg === 'string') {
        return this.formatMessage(msg, options);
      }

      return msg;
    },

    /** @public **/
    exists(key, localeName) {
      const localeNames = this.localeWithDefault(localeName);
      (false && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert(`[ember-intl] locale is unset, cannot lookup '${key}'`, Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(localeName => this._adapter.has(localeName, key));
    },

    /** @public */
    setLocale(locale) {
      Ember.set(this, 'locale', locale);
    },

    /**
     * A utility method for registering CLDR data against
     * intl-messageformat and intl-relativeformat.
     *
     * @method addLocaleData
     * @param {Object} locale data
     * @public
     */
    addLocaleData(data) {
      _intlMessageformat.default.__addLocaleData(data);

      _intlRelativeformat.default.__addLocaleData(data);
    },

    /** @public **/
    addTranslations(localeName, payload) {
      const locale = this.translationsFor(localeName);
      return locale.addTranslations(payload);
    },

    /** @public **/
    translationsFor(localeName) {
      return this._adapter.localeFactory((0, _normalizeLocale.default)(localeName));
    },

    /** @private **/
    getFormat(formatType, format) {
      const formats = Ember.get(this, 'formats');

      if (formats && formatType && typeof format === 'string') {
        return Ember.get(formats, `${formatType}.${format}`);
      }
    },

    /** @private **/
    localeWithDefault(localeName) {
      if (!localeName) {
        return this._locale || [];
      }

      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },

    /** @private **/
    updateDocumentLanguage(locales) {
      const dom = (0, _getDom.default)(this);

      if (dom) {
        const [primaryLocale] = locales;
        const html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    }

  });

  _exports.default = _default;

  function formatter(name) {
    return function (value, options, formats) {
      let formatOptions = options;

      if (options && typeof options.format === 'string') {
        formatOptions = Ember.assign({}, this.getFormat(name, formatOptions.format), formatOptions);
      }

      return this._formatters[name].format(value, formatOptions, {
        formats: formats || this.formats,
        locale: this.localeWithDefault(formatOptions && formatOptions.locale)
      });
    };
  }
});