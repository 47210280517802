define("shared/all-dns-records/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convert(obj, intl) {
    const namespace = Ember.get(obj, 'namespaceId');
    const name = Ember.get(obj, 'displayName');
    return {
      group: intl.t('allWorkloads.namespace', {
        name: namespace
      }),
      combined: `${namespace}/${name}`,
      namespace,
      id: Ember.get(obj, 'id'),
      clusterIp: Ember.get(obj, 'clusterIp'),
      name,
      kind: Ember.get(obj, 'type'),
      obj
    };
  }

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    app: Ember.inject.service(),
    _allDnsRecords: null,

    init() {
      this._super(...arguments);

      const store = Ember.get(this, 'store');
      Ember.set(this, '_allDnsRecords', store.all('service'));
    },

    dnsRecords: Ember.computed('_allDnsRecords.@each.{id,namespaceId,displayName,type}', function () {
      const intl = Ember.get(this, 'intl');
      return Ember.get(this, '_allDnsRecords').filter(x => Ember.get(x, 'ownerReferences.firstObject.kind') !== 'Ingress').map(x => convert(x, intl)).sortBy('combined');
    }),
    list: Ember.computed.alias('dnsRecords'),
    grouped: Ember.computed('list.[]', function () {
      return this.group(Ember.get(this, 'list'));
    }),

    byId(id) {
      return Ember.get(this, '_allDnsRecords').findBy('id', id);
    },

    group(list) {
      const out = {};
      list.slice().sortBy('group', 'name', 'id').forEach(obj => {
        let ary = out[obj.group];

        if (!ary) {
          ary = [];
          out[obj.group] = ary;
        }

        ary.push(obj);
      });
      return out;
    },

    matching(nameOrCombined, defaultNamespace) {
      // If the defaultNamespace is an object, convert to a name
      if (defaultNamespace && typeof defaultNamespace === 'object') {
        defaultNamespace = Ember.get(defaultNamespace, 'name');
      } // If the input has no namespace, add the default one


      let combined = nameOrCombined;

      if (defaultNamespace && !nameOrCombined.includes('/')) {
        combined = `${defaultNamespace}/${nameOrCombined}`;
      }

      let match = Ember.get(this, 'list').findBy('combined', combined);

      if (match) {
        return match.obj;
      }
    }

  });

  _exports.default = _default;
});