define("shared/role-template/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    _allRoleTemplates: null,

    init() {
      this._super(...arguments);

      Ember.set(this, '_allRoleTemplates', Ember.get(this, 'globalStore').all('roleTemplate'));
      Ember.get(this, 'globalStore').find('roleTemplate');
    },

    fetchFilteredRoleTemplates(id = null, opts = {
      filter: {
        locked: false,
        hidden: false
      }
    }) {
      const store = Ember.get(this, 'globalStore');
      return store.find('roleTemplate', id, opts);
    },

    allFilteredRoleTemplates: Ember.computed('_allRoleTemplates.@each.{locked,hidden}', function () {
      return Ember.get(this, '_allRoleTemplates').filter(rt => !Ember.get(rt, 'locked') && !Ember.get(rt, 'hidden'));
    }),
    allVisibleRoleTemplates: Ember.computed('_allRoleTemplates.@each.{locked,hidden}', function () {
      return Ember.get(this, '_allRoleTemplates').filter(rt => !Ember.get(rt, 'hidden'));
    })
  });

  _exports.default = _default;
});