define("shared/mixins/storage-class-provisioner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    parameters: null,
    editing: null,
    fields: null,
    model: null,

    init() {
      if (!Ember.get(this, 'fields')) {
        Ember.set(this, 'fields', []);
      }

      this._super(...arguments);

      if (this.registerHook) {
        this.registerHook(this.updateParams.bind(this), {
          name: 'updateParams'
        });
      }
    },

    updateParams() {
      // Override this to handle custom parameters
      //
      const fields = Ember.get(this, 'fields') || [];

      if (fields.length > 0) {
        const out = {};
        fields.forEach(field => {
          const key = field.id ? field.id : field;
          const value = Ember.get(this, `model.${key}`) || '';

          if (value) {
            out[key] = value;
          }
        });
        Ember.set(this, 'parameters', out);
      }
    },

    didReceiveAttrs() {
      const fields = Ember.get(this, 'fields') || [];

      if (fields.length > 0) {
        const changes = {};
        fields.forEach(field => {
          const key = field.id ? field.id : field;
          changes[key] = Ember.get(this, `parameters.${key}`) || Ember.get(field, 'options.firstObject.value') || '';
        });
        Ember.setProperties(this, {
          model: changes
        });
      }
    },

    fieldsGroup: Ember.computed('fields.[]', function () {
      const fields = Ember.get(this, 'fields') || [];
      const group = [];
      let groupIndex = 0;
      fields.forEach((item, index) => {
        if (index % 3 === 0) {
          group.push([item]);
          groupIndex++;
        } else {
          group[groupIndex - 1].push(item);
        }
      });
      return group;
    })
  });

  _exports.default = _default;
});