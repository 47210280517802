define("global-admin/security/authentication/freeipa/controller", ["exports", "ui/utils/constants", "global-admin/mixins/authentication", "global-admin/mixins/ldap-auth"], function (_exports, _constants, _authentication, _ldapAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLAIN_PORT = 389;
  const TLS_PORT = 636;

  var _default = Ember.Controller.extend(_authentication.default, _ldapAuth.default, {
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    confirmDisable: false,
    editing: false,
    testing: false,
    isActiveDirectory: false,
    errors: null,
    addUserInput: '',
    addOrgInput: '',
    username: '',
    password: '',
    providerName: 'ldap.providerName.freeipa',
    providerSaveLabel: 'ldap.providerName.saveLabels.freeipa',
    authConfig: Ember.computed.alias('model.freeipaConfig'),

    init() {
      this._super(...arguments);

      if (Ember.get(this, 'authConfig')) {
        this.tlsChanged();
      }
    },

    actions: {
      test() {
        this.send('clearError');
        const model = Ember.get(this, 'authConfig');
        const am = Ember.get(model, 'accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am
        });
        const errors = model.validationErrors();

        if (errors.get('length')) {
          Ember.setProperties(this, {
            errors,
            testing: false
          });
          Ember.set(model, 'enabled', false);
        } else {
          Ember.set(model, 'enabled', true);
          Ember.set(this, 'testing', true);
          model.doAction('testAndApply', {
            ldapConfig: model,
            enabled: true,
            username: Ember.get(this, 'username'),
            password: Ember.get(this, 'password')
          }).then(() => {
            this.send('waitAndRefresh');
          }).catch(err => {
            model.set('enabled', false);
            this.send('gotError', err);
          });
        }
      }

    },
    tlsChanged: Ember.observer('authConfig.tls', function () {
      var on = Ember.get(this, 'authConfig.tls') || false;
      var port = parseInt(Ember.get(this, 'authConfig.port'), 10);

      if (on && port === PLAIN_PORT) {
        Ember.set(this, 'authConfig.port', TLS_PORT);
      } else if (!on && port === TLS_PORT) {
        Ember.set(this, 'authConfig.port', PLAIN_PORT);
        Ember.set(this, 'authConfig.tls', false);
      }
    }),
    createDisabled: Ember.computed('username.length', 'password.length', function () {
      return !Ember.get(this, 'username.length') || !Ember.get(this, 'password.length');
    }),
    numUsers: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_USER)).get('length');
    }),
    numGroups: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_GROUP)).get('length');
    }),
    configServers: Ember.computed('authConfig.servers', {
      get() {
        return (Ember.get(this, 'authConfig.servers') || []).join(',');
      },

      set(key, value) {
        Ember.set(this, 'authConfig.servers', value.split(','));
        return value;
      }

    }),
    userType: _constants.default.PROJECT.TYPE_OPENLDAP_USER,
    groupType: _constants.default.PROJECT.TYPE_OPENLDAP_GROUP
  });

  _exports.default = _default;
});