define("shared/all-workloads/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convert(obj, intl) {
    const namespace = Ember.get(obj, 'namespace.displayName');
    const name = Ember.get(obj, 'displayName');
    return {
      group: intl.t('allWorkloads.namespace', {
        name: namespace
      }),
      combined: `${namespace}/${name}`,
      id: Ember.get(obj, 'id'),
      stackName: namespace,
      name,
      kind: Ember.get(obj, 'type'),
      obj
    };
  }

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    app: Ember.inject.service(),
    _allWorkloads: null,
    _allPods: null,

    init() {
      this._super(...arguments);

      const store = Ember.get(this, 'store');
      Ember.set(this, '_allWorkloads', store.all('workload'));
      Ember.set(this, '_allPods', store.all('pod'));
    },

    workloads: Ember.computed('_allWorkloads.@each.{id,namespaceId,displayName,type}', function () {
      const intl = Ember.get(this, 'intl');
      return Ember.get(this, '_allWorkloads').map(x => convert(x, intl)).sortBy('combined');
    }),
    pods: Ember.computed('_allPods.@each.{id,namespaceId,displayName,type}', function () {
      const intl = Ember.get(this, 'intl');
      return Ember.get(this, '_allPods').map(x => convert(x, intl)).sortBy('combined');
    }),
    list: Ember.computed.alias('workloads'),
    listWithPods: Ember.computed('_allPods', '_allWorkloads', 'pods', 'workloads', function () {
      const intl = Ember.get(this, 'intl');
      const out = Ember.get(this, '_allWorkloads').map(x => convert(x, intl));
      out.pushObjects(Ember.get(this, '_allPods').map(x => convert(x, intl)));
      return out.sortBy('combined');
    }),
    grouped: Ember.computed('list.[]', function () {
      return this.group(Ember.get(this, 'list'));
    }),
    groupedWithPods: Ember.computed('listWithPods.[]', function () {
      return this.group(Ember.get(this, 'listWithPods'));
    }),

    byId(id) {
      return this.get('_allWorkloads').findBy('id', id);
    },

    group(list) {
      const out = {};
      list.slice().sortBy('group', 'name', 'id').forEach(obj => {
        let ary = out[obj.group];

        if (!ary) {
          ary = [];
          out[obj.group] = ary;
        }

        ary.push(obj);
      });
      return out;
    },

    matching(nameOrCombined, defaultNamespace, withPods = false) {
      // If the defaultNamespace is an object, convert to a name
      if (defaultNamespace && typeof defaultNamespace === 'object') {
        defaultNamespace = Ember.get(defaultNamespace, 'name');
      } // If the input has no namespace, add the default one


      let combined = nameOrCombined;

      if (defaultNamespace && !nameOrCombined.includes('/')) {
        combined = `${defaultNamespace}/${nameOrCombined}`;
      }

      const ary = withPods ? Ember.get(this, 'listWithPods') : Ember.get(this, 'list');
      let match = ary.findBy('combined', combined);

      if (match) {
        return match.obj;
      }
    }

  });

  _exports.default = _default;
});