define("nodes/components/driver-oci/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-oci/template", "shared/utils/oci"], function (_exports, _nodeDriver, _template, _oci) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    oci: Ember.inject.service(),
    layout: _template.default,
    driverName: 'oci',
    region: '',
    nodeAvailabilityDomain: '',
    nodeImage: '',
    step: 1,
    regionChoices: _oci.OCI_REGIONS,
    config: Ember.computed.alias('model.ociConfig'),

    init() {
      this._super(...arguments);
    },

    actions: {
      finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }

    },
    adChoices: Ember.computed('config.region', 'model.cloudCredentialId', 'primaryResource.cloudCredentialId', async function () {
      let token = Ember.get(this, 'primaryResource.cloudCredentialId');
      let region = Ember.get(this, 'config.region');

      if (token !== null && token !== '' && region !== null && region !== '') {
        const auth = {
          type: 'cloud',
          token
        };
        const options = await this.oci.request(auth, 'availabilityDomains', {
          params: {
            region
          }
        });
        return this.mapToContent(options);
      }

      return {
        value: '',
        label: ''
      };
    }),
    nodeShapeChoices: Ember.computed('config.{nodeCompartmentId,region}', 'model.cloudCredentialId', 'primaryResource.cloudCredentialId', async function () {
      let token = Ember.get(this, 'primaryResource.cloudCredentialId');
      let compartment = Ember.get(this, 'config.nodeCompartmentId');

      if (token !== null && token !== '' && compartment !== null && compartment !== '' && (compartment.startsWith('ocid1.compartment') || compartment.startsWith('ocid1.tenancy'))) {
        const auth = {
          type: 'cloud',
          token
        };
        const options = await this.oci.request(auth, 'nodeShapes', {
          params: {
            compartment,
            region: Ember.get(this, 'config.region')
          }
        });
        return this.mapToContent(options);
      }

      return {
        value: '',
        label: ''
      };
    }),
    imageChoices: Ember.computed('config.{nodeCompartmentId,region}', 'model.cloudCredentialId', 'primaryResource.cloudCredentialId', async function () {
      let token = Ember.get(this, 'primaryResource.cloudCredentialId');
      let compartment = Ember.get(this, 'config.nodeCompartmentId');

      if (token !== null && token !== '' && compartment !== null && compartment !== '' && (compartment.startsWith('ocid1.compartment') || compartment.startsWith('ocid1.tenancy'))) {
        const auth = {
          type: 'cloud',
          token
        };
        const options = await this.oci.request(auth, 'nodeImages', {
          params: {
            compartment,
            region: Ember.get(this, 'config.region')
          }
        });
        return this.mapToContent(this.filterOut(options, 'Oracle-Linux-8'));
      }

      return {
        value: '',
        label: ''
      };
    }),
    selectedAd: Ember.computed('config.nodeAvailabilityDomain', function () {
      const ad = Ember.get(this, 'config.nodeAvailabilityDomain');
      return ad;
    }),
    selectedNodeShape: Ember.computed('config.nodeShape', function () {
      const nodeShape = Ember.get(this, 'config.nodeShape');
      return nodeShape;
    }),
    selectedImage: Ember.computed('config.nodeImage', function () {
      const nodeImage = Ember.get(this, 'config.nodeImage');
      return nodeImage;
    }),
    selectedRegion: Ember.computed('config.region', function () {
      const region = Ember.get(this, 'config.region');
      return region;
    }),

    bootstrap() {
      const config = Ember.get(this, 'globalStore').createRecord({
        type: 'ociConfig',
        region: ''
      });
      Ember.set(this, 'model.ociConfig', config);
    },

    mapToContent(folderOptions) {
      if (folderOptions && typeof folderOptions.map === 'function') {
        return folderOptions.map(option => ({
          label: option,
          value: option
        }));
      }
    },

    filterOut(folderOptions, filter) {
      if (folderOptions && typeof folderOptions.map === 'function') {
        return folderOptions.filter(entry => !entry.includes(filter));
      }
    },

    validate() {
      function getRegionIdent(ocid) {
        var start = ocid.split('.', 3).join('.').length;
        var end = ocid.split('.', 4).join('.').length;
        return ocid.substring(start + 1, end);
      } // Get generic API validation errors


      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push('Name is required');
      }

      if (!Ember.get(this, 'config.region')) {
        errors.push('Specifying a oci Region is required');
      }

      if (!Ember.get(this, 'config.nodeImage')) {
        errors.push('Specifying a oci node image is required');
      }

      if (!Ember.get(this, 'config.nodeShape')) {
        errors.push('Specifying a oci node shape is required');
      }

      if (!Ember.get(this, 'config.nodeCompartmentId')) {
        errors.push('Specifying an oci node compartment is required');
      }

      if (!Ember.get(this, 'config.nodeCompartmentId').startsWith('ocid1.compartment') && !Ember.get(this, 'config.nodeCompartmentId').startsWith('ocid1.tenancy')) {
        errors.push('Specifying a valid oci node compartment is required');
      }

      if (!Ember.get(this, 'config.nodeAvailabilityDomain')) {
        errors.push('Specifying a oci node availability domain is required');
      }

      if (!Ember.get(this, 'config.vcnCompartmentId')) {
        Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.nodeCompartmentId'));
      } else {
        if (!Ember.get(this, 'config.vcnCompartmentId').startsWith('ocid1.compartment') && !Ember.get(this, 'config.vcnCompartmentId').startsWith('ocid1.tenancy')) {
          errors.push('Specifying a valid oci VCN compartment is required');
        }
      }

      if (!Ember.get(this, 'config.vcnId') || !Ember.get(this, 'config.vcnId').startsWith('ocid1.vcn')) {
        errors.push('Specifying a valid oci VCN OCID is required');
      }

      if (!Ember.get(this, 'config.subnetId') || !Ember.get(this, 'config.subnetId').startsWith('ocid1.subnet')) {
        errors.push('Specifying a valid oci subnet OCID is required');
      } // phoenix and ashburn have different region identifiers


      if (Ember.get(this, 'config.region').includes('phoenix')) {
        if (!Ember.get(this, 'config.subnetId').includes('phx') || !Ember.get(this, 'config.vcnId').includes('phx')) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } else if (Ember.get(this, 'config.region').includes('ashburn')) {
        if (!Ember.get(this, 'config.subnetId').includes('iad') || !Ember.get(this, 'config.vcnId').includes('iad')) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } else {
        if (!Ember.get(this, 'config.region').includes(getRegionIdent(Ember.get(this, 'config.subnetId'))) || !Ember.get(this, 'config.region').includes(getRegionIdent(Ember.get(this, 'config.vcnId')))) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } // Set the array of errors for display,
      // and return true if saving should continue.


      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        Ember.set(this, 'errors', null);
        return true;
      }
    }

  });

  _exports.default = _default;
});