define("shared/oci/service", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OCI_META_API = '/meta/oci';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    request(auth = {}, command, opt = {}) {
      let url = `${OCI_META_API}/${command}`;
      let token = Ember.get(auth, 'token');
      let headers = {
        'Accept': 'application/json'
      };
      let data = {};

      if (token === null || token === '' || token === undefined) {
        // OKE and cloud-credential form will pass these values directly
        data = auth;
        Ember.set(headers, 'Content-Type', 'application/json');
      } else {
        // All other paths must pass the cloudCredentialId as the token
        url = (0, _util.addQueryParam)(url, 'cloudCredentialId', token);
        url = (0, _util.addQueryParams)(url, opt.params || {});
      }

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', `Bearer credID=${token} passwordField=privateKeyPassphrase`);
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', `Bearer ${token}`);
      }

      return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
      }).then(resp => {
        let json = resp.json(); // there's always a body

        if (resp.status >= 200 && resp.status < 300) {
          return json;
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      }).catch(err => {
        return Ember.RSVP.reject(err);
      });
    }

  });

  _exports.default = _default;
});