define("global-admin/security/authentication/github/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      let gs = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        githubConfig: gs.find('authconfig', 'github', {
          forceReload: true
        }),
        principals: gs.all('principal')
      }).catch(e => {
        return e;
      });
    },

    setupController(controller, model) {
      let hostname = Ember.get(model, 'githubConfig.hostname');
      controller.setProperties({
        model,
        confirmDisable: false,
        testing: false,
        organizations: Ember.get(this, 'session.orgs') || [],
        errors: null,
        isEnterprise: hostname && hostname !== 'github.com' ? true : false
      });
      controller.set('saved', true);
    }

  });

  _exports.default = _default;
});