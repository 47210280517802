define("shared/components/modal-yaml/component", ["exports", "ui/mixins/new-or-edit", "codemirror", "js-yaml", "shared/mixins/modal-base", "shared/utils/fetch-yaml", "shared/components/modal-yaml/template"], function (_exports, _newOrEdit, _codemirror, _jsYaml, _modalBase, _fetchYaml, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    store: Ember.inject.service('store'),
    layout: _template.default,
    model: null,
    errors: null,
    compose: null,
    loading: null,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    resource: Ember.computed.alias('modalService.modalOpts.resource'),
    readOnly: Ember.computed.alias('modalService.modalOpts.readOnly'),

    init() {
      this._super(...arguments);

      window.jsyaml || (window.jsyaml = _jsYaml.default);
      let resource = Ember.get(this, 'resource');

      if (resource && resource.links.yaml) {
        let yamlLink = resource.links.yaml;
        Ember.set(this, 'loading', true);
        return (0, _fetchYaml.default)(yamlLink).then(yaml => {
          Ember.set(this, 'editing', true);
          Ember.set(this, 'model', {
            resource,
            yaml
          });
        }).catch(error => {
          Ember.set(this, 'errors', [Ember.get(error, 'message') || Ember.get(error, 'xhr')]);
        }).finally(() => {
          Ember.set(this, 'loading', false);
        });
      } else {
        Ember.set(this, 'editing', false);
        Ember.set(this, 'model', {
          resource,
          yaml: ''
        });
      }
    },

    actions: {
      cancel() {
        return this._super(...arguments);
      },

      close() {
        return this._super(...arguments);
      },

      save(success) {
        let model = Ember.get(this, 'model');
        const lintError = [];

        try {
          _jsYaml.default.safeLoadAll(model.yaml, y => {
            lintError.pushObjects(_codemirror.default.lint.yaml(y));
          });
        } catch (error) {
          Ember.set(this, 'errors', [error.message || error.xhr]);
          success(false);
          return;
        }

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          success(false);
          return;
        }

        Ember.set(this, 'errors', null);
        let resource = model.resource;

        if (resource) {
          Ember.get(this, 'store').request({
            data: JSON.stringify(_jsYaml.default.load(model.yaml)),
            url: resource.links.yaml,
            method: 'PUT'
          }).then(() => {
            this.send('cancel');
          }).catch(error => {
            Ember.set(this, 'errors', [Ember.get(error, 'message') || Ember.get(error, 'xhr')]);
            success(false);
          });
          return;
        }
      }

    },
    lintObserver: Ember.observer('model.yaml', function () {
      const yaml = Ember.get(this, 'model.yaml');
      const lintError = [];

      _jsYaml.default.safeLoadAll(yaml, y => {
        lintError.pushObjects(_codemirror.default.lint.yaml(y));
      });

      if (lintError.length) {
        Ember.set(this, 'errors', null);
      }
    }),
    filename: Ember.computed('model.resource', function () {
      let resource = Ember.get(this, 'model.resource');

      if (resource) {
        return `${resource.name}.yaml`;
      }

      return 'kubenetes.yaml';
    })
  });

  _exports.default = _default;
});