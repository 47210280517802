define("shared/utils/stateful-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StatefulPromise {
    static wrap(target, defaultValue) {
      const promise = Ember.RSVP.resolve(target);
      Ember.setProperties(promise, {
        loading: true,
        loaded: false,
        error: false,
        value: defaultValue
      });
      promise.then(value => {
        Ember.setProperties(promise, {
          loading: false,
          loaded: true,
          value
        });
      }, errorValue => {
        Ember.setProperties(promise, {
          loading: false,
          error: true,
          value: defaultValue,
          errorValue
        });
      });
      return promise;
    }

  }

  _exports.default = StatefulPromise;
});