define("shared/components/pretty-json/component", ["exports", "shared/components/pretty-json/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    json: Ember.computed('value', function () {
      var value = `${this.get('value') || ''}`;

      if (value === '{}' || value === '[]') {
        return false;
      }

      if (['[', '{'].indexOf(value.substr(0, 1)) >= 0) {
        try {
          var pretty = JSON.stringify(JSON.parse(value), null, 2);
          return pretty;
        } catch (e) {}
      }

      return null;
    })
  });

  _exports.default = _default;
});