define("global-admin/clusters/new/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    redirect()
    /* model , transition */
    {
      this.transitionTo('clusters.new.select');
    }

  });

  _exports.default = _default;
});