define("shared/components/modal-save-rke-template/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-save-rke-template/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_REV_NAME = 'v1';

  var _default = Ember.Component.extend(_modalBase.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'medium-modal'],
    clusterTemplateName: null,
    clusterTemplateRevisionName: null,
    cluster: Ember.computed.alias('modalService.modalOpts.cluster'),

    init() {
      this._super(...arguments);

      Ember.set(this, 'clusterTemplateRevisionName', DEFAULT_REV_NAME);
    },

    actions: {
      save() {
        const {
          cluster,
          clusterTemplateName,
          clusterTemplateRevisionName
        } = this;
        return cluster.doAction('saveAsTemplate', {
          clusterTemplateName,
          clusterTemplateRevisionName
        }).then(() => {
          return this.cluster.waitForClusterTemplateToBeAttached().then(() => {
            return this.router.transitionTo('global-admin.cluster-templates.detail', this.cluster.clusterTemplateRevisionId);
          });
        }).catch(err => {
          return this.growl.fromError(err);
        });
      }

    },
    saveDisabled: Ember.computed('clusterTemplateName', 'clusterTemplateRevisionName', function () {
      const {
        clusterTemplateName,
        clusterTemplateRevisionName
      } = this;

      if (Ember.isEmpty(clusterTemplateName) && Ember.isEmpty(clusterTemplateRevisionName)) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});