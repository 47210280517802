define("shared/helpers/date-recent", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateCalendar = dateCalendar;
  _exports.default = void 0;

  function dateCalendar(params) {
    let date = (0, _moment.default)(params[0]);
    let now = (0, _moment.default)();
    let diff = now.diff(date);

    if (Math.abs(diff) > 44 * 60 * 1000) {
      return date.calendar();
    } else if (diff > 0) {
      return date.fromNow();
    } else {
      return date.toNow();
    }
  }

  var _default = Ember.Helper.helper(dateCalendar);

  _exports.default = _default;
});