define("shared/helpers/has-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasOverride = hasOverride;
  _exports.default = void 0;

  function hasOverride(params, hash) {
    const {
      questions,
      paramName
    } = hash;
    let match = null;

    if (!questions) {
      return false;
    } else {
      match = questions.findBy('variable', paramName);

      if (match) {
        return true;
      } else {
        return false;
      }
    }
  }

  var _default = Ember.Helper.helper(hasOverride);

  _exports.default = _default;
});