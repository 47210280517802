define("shared/components/input-paste/component", ["exports", "shared/mixins/intl-placeholder", "shared/components/input-paste/template", "jquery"], function (_exports, _intlPlaceholder, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend(_intlPlaceholder.default, {
    layout: _template.default,
    separators: null,
    _onPaste: null,

    didInsertElement() {
      this._super();

      this.set('_onPaste', this.handlePaste.bind(this));
      (0, _jquery.default)(this.element).on('paste', Ember.get(this, '_onPaste'));
    },

    willDestroyElement() {
      (0, _jquery.default)(this.element).off('paste', Ember.get(this, '_onPaste'));

      this._super();
    },

    handlePaste(event) {
      var e = event.originalEvent;
      const separators = Ember.get(this, 'separators');

      if (e && e.clipboardData && e.clipboardData.getData && e.clipboardData.types) {
        if (e.clipboardData.types.includes('text/plain')) {
          var text = e.clipboardData.getData('text/plain');
          let hasSeparator = true;

          if (text && separators) {
            hasSeparator = separators.some(separator => text.indexOf(separator) > -1);
          }

          if (text && hasSeparator) {
            e.stopPropagation();
            e.preventDefault();
            this.pasted(text, event.target);
            return false;
          }
        }

        return true;
      }
    },

    pasted() {
      throw new Error('pasted action is required!');
    }

  });

  _exports.default = _default;
});