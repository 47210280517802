define("global-admin/security/accounts/users/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    sortBy: 'username',
    refreshing: false,
    hasRefreshProviderAccess: false,
    errors: null,
    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      translationKey: 'generic.name',
      name: 'name',
      sort: ['name']
    }, {
      translationKey: 'generic.id',
      name: 'id',
      sort: ['id']
    }, {
      translationKey: 'accountsPage.index.table.username',
      name: 'username',
      sort: ['username']
    }],
    actions: {
      refreshAllTokens() {
        Ember.set(this, 'refreshing', true);
        this.globalStore.request({
          url: '/v3/users?action=refreshauthprovideraccess',
          method: 'POST',
          data: {}
        }).then(() => {
          const successTitle = this.intl.t('action.refreshAuthProviderAccess.allSuccess.title');
          const successMessage = this.intl.t('action.refreshAuthProviderAccess.allSuccess.message');
          this.growl.success(successTitle, successMessage);
        }).catch(err => {
          Ember.set(this, 'errors', [err.message]);
        }).finally(() => {
          Ember.set(this, 'refreshing', false);
        });
      }

    }
  });

  _exports.default = _default;
});