define("shared/components/graph-area/component", ["exports", "shared/mixins/throttled-resize", "shared/utils/util", "shared/components/graph-area/theme", "shared/components/graph-area/template", "jquery"], function (_exports, _throttledResize, _util, _theme, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getFormatter(unit, full = false) {
    switch (unit) {
      case 'seconds':
        return _util.formatSecond;

      case 'pps':
        return value => {
          return `${(0, _util.roundValue)(value)} ${full ? 'Packets Per Second' : 'Pps'}`;
        };

      case 'ops':
        return value => {
          return `${(0, _util.roundValue)(value)} ${full ? 'Operation Per Second' : 'Ops'}`;
        };

      case 'ms':
        return value => {
          return `${(0, _util.roundValue)(value)} ms`;
        };

      case 'mcpu':
        return value => {
          return `${(0, _util.roundValue)(value)} mCPU`;
        };

      case 'percent':
        return _util.formatPercent;

      case 'bps':
      case 'kbps':
        return _util.formatKbps;

      case 'byte':
        return _util.formatMib;

      default:
        return _util.roundValue;
    }
  }

  function getConverter(unit) {
    switch (unit) {
      case 'percent':
        return value => value * 100;

      case 'mcpu':
        return value => value * 1000;

      case 'bps':
        return value => value / 1024;

      case 'byte':
        return value => value / 1048576;

      default:
        return value => value;
    }
  }

  const LOADING_PARAMS = {
    text: '',
    color: '#3d3d3d',
    textColor: '#3d3d3d',
    maskColor: 'rgba(255, 255, 255, 0.8)',
    zlevel: 0
  };
  var ECharts = null;

  var _default = Ember.Component.extend(_throttledResize.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    tagName: 'div',
    classNames: ['graph-area'],
    model: null,
    fields: null,
    chart: null,
    formatter: 'value',
    needRefresh: false,

    init() {
      this._super(...arguments);

      if (!ECharts) {
        Ember.RSVP.all([emberAutoImportDynamic("echarts/lib/echarts"), emberAutoImportDynamic("echarts/lib/chart/line"), emberAutoImportDynamic("echarts/lib/component/tooltip")]).then(modules => {
          ECharts = modules[0].default;
          ECharts.registerTheme('walden', _theme.theme);
          this.didRender();
        });
      }
    },

    willDestroyElement() {
      const chart = Ember.get(this, 'chart');

      if (chart) {
        chart.clear();
        chart.dispose();
      }
    },

    didRender() {
      if (ECharts && !Ember.get(this, 'chart')) {
        this.create();
        setTimeout(() => {
          const chart = Ember.get(this, 'chart');

          if (chart) {
            chart.resize();
          }
        }, 200);
      }
    },

    loadingDidChange: Ember.observer('loading', function () {
      const chart = Ember.get(this, 'chart');

      if (chart && Ember.get(this, 'loading')) {
        chart.showLoading(LOADING_PARAMS);
      } else if (chart) {
        chart.hideLoading();
      }
    }),
    dataDidChange: Ember.observer('series', function () {
      if (Ember.get(this, 'chart')) {
        this.draw();
      }
    }),

    onResize() {
      if (!ECharts || this.isDestroyed || this.isDestroying) {
        return;
      }

      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').resize();
      }
    },

    create() {
      const chart = ECharts.init((0, _jquery.default)(this.element).find('.content')[0], 'walden');
      Ember.set(this, 'chart', chart);
      chart.showLoading(LOADING_PARAMS);
      this.draw();
    },

    draw() {
      const chart = Ember.get(this, 'chart');

      if (!chart) {
        return;
      }

      const minMax = Ember.get(this, 'formatter') === 'percent' ? 100 : null;
      let setMax = true;
      const series = [];
      const fields = (Ember.get(this, 'series') || []).filter(serie => Ember.get(serie, 'points.length') > 1).map(serie => {
        return {
          id: Ember.get(serie, 'name'),
          data: (Ember.get(serie, 'points') || []).map(p => [p[1], getConverter(Ember.get(this, 'formatter'))(p[0])])
        };
      });
      fields.forEach(field => {
        const serie = field.data || [];
        const data = [];
        serie.forEach(d => {
          if (minMax && setMax && d[1] > minMax) {
            setMax = false;
          }

          data.push(d);
        });
        series.push({
          name: field.id,
          type: 'line',
          showSymbol: false,
          animation: false,
          data,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 1
              }
            }
          }
        });
      });
      const formatter = getFormatter(Ember.get(this, 'formatter'), true);
      let option = {
        tooltip: {
          trigger: 'axis',

          position(pos, params, dom, rect, size) {
            const obj = {
              top: 60
            };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
          },

          formatter(params) {
            let html = '';
            params.forEach((p, i) => {
              if (i === 0) {
                html = `<div class="text-left">${p.axisValueLabel}`;
              }

              const value = (0, _util.escapeHtml)(formatter(p.data[1]));
              let label = (0, _util.escapeHtml)(p.seriesName);
              html += `<br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${p.color};"></span> ${label} : ${value}`;
            });
            html += '</div>';
            return html.htmlSafe();
          }

        },
        grid: {
          top: '10px',
          left: '30px',
          right: '30px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: getFormatter(Ember.get(this, 'formatter'))
          },
          splitArea: {
            show: true
          }
        },
        series
      };

      if (setMax && minMax) {
        option.yAxis.max = minMax;
      }

      chart.clear();
      chart.setOption(option, true);
      chart.hideLoading();
    }

  });

  _exports.default = _default;
});