define("nodes/components/driver-packet/component", ["exports", "shared/mixins/node-driver", "@rancher/ember-api-store/utils/fetch", "nodes/components/driver-packet/template"], function (_exports, _nodeDriver, _fetch, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OS_WHITELIST = ['centos_7', 'coreos_stable', 'ubuntu_14_04', 'ubuntu_16_04', 'rancher'];
  const PLAN_BLACKLIST = ['baremetal_2a']; // quick wheres james spader?

  const DEFAULTS = {
    os: 'ubuntu_16_04',
    facilityCode: 'ewr1',
    plan: 'baremetal_0',
    billingCycle: 'hourly'
  };

  var _default = Ember.Component.extend(_nodeDriver.default, {
    layout: _template.default,
    driverName: 'packet',
    facilityChoices: null,
    planChoices: null,
    osChoices: null,
    step: 1,
    config: Ember.computed.alias('model.packetConfig'),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        facilityChoices: [],
        planChoices: [],
        osChoices: [],
        allOS: []
      });
    },

    actions: {
      authPacket(savedCB) {
        let promises = {
          plans: this.apiRequest('plans'),
          opSys: this.apiRequest('operating-systems'),
          facilities: this.apiRequest('facilities')
        };
        Ember.RSVP.hash(promises).then(hash => {
          let osChoices = this.parseOSs(hash.opSys.operating_systems);
          let selectedPlans = this.parsePlans(osChoices.findBy('slug', 'ubuntu_14_04'), hash.plans.plans);
          let config = Ember.get(this, 'config');
          Ember.setProperties(this, {
            allOS: osChoices,
            allPlans: hash.plans.plans,
            step: 2,
            facilityChoices: hash.facilities.facilities,
            osChoices,
            planChoices: selectedPlans
          });
          Ember.setProperties(config, DEFAULTS);
          savedCB(true);
        }, err => {
          let errors = Ember.get(this, 'errors') || [];
          errors.push(`${err.statusText}: ${err.body.message}`);
          Ember.set(this, 'errors', errors);
          savedCB(false);
        });
      }

    },
    planChoiceDetails: Ember.computed('allPlans', 'config.plan', function () {
      let planSlug = Ember.get(this, 'config.plan');
      let plan = Ember.get(this, 'allPlans').findBy('slug', planSlug);
      return plan;
    }),
    facilityObserver: Ember.on('init', Ember.observer('config.facility', function () {
      let facilities = Ember.get(this, 'facilityChoices');
      let slug = Ember.get(this, 'config.facility');
      let facility = facilities.findBy('code', slug);
      let plans = Ember.get(this, 'allPlans');
      let out = [];

      if (plans && facility) {
        plans.forEach(plan => {
          plan.available_in.forEach(fac => {
            let facId = fac.href.split('/')[fac.href.split('/').length - 1];

            if (facility.id === facId) {
              out.push(plan);
            }
          });
        });
        Ember.set(this, 'planChoices', out);
      }
    })),

    bootstrap() {
      let store = Ember.get(this, 'globalStore');
      let config = store.createRecord({
        type: 'packetConfig',
        projectId: '',
        apiKey: ''
      });
      const model = Ember.get(this, 'model');
      Ember.set(model, 'packetConfig', config);
    },

    apiRequest(command, opt, out) {
      opt = opt || {};
      let url = `${Ember.get(this, 'app.proxyEndpoint')}/`;

      if (opt.url) {
        url += opt.url.replace(/^http[s]?\/\//, '');
      } else {
        url += `${'api.packet.net'}/${command}`;
      }

      return (0, _fetch.default)(url, {
        headers: {
          'Accept': 'application/json',
          'X-Auth-Token': Ember.get(this, 'config.apiKey')
        }
      }).then(res => {
        let body = res.body;

        if (out) {
          out[command].pushObjects(body[command]);
        } else {
          out = body;
        } // De-paging


        if (body && body.links && body.links.pages && body.links.pages.next) {
          opt.url = body.links.pages.next;
          return this.apiRequest(command, opt, out).then(() => {
            return out;
          });
        } else {
          return out;
        }
      });
    },

    parseOSs(osList) {
      return osList.filter(os => {
        if (OS_WHITELIST.includes(os.slug) && !Ember.isEmpty(os.provisionable_on)) {
          return os;
        }
      });
    },

    parsePlans(os, plans) {
      let out = [];
      os.provisionable_on.forEach(loc => {
        let plan = plans.findBy('slug', loc);

        if (plan && !PLAN_BLACKLIST.includes(loc)) {
          out.push(plan);
        }
      });
      return out;
    },

    validate() {
      let errors = Ember.get(this, 'model').validationErrors();

      if (!Ember.get(this, 'config.projectId')) {
        errors.push('Project ID is required');
      }

      if (!Ember.get(this, 'config.apiKey')) {
        errors.push('API Key is requried');
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});