define("nodes/components/driver-aliyunecs/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-aliyunecs/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ENDPOINT = 'https://ecs.aliyuncs.com';
  const PAGE_SIZE = 50;
  const NONE_OPT_DISK = [{
    value: 'cloud'
  }];
  const DEFAULT_IMAGE = 'ubuntu_16_04_64';
  const OPT_DISK = [{
    value: 'cloud_efficiency'
  }, {
    value: 'cloud_ssd'
  }];
  const DEFAULT_INSTANCE_TYPE = 'ecs.g5.large';
  const DEFAULT_INTERNET_CHARGE_TYPE = 'PayByBandwidth';

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    driverName: 'aliyunecs',
    zones: null,
    regions: null,
    securityGroups: null,
    images: null,
    instanceTypes: null,
    showCustomApiEndpoint: false,
    ecsClient: null,
    step: 1,
    config: Ember.computed.alias('model.aliyunecsConfig'),

    init() {
      this._super(...arguments);

      if (!Ember.get(this, 'config.internetChargeType')) {
        Ember.set(this, 'config.internetChargeType', DEFAULT_INTERNET_CHARGE_TYPE);
      }

      if (Ember.get(this, 'config.apiEndpoint')) {
        Ember.set(this, 'showCustomApiEndpoint', true);
      }
    },

    actions: {
      alyLogin(cb) {
        Ember.setProperties(this, {
          'errors': null,
          'config.accessKeyId': (Ember.get(this, 'config.accessKeyId') || '').trim(),
          'config.accessKeySecret': (Ember.get(this, 'config.accessKeySecret') || '').trim()
        });
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const accessKey = Ember.get(this, 'config.accessKeyId');
        const accessSecret = Ember.get(this, 'config.accessKeySecret');

        if (!accessKey) {
          errors.push(intl.t('nodeDriver.aliyunecs.errors.accessKeyRequired'));
        }

        if (!accessSecret) {
          errors.push(intl.t('nodeDriver.aliyunecs.errors.accessSecretRequired'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        let ecs;

        try {
          const location = window.location;
          let endpoint = Ember.get(this, 'config.apiEndpoint') ? Ember.get(this, 'config.apiEndpoint') : ENDPOINT;
          endpoint = `${Ember.get(this, 'app.proxyEndpoint')}/${endpoint.replace('//', '/')}`;
          endpoint = `${location.origin}${endpoint}`;
          ecs = new ALY.ECS({
            accessKeyId: Ember.get(this, 'config.accessKeyId'),
            secretAccessKey: Ember.get(this, 'config.accessKeySecret'),
            apiVersion: '2014-05-26',
            endpoint
          });
          ecs.describeRegions({}, (err, res) => {
            if (err) {
              let errors = Ember.get(this, 'errors') || [];
              errors.pushObject(err.message || err);
              Ember.set(this, 'errors', errors);
              cb();
              return;
            }

            Ember.set(this, 'ecsClient', ecs);
            Ember.set(this, 'regions', res.Regions.Region.map(region => {
              return {
                value: region.RegionId,
                label: region.LocalName
              };
            }));
            this.regionDidChange();
            Ember.set(this, 'step', 2);
            cb();
          });
        } catch (err) {
          const errors = Ember.get(this, 'errors') || [];
          errors.pushObject(err.message || err);
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }
      },

      loadStorageTypes(cb) {
        Ember.set(this, 'errors', null);
        const errors = Ember.get(this, 'errors') || [];
        const intl = Ember.get(this, 'intl');
        const zone = Ember.get(this, 'config.zone');
        const vpcId = Ember.get(this, 'config.vpcId');
        const vswitchId = Ember.get(this, 'config.vswitchId');

        if (zone) {
          if (!vpcId) {
            errors.push(intl.t('nodeDriver.aliyunecs.errors.vpcIdRequired'));
          }

          if (!vswitchId) {
            errors.push(intl.t('nodeDriver.aliyunecs.errors.vswitchIdRequired'));
          }
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();
          return;
        }

        if (!Ember.get(this, 'config.securityGroup')) {
          Ember.set(this, 'config.securityGroup', 'docker-machine');
        }

        Ember.set(this, 'step', 3);
        this.diskCategoryChoicesDidChange();
        cb();
      },

      loadInstanceTypes(cb) {
        this.fetch('Image', 'Images').then(images => {
          Ember.set(this, 'images', images.filter(image => {
            return image.raw.OSType === 'linux' && ((this, 'config.ioOptimized') === 'none' || image.raw.IsSupportIoOptimized);
          }).map(image => {
            return {
              label: image.raw.ImageOwnerAlias === 'system' ? image.raw.OSName : image.raw.ImageName,
              value: image.value,
              raw: image.raw
            };
          }));
          const imageId = Ember.get(this, 'config.imageId');
          let found = Ember.get(this, 'images').findBy('value', imageId);

          if (!found) {
            const ubuntu = Ember.get(this, 'images').find(i => Ember.get(i, 'value').startsWith(DEFAULT_IMAGE));
            const defaultImage = ubuntu ? ubuntu.value : Ember.get(this, 'images.firstObject.value');
            Ember.set(this, 'config.imageId', defaultImage);
          }

          this.fetch('InstanceType', 'InstanceTypes').then(instanceTypes => {
            this.fetchAvailableResources().then(availableResources => {
              Ember.set(this, 'instanceTypes', instanceTypes.filter(instanceType => availableResources.indexOf(instanceType.value) > -1).map(instanceType => {
                return {
                  group: instanceType.raw.InstanceTypeFamily,
                  value: instanceType.value,
                  label: `${instanceType.raw.InstanceTypeId} ( ${instanceType.raw.CpuCoreCount} ${instanceType.raw.CpuCoreCount > 1 ? 'Cores' : 'Core'} ${instanceType.raw.MemorySize}GB RAM )`
                };
              }));
              let instanceType;

              if (Ember.get(this, 'instanceTypes').findBy('value', Ember.get(this, 'config.instanceType'))) {
                instanceType = Ember.get(this, 'config.instanceType');
              } else {
                instanceType = Ember.get(this, 'instanceTypes.firstObject.value');
              }

              Ember.set(this, 'config.instanceType', instanceType);
              Ember.set(this, 'step', 4);
              cb();
            });
          }).catch(err => {
            const errors = Ember.get(this, 'errors') || [];
            errors.pushObject(err.message || err);
            Ember.set(this, 'errors', errors);
            cb();
            return;
          });
        }).catch(err => {
          const errors = Ember.get(this, 'errors') || [];
          errors.pushObject(err.message || err);
          Ember.set(this, 'errors', errors);
          cb();
          return;
        });
      }

    },
    zoneDidChange: Ember.observer('config.zone', function () {
      if (Ember.get(this, 'config.vpcId') && !Ember.get(this, 'vswitches')) {
        this.fetch('VSwitch', 'VSwitches').then(vswitches => {
          Ember.set(this, 'vswitches', vswitches);
          this.resetVswitch();
        });
      } else {
        this.resetVswitch();
      }
    }),
    vpcDidChange: Ember.observer('config.vpcId', function () {
      const vpcId = Ember.get(this, 'config.vpcId');

      if (vpcId) {
        this.fetch('VSwitch', 'VSwitches').then(vswitches => {
          Ember.set(this, 'vswitches', vswitches);
          const selectedVSwitch = Ember.get(this, 'config.vswitchId');

          if (selectedVSwitch) {
            const found = vswitches.findBy('value', selectedVSwitch);

            if (!found) {
              Ember.set(this, 'config.vswitchId', null);
            }
          }
        });
        this.fetch('SecurityGroup', 'SecurityGroups').then(securityGroups => {
          Ember.set(this, 'securityGroups', securityGroups);
          const selectedSecurityGroup = Ember.get(this, 'config.securityGroup');

          if (selectedSecurityGroup) {
            const found = securityGroups.findBy('value', selectedSecurityGroup);

            if (!found) {
              Ember.set(this, 'config.securityGroup', 'docker-machine');
            }
          }
        });
      } else {
        Ember.set(this, 'config.vswitchId', null);
        Ember.set(this, 'config.securityGroup', 'docker-machine');
      }
    }),
    regionDidChange: Ember.observer('config.region', function () {
      const region = Ember.get(this, 'config.region');

      if (region) {
        this.fetch('Vpc', 'Vpcs').then(vpcs => {
          Ember.set(this, 'vpcs', vpcs);
          const selectedVPC = Ember.get(this, 'config.vpcId');

          if (selectedVPC) {
            const found = vpcs.findBy('value', selectedVPC);

            if (!found) {
              Ember.set(this, 'config.vpcId', null);
            } else {
              this.vpcDidChange();
            }
          }
        });
        this.fetch('Zone', 'Zones').then(zones => {
          Ember.set(this, 'zones', zones);
          const selectedZone = Ember.get(this, 'config.zone');

          if (selectedZone) {
            const found = zones.findBy('value', selectedZone);

            if (!found) {
              Ember.set(this, 'config.zone', null);
            } else {
              this.zoneDidChange();
            }
          }
        });
      }
    }),
    showCustomApiEndpointDidChange: Ember.observer('showCustomApiEndpoint', function () {
      if (!Ember.get(this, 'showCustomApiEndpoint')) {
        Ember.set(this, 'config.apiEndpoint', '');
      }
    }),
    diskCategoryChoicesDidChange: Ember.observer('diskCategoryChoices.@each.value', function () {
      const systemDiskCategory = Ember.get(this, 'config.systemDiskCategory');
      let found = Ember.get(this, 'diskCategoryChoices').findBy('value', systemDiskCategory);

      if (!found) {
        Ember.set(this, 'config.systemDiskCategory', Ember.get(this, 'diskCategoryChoices.firstObject.value'));
      }

      const diskCategory = Ember.get(this, 'config.diskCategory');
      found = Ember.get(this, 'diskCategoryChoices').findBy('value', diskCategory);

      if (!found) {
        Ember.set(this, 'config.diskCategory', Ember.get(this, 'diskCategoryChoices.firstObject.value'));
      }
    }),
    filteredVSwitches: Ember.computed('vswitches.[]', 'config.zone', function () {
      const zone = Ember.get(this, 'config.zone');
      return (Ember.get(this, 'vswitches') || []).filter(swith => {
        if (zone && zone !== swith.raw.ZoneId) {
          return false;
        }

        return true;
      });
    }),
    diskCategoryChoices: Ember.computed('config.ioOptimized', function () {
      return Ember.get(this, 'config.ioOptimized') === 'none' ? NONE_OPT_DISK : OPT_DISK;
    }),

    bootstrap() {
      const config = Ember.get(this, 'globalStore').createRecord({
        type: 'aliyunecsConfig',
        accessKeySecret: '',
        instanceType: DEFAULT_INSTANCE_TYPE,
        ioOptimized: 'optimized',
        internetChargeType: DEFAULT_INTERNET_CHARGE_TYPE
      });
      Ember.set(this, 'model.engineInstallURL', 'http://dev-tool.oss-cn-shenzhen.aliyuncs.com/docker-install/1.13.1.sh');
      Ember.set(this, 'model.engineRegistryMirror', ['https://s06nkgus.mirror.aliyuncs.com']);
      Ember.set(this, 'model.aliyunecsConfig', config);
    },

    resetVswitch() {
      const switches = Ember.get(this, 'filteredVSwitches');
      const selectedVSwitch = Ember.get(this, 'config.vswitchId');

      if (selectedVSwitch) {
        const found = switches.findBy('value', selectedVSwitch);

        if (!found) {
          Ember.set(this, 'config.vswitchId', null);
        }
      }
    },

    validate() {
      this._super();

      const errors = Ember.get(this, 'model').validationErrors();
      const intl = Ember.get(this, 'intl');
      const name = Ember.get(this, 'model.name');
      const sshPassword = Ember.get(this, 'config.sshPassword');
      const lower = /[a-z]/.test(sshPassword) ? 1 : 0;
      const upper = /[A-Z]/.test(sshPassword) ? 1 : 0;
      const number = /[0-9]/.test(sshPassword) ? 1 : 0;
      const special = /[?+*$^().|<>';:\-=\[\]\{\},&%#@!~`\\]/.test(sshPassword) ? 1 : 0;

      if (!name) {
        errors.push('Name is required');
      }

      if (sshPassword && sshPassword.length < 8 || sshPassword.length > 30) {
        errors.push(intl.t('nodeDriver.aliyunecs.errors.sshPasswordLengthNotValid'));
      }

      if (sshPassword && !/[?+*$^().|<>';:\-=\[\]\{\},&%#@!~`\\a-zA-Z0-9]+/.test(sshPassword)) {
        errors.push(intl.t('nodeDriver.aliyunecs.errors.sshPasswordInvalidCharacter'));
      }

      if (sshPassword && lower + upper + number + special < 3) {
        errors.push(Ember.get(this, 'intl').t('nodeDriver.aliyunecs.errors.sshPasswordFormatError'));
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    fetchAvailableResources() {
      const ecs = Ember.get(this, 'ecsClient');
      const region = Ember.get(this, 'config.region');
      const results = [];
      const params = {
        RegionId: region,
        IoOptimized: Ember.get(this, 'config.ioOptimized'),
        SystemDiskCategory: Ember.get(this, 'config.systemDiskCategory'),
        DataDiskCategory: Ember.get(this, 'config.diskCategory')
      };

      if (Ember.get(this, 'config.zone')) {
        params['ZoneId'] = Ember.get(this, 'config.zone');
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        ecs['describeAvailableResource'](params, (err, res) => {
          if (err) {
            reject(err);
            return;
          }

          const zones = res['AvailableZones'];
          zones.AvailableZone.forEach(zone => {
            zone['AvailableResources']['AvailableResource'].forEach(resource => {
              resource['SupportedResources']['SupportedResource'].forEach(support => {
                if (support.Status === 'Available' && results.indexOf(support.Value) === -1) {
                  results.pushObject(support.Value);
                }
              });
            });
          });
          resolve(results);
        });
      });
    },

    fetch(resource, plural, page = 1) {
      const ecs = Ember.get(this, 'ecsClient');
      const region = Ember.get(this, 'config.region');
      const results = [];
      let params = {
        PageSize: PAGE_SIZE,
        PageNumber: page
      };

      switch (resource) {
        case 'InstanceType':
          params = {};
          break;

        case 'VSwitch':
          params.VpcId = Ember.get(this, 'config.vpcId');
          break;

        case 'SecurityGroup':
          params.VpcId = Ember.get(this, 'config.vpcId');
          params.RegionId = region;
          break;

        case 'Zone':
          params = {
            RegionId: region
          };
          break;

        default:
          params.RegionId = region;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        ecs[`describe${plural}`](params, (err, res) => {
          if (err) {
            reject(err);
            return;
          }

          const current = res[`${plural}`][resource];
          results.pushObjects(current.map(item => {
            return {
              label: item[`${resource}Id`],
              value: item[`${resource}Id`],
              raw: item
            };
          }));

          if (res.TotalCount > PAGE_SIZE * (page - 1) + current.length) {
            return this.fetch(resource, plural, page + 1).then(array => {
              results.pushObjects(array);
              resolve(results);
            }).catch(err => {
              reject(err);
            });
          } else {
            resolve(results);
          }
        });
      });
    }

  });

  _exports.default = _default;
});