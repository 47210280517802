define("shared/components/identity-block/component", ["exports", "shared/components/identity-block/template", "identicon.js"], function (_exports, _template, _identicon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let missingPrincipals = [];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    // Principal or id+parsedExternalType
    principal: null,
    parsedExternalType: null,
    principalId: null,
    avatar: true,
    link: true,
    size: 35,
    loading: false,
    classNames: ['gh-block'],
    attributeBindings: ['aria-label:principal.name'],
    unknownUser: false,
    wide: true,

    init() {
      this._super(...arguments);

      const store = Ember.get(this, 'globalStore');
      const principalId = Ember.get(this, 'principalId');
      const missingPrincipal = missingPrincipals.indexOf(principalId);

      if (Ember.get(this, 'principal') || missingPrincipal > 0) {
        return;
      }

      if (principalId) {
        let principal = store.getById('principal', principalId);

        if (principal) {
          Ember.set(this, 'principal', principal);
          return;
        }

        Ember.set(this, 'loading', true);
        store.find('principal', principalId, {
          forceReload: true
        }).then(principal => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.run.next(() => {
            Ember.set(this, 'principal', principal);
          });
        }).catch(() =>
        /* err*/
        {
          // Do something..
          missingPrincipals.pushObject(principalId);
        }).finally(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          Ember.set(this, 'loading', false);
          Ember.set(this, 'unknownUser', true);
        });
      }
    },

    avatarSrc: Ember.computed('principal.id', function () {
      return `data:image/png;base64,${new _identicon.default(AWS.util.crypto.md5(Ember.get(this, 'principal.id') || 'Unknown', 'hex'), 80, 0.01).toString()}`;
    }),

    willDestroy() {
      this._super(...arguments);

      missingPrincipals = [];
      Ember.set(this, 'unknownUser', false);
    }

  });

  _exports.default = _default;
});