define("shared/components/cluster-driver/driver-import/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-import/template"], function (_exports, _clusterDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'importedConfig',
    step: 1,
    nodeForInfo: null,
    isDockerCluster: false,
    isEdit: Ember.computed.equal('mode', 'edit'),
    isView: Ember.computed.equal('mode', 'view'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),
    isK3sCluster: Ember.computed.equal('model.cluster.driver', 'k3s'),
    isRke2Cluster: Ember.computed.equal('model.cluster.driver', 'rke2'),
    nodes: Ember.computed.reads('model.cluster.masterNodes'),

    didReceiveAttrs() {
      if (Ember.get(this, 'isEdit')) {
        if (this.isK3sCluster) {
          Ember.set(this, 'configField', 'k3sConfig');

          if (this.model.cluster.masterNodes.length === 1) {
            Ember.set(this, 'nodeForInfo', this.model.cluster.masterNodes.firstObject);
          }

          if (Ember.isEmpty(this.model.cluster.k3sConfig)) {
            Ember.set(this, 'isDockerCluster', true);
          }
        } else if (this.isRke2Cluster) {
          Ember.set(this, 'configField', 'rke2Config');

          if (this.model.cluster.masterNodes.length === 1) {
            Ember.set(this, 'nodeForInfo', this.model.cluster.masterNodes.firstObject);
          }

          if (Ember.isEmpty(this.model.cluster.rke2Config)) {
            Ember.set(this, 'model.cluster.rke2Config', this.globalStore.createRecord({
              type: 'rke2Config',
              kubernetesVersion: this.cluster.version.gitVersion
            }));
          }
        }
      }
    },

    actions: {
      setActiveNodeForInfo(selection) {
        const node = selection ? this.nodes.findBy('id', selection.id) : null;
        Ember.set(this, 'nodeForInfo', node);
      }

    },
    clusterChanged: Ember.observer('originalCluster.state', function () {
      if (Ember.get(this, 'step') >= 2) {
        const state = Ember.get(this, 'originalCluster.state');

        if (!['pending', 'initializing', 'active'].includes(state)) {
          if (this.close) {
            this.close();
          }
        }
      }
    }),
    nodeInfoId: Ember.computed({
      get() {
        const {
          nodeForInfo
        } = this;

        if (Ember.isEmpty(nodeForInfo)) {
          return null;
        } else {
          return nodeForInfo.id;
        }
      },

      set(key, value) {
        const {
          nodeForInfo
        } = this;

        if (!Ember.isEmpty(nodeForInfo)) {
          Ember.set(nodeForInfo, 'id', value);
        }

        return value;
      }

    }),
    nodesOptions: Ember.computed('nodes.@each.state', function () {
      return this.nodes.map(node => ({
        id: node.id,
        displayName: node.displayName
      }));
    }),

    willSave() {
      const {
        configField: field,
        config
      } = this;
      let errors = [];

      if (field === 'k3sConfig' && !Ember.isEmpty(config)) {
        if (config.k3supgradeStrategy) {
          // doesn't work because missing deep validation
          // errors = config.k3supgradeStrategy.validationErrors();
          if (config.k3supgradeStrategy.serverConcurrency <= 0 || config.k3supgradeStrategy.workerConcurrency <= 0) {
            errors.push(this.intl.t('clusterNew.k3simport.errors.concurrency.negative'));
          }
        }
      }

      if (!Ember.isEmpty(errors)) {
        Ember.set(this, 'errors', errors);
        return false;
      }

      return this._super();
    },

    doneSaving() {
      return this.loadToken();
    },

    loadToken() {
      Ember.set(this, 'step', 2);
      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});