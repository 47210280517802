define("shared/bulk-action-handler/service", ["exports", "shared/utils/download-files"], function (_exports, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    modalService: Ember.inject.service('modal'),
    app: Ember.inject.service(),

    promptStop(nodes) {
      this.get('modalService').toggleModal('modal-container-stop', {
        escToClose: true,
        model: nodes
      });
    },

    promptDelete(nodes) {
      this.get('modalService').toggleModal('confirm-delete', {
        escToClose: true,
        resources: nodes
      });
    },

    drain(nodes) {
      this.get('modalService').toggleModal('modal-drain-node', {
        escToClose: true,
        resources: nodes
      });
    },

    move(nodes) {
      this.get('modalService').toggleModal('modal-move-namespace', nodes);
    },

    downloadYaml(nodes) {
      (0, _downloadFiles.downloadResourceYaml)(nodes);
    }

  });

  _exports.default = _default;
});