define("shared/components/cluster-driver/driver-azureaks/component", ["exports", "shared/mixins/cluster-driver", "shared/components/cluster-driver/driver-azureaks/template", "ipaddr.js", "shared/utils/constants", "semver", "ui/utils/azure-choices"], function (_exports, _clusterDriver, _template, _ipaddr, _constants, _semver, _azureChoices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NETWORK_POLICY = ['calico'];
  const CHINA_REGION_API_URL = 'https://management.chinacloudapi.cn/';
  const CHINA_REGION_AUTH_URL = 'https://login.chinacloudapi.cn/';
  const NETWORK_PLUGINS = [{
    label: 'Kubenet',
    value: 'kubenet'
  }, {
    label: 'Azure',
    value: 'azure'
  }];
  const LB_SKUS = [{
    label: 'Standard',
    value: 'standard'
  }, {
    label: 'Basic',
    value: 'basic'
  }];

  var _default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    versionChoiceService: Ember.inject.service('version-choices'),
    layout: _template.default,
    configField: 'azureKubernetesServiceConfig',
    zones: _azureChoices.aksRegions,
    versions: null,
    machineSizes: _azureChoices.sizes,
    step: 1,
    netMode: 'default',
    monitoringRegionConent: [],
    networkPlugins: NETWORK_PLUGINS,
    lbSkus: LB_SKUS,
    defaultK8sVersionRange: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE}`),
    editing: Ember.computed.equal('mode', 'edit'),
    isNew: Ember.computed.equal('mode', 'new'),

    init() {
      this._super(...arguments);

      let config = Ember.get(this, 'cluster.azureKubernetesServiceConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          adminUsername: 'azureuser',
          agentOsdiskSize: 100,
          agentPoolName: 'rancher',
          agentVmSize: 'Standard_D2_v2',
          count: 3,
          enableHttpApplicationRouting: false,
          enableMonitoring: true,
          location: 'eastus',
          type: 'azureKubernetesServiceConfig'
        });
        Ember.set(this, 'cluster.azureKubernetesServiceConfig', config);
        Ember.set(this, 'loadBalancerSku', 'basic');
      } else {
        const tags = Ember.get(config, 'tags') || [];
        const map = {};
        tags.map((t = '') => {
          const split = t.split('=');
          Ember.set(map, split[0], split[1]);
        });
        Ember.set(this, 'tags', map);

        if (Ember.get(config, 'networkPolicy') || Ember.get(config, 'subnet')) {
          Ember.set(this, 'netMode', 'advanced');
        }
      }
    },

    actions: {
      authenticate(cb) {
        const store = Ember.get(this, 'globalStore');
        const data = {
          clientId: Ember.get(this, 'config.clientId'),
          clientSecret: Ember.get(this, 'config.clientSecret'),
          subscriptionId: Ember.get(this, 'config.subscriptionId'),
          tenantId: Ember.get(this, 'config.tenantId'),
          region: Ember.get(this, 'config.location')
        };

        if (Ember.get(this, 'isChinaRegion')) {
          Ember.setProperties(data, {
            baseUrl: CHINA_REGION_API_URL,
            authBaseUrl: CHINA_REGION_AUTH_URL
          });
        }

        const aksRequest = {
          versions: store.rawRequest({
            url: '/meta/aksVersions',
            method: 'POST',
            data
          }),
          virtualNetworks: store.rawRequest({
            url: '/meta/aksVirtualNetworks',
            method: 'POST',
            data
          })
        };
        return Ember.RSVP.hash(aksRequest).then(resp => {
          const {
            mode
          } = this;
          const {
            versions,
            virtualNetworks
          } = resp;
          const isEdit = mode === 'edit';
          const versionz = Ember.get(versions, 'body') || [];
          const initialVersion = isEdit ? this.config.kubernetesVersion : _semver.default.maxSatisfying(versionz, this.defaultK8sVersionRange);

          if (!isEdit && initialVersion) {
            Ember.set(this, 'cluster.azureKubernetesServiceConfig.kubernetesVersion', initialVersion);
          }

          Ember.setProperties(this, {
            step: 2,
            versions: Ember.get(versions, 'body') || [],
            virtualNetworks: Ember.get(virtualNetworks, 'body') || []
          });
          cb(true);
        }).catch(xhr => {
          const err = xhr.body.message || xhr.body.code || xhr.body.error;
          Ember.setProperties(this, {
            errors: [err]
          });
          cb(false, [err]);
        });
      },

      setTags(section) {
        const out = [];

        for (let key in section) {
          out.pushObject(`${key}=${section[key]}`);
        }

        Ember.set(this, 'config.tags', out);
      }

    },
    resetAdvancedOptions: Ember.on('init', Ember.observer('netMode', function () {
      if (Ember.get(this, 'isNew') && Ember.get(this, 'netMode') === 'default') {
        const config = Ember.get(this, 'config');
        let {
          subnet,
          virtualNetwork,
          virtualNetworkResourceGroup,
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        } = this.globalStore.getById('schema', 'azurekubernetesserviceconfig').getCreateDefaults();
        Ember.setProperties(config, {
          subnet,
          virtualNetwork,
          virtualNetworkResourceGroup,
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        });
      }
    })),
    loadBalancerSku: Ember.computed('config.loadBalancerSku', {
      get() {
        const lbSku = Ember.get(this, 'config.loadBalancerSku');

        if (lbSku === 'standard') {
          return 'Standard';
        }

        return 'Basic';
      },

      set(key, value) {
        Ember.set(this, 'config.loadBalancerSku', value);
        return value;
      }

    }),
    versionChoices: Ember.computed('versions', function () {
      const {
        versions = [],
        mode,
        config: {
          kubernetesVersion: initialVersion
        }
      } = this; // azure versions come in oldest to newest

      return this.versionChoiceService.parseCloudProviderVersionChoices((versions || []).reverse(), initialVersion, mode);
    }),
    networkChoice: Ember.computed({
      set(key, value = '') {
        const [subnet, virtualNetwork, virtualNetworkResourceGroup] = value.split(':');
        const config = Ember.get(this, 'config');

        if (subnet && virtualNetwork && virtualNetworkResourceGroup) {
          Ember.setProperties(config, {
            subnet,
            virtualNetwork,
            virtualNetworkResourceGroup
          });
        }

        return value;
      }

    }),
    filteredVirtualNetworks: Ember.computed('config.virtualNetwork', 'virtualNetworks', function () {
      const vnets = Ember.get(this, 'virtualNetworks') || [];
      const subNets = [];
      vnets.forEach(vnet => {
        (Ember.get(vnet, 'subnets') || []).forEach(subnet => {
          subNets.pushObject({
            name: `${Ember.get(subnet, 'name')} (${Ember.get(subnet, 'addressRange')})`,
            group: Ember.get(vnet, 'name'),
            value: `${Ember.get(subnet, 'name')}:${Ember.get(vnet, 'name')}:${Ember.get(vnet, 'resourceGroup')}`
          });
        });
      });
      return subNets;
    }),
    networkChoiceDisplay: Ember.computed('virtualNetworks', 'config.virtualNetwork', 'config.subnet', function () {
      const selected = (Ember.get(this, 'virtualNetworks') || []).findBy('name', Ember.get(this, 'config.virtualNetwork')) || {};
      const subnet = (Ember.get(selected, 'subnets') || []).findBy('name', Ember.get(this, 'config.subnet')) || {};
      return `${Ember.get(subnet, 'name')} (${Ember.get(subnet, 'addressRange')})`;
    }),
    isEditable: Ember.computed('mode', function () {
      return Ember.get(this, 'mode') === 'edit' || Ember.get(this, 'mode') === 'new' ? true : false;
    }),
    isChinaRegion: Ember.computed('config.location', function () {
      return Ember.get(this, 'config.location').startsWith('china');
    }),
    saveDisabled: Ember.computed('config.subscriptionId', 'config.tenantId', 'config.clientId', 'config.clientSecret', 'config.location', function () {
      return Ember.get(this, 'config.tenantId') && Ember.get(this, 'config.clientId') && Ember.get(this, 'config.clientSecret') && Ember.get(this, 'config.subscriptionId') && Ember.get(this, 'config.location') ? false : true;
    }),
    networkPolicyContent: Ember.computed(() => {
      return NETWORK_POLICY.map(n => {
        return {
          label: n,
          value: n
        };
      });
    }),

    validate() {
      const intl = Ember.get(this, 'intl');
      let model = Ember.get(this, 'cluster');
      let errors = model.validationErrors() || [];
      const vnetSet = !!Ember.get(this, 'config.virtualNetwork');

      if (vnetSet) {
        errors = errors.concat(this.validateVnetInputs());
      }

      if (!Ember.get(this, 'config.resourceGroup')) {
        errors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.resourceGroup.label')
        }));
      }

      if (!Ember.get(this, 'config.sshPublicKeyContents')) {
        errors.push(intl.t('validation.required', {
          key: intl.t('clusterNew.azureaks.ssh.label')
        }));
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    validateVnetInputs() {
      const intl = Ember.get(this, 'intl');
      const errors = [];
      const config = Ember.get(this, 'config');
      const vnet = Ember.get(this, 'virtualNetworks').findBy('name', Ember.get(config, 'virtualNetwork'));

      if (vnet) {
        let subnet = Ember.get(vnet, `subnets`).findBy('name', Ember.get(config, 'subnet'));

        let vnetRange = _ipaddr.default.parseCIDR(Ember.get(subnet, 'addressRange'));

        let {
          serviceCidr,
          dnsServiceIp,
          dockerBridgeCidr
        } = config;
        let parsedServiceCidr = null;
        let parsedDnsServiceIp = null;
        let parsedDockerBridgeCidr = null;

        if (!serviceCidr && !dnsServiceIp && !dockerBridgeCidr) {
          errors.pushObject('You must include all required fields when using a Virtual Network');
        }

        try {
          parsedServiceCidr = _ipaddr.default.parseCIDR(serviceCidr); // check if serviceCidr falls within the VNet/Subnet range

          if (parsedServiceCidr && vnetRange[0].match(parsedServiceCidr)) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedServiceCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.serviceCidr'));
        }

        try {
          parsedDnsServiceIp = _ipaddr.default.parse(dnsServiceIp); // check if dnsService exists in range

          if (parsedDnsServiceIp && vnetRange[0].match(parsedDnsServiceIp, vnetRange[1])) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDnsServiceIp'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dnsServiceIp'));
        }

        try {
          parsedDockerBridgeCidr = _ipaddr.default.parseCIDR(dockerBridgeCidr); // check that dockerBridge doesn't overlap

          if (parsedDockerBridgeCidr && (vnetRange[0].match(parsedDockerBridgeCidr) || parsedServiceCidr[0].match(parsedDockerBridgeCidr))) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDockerBridgeCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dockerBridgeCidr'));
        }
      }

      return errors;
    },

    willSave() {
      const enableMonitoring = Ember.get(this, 'config.enableMonitoring');
      const config = Ember.get(this, 'config');

      if (enableMonitoring) {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: '',
          logAnalyticsWorkspace: ''
        });
      } else {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: null,
          logAnalyticsWorkspace: null
        });
      }

      if (Ember.get(this, 'isChinaRegion')) {
        Ember.setProperties(config, {
          baseUrl: CHINA_REGION_API_URL,
          authBaseUrl: CHINA_REGION_AUTH_URL
        });
      } else {
        delete config['baseUrl'];
        delete config['authBaseUrl'];
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});