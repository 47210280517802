define("shared/components/modal-update-azuread/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-update-azuread/template", "jquery"], function (_exports, _modalBase, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal', 'modal-logs'],
    azureAd: Ember.computed.alias('modalService.modalOpts.azureAd'),
    config: Ember.computed.alias('modalService.modalOpts.model'),
    action: Ember.computed.alias('modalService.modalOpts.action'),

    didRender() {
      setTimeout(() => {
        try {
          (0, _jquery.default)('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    },

    actions: {
      confirm() {
        Ember.get;
        this.get('azureAd').upgrade(Ember.get(this, 'config'));
        this.send('cancel');
      }

    }
  });

  _exports.default = _default;
});