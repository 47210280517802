define("shared/helpers/date-calendar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateCalendar = dateCalendar;
  _exports.default = void 0;

  function dateCalendar(params, options) {
    let out = (0, _moment.default)(params[0]).calendar();

    if (options && options.withToday !== true) {
      out = out.replace('Today at ', '');
    }

    return out;
  }

  var _default = Ember.Helper.helper(dateCalendar);

  _exports.default = _default;
});