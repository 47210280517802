define("shared/components/input-slider/component", ["exports", "ui/utils/constants", "shared/components/input-slider/template", "jquery"], function (_exports, _constants, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function clientX(event) {
    if (typeof event.clientX !== 'undefined') {
      return event.clientX;
    }

    var orig = event.originalEvent;

    if (orig) {
      if (typeof orig.clientX !== 'undefined') {
        return orig.clientX;
      }

      if (orig.touches && orig.touches.length) {
        return orig.touches[0].clientX;
      }
    }

    return 0;
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['slider'],
    classNameBindings: ['disabled', 'active'],
    disabled: false,
    initialValue: null,
    value: null,
    // Bind something to this to get the value, or use the action to get it
    valueMin: 0,
    // The smallest and biggest value is allowed to be
    valueMax: 100,
    scaleMin: null,
    // The smallest and biggest values shown on the display.  If these are not equal to valueMin/max then there will be
    scaleMax: null,
    // a part of the slider that the user can't select, e.g. if you want to show 0 but have a minimum value of 1.
    step: 1,
    // Increment
    active: false,
    dragFn: null,
    upFn: null,

    init() {
      this._super(...arguments);

      var initial = Ember.get(this, 'initialValue');

      if (initial !== null) {
        Ember.set(this, 'value', initial);
      }
    },

    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, 'valueChanged');
    },

    willDestroyElement() {
      (0, _jquery.default)('BODY').off('mousemove', Ember.get(this, 'dragFn')); // eslint-disable-line

      (0, _jquery.default)('BODY').off('mouseup', Ember.get(this, 'upFn')); // eslint-disable-line
    },

    valueChanged: Ember.observer('value', 'valueMin', 'valueMax', 'percent', 'elementId', function () {
      const {
        elementId
      } = this;
      const originalValue = Ember.get(this, 'value');
      let targetSliderBar = elementId ? `.${elementId}.slider-bar` : '.slider-bar';
      let targetSliderHandle = elementId ? `.${elementId}.slider-handle` : '.slider-handle';
      let value = Math.max(Ember.get(this, 'valueMin'), Math.min(originalValue, Ember.get(this, 'valueMax')));

      if (isNaN(value)) {
        value = Ember.get(this, 'valueMin');
      }

      if (this.changed) {
        this.changed(value);
      }

      if (value && originalValue !== value) {
        Ember.set(this, 'value', value);
        return;
      }

      var percent = Ember.get(this, 'percent');
      (0, _jquery.default)(targetSliderBar).css('width', `${percent}%`); // eslint-disable-line

      (0, _jquery.default)(targetSliderHandle).css('left', `${percent}%`); // eslint-disable-line
    }),
    _scaleMin: Ember.computed('scaleMin', 'valueMin', function () {
      var min = Ember.get(this, 'scaleMin');

      if (min === null) {
        min = Ember.get(this, 'valueMin');
      }

      return min;
    }),
    _scaleMax: Ember.computed('scaleMax', 'valueMax', function () {
      var min = Ember.get(this, 'scaleMax');

      if (min === null) {
        min = Ember.get(this, 'valueMax');
      }

      return min;
    }),
    percent: Ember.computed('value', 'valueMin', 'valueMax', '_scaleMin', '_scaleMax', function () {
      var cur = Ember.get(this, 'value');
      var min = Math.min(Ember.get(this, '_scaleMin'), Ember.get(this, 'valueMin'));
      var max = Math.max(Ember.get(this, '_scaleMax'), Ember.get(this, 'valueMax'));
      return ((cur - min) / (max - min) * 100).toFixed(3);
    }),

    alignValue(val) {
      var step = Ember.get(this, 'step');
      var min = Ember.get(this, 'valueMin');
      var max = Ember.get(this, 'valueMax'); // Subtract out the minimum so that modulus will work for
      // determining how close val is to a valid step

      val -= min;
      var diff = val % step;

      if (diff >= step / 2) {
        val = val - diff + step;
      } else if (diff > 0) {
        val = val - diff;
      } // Add the minimum back in


      val += min; // Make sure the value is within range
      // (if `max` is not an increment of `step` it's your problem..)

      val = Math.max(min, Math.min(val, max));
      return val;
    },

    pointToValue(screenX) {
      var $elem = (0, _jquery.default)(this.element);
      var offset = $elem.offset();
      var width = $elem.outerWidth();
      var x = screenX - offset.left;
      var percent = Math.max(0, Math.min(x / width, 1));
      var min = Ember.get(this, '_scaleMin');
      var max = Ember.get(this, '_scaleMax');
      var rawValue = min + percent * (max - min);
      var aligned = this.alignValue(rawValue);
      return aligned;
    },

    click(event) {
      if (Ember.get(this, 'disabled')) {
        return false;
      }

      var value = this.pointToValue(clientX(event));
      Ember.set(this, 'value', value);
      (0, _jquery.default)('.slider-handle').focus();
    },

    mouseDown(event) {
      if (Ember.get(this, 'disabled')) {
        return false;
      }

      Ember.set(this, 'active', true);

      if (!Ember.get(this, 'dragFn')) {
        Ember.set(this, 'dragFn', this.drag.bind(this));
      }

      if (!Ember.get(this, 'upFn')) {
        Ember.set(this, 'upFn', this.mouseUp.bind(this));
      }

      (0, _jquery.default)('BODY').on('mousemove', Ember.get(this, 'dragFn')); // eslint-disable-line

      (0, _jquery.default)('BODY').on('mouseup', Ember.get(this, 'upFn')); // eslint-disable-line

      this.drag(event);
    },

    drag(event) {
      event.preventDefault();

      if (Ember.get(this, 'disabled')) {
        return false;
      }

      var value = this.pointToValue(clientX(event));
      Ember.set(this, 'value', value);
    },

    mouseUp()
    /* event*/
    {
      (0, _jquery.default)('BODY').off('mousemove', Ember.get(this, 'dragFn')); // eslint-disable-line

      (0, _jquery.default)('BODY').off('mouseup', Ember.get(this, 'upFn')); // eslint-disable-line

      Ember.set(this, 'active', false);
    },

    keyDown(event) {
      var handled = false;

      switch (event.which) {
        case _constants.default.KEY.LEFT:
          this.decrementProperty('value', Ember.get(this, 'step'));
          handled = true;
          break;

        case _constants.default.KEY.RIGHT:
          this.incrementProperty('value', Ember.get(this, 'step'));
          handled = true;
          break;

        case _constants.default.KEY.UP:
          Ember.set(this, 'value', Ember.get(this, 'valueMax'));
          handled = true;
          break;

        case _constants.default.KEY.DOWN:
          Ember.set(this, 'value', Ember.get(this, 'valueMin'));
          handled = true;
          break;
      }

      if (handled) {
        event.preventDefault();
      }
    }

  });

  _exports.default = _default;
});