define("shared/components/run-scan-modal/component", ["exports", "shared/mixins/modal-base", "shared/components/run-scan-modal/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    cisHelpers: Ember.inject.service(),
    securityScanConfig: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    profile: null,
    loading: true,
    classNames: ['generic', 'about', 'medium-modal'],

    init() {
      this._super(...arguments);

      this.updateProfile();
      this.securityScanConfig.loadAsyncConfigMap(Ember.get(this, 'modalOpts.cluster')).then(() => Ember.set(this, 'loading', false));
    },

    actions: {
      run() {
        const cluster = Ember.get(this, 'modalOpts.cluster');
        const onRun = Ember.get(this, 'modalOpts.onRun');
        const intl = Ember.get(this, 'intl');
        const profile = Ember.get(this, 'profileObject');
        const clusterName = Ember.get(this, 'modalOpts.cluster.displayName');
        cluster.doAction('runSecurityScan', {
          failuresOnly: false,
          skip: null,
          profile: profile.profile,
          overrideBenchmarkVersion: profile.benchmark
        }).then(() => {
          this.growl.success(intl.t('cis.scan.growl.success', {
            clusterName
          }), '');
        });
        this.get('modalService').toggleModal();
        (onRun || (() => {}))();
      }

    },
    cisScanProfileOptionsChanged: Ember.observer('cisHelpers.cisScanProfileOptions.[]', function () {
      this.updateProfile();
    }),
    profileObject: Ember.computed('cisHelpers.cisScanProfiles', 'profile', function () {
      return this.cisHelpers.cisScanProfiles[Ember.get(this, 'profile')];
    }),
    testsNotRunning: Ember.computed('cisHelpers.benchmarkLookup', 'profileObject', function () {
      const benchmarkLookup = Ember.get(this, 'cisHelpers.benchmarkLookup');
      const profile = Ember.get(this, 'profileObject');

      if (!benchmarkLookup || !profile) {
        return {};
      }

      const mapping = benchmarkLookup[profile.benchmark];

      if (!mapping) {
        return {};
      }

      const skippedChecks = profile.profile !== 'hardened' ? mapping.skippedChecks : [];
      return {
        skippedChecks,
        notApplicableChecks: mapping.notApplicableChecks,
        userSkippedChecks: mapping.userSkippedChecks.join(', ')
      };
    }),
    profileDocsHtml: Ember.computed('settings.docsBase', function () {
      return this.intl.t('cis.scan.modal.profileDocs', {
        docsBase: this.settings.docsBase
      });
    }),
    testDocsHtml: Ember.computed('settings.docsBase', function () {
      return this.intl.t('cis.scan.modal.testDocs', {
        docsBase: this.settings.docsBase
      });
    }),

    updateProfile() {
      if (this.cisHelpers.cisScanProfileOptions.length > 0) {
        const kubernetesVersion = Ember.get(this, 'modalOpts.cluster.rancherKubernetesEngineConfig.kubernetesVersion');
        const defaultOption = this.cisHelpers.getDefaultCisScanProfileOption(kubernetesVersion);
        Ember.set(this, 'profile', defaultOption);
      }
    }

  });

  _exports.default = _default;
});