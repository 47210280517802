define("shared/release-versions/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    allVersions: null,

    async getAllVersions(driver) {
      try {
        const path = driver === 'k3s' ? '/v1-k3s-release/release' : '/v1-rke2-release/release';
        const resp = await this.globalStore.request({
          url: path
        });
        const versions = [];
        resp.content.forEach(ver => {
          versions.pushObject(ver.version);
        });
        return Ember.set(this, 'allVersions', versions);
      } catch (err) {
        return Ember.set(this, 'allVersions', []);
      }
    }

  });

  _exports.default = _default;
});