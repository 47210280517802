define("global-admin/cluster-templates/detail/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentRoute: 'global-admin.cluster-templates.index',
    actions: {
      cancel() {
        this.send('goToPrevious', this.parentRoute);
      },

      done() {
        this.transitionToRoute('cluster-templates.index');
      }

    }
  });

  _exports.default = _default;
});