define("global-admin/global-registry/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NAME = 'global-registry';

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      const store = Ember.get(this, 'globalStore');
      const clusters = store.all('cluster');
      const cluster = clusters.findBy('id', 'local');
      const globalRegistryEnabled = store.all('setting').findBy('id', 'global-registry-enabled');

      if (!cluster) {
        return {
          showForm: false,
          globalRegistryEnabled
        };
      }

      const project = Ember.get(cluster, 'systemProject');
      const apps = project.followLink('apps');
      const namespaces = cluster.followLink('namespaces');
      const storageClasses = cluster.followLink('storageClasses');
      const persistentVolumeClaims = project.followLink('persistentVolumeClaims');
      return Ember.RSVP.all([apps, namespaces, storageClasses, persistentVolumeClaims]).then(data => {
        const apps = data[0] || [];
        const storageClasses = data[2] || [];
        const persistentVolumeClaims = data[3] || [];
        return {
          app: apps.findBy('name', NAME),
          nsExists: true,
          cluster,
          project,
          storageClasses,
          persistentVolumeClaims: persistentVolumeClaims.filter(p => p.namespaceId === 'cattle-system' && p.state === 'bound'),
          showForm: true
        };
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});