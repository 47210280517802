define("shared/components/upgrade-btn/component", ["exports", "shared/mixins/upgrade-component", "shared/components/upgrade-btn/template"], function (_exports, _upgradeComponent, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_upgradeComponent.default, {
    layout: _template.default,
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['color', 'pad'],
    pad: Ember.computed('color', function () {
      if (this.get('color') === 'bg-transparent') {
        return 'p-0';
      }

      return '';
    }),

    click() {
      this.doUpgrade();
    }

  });

  _exports.default = _default;
});