define("shared/helpers/link-ssl-domain", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params
    /* , options*/
    ) {
      let name = params[0] || '';

      if (name.substr(0, 1) === '*') {
        return name;
      } else {
        return `<a href="https://${encodeURIComponent(name)}" target="_blank" rel="nofollow noreferrer noopener">${(0, _util.escapeHtml)(name)} <i class="icon icon-sm icon-external-link"/></a>`.htmlSafe();
      }
    }

  });

  _exports.default = _default;
});