define("shared/components/managed-import-cluster-info/component", ["exports", "shared/components/managed-import-cluster-info/template", "semver", "shared/utils/sort"], function (_exports, _template, _semver, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    releaseVersions: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    configField: 'k3sConfig',
    config: Ember.computed('cluster.{k3sConfig,rke2Config}', 'configField', function () {
      return Ember.get(this, `cluster.${this.configField}`);
    }),
    upgradeStrategy: Ember.computed('config.{k3supgradeStrategy,rke2upgradeStrategy}', 'configField', function () {
      const {
        configField
      } = this;
      const upgradeStrategyPath = configField === 'k3sConfig' ? 'k3supgradeStrategy' : 'rke2upgradeStrategy';
      return Ember.get(this.config, upgradeStrategyPath);
    }),
    serverConcurrency: Ember.computed('upgradeStrategy.serverConcurrency', {
      get() {
        return Ember.get(this, 'upgradeStrategy.serverConcurrency');
      },

      set(key, value) {
        Ember.set(this, 'upgradeStrategy.serverConcurrency', this.coerceToInt(value));
        return value;
      }

    }),
    workerConcurrency: Ember.computed('upgradeStrategy.workerConcurrency', {
      get() {
        return Ember.get(this, 'upgradeStrategy.workerConcurrency');
      },

      set(key, value) {
        Ember.set(this, 'upgradeStrategy.workerConcurrency', this.coerceToInt(value));
        return value;
      }

    }),
    allVersions: Ember.computed('releaseVersions.allVersions.[]', function () {
      const currentVersion = Ember.get(this, `config.kubernetesVersion`);
      const versionsMapped = [];
      let allVersions = this.releaseVersions.allVersions || [];

      if (!allVersions.includes(currentVersion)) {
        allVersions.unshift(currentVersion);
      }

      allVersions = [...(0, _sort.sortVersions)(allVersions).reverse()];
      allVersions.forEach(v => {
        if (_semver.default.gte(v, currentVersion)) {
          versionsMapped.pushObject({
            value: v,
            label: v
          });
        }
      });
      return versionsMapped;
    }),

    coerceToInt(value) {
      let errors;
      let coerced = value;
      errors = Ember.set(this, 'errors', []);
      coerced = parseInt(value, 10);

      if (isNaN(coerced)) {
        errors.push(this.intl.t('managedImportClusterInfo.error.int'));
        Ember.set(this, 'errors', errors);
        return value;
      }

      return coerced;
    }

  });

  _exports.default = _default;
});