define("shared/mixins/grafana", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    grafana: Ember.inject.service(),
    grafanaUrl: Ember.computed('baseType', 'grafana.dashboards', 'grafanaDashboardName', 'grafanaResourceId', 'namespaceId', 'podName', 'scope.currentCluster.monitoringStatus.grafanaEndpoint', 'scope.currentProject.isMonitoringReady', 'scope.currentProject.monitoringStatus.grafanaEndpoint', 'type', function () {
      let dashboardName = Ember.get(this, 'baseType') === 'workload' ? (Ember.get(this, 'type') || '').capitalize() : Ember.get(this, 'grafanaDashboardName');
      const dashboard = (Ember.get(this, 'grafana.dashboards') || []).findBy('title', dashboardName);

      if (!dashboard) {
        return;
      }

      const found = Ember.get(this, 'globalStore').all('project').findBy('isSystemProject', true);
      const isProjectReady = Ember.get(this, 'scope.currentProject.isMonitoringReady');
      let grafanaUrl;

      if (isProjectReady) {
        grafanaUrl = `${Ember.get(this, 'scope.currentProject.monitoringStatus.grafanaEndpoint')}${dashboard.url}`;
      } else if (found) {
        grafanaUrl = `${Ember.get(this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint')}${dashboard.url}`;
      }

      switch (Ember.get(this, 'type')) {
        case 'node':
          grafanaUrl += `?var-node=${Ember.get(this, 'grafanaResourceId')}:9796`;
          break;

        case 'deployment':
          grafanaUrl += `?var-deployment_namespace=${Ember.get(this, 'namespaceId')}&var-deployment_name=${Ember.get(this, 'grafanaResourceId')}`;
          break;

        case 'daemonSet':
          grafanaUrl += `?var-daemonset_namespace=${Ember.get(this, 'namespaceId')}&var-daemonset_name=${Ember.get(this, 'grafanaResourceId')}`;
          break;

        case 'statefulSet':
          grafanaUrl += `?var-statefulset_namespace=${Ember.get(this, 'namespaceId')}&var-statefulset_name=${Ember.get(this, 'grafanaResourceId')}`;
          break;

        case 'pod':
          grafanaUrl += `?var-namespace=${Ember.get(this, 'namespaceId')}&var-pod=${Ember.get(this, 'grafanaResourceId')}&var-container=All`;
          break;

        case 'container':
          grafanaUrl += `?var-namespace=${Ember.get(this, 'namespaceId')}&var-pod=${Ember.get(this, 'podName')}&var-container=${Ember.get(this, 'grafanaResourceId')}`;
          break;
      }

      return grafanaUrl;
    })
  });

  _exports.default = _default;
});