define("global-admin/clusters/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    provider: null,

    init() {
      this._super(...arguments);

      this.router.on('routeDidChange', transition => {
        if (transition.to && transition.to.params.provider) {
          this.set('provider', transition.to.params.provider);
        }
      });
    }

  });

  _exports.default = _default;
});