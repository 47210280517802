define("shared/mixins/promise-to-cb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toCb(name_or_fn, ...args) {
      return (results, cb) => {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        let promise;

        if (typeof name_or_fn === 'string') {
          promise = this[name_or_fn](...args, results);
        } else {
          promise = name_or_fn(...args, results);
        }

        promise.then(res => {
          cb(null, res);
        }).catch(err => {
          cb(err, null);
        });
      };
    }

  });

  _exports.default = _default;
});