define("shared/components/tooltip-element/component", ["exports", "jquery", "shared/components/tooltip-element/template"], function (_exports, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DELAY = 100;

  var _default = Ember.Component.extend({
    tooltipService: Ember.inject.service('tooltip'),
    layout: _template.default,
    classNameBindings: ['inlineBlock:vertical-middle', 'clip:clip'],
    tagName: 'span',
    inlineBlock: false,
    clip: false,
    model: null,
    size: 'default',
    ariaRole: ['tooltip'],
    textChangedEvent: null,
    tooltipFor: null,
    showTimer: null,
    textChanged: Ember.observer('textChangedEvent', function () {
      this.show(this.get('textChangedEvent'));
    }),
    modelObserver: Ember.observer('model', 'textChangedEvent', function () {
      let opts = this.get('tooltipService.tooltipOpts');

      if (opts && this.get('tooltipFor') === opts.tooltipFor) {
        Ember.run.next(() => {
          this.set('tooltipService.tooltipOpts.model', this.get('model'));
        });
      }
    }),

    mouseEnter(evt) {
      if (!this.get('tooltipService.requireClick')) {
        let tgt = (0, _jquery.default)(evt.currentTarget);

        if (this.get('tooltipService.tooltipOpts')) {
          this.set('tooltipService.tooltipOpts', null);
        }

        if (this.get('model')) {
          // Wait for a little bit of time so that the mouse can pass through
          // another tooltip-element on the way to the dropdown trigger of a
          // tooltip-action-menu without changing the tooltip.
          this.set('showTimer', Ember.run.later(() => {
            this.show(tgt);
          }, DELAY));
        }
      }
    },

    show(node) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let svc = this.get('tooltipService');
      this.set('showTimer', null);
      svc.cancelTimer();
      let out = {
        type: this.get('type'),
        baseClass: this.get('baseClass'),
        eventPosition: node.offset(),
        originalNode: node,
        model: this.get('model'),
        template: this.get('tooltipTemplate'),
        tooltipFor: this.get('tooltipFor'),
        placement: this.get('placement')
      };

      if (this.get('isCopyTo')) {
        out.isCopyTo = true;
      }

      svc.set('tooltipOpts', out);
    },

    mouseLeave() {
      if (!this.get('tooltipService.openedViaContextClick')) {
        if (this.get('showTimer')) {
          Ember.run.cancel(this.get('showTimer'));
        } else {
          this.get('tooltipService').leave();
        }
      }
    }

  });

  _exports.default = _default;
});