define("shared/components/input-answers/component", ["exports", "ui/utils/platform", "shared/utils/evaluate", "shared/components/input-answers/template", "js-yaml", "shared/utils/convert-yaml", "shared/mixins/input-answers"], function (_exports, _platform, _evaluate, _template, _jsYaml, _convertYaml, _inputAnswers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HIDDEN = 'Hidden';

  var _default = Ember.Component.extend(_inputAnswers.default, {
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    pasteOrUpload: false,
    isMultiClusterApp: false,
    accept: '.yml, .yaml',
    showHeader: true,
    answerSections: null,
    questions: Ember.computed.alias('selectedTemplate.allQuestions'),
    valuesYaml: Ember.computed.alias('selectedTemplate.valuesYaml'),

    didInsertElement() {
      this._super(...arguments); // don't show yaml editor before the component is finished rendering or we get an empty yaml editor until user clicks in yaml form.
      // reason being code-mirror doesn't have an element to initialize on


      Ember.run.next(() => {
        const {
          isMultiClusterApp
        } = this;
        let pasteOrUpload = isMultiClusterApp ? !!Ember.get(this, 'app.answers.firstObject.valuesYaml') : !!Ember.get(this, 'app.valuesYaml');
        Ember.set(this, 'pasteOrUpload', pasteOrUpload);
      });
    },

    // shared actions exist in mixin
    // actions: {},
    pastedAnswers: Ember.computed('app.answers.firstObject.valuesYaml', 'app.valuesYaml', 'pasteOrUpload', 'questions', {
      get()
      /* key */
      {
        let valuesYaml;
        const {
          isMultiClusterApp
        } = this;

        if (isMultiClusterApp) {
          valuesYaml = Ember.get(this, 'app.answers.firstObject.valuesYaml') || '';
        } else {
          valuesYaml = Ember.get(this, 'app.valuesYaml') || '';
        }

        let yaml;

        if (valuesYaml) {
          yaml = valuesYaml;
        } else {
          const questions = Ember.get(this, 'questions');
          const input = {};
          questions.forEach(q => {
            if (q.answer !== undefined && q.answer !== null) {
              input[q.variable] = q.answer;
            } else if (q.default !== undefined && q.default !== null) {
              input[q.variable] = q.default;
            } else {
              input[q.variable] = '';
            }
          });
          yaml = (0, _convertYaml.default)(input);
        }

        Ember.set(this, 'valuesYaml', yaml);
        return yaml;
      },

      set(key, value) {
        try {
          _jsYaml.default.safeLoad(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', [`YAML Parse Error: ${err.snippet} - ${err.message}`]);
          return value;
        }

        Ember.setProperties(this, {
          yamlErrors: [],
          valuesYaml: value
        });
        return value;
      }

    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    answerDidChange: Ember.on('init', Ember.observer('questions.@each.answer', function () {
      const questions = Ember.get(this, 'questions') || [];
      let oldQuestions = [];
      let newQuestions = [];
      (Ember.get(this, 'answerSections') || []).forEach(section => {
        section.data.forEach(group => {
          group.forEach(q => {
            oldQuestions.push(q.variable);
          });
        });
      });
      oldQuestions = oldQuestions.sort();
      const filteredQuestions = questions.filter(q => (0, _evaluate.evaluate)(q, questions));
      newQuestions = filteredQuestions.map(q => q.variable).sort();
      const sections = {};
      const notInAnySection = [];
      filteredQuestions.forEach(item => {
        if (item.group) {
          if (item.group === HIDDEN) {
            return;
          }

          if (!sections[item.group]) {
            sections[item.group] = [];
          }

          sections[item.group].push(item);
        } else {
          notInAnySection.push(item);
        }
      });
      const allSections = [];
      Object.keys(sections).forEach(key => {
        allSections.push({
          title: key,
          data: sections[key]
        });
      });

      if (notInAnySection.length) {
        allSections.push({
          data: notInAnySection
        });
      }

      const out = [];
      allSections.forEach(section => {
        const data = [];
        let dataIndex = 0;
        out.push({
          title: section.title,
          data
        });
        section.data.forEach((item, index) => {
          if (index % 2 === 0) {
            data.push([item]);
            dataIndex++;
          } else {
            data[dataIndex - 1].push(item);
          }
        });
      });

      if (newQuestions.toString() !== oldQuestions.toString()) {
        Ember.set(this, 'answerSections', out);
      }
    })),

    change(event) {
      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      var input = event.target;

      if (input.files && input.files[0]) {
        let file = input.files[0];
        var reader = new FileReader();

        reader.onload = event2 => {
          var out = event2.target.result;
          Ember.set(this, 'pastedAnswers', out);
          input.value = '';
        };

        reader.onerror = err => {
          Ember.get(this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.readAsText(file);
      }
    }

  });

  _exports.default = _default;
});