define("shared/components/modal-shell/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-shell/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    containerName: Ember.computed.alias('modalService.modalOpts.containerName'),

    init() {
      this._super(...arguments);

      this.shortcuts.disable();
    },

    willDestroy() {
      this._super(...arguments);

      this.shortcuts.enable();
    }

  });

  _exports.default = _default;
});