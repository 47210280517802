define('ivy-codemirror/services/code-mirror', ['exports', 'codemirror'], function (exports, _codemirror) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      this._instances = Object.create(null);
    },

    fromTextArea(id, textarea) {
      return this.registerInstance(id, _codemirror.default.fromTextArea(textarea));
    },

    instanceFor(id) {
      return this._instances[id];
    },

    registerInstance(id, instance) {
      this._instances[id] = instance;

      return instance;
    },

    signal(emitter, type, ...values) {
      _codemirror.default.signal(emitter, type, ...values);
    },

    unregisterInstance(id) {
      delete this._instances[id];
    }
  });
});