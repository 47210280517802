define("shared/components/input-integer/component", ["exports", "ui/utils/platform", "shared/components/input-integer/template"], function (_exports, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitize = sanitize;
  _exports.default = void 0;

  function sanitize(val) {
    val = `${val}`.trim().replace(/[^0-9-]/g, '');
    val = val.substr(0, 1) + val.substr(1).replace('-', '');
    return val;
  }

  var _default = Ember.TextField.extend({
    layout: _template.default,
    editing: true,
    nullable: false,
    attributeBindings: ['pattern', 'inputmode'],
    pattern: '[0-9]*',
    inputmode: 'numeric',
    type: Ember.computed(() => {
      return _platform.isMobile ? 'number' : 'text';
    }),

    _elementValueDidChange() {
      let val = this.element.value;
      let cur = val;
      val = sanitize(val);
      let num = parseInt(val, 10);
      let max = parseInt(this.get('max'), 10);

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = `${max}`;
      }

      if (cur !== val) {
        this.element.value = val;
      }

      this.set('value', val);
    },

    focusOut() {
      this._super(...arguments);

      let val = this.element.value;
      let cur = val;
      val = sanitize(val);
      let num = parseInt(val, 10);
      let min = parseInt(this.get('min'), 10);

      if (!this.nullable) {
        if (!isNaN(num) && !isNaN(min) && num < min) {
          val = `${min}`;
        }

        if (!isNaN(num)) {
          if (!isNaN(min) && num < min) {
            val = `${min}`;
          }
        } else {
          if (!isNaN(min) && (num < min || isNaN(num))) {
            val = `${min}`;
          }
        }
      }

      if (cur !== val) {
        this.element.value = val;
        this.set('value', val);
      }
    }

  });

  _exports.default = _default;
});