define("shared/components/share-member-row/component", ["exports", "ui/utils/errors", "shared/components/share-member-row/template", "identicon.js", "shared/utils/constants"], function (_exports, _errors, _template, _identicon, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    tagName: '',
    member: null,
    editing: true,
    isPublic: false,
    clusterResource: null,
    users: null,
    principal: null,
    principalId: null,
    principalGravatarSrc: null,

    init() {
      this._super(...arguments);

      const {
        isPublic,
        member
      } = this;

      if (!isPublic && (member.userPrincipalId || member.groupPrincipalId)) {
        const principalId = member.userPrincipalId || member.groupPrincipalId;
        this.globalStore.rawRequest({
          url: `principals/${encodeURIComponent(principalId)}`,
          method: 'GET'
        }).then(xhr => {
          if (xhr.status === 204) {
            return;
          }

          if (xhr.body && typeof xhr.body === 'object') {
            Ember.set(this, 'principal', Ember.set(this, 'external', xhr.body));
            this.principalChanged();
          }

          return xhr;
        }).catch(xhr => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (member.userPrincipalId) {
            Ember.set(this, 'principalId', member.userPrincipalId);
            Ember.set(this, 'principalGravatarSrc', `data:image/png;base64,${new _identicon.default(AWS.util.crypto.md5(member.userPrincipalId || 'Unknown', 'hex'), 80, 0.01).toString()}`);
          }

          return xhr;
        });
      }
    },

    actions: {
      gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },

      addAuthorized(principal) {
        if (principal) {
          let {
            principalType,
            id
          } = principal;

          if (principalType === 'user') {
            Ember.set(this, 'member.userPrincipalId', id);
          } else if (principalType === 'group') {
            Ember.set(this, 'member.groupPrincipalId', id);
          }
        }
      },

      remove() {
        this.remove(this.member);
      }

    },
    choices: Ember.computed('C.CLUSTER_TEMPLATE_ROLES', () => {
      let roles = _constants.default.CLUSTER_TEMPLATE_ROLES;
      return Object.keys(roles).map(key => {
        return {
          label: `shareMemberRow.accessType.${roles[key]}`,
          value: roles[key]
        };
      });
    }),
    noUpdate: Ember.computed('editing', 'principal', 'principalId', function () {
      if (this.editing) {
        if (this.principal || this.principalId) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

    remove() {
      throw new Error('remove is a required action!');
    }

  });

  _exports.default = _default;
});