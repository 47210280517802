define("shared/utils/download-files", ["exports", "shared/utils/fetch-yaml", "shared/utils/util", "jszip", "file-saver"], function (_exports, _fetchYaml, _util, _jszip, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;
  _exports.generateZip = generateZip;
  _exports.downloadResourceYaml = downloadResourceYaml;

  function downloadFile(fileName, content, contentType = 'text/plain;charset=utf-8') {
    const blob = new Blob([content], {
      type: contentType
    });
    (0, _fileSaver.saveAs)(blob, fileName);
  } // [{name: 'file1', file: 'data'}, {name: 'file2', file: 'data2'}]


  function generateZip(files) {
    const zip = new _jszip.default();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      zip.file(file.name, file.file);
    }

    return zip.generateAsync({
      type: 'blob'
    }).then(contents => {
      return contents;
    });
  }

  function downloadResourceYaml(resources) {
    if (!resources.length) {
      return;
    }

    if (resources.length <= 1) {
      let resource = resources[0];
      let yamlLink = resource.links.yaml;

      if (yamlLink) {
        yamlLink = (0, _util.addQueryParam)(yamlLink, 'export', 'true');
        (0, _fetchYaml.default)(yamlLink).then(yaml => {
          downloadFile(`${resource.name}.yaml`, yaml);
        });
      }
    } else {
      let hashRequest = [];

      for (let i = 0; i < resources.length; i++) {
        let resource = resources[i];
        let yamlLink = resource.links.yaml;

        if (yamlLink) {
          yamlLink = (0, _util.addQueryParam)(yamlLink, 'export', 'true');
          hashRequest.push((0, _fetchYaml.default)(yamlLink));
        }
      }

      Ember.RSVP.all(hashRequest).then(data => {
        let files = data.map((ele, index) => {
          return {
            name: `${resources[index].name}.yaml`,
            file: ele
          };
        });
        generateZip(files).then(zip => {
          downloadFile(`${resources[0].type}.zip`, zip, zip.type);
        });
      });
    }
  }
});