define("global-admin/components/form-hostports-policy/component", ["exports", "global-admin/components/form-hostports-policy/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    readOnly: false,
    statusClass: null,
    status: null,

    init() {
      this._super(...arguments);

      this.set('model.hostPorts', this.get('model.hostPorts') || []);
    },

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    actions: {
      add() {
        this.get('model.hostPorts').pushObject(this.get('globalStore').createRecord({
          type: 'hostPortRange',
          min: 6000,
          max: 7000
        }));
      },

      remove(obj) {
        this.get('model.hostPorts').removeObject(obj);
      }

    }
  });

  _exports.default = _default;
});