define("shared/shibboleth-auth/service", ["exports", "shared/utils/constants", "shared/utils/util", "shared/settings/service"], function (_exports, _constants, _util, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    session: Ember.inject.service(),
    access: Ember.inject.service(),
    app: Ember.inject.service(),
    hasToken: null,
    parseIdentity: Ember.observer('hasToken', function () {
      let locToken = this.get('hasToken');

      if (locToken) {
        let userIdent = locToken.userIdentity;
        this.set(`session.${_constants.default.SESSION.IDENTITY}`, userIdent);
      }
    }),

    getToken() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('globalStore').rawRequest({
          url: 'token'
        }).then(xhr => {
          resolve(xhr.body.data[0]);
          return;
        }).catch(err => {
          reject(err);
        });
      });
    },

    buildRedirectUrl(url, test = false) {
      let redirect = url;
      let qp = {
        redirectBackBase: window.location.origin,
        redirectBackPath: '/login/shibboleth-auth/'
      };

      if (test) {
        qp.redirectBackPath = `${qp.redirectBackPath}?shibbolethTest=1`;
      }

      return _util.default.addQueryParams(redirect, qp);
    },

    waitAndRefresh(url) {
      $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line

      setTimeout(() => {
        window.location.href = url || window.location.href;
      }, 1000);
    },

    authorizeRedirect(url) {
      window.location.href = url;
    },

    authenticationSucceeded(model) {
      let url = window.location.href;
      model = model.clone();
      model.setProperties({
        'enabled': true,
        'accessMode': 'restricted',
        'allowedIdentities': []
      });
      model.save().then(() => {
        // for some reason we can't get past here because we've set auth true?
        return this.get('globalStore').find('setting', (0, _service.denormalizeName)(_constants.default.SETTING.API_HOST)).then(setting => {
          if (setting.get('value')) {
            this.waitAndRefresh(url);
          } else {
            // Default the api.host so the user won't have to set it in most cases
            if (window.location.hostname === 'localhost') {
              this.waitAndRefresh(url);
            } else {
              setting.set('value', window.location.origin);
              return setting.save().then(() => {
                this.waitAndRefresh(url);
              });
            }
          }
        });
      }).catch(() => {
        this.set('access.enabled', false);
      });
    }

  });

  _exports.default = _default;
});