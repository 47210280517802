define("global-admin/global-dns/entries/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    beforeModel() {
      return Ember.RSVP.hash({
        allProjects: this.globalStore.findAll('project'),
        allMCApps: this.globalStore.findAll('multiclusterapp')
      });
    },

    model() {
      return this.globalStore.findAll('globaldns');
    }

  });

  _exports.default = _default;
});