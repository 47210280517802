define("shared/components/search-text/component", ["exports", "shared/components/search-text/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    searchFields: ['displayName', 'id:prefix', 'displayState'],
    actions: {
      clearSearch() {
        Ember.set(this, 'searchText', '');
      }

    }
  });

  _exports.default = _default;
});