define("shared/components/form-global-resource-roles/component", ["exports", "shared/components/form-global-resource-roles/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['row'],
    multiClusterApp: null,

    didReceiveAttrs() {
      let {
        roles
      } = this.multiClusterApp;

      if (!roles || roles.length === 0) {
        Ember.run.next(() => {
          this.addRole('project-member', '');
        });
      }
    },

    role: Ember.computed('multiClusterApp.roles.[]', {
      get() {
        let {
          roles = []
        } = this.multiClusterApp;
        return roles.find(role => role === 'project-member' || role === 'cluster-owner');
      },

      set(key, value) {
        this.addRole(value, this.role);
        return value;
      }

    }),
    otherRoles: Ember.computed('multiClusterApp.roles.[]', function () {
      let {
        roles = []
      } = this.multiClusterApp;
      return roles.filter(role => role !== 'cluster-owner' && role !== 'project-member');
    })
  });

  _exports.default = _default;
});