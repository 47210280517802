define("shared/components/storage-class/provisioner-glusterfs/component", ["exports", "shared/components/storage-class/provisioner-glusterfs/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELDS = ['resturl', 'restuser', 'restuserkey', 'secretNamespace', 'secretName', 'clusterid', 'gidMin', 'gidMax', 'volumetype'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'glusterfs',
    fields: FIELDS
  });

  _exports.default = _default;
});