define("global-admin/security/roles/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model(params) {
      const store = Ember.get(this, 'globalStore');
      let role = null;

      if (Ember.get(params, 'type') && Ember.get(params, 'type') === 'global') {
        role = Ember.get(this, 'globalStore').find('globalRole', Ember.get(params, 'role_id'));
      } else {
        role = Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(params.role_id);
      }

      return Ember.RSVP.hash({
        policies: store.find('podSecurityPolicyTemplate'),
        role,
        roles: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(null, null)
      }).then(hash => {
        return {
          policies: hash.policies,
          role: hash.role.clone(),
          roles: hash.roles
        };
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let type = '';

      if (Ember.get(model, 'role.type') === 'globalRole') {
        type = 'global';
      } else if (Ember.get(model, 'role.context') === 'project') {
        type = 'project';
      } else if (Ember.get(model, 'role.context') === 'cluster') {
        type = 'cluster';
      }

      Ember.setProperties(controller, {
        type,
        readOnly: true
      });
    },

    queryParams: {
      type: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});