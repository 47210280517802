define("shared/linode/service", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LINODE_API = 'api.linode.com/v4';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),

    request(auth = {}, command) {
      let url = `${Ember.get(this, 'app.proxyEndpoint')}/`;
      let token = Ember.get(auth, 'token');
      let headers = {
        'Accept': 'application/json'
      };

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', `Bearer credID=${token} passwordField=token`);
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', `Bearer ${token}`);
      }

      url += `${LINODE_API}/${command}`;
      return (0, _fetch.default)(url, {
        headers
      }).then(res => {
        return res.body;
      }).catch(err => {
        return Ember.RSVP.reject(err);
      });
    }

  });

  _exports.default = _default;
});