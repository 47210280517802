define("shared/components/storage-class/provisioner-longhorn/component", ["exports", "shared/components/storage-class/provisioner-longhorn/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_PROVISIONER_CONFIG = {
    numberOfReplicas: '3',
    staleReplicaTimeout: '2880',
    fromBackup: '',
    diskSelector: '',
    nodeSelector: '',
    recurringJobs: ''
  };

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'longhorn',
    mode: 'new',
    defaultProvisionerConfig: DEFAULT_PROVISIONER_CONFIG,
    fields: Object.keys(DEFAULT_PROVISIONER_CONFIG),

    didReceiveAttrs() {
      const {
        defaultProvisionerConfig
      } = this;
      const parameters = Ember.get(this, 'parameters') || {};
      Ember.setProperties(this, {
        model: { ...defaultProvisionerConfig,
          ...parameters
        }
      });
    },

    updateParams() {
      const filteredEntries = Object.entries(Ember.get(this, 'model') || {}).filter(entry => entry[1]);
      Ember.set(this, 'parameters', Object.fromEntries(filteredEntries));
    }

  });

  _exports.default = _default;
});