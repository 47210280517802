define("global-admin/components/new-edit-role/component", ["exports", "ui/utils/constants", "global-admin/components/new-edit-role/template", "shared/mixins/view-new-edit"], function (_exports, _constants, _template, _viewNewEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ruleVerbs = _constants.default.RULE_VERBS.map(verb => `rolesPage.new.form.allow.${verb}`);

  const BASIC_CONTEXT = [{
    label: 'All',
    value: ''
  }, {
    label: 'Project',
    value: 'project'
  }, {
    label: 'Cluster',
    value: 'cluster'
  }];

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    model: null,
    ruleArray: null,
    roleArray: null,
    readOnly: null,
    roleType: null,
    contexts: BASIC_CONTEXT,
    mode: 'new',
    ruleVerbs,
    primaryResource: Ember.computed.alias('model.role'),
    readOnlyOrBuiltIn: Ember.computed.or('readOnly', 'builtIn', 'isView'),

    init() {
      this._super(...arguments);

      const ruleArray = [];
      const model = Ember.get(this, 'primaryResource');
      (Ember.get(model, 'rules') || []).forEach(rule => {
        ruleArray.push(rule);
      });
      const roleArray = (Ember.get(model, 'roleTemplateIds') || []).map(id => {
        return {
          roleId: id
        };
      });
      this.setProperties({
        ruleArray,
        roleArray
      });
    },

    actions: {
      cancel() {
        this.goBack();
      },

      addRule() {
        Ember.get(this, 'ruleArray').pushObject({
          apiGroups: ['*'],
          type: 'policyRule',
          resource: null,
          resources: [],
          verbs: []
        });
      },

      removeRule(obj) {
        Ember.get(this, 'ruleArray').removeObject(obj);
      },

      addOtherRole() {
        Ember.get(this, 'roleArray').pushObject({
          roleId: null
        });
      },

      removeOtherRole(obj) {
        Ember.get(this, 'roleArray').removeObject(obj);
      }

    },
    readableRole: Ember.computed('roleType', function () {
      return (Ember.get(this, 'roleType') || '').capitalize();
    }),
    isDefault: Ember.computed('model.role', 'roleType', {
      get()
      /* key */
      {
        const model = Ember.get(this, 'model.role');
        const type = Ember.get(this, 'roleType');
        const field = this.getDefaultField(type);
        return Ember.get(model, field);
      },

      set(key, value) {
        const model = Ember.get(this, 'model.role');
        const type = Ember.get(this, 'roleType');
        const field = this.getDefaultField(type);
        return model.set(field, value);
      }

    }),
    builtIn: Ember.computed('model.role.builtin', function () {
      return Ember.get(this, 'model.role.builtin') === true;
    }),
    otherRoles: Ember.computed('model.role.id', 'model.roles.@each.id', function () {
      return Ember.get(this, 'model.roles').filter(role => Ember.get(this, 'model.role.id') !== role.id);
    }),
    ruleResources: Ember.computed('model.globalRoles.[]', 'model.roleTemplates.[]', function () {
      const {
        model: {
          globalRoles,
          roles: roleTemplates
        },
        roleType
      } = this;
      let groupedResourceRules;

      switch (roleType) {
        case 'global':
          if (!Ember.isEmpty(globalRoles)) {
            groupedResourceRules = this.getRuleResourceList(globalRoles);
          }

          break;

        default:
          if (!Ember.isEmpty(roleTemplates)) {
            groupedResourceRules = this.getRuleResourceList(roleTemplates.filterBy('context', roleType));
          }

          break;
      }

      return groupedResourceRules;
    }),

    getRuleResourceList(roles) {
      const groupedResourceRules = [];
      roles.forEach(role => {
        if (!Ember.isEmpty(role.rules)) {
          // currently is ungrouped but can be grouped.
          // The problem is that these are just default resources in a particular role,
          // they are not unique so they show up duplicated under different groups.
          // we need some discussion whether this is okay or not
          // const group = role.name;
          role.rules.forEach(rule => {
            if (!Ember.isEmpty(rule.resources)) {
              rule.resources.forEach(resource => {
                if (resource !== '*') {
                  groupedResourceRules.push({
                    // group,
                    label: resource,
                    value: resource
                  });
                }
              });
            }
          });
        }
      });
      return groupedResourceRules.uniqBy('value').sortBy('label');
    },

    getDefaultField(type) {
      let out = '';

      switch (type) {
        case 'global':
          out = 'newUserDefault';
          break;

        case 'cluster':
          out = 'clusterCreatorDefault';
          break;

        case 'project':
          out = 'projectCreatorDefault';
          break;

        default:
          break;
      }

      return out;
    },

    goBack() {
      Ember.get(this, 'router').transitionTo('global-admin.security.roles.index', {
        queryParams: {
          context: Ember.get(this, 'roleType')
        }
      });
    },

    validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if ((Ember.get(this, 'primaryResource.name') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').t('rolesPage.new.errors.nameReq'));
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },

    willSave() {
      const role = Ember.get(this, 'model.role');
      const actualRules = []; // Create the actual rules

      const rules = Ember.get(this, 'ruleArray');
      let obj;

      for (let i = rules.length - 1; i >= 0; i--) {
        obj = rules[i];

        if (!Ember.isEmpty(obj.resources) || !Ember.isEmpty(obj.nonResourceURLs)) {
          const resources = [];
          const out = {
            type: obj.type,
            verbs: obj.verbs ? obj.verbs.slice() : null
          };

          if (!Ember.isEmpty(obj.apiGroups)) {
            out['apiGroups'] = obj.apiGroups.slice();
          }

          if (!Ember.isEmpty(obj.resources)) {
            obj.resources.forEach(r => {
              resources.pushObjects((r || '').split(',').filter(r => r));
            });
            out['resources'] = resources;
          } else if (!Ember.isEmpty(obj.nonResourceURLs)) {
            obj.nonResourceURLs.forEach(r => {
              resources.pushObjects((r || '').split(',').filter(r => r));
            });
            out['nonResourceURLs'] = resources;
          }

          actualRules.push(out);
        } else {
          rules.removeObject(obj);
        }
      }

      role.set('rules', actualRules); // Add roles

      const roles = Ember.get(this, 'roleArray');

      for (let i = roles.length - 1; i >= 0; i--) {
        obj = roles[i];

        if (!obj.roleId) {
          roles.removeObject(obj);
        }
      }

      role.set('roleTemplateIds', roles.map(obj => obj.roleId));
      return this._super(...arguments);
    },

    doneSaving() {
      this.goBack();
    }

  });

  _exports.default = _default;
});