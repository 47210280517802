define("nodes/custom-drivers/node-drivers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        drivers: this.get('globalStore').findAll('nodeDriver')
      }).then(hash => {
        return hash;
      });
    }

  });

  _exports.default = _default;
});