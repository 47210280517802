define("shared/components/modal-edit-driver/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "shared/components/modal-edit-driver/template", "jquery"], function (_exports, _newOrEdit, _modalBase, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    clone: null,
    errors: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    primaryResource: Ember.computed.alias('originalModel'),

    init() {
      this._super(...arguments);

      const clone = Ember.get(this, 'originalModel').clone();
      Ember.setProperties(this, {
        clone,
        model: clone
      });
      Ember.run.scheduleOnce('afterRender', this, 'setupFocus');
    },

    actions: {
      updateWhitelist(list) {
        Ember.set(this, 'primaryResource.whitelistDomains', list);
      }

    },
    driverType: Ember.computed('model.type', function () {
      return Ember.get(this, 'model.type') === 'nodeDriver' ? 'Node' : 'Cluster';
    }),
    editing: Ember.computed('clone.id', function () {
      return !!Ember.get(this, 'clone.id');
    }),

    doneSaving() {
      this.send('cancel');
    },

    setupFocus() {
      Ember.run.next(() => (0, _jquery.default)(this.element).find('input:first')[0].focus()); // gotta make sure that modal is rendered
    }

  });

  _exports.default = _default;
});