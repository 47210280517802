define("ember-asset-loader/loaders/js", ["exports", "ember-asset-loader/loaders/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Default loader function for JS assets. Loads them by inserting a script tag
   * with onload/onerror handlers that correspond to the resolve/reject callbacks
   * of the return Promise.
   *
   * @param {String} uri
   * @return {Promise}
   */
  var _default = (0, _utilities.nodeLoader)(function js(uri) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${uri}"]`)) {
        return resolve();
      }

      const script = (0, _utilities.createLoadElement)('script', resolve, function (error) {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }

        reject(error);
      });
      script.src = uri;
      script.async = false;
      document.head.appendChild(script);
    });
  });

  _exports.default = _default;
});