define("shared/mixins/crud-catalog", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    router: Ember.inject.service(),
    app: null,
    appName: null,
    nsName: null,
    appVersion: null,
    cluster: null,
    project: null,
    timeOutAnchor: null,
    enabled: false,
    ready: false,
    saved: false,
    confirmDisable: false,
    forceUpgrade: false,

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        enabled: !!Ember.get(this, 'app') && Ember.get(this, 'app.state') !== 'removing',
        ready: !!Ember.get(this, 'app') && _constants.default.ACTIVEISH_STATES.includes(Ember.get(this, 'app.state'))
      });
      this.startAppStatusChecker();
    },

    actions: {
      disable() {
        const url = Ember.get(this, 'app.links.self');
        Ember.get(this, 'globalStore').rawRequest({
          url,
          method: 'DELETE'
        }).then(() => {
          setTimeout(() => {
            window.location.href = window.location.href; // eslint-disable-line no-self-assign
          }, 1000);
        }).catch(err => {
          this.showError(err);
        });
      },

      promptDisable() {
        Ember.set(this, 'confirmDisable', true);
        Ember.run.later(this, function () {
          Ember.set(this, 'confirmDisable', false);
        }, 10000);
      }

    },

    startAppStatusChecker() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const timeOutAnchor = setTimeout(() => {
        this.queryStatus();
      }, 30000);
      Ember.set(this, 'timeOutAnchor', timeOutAnchor);
    },

    queryStatus() {
      const url = Ember.get(this, 'app.links.self');

      if (url) {
        Ember.get(this, 'globalStore').rawRequest({
          url,
          method: 'GET'
        }).then(res => {
          const app = Ember.get(res, 'body');
          Ember.setProperties(this, {
            enabled: Ember.get(app, 'state') !== 'removing',
            ready: _constants.default.ACTIVEISH_STATES.includes(Ember.get(app, 'state')),
            app: Ember.get(this, 'globalStore').createRecord({
              type: 'app',
              ...app
            })
          });
        }).finally(() => {
          this.startAppStatusChecker();
        });
      } else {
        Ember.set(this, 'ready', false);
      }
    },

    save(cb, answers, refresh = false) {
      const customAnswers = Ember.get(this, 'customAnswers') || {};
      Object.keys(customAnswers).forEach(key => {
        answers[key] = customAnswers[key];
      });
      Ember.set(this, 'answers', answers);

      if (Ember.get(this, 'enabled')) {
        this.update(cb);

        if (Ember.get(this, 'actions.upgrade')) {
          this.send('upgrade');
        }
      } else {
        this.create(cb);
      }

      if (refresh) {
        this.refresh();
      }
    },

    update(cb) {
      const {
        templateVersion,
        app
      } = this;
      const {
        externalIdInfo
      } = app;
      const currentVersion = Ember.get(this, 'apps.firstObject.externalIdInfo.version');

      if (!templateVersion || !currentVersion) {
        return cb(false);
      }

      const data = {
        answers: Ember.get(this, 'answers'),
        externalId: Ember.get(app, 'externalId').replace(`version=${Ember.get(externalIdInfo, 'version')}`, `version=${templateVersion}`)
      };

      if (this.forceUpgrade) {
        Ember.set(data, 'forceUpgrade', true);
      }

      Ember.get(this, 'globalStore').rawRequest({
        url: Ember.get(this, 'app.actionLinks.upgrade'),
        method: 'POST',
        data
      }).then(() => {
        Ember.set(this, 'saved', true);
      }).catch(err => {
        this.showError(err);
      }).finally(() => {
        cb();
      });
    },

    create(cb) {
      let promise;

      if (Ember.get(this, 'nsExists')) {
        if (Ember.get(this, 'nsNeedMove')) {
          promise = this.moveNamespace(cb);
        } else {
          promise = Ember.RSVP.resolve();
        }
      } else {
        promise = this.createNamespace(cb);
      }

      promise.then(() => {
        Ember.get(this, 'globalStore').rawRequest({
          url: `/v3/projects/${Ember.get(this, 'project.id')}/app`,
          method: 'POST',
          data: {
            answers: Ember.get(this, 'answers'),
            externalId: Ember.get(this, 'appVersion'),
            name: Ember.get(this, 'appName'),
            projectId: Ember.get(this, 'project.id'),
            targetNamespace: Ember.get(this, 'nsName')
          }
        }).then(res => {
          Ember.setProperties(this, {
            enabled: true,
            app: Ember.get(this, 'globalStore').createRecord({ ...res.body,
              type: 'app'
            })
          });
          Ember.set(this, 'saved', true);

          if (this.doneSaving) {
            this.doneSaving();
          }
        }).catch(err => {
          this.showError(err);
        }).finally(() => {
          cb();
        });
      });
    },

    createNamespace(cb) {
      return Ember.get(this, 'globalStore').rawRequest({
        url: `/v3/clusters/${Ember.get(this, 'cluster.id')}/namespace`,
        method: 'POST',
        data: {
          name: Ember.get(this, 'nsName'),
          projectId: Ember.get(this, 'project.id')
        }
      }).catch(err => {
        this.showError(err);
        cb();
      });
    },

    moveNamespace(cb) {
      return Ember.get(this, 'globalStore').rawRequest({
        url: `/v3/clusters/${Ember.get(this, 'cluster.id')}/namespace/${Ember.get(this, 'namespace.id')}?action=move`,
        method: 'POST',
        data: {
          projectId: Ember.get(this, 'project.id')
        }
      }).catch(err => {
        this.showError(err);
        cb();
      });
    },

    willDestroyElement() {
      this.clearTimeOut();

      this._super();
    },

    clearTimeOut() {
      const timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    },

    refresh() {
      const currentRouteName = Ember.get(this, 'router.currentRouteName');
      const routeAddons = ['global-admin.'];
      let routeName = currentRouteName;
      routeAddons.map(r => {
        if (currentRouteName.startsWith(r)) {
          routeName = currentRouteName.replace(r, '');
        }
      });
      const currentRouteInstance = Ember.getOwner(this).lookup(`route:${routeName}`);
      currentRouteInstance.send('refresh');
    },

    showError(err) {
      Ember.get(this, 'growl').fromError('Error', Ember.get(err, 'body.message'));
    }

  });

  _exports.default = _default;
});