define("shared/helpers/join-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, options) {
      let separator = options.separator || ', ';
      return (params[0] || []).join(separator);
    }

  });

  _exports.default = _default;
});