define("shared/mixins/lazy-icon", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    srcSet: false,
    genericIconPath: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'genericIconPath', `${Ember.get(this, 'app.baseAssets')}assets/images/generic-catalog.svg`);
    },

    initAppIcon() {
      if (!Ember.get(this, 'srcSet')) {
        Ember.set(this, 'srcSet', true);
        const $icon = (0, _jquery.default)(this.element).find('.catalog-icon > img');
        const $srcPath = $icon.attr('src');

        if ($srcPath === this.genericIconPath) {
          $icon.attr('src', $icon.data('src'));
          const img = (0, _jquery.default)(this.element).find('img');
          img.on('error', () => {
            if (this.isDestroyed || this.isDestroying) {
              if (img) {
                img.off('error');
              }

              return;
            }

            $icon.attr('src', this.genericIconPath);
          });
        }
      }
    }

  });

  _exports.default = _default;
});