define("global-admin/components/new-multi-cluster-app/component", ["exports", "ui/utils/errors", "shared/mixins/new-or-edit", "ui/utils/constants", "ui/utils/util", "ember-concurrency", "yamljs", "global-admin/components/new-multi-cluster-app/template", "shared/utils/evaluate", "shared/mixins/catalog-app", "jquery"], function (_exports, _errors, _newOrEdit, _constants, _util, _emberConcurrency, _yamljs, _template, _evaluate, _catalogApp, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OVERRIDE_HEADERS = [{
    translationKey: 'newMultiClusterApp.overrides.table.scope',
    name: 'scope',
    sort: ['scope']
  }, {
    translationKey: 'newMultiClusterApp.overrides.table.question',
    name: 'question',
    sort: ['question']
  }, {
    translationKey: 'newMultiClusterApp.overrides.table.answer',
    name: 'answer',
    sort: ['answer']
  }];

  var _default = Ember.Component.extend(_newOrEdit.default, _catalogApp.default, {
    catalog: Ember.inject.service(),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    allTemplates: null,
    templateResource: null,
    versionsArray: null,
    versionsLinks: null,
    actuallySave: true,
    showHeader: true,
    showPreview: true,
    decoding: false,
    upgradeStrategy: false,
    titleAdd: 'newCatalog.titleAdd',
    titleUpgrade: 'newCatalog.titleUpgrade',
    selectVersionAdd: 'newCatalog.selectVersionAdd',
    selectVersionUpgrade: 'newCatalog.selectVersionUpgrade',
    saveUpgrade: 'newCatalog.saveUpgrade',
    saveNew: 'newCatalog.saveNew',
    sectionClass: 'box mb-20',
    showDefaultVersionOption: false,
    classNames: ['launch-catalog', 'launch-multicluster-app'],
    multiClusterApp: null,
    srcSet: false,
    detailExpanded: false,
    previewOpen: false,
    previewTab: null,
    questionsArray: null,
    selectedTemplateUrl: null,
    selectedTemplateModel: null,
    readmeContent: null,
    appReadmeContent: null,
    pastedAnswers: null,
    noAppReadme: null,
    selectedFileContetnt: null,
    answerOverrides: null,
    projects: null,
    clusters: null,
    isClone: false,
    projectsToAddOnUpgrade: null,
    projectsToRemoveOnUpgrade: null,
    editable: null,
    mcAppIsSaving: false,
    overridesHeaders: OVERRIDE_HEADERS,
    isGKE: Ember.computed.alias('scope.currentCluster.isGKE'),
    primaryResource: Ember.computed.alias('multiClusterApp'),
    editing: Ember.computed.notEmpty('primaryResource.id'),
    isLonghorn: Ember.computed.equal('templateResource.id', _constants.default.STORAGE.LONGHORN_CATALOG_TEMPLATE_ID),

    init() {
      this._super(...arguments);

      Ember.set(this, 'editable', {
        selectedTemplateUrl: null,
        multiClusterApp: {
          targets: []
        }
      });
      this.initAttrs();
      this.initUpgradeStrategy();
      Ember.run.scheduleOnce('afterRender', this, this.setupComponent);

      if (Ember.get(this, 'multiClusterApp.targets')) {
        Ember.set(this, 'editable.multiClusterApp.targets', [...Ember.get(this, 'multiClusterApp.targets')]);
      }
    },

    didRender() {
      this.initCatalogIcon();
    },

    actions: {
      addTarget(targetIn) {
        if (targetIn && !Ember.get(targetIn, 'type')) {
          const {
            editing,
            projectsToAddOnUpgrade,
            projectsToRemoveOnUpgrade
          } = this;
          const multiClusterApp = this.editable.multiClusterApp;
          let target = null;
          let toRemoveMatch = (projectsToRemoveOnUpgrade || []).findBy('projectId', Ember.get(targetIn, 'value'));

          if (toRemoveMatch) {
            // a project was remove then re-added
            this.projectsToRemoveOnUpgrade.removeObject(targetIn);
            target = toRemoveMatch;
          } else {
            target = this.globalStore.createRecord({
              type: 'target',
              projectId: Ember.get(targetIn, 'value')
            });
          }

          if (editing) {
            projectsToAddOnUpgrade.pushObject(target);
          }

          if (multiClusterApp.targets) {
            multiClusterApp.targets.pushObject(target);
          } else {
            Ember.set(multiClusterApp, 'targets', [target]);
          }
        }
      },

      removeTarget(target) {
        const {
          editing,
          projectsToRemoveOnUpgrade,
          projectsToAddOnUpgrade
        } = this;
        let targetToAddMatch = (projectsToAddOnUpgrade || []).findBy('projectId', Ember.get(target, 'projectId'));

        if (targetToAddMatch) {
          // a project was added then removed
          this.projectsToAddOnUpgrade.removeObject(targetToAddMatch);
        } else {
          if (editing) {
            projectsToRemoveOnUpgrade.pushObject(target);
          }
        }

        Ember.get(this, 'editable.multiClusterApp.targets').removeObject(target);
      },

      addRole(roleId, roleToRemove) {
        let {
          roles
        } = this.multiClusterApp;

        if (!roles) {
          roles = [];
        }

        if (roles.indexOf(roleToRemove) > -1) {
          roles.removeObject(roleToRemove);
        }

        if (roleId !== '') {
          roles.pushObject(roleId);
          Ember.set(this, 'multiClusterApp.roles', roles);
        } else {
          // its possible that the user set extra roles via the api, we shouldn't clobber those roles as well.
          if (roles.length > 1) {
            Ember.set(this, 'multiClusterApp.roles', roles);
          } else {
            Ember.set(this, 'multiClusterApp.roles', null);
          }
        }
      },

      addAuthorizedPrincipal(principal) {
        if (principal) {
          let {
            members = []
          } = this.multiClusterApp;

          if (!members) {
            members = [];
          }

          members.pushObject(principal);
          Ember.set(this, 'multiClusterApp.members', members);
        }
      },

      removeMember(member) {
        let {
          members
        } = this.multiClusterApp;
        members.removeObject(member);
      },

      gotError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);
      },

      addAnswerOverride() {
        let {
          answerOverrides
        } = this;
        let nueOverride = {
          scope: null,
          question: null,
          answer: null,
          isSubQuestion: false
        };

        if (answerOverrides) {
          answerOverrides.pushObject(nueOverride);
        } else {
          answerOverrides = [nueOverride];
        }

        Ember.set(this, 'answerOverrides', answerOverrides);
      },

      removeAnswerOverride(answer) {
        this.answerOverrides.removeObject(answer);
      },

      addDependentSubQuestions(answers) {
        let {
          answerOverrides
        } = this;
        answerOverrides.pushObjects(answers);
      },

      removeDependentSubQuestions(answers) {
        let {
          answerOverrides
        } = this;
        answerOverrides.removeObjects(answers);
      },

      toogleDetailedDescriptions() {
        Ember.set(this, 'detailExpanded', true);
      },

      cancel() {
        if (this.cancel) {
          this.cancel();
        }
      },

      togglePreview() {
        this.toggleProperty('previewOpen');
      },

      selectPreviewTab(tab) {
        Ember.set(this, 'previewTab', tab);
      }

    },
    updateAnswerOverrides: Ember.observer('selectedTemplateModel', 'multiClusterApp.answers.@each.{values}', function () {
      if (this.mcAppSaving) {
        return;
      }

      let {
        selectedTemplateModel = {}
      } = this;
      const questions = Ember.get(selectedTemplateModel, 'questions');
      const customAnswers = questions ? Ember.get(selectedTemplateModel, 'customAnswers') : Ember.get(this, 'multiClusterApp.answers');
      const answerOverrides = [];
      Object.keys(customAnswers).forEach(customAnswerKey => {
        let answer = Ember.get(customAnswers, customAnswerKey);

        if (isGlobalAnswersCollection(answer)) {
          if (this.isClone || this.editing) {
            Object.keys(answer.values).forEach(valueKey => {
              (questions || []).forEach(q => {
                if (Ember.get(q, 'variable') === valueKey) {
                  customAnswers[customAnswerKey];
                  let allAnswersFromInput = Ember.get(customAnswers, `${customAnswerKey}.values`);
                  let answerFromInput = allAnswersFromInput ? allAnswersFromInput[valueKey] : null;

                  try {
                    answerFromInput = JSON.parse(answerFromInput);
                  } catch (e) {}

                  Ember.set(q, 'answer', answerFromInput);
                } else if (Ember.get(q, 'subquestions') && Ember.get(q, 'subquestions').findBy('variable', valueKey)) {
                  let sqMatch = Ember.get(q, 'subquestions').findBy('variable', valueKey);
                  let allAnswersFromInput = Ember.get(customAnswers, `${customAnswerKey}.values`);
                  let answerFromInput = allAnswersFromInput ? allAnswersFromInput[valueKey] : null;
                  Ember.set(sqMatch, 'answer', answerFromInput);
                }
              });
            });
          }
        } else {
          Object.keys(answer.values).forEach(valueKey => {
            let isSubQuestion = false;
            (questions || []).forEach(q => {
              if (Ember.get(q, 'subquestions') && Ember.get(q, 'subquestions').findBy('variable', valueKey)) {
                isSubQuestion = true;
              }
            });
            let nueOverride = {
              scope: answer.clusterId || answer.projectId,
              answer: answer.values[valueKey] ? answer.values[valueKey] : null,
              question: valueKey,
              isSubQuestion
            };
            answerOverrides.pushObject(nueOverride);
          });
        }
      });
      Ember.set(this, 'answerOverrides', answerOverrides);

      function isGlobalAnswersCollection(answer) {
        if (Ember.isEmpty(answer.clusterId) && Ember.isEmpty(answer.projectId)) {
          return true;
        } else {
          return false;
        }
      }
    }),
    upgradeStrategyChanged: Ember.observer('upgradeStrategy', function () {
      const {
        upgradeStrategy,
        multiClusterApp,
        globalStore
      } = this;

      if (upgradeStrategy) {
        Ember.set(multiClusterApp, 'upgradeStrategy', globalStore.createRecord({
          type: 'upgradeStrategy',
          rollingUpdate: globalStore.createRecord({
            type: 'rollingUpdate',
            batchSize: 1,
            interval: 1
          })
        }));
      } else {
        Ember.set(multiClusterApp, 'upgradeStrategy', null);
      }
    }),
    templateOrHelmChartQuestions: Ember.computed('selectedTemplateModel', 'selectedTemplateModel.allQuestions.@each.{answer,variable}', function () {
      let {
        selectedTemplateModel,
        multiClusterApp
      } = this;
      let nueQuestions = [];

      if (Ember.get(selectedTemplateModel, 'questions')) {
        return Ember.get(selectedTemplateModel, 'questions');
      } else {
        if (Ember.get(multiClusterApp, 'answers.firstObject.values')) {
          let helmQuestions = Ember.get(multiClusterApp, 'answers.firstObject.values');
          nueQuestions = Object.keys(helmQuestions).map(qk => {
            return {
              variable: qk,
              answer: helmQuestions[qk]
            };
          });
        }

        return nueQuestions;
      }
    }),
    answers: Ember.computed('templateOrHelmChartQuestions.@each.{variable,answer}', function () {
      const out = {};
      const allQuestions = [];
      (Ember.get(this, 'templateOrHelmChartQuestions') || []).forEach(item => {
        allQuestions.push(item);
        (Ember.get(item, 'subquestions') || []).forEach(sub => {
          allQuestions.push(sub);
        });
      });
      const filteredQuestions = allQuestions.filter(q => (0, _evaluate.evaluate)(q, allQuestions));
      filteredQuestions.forEach(item => {
        out[item.variable] = (0, _evaluate.stringifyAnswer)(item.answer);
      });
      return out;
    }),
    answersArray: Ember.computed('primaryResource.answers.firstObject.values', 'selectedTemplateModel.{customAnswers,questions}', function () {
      const model = Ember.get(this, 'selectedTemplateModel');

      if (Ember.get(model, 'questions')) {
        const questions = [];
        (Ember.get(this, 'selectedTemplateModel.questions') || []).forEach(q => {
          questions.push(q);
          const subquestions = Ember.get(q, 'subquestions');

          if (subquestions) {
            questions.pushObjects(subquestions);
          }
        });
        const customAnswers = Ember.get(this, 'selectedTemplateModel.customAnswers') || {};
        Object.keys(customAnswers).forEach(key => {
          questions.push({
            variable: key,
            answer: customAnswers[key]
          });
        });
        return questions;
      } else {
        return Ember.get(this, 'primaryResource.answers.firstObject.values');
      }
    }),
    answersString: Ember.computed('answersArray.@each.{answer,variable}', 'selectedTemplateModel', function () {
      const model = Ember.get(this, 'selectedTemplateModel');

      if (Ember.get(model, 'questions')) {
        let neu = {};
        (Ember.get(this, 'answersArray') || []).filter(a => typeof a.answer !== 'object').forEach(a => {
          neu[a.variable] = Ember.isEmpty(a.answer) ? a.default : a.answer;
        });
        const customAnswers = Ember.get(model, 'customAnswers') || {};
        Object.keys(customAnswers).forEach(key => {
          if (typeof customAnswers[key] !== 'object') {
            neu[key] = customAnswers[key];
          }
        });
        return _yamljs.default.stringify(neu);
      } else {
        return JSON.stringify(Ember.get(this, 'answersArray'));
      }
    }),
    allProjectsAndClustersUngrouped: Ember.computed('clusters', 'primaryResource.targets.@each.projectId', 'projects.[]', function () {
      let out = [];
      Ember.get(this, 'clusters').forEach(c => {
        out.pushObject({
          name: Ember.get(c, 'name'),
          value: Ember.get(c, 'id'),
          isCluster: true
        });
        c.get('projects').forEach(p => {
          out.pushObject({
            name: Ember.get(p, 'name'),
            value: Ember.get(p, 'id'),
            isProject: true
          });
        });
      });
      return out;
    }),
    getTemplate: (0, _emberConcurrency.task)(function* () {
      let url = Ember.get(this, 'editable.selectedTemplateUrl');

      if (url === 'default') {
        let defaultUrl = Ember.get(this, 'defaultUrl');

        if (defaultUrl) {
          url = defaultUrl;
        } else {
          url = null;
        }
      }

      if (url) {
        let version = Ember.get(this, 'settings.rancherVersion');

        if (version) {
          url = _util.default.addQueryParam(url, 'rancherVersion', version);
        }

        let current = Ember.get(this, 'primaryResource.answers');

        if (!current) {
          current = {};
          Ember.set(this, 'primaryResource.answers', current);
        }

        var selectedTemplateModel = yield Ember.get(this, 'catalog').fetchByUrl(url).then(response => {
          if (response.questions) {
            const questions = [];
            const customAnswers = {};
            response.questions.forEach(q => {
              questions.push(q);
              const subquestions = Ember.get(q, 'subquestions');

              if (subquestions) {
                questions.pushObjects(subquestions);
              }
            });
            questions.forEach(item => {
              // This will be the component that is rendered to edit this answer
              item.inputComponent = `schema/input-${item.type}`; // Only types marked supported will show the component, Ember will explode if the component doesn't exist

              item.supported = _constants.default.SUPPORTED_SCHEMA_INPUTS.indexOf(item.type) >= 0;

              if (typeof current[item.variable] !== 'undefined') {
                // If there's an existing value, use it (for upgrade)
                item.answer = current[item.variable];
              } else if (item.type === 'service' || item.type === 'certificate') {// Loaded async and then the component picks the default
              } else if (item.type === 'boolean') {
                // Coerce booleans
                item.answer = item.default === 'true' || item.default === true;
              } else {
                // Everything else
                item.answer = item.default || null;
              }
            });
            Object.keys(current).forEach(key => {
              const q = questions.findBy('variable', key);

              if (!q) {
                customAnswers[key] = current[key];
              }
            });
            response.customAnswers = customAnswers;
          }

          return response;
        });
        Ember.setProperties(this, {
          selectedTemplateModel,
          'primaryResource.templateVersionId': selectedTemplateModel.id
        });
        const files = Object.keys(selectedTemplateModel.get('files')) || [];

        if (files.length > 0) {
          const valuesYaml = files.find(file => file.endsWith('/values.yaml'));
          Ember.set(this, 'previewTab', valuesYaml ? valuesYaml : files[0]);
        }
      } else {
        Ember.setProperties(this, {
          selectedTemplateModel: null,
          readmeContent: null,
          appReadmeContent: null,
          noAppReadme: false
        });
      }

      this.updateReadme();
    }),

    validate() {
      this._super(...arguments);

      const errors = Ember.get(this, 'errors') || [];
      errors.pushObjects(Ember.get(this, 'selectedTemplateModel').validationErrors(this.answers) || []);
      errors.pushObjects(this.validateTargetsProjectIds());
      Ember.set(this, 'errors', errors.uniq());
      return errors.length === 0;
    },

    validateTargetsProjectIds() {
      let errors = [];
      let targets = Ember.get(this, 'editable.multiClusterApp.targets');

      if (targets && targets.length >= 1) {
        targets.forEach(target => {
          if (!Ember.get(target, 'projectId')) {
            errors.push(this.intl.t('validation.targets.missingProjectId'));
          }
        });
      }

      return errors;
    },

    willSave() {
      Ember.set(this, 'errors', null);
      const {
        primaryResource
      } = this;
      Ember.set(primaryResource, 'targets', this.editable.multiClusterApp.targets);
      Ember.set(primaryResource, 'answers', this.buildAnswerMap());
      const ok = this.validate();

      if (!ok) {
        // Validation failed
        return false;
      }

      if (Ember.get(this, 'actuallySave')) {
        if (this.editing) {
          return this.doProjectActions();
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    doProjectActions() {
      const {
        primaryResource
      } = this;
      const {
        projectsToAddOnUpgrade,
        projectsToRemoveOnUpgrade
      } = this;
      const promises = [];
      const toAdd = (projectsToAddOnUpgrade || []).map(p => Ember.get(p, 'projectId')).uniq();
      const toRemove = (projectsToRemoveOnUpgrade || []).map(p => Ember.get(p, 'projectId')).uniq();
      const filteredToAdd = toAdd.filter(id => !toRemove.includes(id));
      const filteredToRemove = toRemove.filter(id => !toAdd.includes(id));

      if (filteredToAdd.length > 0) {
        promises.push(primaryResource.doAction('addProjects', {
          projects: filteredToAdd
        }));
      }

      if (filteredToRemove.length > 0) {
        promises.push(primaryResource.doAction('removeProjects', {
          projects: filteredToRemove
        }));
      }

      if (promises.length > 0) {
        return Ember.RSVP.all(promises).then(() => {
          return true;
        }).catch(() =>
        /* handled by growl error */
        {
          return false;
        });
      } else {
        return true;
      }
    },

    doneSaving() {
      return this.router.transitionTo('global-admin.multi-cluster-apps');
    },

    buildAnswerMap() {
      const {
        globalStore,
        answers,
        answerOverrides
      } = this;
      let answer = {
        type: 'answer',
        clusterId: null,
        projectId: null,
        values: null
      };
      let out = [];
      let globalAnswers = answer;
      Ember.set(globalAnswers, 'values', answers);
      out.pushObject(globalStore.createRecord(globalAnswers));

      if (answerOverrides && answerOverrides.length > 0) {
        answerOverrides.forEach(override => {
          let outMatch = out.findBy('clusterId', override.scope) || out.findBy('projectId', override.scope);
          let questionVariable = Ember.get(override, 'question.variable') ? override.question.variable : override.question;

          if (outMatch) {
            outMatch.values[questionVariable] = (0, _evaluate.stringifyAnswer)(override.answer);
          } else {
            let newOverrideAnswer = {
              type: 'answer',
              clusterId: null,
              projectId: null,
              values: {}
            };
            let overrideScope = Ember.get(this, 'allProjectsAndClustersUngrouped').findBy('value', override.scope);

            if (Ember.get(overrideScope, 'isProject')) {
              Ember.set(newOverrideAnswer, 'projectId', override.scope);
            }

            if (Ember.get(overrideScope, 'isCluster')) {
              Ember.set(newOverrideAnswer, 'clusterId', override.scope);
            }

            newOverrideAnswer.values[questionVariable] = (0, _evaluate.stringifyAnswer)(override.answer);
            out.pushObject(globalStore.createRecord(newOverrideAnswer));
          }
        });
      }

      return out;
    },

    initAttrs() {
      Ember.setProperties(this, {
        selectedTemplateModel: null,
        projectsToAddOnUpgrade: [],
        projectsToRemoveOnUpgrade: []
      });
    },

    initCatalogIcon() {
      if (!this.get('srcSet')) {
        Ember.set(this, 'srcSet', true);
        const $icon = (0, _jquery.default)('img');
        $icon.attr('src', $icon.data('src'));
        (0, _jquery.default)('img').on('error', () => {
          $icon.attr('src', `${this.get('app.baseAssets')}assets/images/generic-catalog.svg`);
        });
      }
    },

    initSelectedTemplateModel() {
      let def = Ember.get(this, 'templateResource.defaultVersion');
      const latest = Ember.get(this, 'templateResource.latestVersion');
      const current = Ember.get(this, 'primaryResource.externalIdInfo.version');
      const links = Ember.get(this, 'versionLinks');
      Ember.set(this, 'selectedTemplateUrl', links[current] || links[def] || links[latest] || null);
    },

    initUpgradeStrategy() {
      const {
        multiClusterApp
      } = this;

      if (Ember.get(multiClusterApp, 'upgradeStrategy.rollingUpdate')) {
        Ember.set(this, 'upgradeStrategy', true);
      }
    },

    setupComponent() {
      if (Ember.get(this, 'selectedTemplateUrl')) {
        this.templateChanged();
      } else {
        this.initSelectedTemplateModel();
      }

      Ember.set(this, 'editable.selectedTemplateUrl', Ember.get(this, 'selectedTemplateUrl'));
    }

  });

  _exports.default = _default;
});