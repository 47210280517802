define("global-admin/security/authentication/azuread/controller", ["exports", "ui/utils/constants", "global-admin/mixins/authentication"], function (_exports, _constants, _authentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, {
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    azureAd: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    confirmDisable: false,
    testing: false,
    editing: false,
    errors: null,
    error: null,
    region: null,
    _boundSucceed: null,
    authConfig: Ember.computed.alias('model.azureADConfig'),
    actions: {
      test() {
        this.send('clearError');
        const model = Ember.get(this, 'authConfig');
        const am = Ember.get(this, 'authConfig.accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am,
          rancherUrl: `${window.location.origin}/verify-auth-azure`
        });
        var errors = model.validationErrors();

        if (errors.get('length')) {
          Ember.set(model, 'enabled', false);
          Ember.setProperties(this, {
            errors,
            testing: false
          });
        } else {
          Ember.set(this, 'testing', true);
          Ember.get(this, 'azureAd').testConfig(model).then(resp => {
            const redirectUrl = Ember.get(resp, 'redirectUrl');

            if (redirectUrl) {
              Ember.set(this, '_boundSucceed', this.authenticationApplied.bind(this));
              Ember.get(this, 'azureAd').test(model, redirectUrl, Ember.get(this, '_boundSucceed'));
            }
          }).catch(err => {
            Ember.set(model, 'enabled', false);
            this.send('gotError', err);
          });
        }
      },

      updateEndpoints() {
        this.get('modalService').toggleModal('modal-update-azuread', {
          azureAd: Ember.get(this, 'azureAd'),
          model: Ember.get(this, 'authConfig')
        });
      }

    },
    numUsers: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_AZURE_USER)).get('length');
    }),
    numGroups: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(principal => principal.includes(_constants.default.PROJECT.TYPE_AZURE_GROUP)).get('length');
    }),
    replyUrl: Ember.computed(() => {
      return `${window.location.origin}/verify-auth-azure`;
    }),
    needsEndpointUpdate: Ember.computed('authConfig.annotations', 'isEnabled', function () {
      return Ember.get(this, 'isEnabled') && (Ember.get(this, 'authConfig.annotations') || {})[_constants.default.AZURE_AD.ANNOTATION_MIGRATED] !== 'true';
    }),

    authenticationApplied(err) {
      Ember.set(this, 'saving', false);

      if (err) {
        Ember.set(this, 'isEnabled', false);
        this.send('gotError', err);
        return;
      }

      this.send('clearError');
    }

  });

  _exports.default = _default;
});