define("shared/components/form-match-expressions/component", ["exports", "shared/components/form-match-expressions/template", "shared/utils/constants", "jquery"], function (_exports, _template, _constants, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EXISTS = 'Exists';
  const DOES_NOT_EXISTS = 'DoesNotExist';

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    initialArray: null,
    editing: true,
    addActionLabel: 'formMatchExpressions.addAction',
    keyLabel: 'formMatchExpressions.key.label',
    valueLabel: 'formMatchExpressions.value.label',
    keyPlaceholder: 'formMatchExpressions.key.placeholder',
    valuePlaceholder: 'formMatchExpressions.value.placeholder',

    init() {
      this._super(...arguments);

      const ary = [];

      if (Ember.get(this, 'initialArray')) {
        Ember.get(this, 'initialArray').forEach(line => {
          ary.push({
            key: Ember.get(line, 'key'),
            operator: Ember.get(line, 'operator'),
            values: (Ember.get(line, 'values') || []).join(',')
          });
        });
      }

      Ember.set(this, 'ary', ary);
    },

    actions: {
      add() {
        let ary = Ember.get(this, 'ary');
        ary.pushObject({
          key: '',
          operator: EXISTS
        });
        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          let elem = (0, _jquery.default)('INPUT.key').last()[0];

          if (elem) {
            elem.focus();
          }
        });
      },

      remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      }

    },
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,operator,values}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),
    operatorChoices: _constants.default.VOLUME_NODE_SELECTOR_OPERATOR,

    fireChanged() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      let arr = [];
      Ember.get(this, 'ary').forEach(row => {
        if ([EXISTS, DOES_NOT_EXISTS].indexOf(Ember.get(row, 'operator')) > -1) {
          arr.pushObject({
            key: Ember.get(row, 'key'),
            operator: Ember.get(row, 'operator')
          });
        } else {
          if (Ember.get(row, 'values')) {
            arr.pushObject({
              key: Ember.get(row, 'key'),
              operator: Ember.get(row, 'operator'),
              values: Ember.get(row, 'values').split(',') || []
            });
          }
        }
      });
      Ember.run.next(() => {
        this.sendAction('changed', arr);
      });
    }

  });

  _exports.default = _default;
});