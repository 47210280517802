define("shared/helpers/driver-name", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params
    /* , options*/
    ) {
      const driver = params[0] || '';
      const intl = Ember.get(this, 'intl');
      const key = `nodeDriver.displayName.${driver.toLowerCase()}`;
      let name = (0, _util.ucFirst)(driver);

      if (intl.exists(key)) {
        name = intl.t(key);
      }

      return name;
    }

  });

  _exports.default = _default;
});