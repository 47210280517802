define("global-admin/security/authentication/shibboleth/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      let gs = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        authConfig: gs.find('authconfig', 'shibboleth', {
          forceReload: true
        }),
        principals: gs.all('principal')
      });
    },

    resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }

  });

  _exports.default = _default;
});