define("shared/components/modal-update-azuread/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0OFSEaIR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"authPage.azuread.updateEndpoint.modal.title\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"display-name\"],[12],[2,\"\\n    \"],[1,[35,1,[\"id\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"authPage.azuread.updateEndpoint.modal.body\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"confirm\"],null],[12],[1,[30,[36,0],[\"authPage.azuread.updateEndpoint.modal.update\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-transparent\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"cancel\"],null],[12],[1,[30,[36,0],[\"generic.cancel\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"authConfig\",\"action\"]}",
    "meta": {
      "moduleName": "shared/components/modal-update-azuread/template.hbs"
    }
  });

  _exports.default = _default;
});