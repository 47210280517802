define("shared/capabilities/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    scope: Ember.inject.service(),
    cluster: Ember.computed.alias('scope.currentCluster'),
    capabilities: Ember.computed.alias('cluster.capabilities'),
    nodePoolsCanScale: Ember.computed('capabilities.nodePoolScalingSupported', function () {
      const {
        capabilities
      } = this || {};
      let poolScaleSupported = false;

      if (Ember.get(capabilities, 'nodePoolScalingSupported')) {
        poolScaleSupported = true;
      }

      return poolScaleSupported;
    }),
    allowedNodePortRanges: Ember.computed('capabilities.nodePortRange', function () {
      const capabilities = Ember.get(this, 'capabilities') || {};
      let ccPorts = [];

      if (Ember.get(capabilities, 'nodePortRange')) {
        let temp = Ember.get(capabilities, 'nodePortRange').split('-');
        ccPorts = [parseInt(temp[0], 10), parseInt(temp[1], 10)];
      }

      return ccPorts;
    }),
    ingressCapabilities: Ember.computed('capabilities.ingressControllers.[]', function () {
      const {
        capabilities
      } = this || {};
      const ingressCapabilities = Ember.get(capabilities, 'ingressCapabilities') || [];
      const provider = ingressCapabilities.length > 0 ? Ember.get(ingressCapabilities, 'firstObject.ingressProvider') : null;
      return {
        defaultIngressProvider: provider,
        ingressControllersCapabilities: ingressCapabilities
      };
    }),
    loadBalancerCapabilites: Ember.computed('capabilities.loadBalancerCapabilities.{enabled,healthCheckSupported}', function () {
      return {
        // `enabled` field is not set for imported clusters
        l4LoadBalancerEnabled: Ember.get(this, 'capabilities.loadBalancerCapabilities.enabled') !== false,
        healthCheckSupported: Ember.get(this, 'capabilities.loadBalancerCapabilities.healthCheckSupported')
      };
    })
  });

  _exports.default = _default;
});