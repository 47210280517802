define("global-admin/clusters/new/route", ["exports", "shared/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model() {
      const gs = this.globalStore;
      const reqs = {
        kontainerDrivers: gs.findAll('kontainerdriver'),
        nodeDrivers: gs.findAll('nodeDriver')
      };

      if (gs.getById('schema', 'clustertemplaterevision')) {
        reqs.clusterTemplates = gs.findAll('clustertemplate');
        reqs.clusterTemplateRevisions = gs.findAll('clustertemplaterevision');
      } else {
        reqs.clusterTemplates = [];
        reqs.clusterTemplateRevisions = [];
      }

      return Ember.RSVP.hash(reqs);
    },

    afterModel(model) {
      // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"
      let {
        kontainerDrivers
      } = model;
      let externalDrivers = kontainerDrivers.filter(d => d.uiUrl !== '' && d.state === 'active');
      let promises = {};
      externalDrivers.forEach(d => {
        if (Ember.get(d, 'hasUi')) {
          const jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, this.get('app.proxyEndpoint'));
          const cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(this, 'app.proxyEndpoint')); // skip setProperties cause of weird names

          Ember.set(promises, `${d.name}Js`, (0, _loadScript.loadScript)(jsUrl, `driver-ui-js-${d.name}`));
          Ember.set(promises, `${d.name}Css`, (0, _loadScript.loadStylesheet)(cssUrl, `driver-ui-css-${d.name}`));
        }
      });

      if (Object.keys(promises).length > 0) {
        return Ember.RSVP.hashSettled(promises).then(settled => {
          let allkeys = Object.keys(settled);
          allkeys.forEach(key => {
            if (Ember.get(settled, `${key}.state`) === 'rejected') {
              let tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
              let match = kontainerDrivers.findBy('id', tmp);
              console.log('Error Loading External Component for: ', match);

              if (match && Ember.get(match, 'scriptError') !== true) {
                Ember.set(match, 'scriptError', Ember.get(this, 'intl').t('clusterNew.externalError'));
              }
            }
          });
        }).finally(() => {
          return model;
        });
      } else {
        return model;
      }
    }

  });

  _exports.default = _default;
});