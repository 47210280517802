define("global-admin/security/policies/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model(params) {
      return this.get('globalStore').find('podSecurityPolicyTemplate').then(policies => {
        const policy = policies.findBy('id', params.policy_id);

        if (!policy) {
          this.replaceWith('security.policies.index');
        }

        return {
          policy: policy.clone()
        };
      });
    }

  });

  _exports.default = _default;
});