define("@glimmer/component/-private/destroyables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDestroyed = _exports.isDestroying = void 0;

  const isDestroying = Ember.__loader.require('@glimmer/runtime').isDestroying;

  _exports.isDestroying = isDestroying;

  const isDestroyed = Ember.__loader.require('@glimmer/runtime').isDestroyed;

  _exports.isDestroyed = isDestroyed;
});