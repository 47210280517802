define("shared/components/catalog-persistence-row/component", ["exports", "shared/components/catalog-persistence-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,

    init() {
      this._super(...arguments);

      this.storageClassInit();

      if (Ember.get(this, 'config.storageClass')) {
        Ember.set(this, 'useStorageClass', true);
      }

      if (Ember.get(this, 'config.existingClaim')) {
        Ember.set(this, 'useStorageClass', false);
      }
    },

    storageClassInit() {
      const storageClassId = Ember.get(this, 'config.storageClass');

      if (!storageClassId) {
        return;
      }

      const {
        storageClasses = []
      } = this;
      const filtered = storageClasses.filter(s => s.id === storageClassId);

      if (filtered.length === 0) {
        Ember.set(this, 'config.storageClass', null);
      }
    }

  });

  _exports.default = _default;
});