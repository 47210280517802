define("shared/components/import-command/component", ["exports", "shared/components/import-command/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLUSTER_ADMIN = 'kubectl create clusterrolebinding cluster-admin-binding --clusterrole cluster-admin --user [USER_ACCOUNT]';

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    clusterAdmin: CLUSTER_ADMIN,
    showClusterAdminWarning: true,
    showEksClusterWarning: false,
    showGkeClusterWarning: false,
    loading: true,
    token: null,

    init() {
      this._super(...arguments);

      this.loadToken();
    },

    loadToken() {
      const cluster = Ember.get(this, 'cluster');

      if (!cluster) {
        return;
      }

      Ember.set(this, 'loading', true);
      return cluster.getOrCreateToken().then(token => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.setProperties(this, {
          token,
          loading: false
        });
      }).catch(err => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.get(this, 'growl').fromError('Error getting command', err);
        Ember.set(this, 'loading', false);
      });
    }

  });

  _exports.default = _default;
});