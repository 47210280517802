define("shared/components/container-shell/component", ["exports", "ui/utils/platform", "shared/mixins/throttled-resize", "shared/components/container-shell/template", "xterm", "xterm-addon-fit", "jquery"], function (_exports, _platform, _throttledResize, _template, _xterm, _xtermAddonFit, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_COMMAND = ['/bin/sh', '-c', 'TERM=xterm-256color; export TERM; [ -x /bin/bash ] && ([ -x /usr/bin/script ] && /usr/bin/script -q -c "/bin/bash" /dev/null || exec /bin/bash) || exec /bin/sh'];
  const statusMap = {
    closed: 'closed',
    connected: 'connected',
    connecting: 'connecting',
    init: 'initializing'
  };

  var _default = Ember.Component.extend(_throttledResize.default, {
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    // URL or instance+cmd
    url: null,
    instance: null,
    command: null,
    alternateLabel: _platform.alternateLabel,
    showProtip: true,
    contenteditable: false,
    error: null,
    socket: null,
    term: null,
    containerName: null,

    init() {
      this._super(...arguments);

      this._bootstrap();
    },

    didInsertElement() {
      this._super(...arguments);

      Ember.run.next(this, 'exec');
    },

    willDestroyElement() {
      this.disconnect(true);

      this._super(...arguments);
    },

    actions: {
      contextMenuHandler() {
        // fix for no paste button in firefox context menu on Windows
        Ember.set(this, 'contenteditable', true);
        setTimeout(() => {
          Ember.set(this, 'contenteditable', false);
        }, 20);
      }

    },
    containerDidChange: Ember.observer('containerName', function () {
      this.disconnect();
      this.exec();
    }),
    runningContainers: Ember.computed('instance.containers', function () {
      return (Ember.get(this, 'instance.containers') || []).filterBy('canShell', true);
    }),
    status: statusMap.connecting,

    _bootstrap() {
      Ember.set(this, 'containerName', Ember.get(this, 'containerName') || Ember.get(this, 'instance.containers.firstObject.name'));
    },

    fit() {
      const term = Ember.get(this, 'term');
      const socket = Ember.get(this, 'socket');

      if (term && socket) {
        try {
          this.fitAddon.fit();
        } catch (error) {}

        socket.send(`4${AWS.util.base64.encode(JSON.stringify({
          Width: term.cols,
          Height: term.rows
        }))}`);
      }
    },

    onResize() {
      Ember.run.next(() => {
        this.fit();
      });
    },

    exec() {
      let url = Ember.get(this, 'url');

      if (!url) {
        const instance = Ember.get(this, 'instance');
        const clusterId = Ember.get(this, 'scope.currentCluster.id');
        const namespaceId = Ember.get(instance, 'namespaceId');
        const podName = Ember.get(instance, 'name');
        const containerName = Ember.get(this, 'containerName');
        const scheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
        let command = Ember.get(instance, 'command') || DEFAULT_COMMAND;
        url = `${scheme}${window.location.host}/k8s/clusters/${clusterId}/api/v1/namespaces/${namespaceId}/pods/${podName}/exec`;
        url += `?container=${encodeURIComponent(containerName)}&stdout=1&stdin=1&stderr=1&tty=1`;

        if (this.isWindows()) {
          command = ['cmd'];
        }

        command.forEach(c => {
          url += `&command=${encodeURIComponent(c)}`;
        });
      }

      this.connect(url);
    },

    isWindows() {
      if (Ember.get(this, 'windows') === undefined) {
        const system = Ember.get(this, 'instance.node.info.os.operatingSystem') || '';
        return system.startsWith('Windows');
      } else {
        return Ember.get(this, 'windows');
      }
    },

    connect(url) {
      const socket = new WebSocket(url, 'base64.channel.k8s.io');
      Ember.set(this, 'socket', socket);

      socket.onclose = (err = {}) => {
        const term = Ember.get(this, 'term');

        if (!Ember.get(this, 'userClosed')) {
          Ember.set(this, 'statusCode', err.code);
        }

        try {
          if (!Ember.get(this, 'userClosed') && Ember.get(this, 'instance.containers.length') === 1) {
            term.dispose();

            if (this.dismiss) {
              this.dismiss();
            }
          }

          Ember.set(this, 'status', statusMap.closed);
        } catch (e) {}
      };

      socket.onerror = () => {
        const term = Ember.get(this, 'term');
        Ember.set(this, 'status', statusMap.closed);

        try {
          if (!Ember.get(this, 'userClosed') && Ember.get(this, 'instance.containers.length') === 1) {
            term.dispose();

            if (this.dismiss) {
              this.dismiss();
            }
          }
        } catch (e) {}
      };

      socket.onopen = () => {
        Ember.set(this, 'status', statusMap.init);
        const fitAddon = new _xtermAddonFit.FitAddon();
        var term = new _xterm.Terminal({
          cursorBlink: true,
          useStyle: true,
          fontSize: 12,
          cols: 180
        });
        term.loadAddon(fitAddon);
        Ember.set(this, 'term', term);
        Ember.set(this, 'fitAddon', fitAddon);
        term.onData(data => {
          socket.send(`0${AWS.util.base64.encode(data)}`);
        });
        term.open((0, _jquery.default)('.shell-body')[0]);
        Ember.run.next(() => {
          term.focus();
          this.fit();
        });

        socket.onmessage = message => {
          Ember.set(this, 'status', statusMap.connected);

          if (this.connected) {
            this.connected();
          }

          const data = message.data.slice(1);

          switch (message.data[0]) {
            case '1':
            case '2':
            case '3':
              term.write(AWS.util.base64.decode(data).toString());
              break;
          }
        };
      };
    },

    disconnect(destroying = false) {
      Ember.setProperties(this, {
        status: statusMap.closed,
        userClosed: true
      });
      const term = Ember.get(this, 'term');

      if (term) {
        term.dispose();

        if (!destroying) {
          Ember.set(this, 'term', null);
        }
      }

      const socket = Ember.get(this, 'socket');

      if (socket) {
        socket.send(`0${AWS.util.base64.encode('exit\r\n')}`);
        socket.close();

        if (!destroying) {
          Ember.set(this, 'socket', null);
        }
      }

      if (this.disconnected) {
        this.disconnected();
      }
    }

  });

  _exports.default = _default;
});