define("shared/components/storage-class/provisioner-rbd/component", ["exports", "shared/components/storage-class/provisioner-rbd/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELDS = ['monitors', 'adminId', 'adminSecretNamespace', 'adminSecretName', 'pool', 'userId', 'userSecretNamespace', 'userSecretName', 'fsType', 'imageFormat', 'imageFeatures'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'rbd',
    fields: FIELDS
  });

  _exports.default = _default;
});