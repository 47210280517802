define("shared/components/form-ssl-rows/component", ["exports", "shared/components/form-ssl-rows/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    editing: null,
    hosts: null,
    hostArray: null,

    init() {
      this._super(...arguments);

      this.initHosts();
    },

    didInsertElement() {
      if (Ember.get(this, 'editing') && Ember.get(this, 'hostArray.length') === 0) {
        this.send('addHost');
      }
    },

    actions: {
      removeHost(host) {
        Ember.get(this, 'hostArray').removeObject(host);
      },

      addHost() {
        Ember.get(this, 'hostArray').pushObject({
          value: ''
        });
      }

    },
    hostDidChange: Ember.observer('hostArray.@each.value', function () {
      const hosts = [];
      Ember.get(this, 'hostArray').filter(host => host.value).forEach(host => {
        hosts.push(host.value);
      });
      Ember.set(this, 'hosts', hosts);
    }),

    initHosts() {
      const hosts = Ember.get(this, 'hosts') || [];
      Ember.set(this, 'hostArray', hosts.map(host => {
        return {
          value: host
        };
      }));
    }

  });

  _exports.default = _default;
});