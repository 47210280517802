define("shared/mixins/reservation-check", ["exports", "shared/utils/util", "shared/utils/parse-unit"], function (_exports, _util, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scope: Ember.inject.service(),
    preRequestsCpu: null,
    preRequestsMemory: null,
    clusterLevelMinCpu: 1000,
    clusterLevelMinMemory: 1000,
    projectLevelMinCpu: 500,
    projectLevelMinMemory: 500,
    insufficient: Ember.computed('scope.currentCluster.nodes', 'insufficientMemory', 'insufficientCpu', function () {
      const allNodes = Ember.get(this, 'scope.currentCluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      return Ember.get(this, 'insufficientMemory') || Ember.get(this, 'insufficientCpu');
    }),
    leftCpu: Ember.computed('cluster.nodes.@each.{allocatable,requested}', function () {
      const allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      const schedulableNodes = allNodes.filterBy('isUnschedulable', false);
      let leftCpu = 0;
      schedulableNodes.forEach(node => {
        const left = (0, _util.convertToMillis)(Ember.get(node, 'allocatable.cpu') || '0') - (0, _util.convertToMillis)(Ember.get(node, 'requested.cpu') || '0');
        leftCpu += left;
      });
      return leftCpu;
    }),
    leftMemory: Ember.computed('cluster.nodes.@each.{allocatable,requested}', function () {
      const allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      const schedulableNodes = allNodes.filterBy('isUnschedulable', false);
      let leftMemory = 0;
      schedulableNodes.forEach(node => {
        const left = (0, _parseUnit.parseSi)(Ember.get(node, 'allocatable.memory'), 1024) / 1048576 - (0, _parseUnit.parseSi)(Ember.get(node, 'requested.memory') || '0', 1024) / 1048576;
        leftMemory += left;
      });
      return leftMemory;
    }),
    insufficientMemory: Ember.computed('preRequestsMemory', 'requestsMemory', 'leftMemory', function () {
      const {
        preRequestsMemory,
        requestsMemory,
        leftMemory,
        enabled,
        minMemory
      } = this;

      if (enabled) {
        return leftMemory <= parseInt(requestsMemory || 0, 10) - parseInt(preRequestsMemory || 0, 10);
      } else {
        return leftMemory <= minMemory;
      }
    }),
    insufficientCpu: Ember.computed('preRequestsCpu', 'requestsCpu', 'leftCpu', function () {
      const {
        preRequestsCpu,
        requestsCpu,
        leftCpu,
        enabled,
        minCpu
      } = this;

      if (enabled) {
        return leftCpu <= parseInt(requestsCpu || 0, 10) - parseInt(preRequestsCpu || 0, 10);
      } else {
        return leftCpu <= minCpu;
      }
    }),
    minCpu: Ember.computed('clusterLevelMinCpu', 'level', 'projectLevelMinCpu', 'requestsCpu', function () {
      let cpu = parseInt(Ember.get(this, 'requestsCpu') || 0, 10);

      if (isNaN(cpu)) {
        cpu = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinCpu') : Ember.get(this, 'projectLevelMinCpu')) + cpu;
    }),
    minMemory: Ember.computed('clusterLevelMinMemory', 'level', 'projectLevelMinMemory', 'requestsMemory', function () {
      let memory = parseInt(Ember.get(this, 'requestsMemory') || 0, 10);

      if (isNaN(memory)) {
        memory = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinMemory') : Ember.get(this, 'projectLevelMinMemory')) + memory;
    }),

    getComponentInsufficient(component, type, reservation) {
      const allNodes = Ember.get(this, 'cluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      const maxLeft = Ember.get(this, `${component}SchedulableNodes`).reduce((out, node) => {
        let left;

        if (type === 'cpu') {
          left = (0, _util.convertToMillis)(Ember.get(node, `allocatable.${type}`)) - (0, _util.convertToMillis)(Ember.get(node, `requested.${type}`) || '0');
        } else if (type === 'memory') {
          left = (0, _parseUnit.parseSi)(Ember.get(node, 'allocatable.memory'), 1024) / 1048576 - (0, _parseUnit.parseSi)(Ember.get(node, 'requested.memory') || '0', 1024) / 1048576;
        }

        return left > out ? left : out;
      }, 0);
      const request = reservation ? reservation : Ember.get(this, `config.${component}Request${(0, _util.ucFirst)(type)}`) || 0;
      return Ember.get(this, 'enabled') ? false : maxLeft <= request;
    },

    getSchedulableNodes(component) {
      const allNodes = Ember.get(this, 'cluster.nodes') || [];
      const out = allNodes.filterBy('isUnschedulable', false).filter(node => (Ember.get(this, `${component}NodeSelectors`) || []).every(selector => {
        const labelValue = (Ember.get(node, 'labels') || {})[Ember.get(selector, 'key')];

        if (Ember.get(selector, 'value') === '') {
          return labelValue !== undefined;
        } else {
          return Ember.get(selector, 'value') === labelValue;
        }
      }));
      return out;
    },

    getComponentWarning(component, componentCpu, componentMemory, displayComponent) {
      const insufficientCpu = Ember.get(this, `insufficient${(0, _util.ucFirst)(component)}Cpu`);
      const insufficientMemory = Ember.get(this, `insufficient${(0, _util.ucFirst)(component)}Memory`);
      const nodeSelectors = Ember.get(this, `${component}NodeSelectors`) || [];
      const intl = Ember.get(this, 'intl');
      const cpu = componentCpu ? componentCpu : Ember.get(this, `config.${component}RequestCpu`);
      const memory = componentMemory ? componentMemory : Ember.get(this, `config.${component}RequestMemory`);
      let prefix = 'clusterIstioPage.insufficientSize.selectors';

      if (nodeSelectors.length === 0) {
        prefix = 'clusterIstioPage.insufficientSize.workload';
      } else {
        const unsupportedSelectors = nodeSelectors.filter(n => n.value === 'true' || n.value === 'false' || /^\d+$/g.test(n.value));

        if (unsupportedSelectors.length > 0) {
          return intl.t(`clusterIstioPage.insufficientSize.selectors.unsupported`, {
            component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
          });
        }
      }

      if (insufficientCpu && insufficientMemory) {
        return intl.t(`${prefix}.all`, {
          cpu,
          memory,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      } else if (insufficientCpu) {
        return intl.t(`${prefix}.cpu`, {
          cpu,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      } else if (insufficientMemory) {
        return intl.t(`${prefix}.memory`, {
          memory,
          component: displayComponent ? displayComponent : (0, _util.parseCamelcase)(component)
        });
      }
    },

    validateLimitAndRequest(component) {
      const errors = [];
      ['requestCpu', 'limitCpu', 'requestMemory', 'limitMemory'].map(suffix => {
        const key = `config.${component}${(0, _util.ucFirst)(suffix)}`;

        if (!Ember.get(this, key)) {
          errors.pushObject((0, _util.requiredError)(`formReservation.${suffix}.label`, {
            component: (0, _util.ucFirst)(component)
          }));
        }
      });
      return errors;
    },

    updateCpuMemoryPreRequest() {
      const answers = Ember.get(this, 'app.answers') || {};
      const workloads = this.getEnalbedWorkloads(answers);
      const preRequestsCpu = workloads.reduce((all, current) => {
        const value = answers[`${current}.resources.requests.cpu`];
        return value ? all + (0, _util.convertToMillis)(value) : all;
      }, 0);
      const preRequestsMemory = workloads.reduce((all, current) => {
        const value = answers[`${current}.resources.requests.memory`];
        return value ? all + (0, _parseUnit.parseSi)(value) / 1048576 : all;
      }, 0);
      Ember.setProperties(this, {
        preRequestsCpu,
        preRequestsMemory
      });
    }

  });

  _exports.default = _default;
});