define("shared/components/modal-show-command/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-show-command/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal'],
    loading: false,
    showSuccess: false,
    isLinux: true,
    tokenErrors: null,
    etcd: false,
    controlplane: false,
    worker: true,
    cluster: Ember.computed.alias('modalOpts.cluster'),
    clusterProvider: Ember.computed.alias('cluster.clusterProvider'),
    canShowAddHost: Ember.computed.alias('cluster.canShowAddHost'),
    isCustom: Ember.computed.equal('clusterProvider', 'custom'),

    init() {
      this._super(...arguments);

      this.shortcuts.disable();
      Ember.set(this, 'tokenErrors', []);
      this.loadToken();
      this.shouldDismissModal();
    },

    isLinuxChanged: Ember.observer('isLinux', function () {
      if (Ember.get(this, 'nodeWhich') !== 'custom') {
        return;
      }

      const isLinux = Ember.get(this, 'isLinux');

      if (!isLinux) {
        Ember.setProperties(this, {
          controlplane: false,
          etcd: false,
          worker: true
        });
      }
    }),
    shouldDismissModal: Ember.observer('canShowAddHost', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const {
        canShowAddHost
      } = this;

      if (!canShowAddHost) {
        Ember.set(this, 'showSuccess', true);
        Ember.run.later(this, () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          this.send('close');
        }, 3000);
      }
    }),
    isImport: Ember.computed('cluster.{eksDisplayEksImport,gkeDisplayImport}', function () {
      const {
        clusterProvider
      } = this;

      if (clusterProvider === 'amazoneksv2') {
        return this.cluster.eksDisplayEksImport;
      } else if (clusterProvider === 'googlegkev2') {
        return this.cluster.gkeDisplayImport;
      }

      return false;
    }),

    async loadToken() {
      const {
        cluster
      } = this;
      Ember.set(this, 'loading', true);

      try {
        const token = await cluster.getOrCreateToken();

        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (token) {
          Ember.set(this, 'token', token);
        }
      } catch (err) {
        const errMsg = this.intl.t('modalShowCommand.errors.token', {
          err: err.message || 'Unknown'
        });
        Ember.set(this, 'tokenErrors', [errMsg]);
      }

      Ember.set(this, 'loading', false);
    },

    willDestroy() {
      this._super(...arguments);

      this.shortcuts.enable();
    }

  });

  _exports.default = _default;
});