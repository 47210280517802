define("shared/components/input-or-display/component", ["exports", "shared/mixins/safe-style", "shared/components/input-or-display/template"], function (_exports, _safeStyle, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_safeStyle.default, {
    layout: _template.default,
    tagName: 'span',
    value: null,
    editable: true,
    classesForInput: 'form-control',
    classesForDisplay: '',
    obfuscate: false,
    obfuscatedValue: Ember.computed('value', function () {
      let val = this.get('value') || '';
      let count = val.length;
      let obChar = '*';
      return new Array(count + 1).join(obChar);
    }),
    hasValue: Ember.computed('value', function () {
      return !Ember.isEmpty(this.value);
    })
  });

  _exports.default = _default;
});