define("ember-intl/-private/formatters/format-date", ["exports", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @hide
   */
  class FormatDate extends _base.default {
    constructor() {
      super();
      this.createNativeFormatter = (0, _fastMemoize.default)((locales, options) => {
        return new Intl.DateTimeFormat(locales, options);
      });
    }

    get options() {
      return Ember.A(['locale', 'format', 'localeMatcher', 'timeZone', 'hour12', 'hourCycle', 'formatMatcher', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName']);
    }

    format(value, options, ctx) {
      const dateTime = new Date(value);
      const formatOptions = this.readOptions(options);
      return this._format(dateTime, formatOptions, undefined, ctx);
    }

  }

  _exports.default = FormatDate;
});