define("shared/components/catalog-index/component", ["exports", "shared/utils/constants", "shared/components/catalog-index/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    catalog: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    search: '',
    parentRoute: null,
    launchRoute: null,
    updating: 'no',
    istio: false,
    projectId: Ember.computed.alias(`cookies.${_constants.default.COOKIE.PROJECT}`),
    catalogs: Ember.computed.union('model.catalogs.globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),

    init() {
      this._super(...arguments);

      Ember.get(this, 'catalog.componentRequestingRefresh');
    },

    actions: {
      clearSearch() {
        Ember.set(this, 'search', '');
      },

      update() {
        Ember.set(this, 'updating', 'yes');
        Ember.get(this, 'catalog').refresh().then(() => {
          Ember.set(this, 'updating', 'no');

          if (this.refresh) {
            this.refresh();
          }
        }).catch(() => {
          Ember.set(this, 'updating', 'error');
        });
      },

      filterCatalog(category, dropdown) {
        if (dropdown && dropdown.isOpen) {
          dropdown.actions.close();
        }

        this.categoryAction(category);
      },

      toggleCollapse(group) {
        const collapsedNames = Ember.get(this, `prefs.${_constants.default.PREFS.COLLAPSED_CATALOGS}`) || [];

        if (group.collapsed) {
          collapsedNames.removeObject(group.name);
        } else {
          collapsedNames.addObject(group.name);
        }

        Ember.set(group, 'collapsed', !group.collapsed);
        Ember.set(this, `prefs.${_constants.default.PREFS.COLLAPSED_CATALOGS}`, collapsedNames);
      }

    },
    childRequestiongRefresh: Ember.observer('catalog.componentRequestingRefresh', function () {
      if (Ember.get(this, 'catalog.componentRequestingRefresh')) {
        this.send('update');
      }
    }),
    projectCatalogs: Ember.computed('model.catalogs.{projecCatalogs,projectCatalogs}', 'scope.currentProject.id', function () {
      return (Ember.get(this, 'model.catalogs.projectCatalogs') || []).filter(c => c.projectId === Ember.get(this, 'scope.currentProject.id'));
    }),
    clusterCatalogs: Ember.computed('model.catalogs.clusterCatalogs', 'scope.currentCluster.id', function () {
      return (Ember.get(this, 'model.catalogs.clusterCatalogs') || []).filter(c => c.clusterId === Ember.get(this, 'scope.currentCluster.id'));
    }),
    categories: Ember.computed('matchingSearch.@each.{category,categories}', function () {
      let map = {};
      Ember.get(this, 'matchingSearch').forEach(tpl => {
        const categories = tpl.categories;

        if (!categories) {
          return;
        }

        for (let i = 0; i < categories.length; i++) {
          let ctgy = categories[i];
          let normalized = ctgy.underscore();

          if (map[normalized]) {
            map[normalized].count += 1;
          } else {
            map[normalized] = {
              name: ctgy,
              category: normalized,
              count: 1
            };
          }
        }
      });
      return Object.values(map);
    }),
    catalogURL: Ember.computed('catalogs', function () {
      var neu = {
        catalogs: {}
      };
      Ember.get(this, 'catalogs').forEach(cat => {
        neu.catalogs[cat.id] = {
          branch: cat.branch,
          url: cat.url
        };
      });
      return JSON.stringify(neu);
    }),
    inScopeTemplates: Ember.computed('catalog._allTemplates.@each.{name,id,catalogId}', 'scope.currentProject', 'istio', function () {
      const svc = Ember.get(this, 'catalog');
      return svc.filter(Ember.get(svc, '_allTemplates'), Ember.get(this, 'scope.currentProject'), Ember.get(this, 'istio'));
    }),
    matchingSearch: Ember.computed('inScopeTemplates.@each.{name,description}', 'search', function () {
      const search = (Ember.get(this, 'search') || '').toLowerCase();
      const all = Ember.get(this, 'inScopeTemplates');

      if (!search) {
        return all;
      }

      return all.filter(tpl => tpl.name && tpl.name.toLowerCase().includes(search) || tpl.description && tpl.description.toLowerCase().includes(search));
    }),
    arrangedContent: Ember.computed('matchingSearch.@each.categoryLowerArray', 'category', function () {
      const category = (Ember.get(this, 'category') || '').toLowerCase();
      const all = Ember.get(this, 'matchingSearch').filter(tpl => Object.keys(Ember.get(tpl, 'versionLinks') || {}).length > 0);

      if (!category || category === 'all') {
        return all;
      }

      return all.filter(tpl => Ember.get(tpl, 'categoryLowerArray').includes(category));
    }),
    groupedContent: Ember.computed('arrangedContent.[]', 'catalogs.@each.name', function () {
      const out = [];
      const collapsedNames = Ember.get(this, `prefs.${_constants.default.PREFS.COLLAPSED_CATALOGS}`) || [];
      const all = Ember.get(this, 'arrangedContent');
      all.forEach(template => {
        const entry = getOrCreateGroup(template.displayCatalogId);
        entry.items.push(template);
      });

      if (!Ember.isEmpty(out)) {
        out.forEach(c => {
          const isHelm3 = c.items.any(i => {
            const {
              catalogRef,
              clusterCatalog,
              projectCatalog
            } = i;

            if (catalogRef && catalogRef.isHelm3 || clusterCatalog && clusterCatalog.isHelm3 || projectCatalog && projectCatalog.isHelm3) {
              return true;
            }
          });
          Ember.set(c, 'isHelm3', isHelm3);
        });
      }

      return out.sortBy('priority', 'name');

      function getOrCreateGroup(name) {
        let entry = out.findBy('name', name);
        let priority = 0;

        if (name === _constants.default.CATALOG.LIBRARY_KEY) {
          priority = 1;
        } else if ([_constants.default.CATALOG.HELM_STABLE_KEY, _constants.default.CATALOG.HELM_INCUBATOR_KEY, _constants.default.CATALOG.ALIBABA_APP_HUB_KEY].includes(name)) {
          priority = 2;
        }

        if (!entry) {
          entry = {
            name,
            priority,
            collapsed: collapsedNames.includes(name),
            items: []
          };
          out.push(entry);
        }

        return entry;
      }
    })
  });

  _exports.default = _default;
});