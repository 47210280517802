define("shared/components/modal-confirm-yaml-switch/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-confirm-yaml-switch/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['medium-modal'],
    model: Ember.computed.alias('modalService.modalOpts.propertiesGoingToBeLost'),
    actions: {
      confirm() {
        this.modalService.modalOpts.finish(this.close.bind(this));
      },

      cancel() {
        this.modalService.modalOpts.finish(this.close.bind(this), true);
      }

    },

    close() {
      this.send('close');
    }

  });

  _exports.default = _default;
});