define("global-admin/global-dns/entries/new/controller", ["exports", "shared/mixins/view-new-edit"], function (_exports, _viewNewEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEADERS = [{
    translationKey: 'newMultiClusterApp.overrides.table.scope',
    name: 'scope',
    sort: ['scope']
  }, {
    translationKey: 'newMultiClusterApp.overrides.table.target',
    name: 'target',
    sort: ['target']
  }];

  var _default = Ember.Controller.extend(_viewNewEdit.default, {
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    queryParams: ['id'],
    memberAccessTypes: ['owner'],
    id: null,
    errros: null,
    saveDisabled: false,
    allTargets: null,
    projectsToAddOnUpgrade: null,
    projectsToRemoveOnUpgrade: null,
    errors: null,
    originalModel: null,
    mode: 'new',
    recordType: 'multi',
    headers: HEADERS,
    config: Ember.computed.alias('model.globaldns'),
    primaryResource: Ember.computed.alias('config'),
    actions: {
      addTarget(targetIn) {
        const isNew = this.mode === 'new';
        const current = Array.prototype.slice.call(Ember.get(this, 'primaryResource.projectIds') || [], 0);
        const projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
        const projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];

        if (targetIn && !Ember.get(targetIn, 'type')) {
          const projectId = Ember.get(targetIn, 'value');
          Ember.run.next(() => {
            if (isNew) {
              current.pushObject(projectId);
              Ember.set(this, 'primaryResource.projectIds', current);
            } else {
              let toRemoveMatch = projectsToRemoveOnUpgrade.find(removeId => removeId === projectId);

              if (toRemoveMatch) {
                // a project was remove then re-added
                Ember.set(this, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade.removeObject(projectId));
              } else {
                projectsToAddOnUpgrade.pushObject(projectId);
                Ember.set(this, 'projectsToAddOnUpgrade', projectsToAddOnUpgrade);
              }
            }
          });
        }
      },

      removeTarget(target) {
        const isNew = this.mode === 'new';
        const current = Array.prototype.slice.call(Ember.get(this, 'primaryResource.projectIds') || [], 0);
        const projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];
        const projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
        const projectId = Ember.get(target, 'projectId');
        Ember.run.next(() => {
          if (isNew) {
            current.removeObject(projectId);
            Ember.set(this, 'primaryResource.projectIds', current);
          } else {
            let targetToAddMatch = projectsToAddOnUpgrade.find(addId => addId === projectId);

            if (targetToAddMatch) {
              Ember.set(this, 'projectsToAddOnUpgrade', projectsToAddOnUpgrade.removeObject(projectId));
            } else {
              projectsToRemoveOnUpgrade.pushObject(projectId);
              Ember.set(this, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade);
            }
          }
        });
      },

      cancel() {
        Ember.set(this, 'id', null);
        this.transitionToRoute('global-dns.entries.index');
      },

      addAuthorizedPrincipal(principal) {
        if (principal) {
          let {
            members = []
          } = this.model.globaldns;

          if (!members) {
            members = [];
          }

          Ember.set(principal, 'accessType', 'owner');
          members.pushObject(this.globalStore.createRecord(principal));
          Ember.set(this, 'model.globaldns.members', members);
        }
      },

      removeAuthorizedPrincipal(member) {
        let {
          members = []
        } = this.model.globaldns;
        members.removeObject(member);
      }

    },
    recordTypeChanged: Ember.observer('recordType', function () {
      const {
        recordType,
        primaryResource = {}
      } = this;
      const {
        multiClusterAppId,
        projectIds
      } = primaryResource;
      let {
        projectsToRemoveOnUpgrade
      } = this;

      if (!projectsToRemoveOnUpgrade) {
        projectsToRemoveOnUpgrade = [];
      }

      switch (recordType) {
        case 'multi':
          if (projectIds && projectIds.length) {
            Ember.set(primaryResource, 'projectIds', null);
            Ember.set(this, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade.pushObjects(projectIds));
          }

          break;

        case 'project':
          if (multiClusterAppId) {
            Ember.set(primaryResource, 'multiClusterAppId', null);
          }

          break;

        default:
          break;
      }
    }),
    combinedProjectIds: Ember.computed('primaryResource.projectIds', 'projectsToRemoveOnUpgrade.[]', 'projectsToAddOnUpgrade.[]', function () {
      const projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
      const projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];
      const projectIds = Ember.get(this, 'primaryResource.projectIds') || [];
      return [...projectsToAddOnUpgrade, ...projectIds].filter(projectId => !projectsToRemoveOnUpgrade.includes(projectId));
    }),
    providers: Ember.computed('model.providers.[]', 'primaryResource', function () {
      let {
        providerId
      } = this.primaryResource;
      let {
        providers
      } = this.model;

      if (providers.length) {
        if (providerId) {
          let match = providers.findBy('id', providerId);

          if (!match) {
            // you don't have access
            providers.pushObject({
              id: providerId,
              name: providerId
            });
          }
        }
      } else {
        providers = [];

        if (providerId) {
          providers.pushObject({
            id: providerId,
            name: providerId
          });
        }
      }

      return providers.map(p => {
        return {
          id: p.id,
          displayName: `${p.name}`
        };
      }).sortBy('name');
    }),
    multiClusterApps: Ember.computed('model.multiClusterApps.[]', 'primaryResource', function () {
      let {
        multiClusterApps
      } = this.model;
      let {
        multiClusterAppId
      } = this.primaryResource;

      if (multiClusterApps.length) {
        if (multiClusterAppId) {
          let match = multiClusterApps.findBy('id', multiClusterAppId);

          if (!match) {
            // you don't have access
            multiClusterApps.pushObject({
              id: multiClusterAppId,
              displayName: `${multiClusterAppId} (no access)`
            });
          }
        }
      } else {
        multiClusterApps = [];

        if (multiClusterAppId) {
          multiClusterApps.pushObject({
            id: multiClusterAppId,
            displayName: `${multiClusterAppId} (no access)`
          });
        }
      }

      return multiClusterApps.map(p => {
        return {
          id: p.id,
          displayName: `${p.displayName}`
        };
      }).sortBy('name');
    }),
    projectTargets: Ember.computed('combinedProjectIds.[]', 'model.allProjects', 'projectsToRemoveOnUpgrade.[]', function () {
      const allProjects = Ember.get(this, 'model.allProjects');
      const projectIds = Ember.get(this, 'combinedProjectIds');
      const out = [];
      projectIds.forEach(projectId => {
        let projectMatch = allProjects.findBy('id', projectId);

        if (projectMatch) {
          out.pushObject({
            projectId,
            project: projectMatch,
            projectName: Ember.get(projectMatch, 'displayName'),
            clusterName: Ember.get(projectMatch, 'cluster.displayName'),
            clusterId: projectId.split(':')[0]
          });
        } else {
          out.pushObject({
            projectId,
            clusterId: projectId.split(':')[0]
          });
        }
      });
      return out;
    }),

    doProjectActions() {
      const {
        primaryResource
      } = this;
      const {
        projectsToAddOnUpgrade,
        projectsToRemoveOnUpgrade
      } = this;
      const promises = [];

      if (projectsToAddOnUpgrade && projectsToAddOnUpgrade.length > 0) {
        promises.push(primaryResource.doAction('addProjects', {
          projectIds: projectsToAddOnUpgrade
        }));
      }

      if (projectsToRemoveOnUpgrade && projectsToRemoveOnUpgrade.length > 0) {
        promises.push(primaryResource.doAction('removeProjects', {
          projectIds: projectsToRemoveOnUpgrade
        }));
      }

      if (promises.length > 0) {
        return Ember.RSVP.all(promises);
      } else {
        return true;
      }
    },

    didSave(neu) {
      const {
        isEdit
      } = this;

      if (isEdit) {
        return this.doProjectActions();
      } else {
        return neu;
      }
    },

    doneSaving() {
      this.send('cancel');
    }

  });

  _exports.default = _default;
});