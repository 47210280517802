define("shared/components/tooltip-action-menu/component", ["exports", "shared/mixins/tooltip", "shared/mixins/stripped-name", "shared/components/tooltip-action-menu/template"], function (_exports, _tooltip, _strippedName, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, _strippedName.default, {
    resourceActions: Ember.inject.service('resource-actions'),
    layout: _template.default,
    needs: ['application'],
    inTooltip: false,
    layoutName: 'tooltip-action-menu',
    model: Ember.computed.alias('tooltipService.tooltipOpts.model'),
    actionsOpen: Ember.computed.alias('resourceActions.open'),

    init() {
      if (this.get('tooltipTemplate')) {
        this.set('layoutName', this.get('tooltipTemplate'));
      }

      this._super(...arguments); // Just so openChanged is ready to go, otherwise you have to chain on('init') on openChanged
      // which because of the context menu click on container dot can cause some issues with checking
      // flags and such. This was the least compliated way to ensure that openChanged would recognize changes


      this.set('actionsOpen', false);
    },

    openChanged: Ember.observer('actionsOpen', function () {
      this.set('tooltipService.requireClick', this.get('actionsOpen'));

      if (!this.get('actionsOpen') && !this.get('inTooltip')) {
        this.get('tooltipService').leave();
      }
    }),

    mouseEnter() {
      this._super();

      this.set('inTooltip', true); // Must get the property before it will be observed for openChanged
      // https://github.com/emberjs/ember.js/issues/10821

      this.get('actionsOpen');
    },

    mouseLeave() {
      this.set('inTooltip', false);

      if (!this.get('actionsOpen')) {
        this.get('tooltipService').leave();
      }
    }

  });

  _exports.default = _default;
});