define("global-admin/settings/advanced/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    beforeModel() {
      return this.get('settings').load([_constants.default.SETTING.TELEMETRY]);
    },

    model() {
      // let settings = this.get('settings');
      let globalStore = Ember.get(this, 'globalStore');
      return globalStore.find('setting').then(settings => {
        let serverUrl = settings.findBy('id', _constants.default.SETTING.SERVER_URL);
        return {
          telemetry: settings.findBy('id', _constants.default.SETTING.TELEMETRY),
          serverUrl: Ember.get(serverUrl, 'value') || window.location.host,
          serverUrlSetting: serverUrl
        };
      });
    },

    resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('error', null);
      }
    }

  });

  _exports.default = _default;
});