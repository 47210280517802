define("shared/components/input-yaml/component", ["exports", "ui/utils/platform", "shared/components/input-yaml/template", "shared/mixins/throttled-resize", "shared/utils/download-files", "codemirror", "js-yaml", "jquery"], function (_exports, _platform, _template, _throttledResize, _downloadFiles, _codemirror, _jsYaml, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_throttledResize.default, {
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    mode: 'text',
    label: null,
    namePlaceholder: '',
    nameRequired: false,
    name: null,
    value: null,
    placeholder: '',
    accept: 'text/*, .yml, .yaml',
    multiple: false,
    viewportMargin: Infinity,
    autoResize: false,
    resizeFooter: 130,
    minHeight: 50,
    inputName: false,
    canChangeName: true,
    canUpload: true,
    showUploadLabel: true,
    gutters: ['CodeMirror-lint-markers'],
    tagName: ['div'],
    showUpload: true,
    showDownload: true,
    showCopy: false,
    shouldChangeName: true,
    _isEditorVisible: false,

    init() {
      this._super(...arguments); // needed for code mirror???


      window.jsyaml || (window.jsyaml = _jsYaml.default);
    },

    didRender() {
      this._super(...arguments); // We're using _isEditorVisible to trigger a refresh in the ivy-codemirror as
      // suggested by https://github.com/IvyApp/ivy-codemirror/issues/2. We do this
      // trigger inside of render to ensure the component is already rendered even
      // after 'loading' has changed.


      Ember.run.next(() => {
        Ember.set(this, '_isEditorVisible', !Ember.get(this, 'loading'));
      });
    },

    actions: {
      click() {
        (0, _jquery.default)(this.element).find('INPUT[type=file]').click();
      },

      wantsChange() {
        Ember.set(this, 'shouldChangeName', true);
      },

      download() {
        let yaml = Ember.get(this, 'value');
        const lintError = [];

        _jsYaml.default.safeLoadAll(yaml, y => {
          lintError.pushObjects(_codemirror.default.lint.yaml(y));
        });

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          return;
        }

        (0, _downloadFiles.downloadFile)(Ember.get(this, 'filename'), yaml);
      }

    },
    loadingDidChange: Ember.observer('loading', function () {
      if (!Ember.get(this, 'loading') && Ember.get(this, 'autoResize')) {
        Ember.run.next(() => {
          this.fit();
        });
      }
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),

    onResize() {
      if (Ember.get(this, 'autoResize')) {
        this.fit();
      }
    },

    fit() {
      if (Ember.get(this, 'autoResize')) {
        var container = (0, _jquery.default)('.codemirror-container');

        if (!container) {
          return;
        }

        const position = container.position();

        if (!position) {
          return;
        }

        const desired = (0, _jquery.default)(window).height() - position.top - Ember.get(this, 'resizeFooter');
        container.css('max-height', Math.max(Ember.get(this, 'minHeight'), desired));
      }
    },

    // fired on file change
    change(event) {
      var input = event.target;

      if (!input.files || !input.files.length) {
        return;
      }

      if (Ember.get(this, 'canChangeName')) {
        const firstName = input.files[0].name;

        if (Ember.get(this, 'multiple')) {
          const ext = firstName.replace(/.*\./, '');
          Ember.set(this, 'name', `multiple.${ext}`);
        } else {
          Ember.set(this, 'name', firstName);
        }

        Ember.set(this, 'shouldChangeName', false);
      }

      const promises = [];
      let file;

      for (let i = 0; i < input.files.length; i++) {
        file = input.files[i];
        promises.push(new Ember.RSVP.Promise((resolve, reject) => {
          var reader = new FileReader();

          reader.onload = res => {
            var out = res.target.result;
            resolve(out);
          };

          reader.onerror = err => {
            Ember.get(this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
            reject(err);
          };

          reader.readAsText(file);
        }));
      }

      Ember.RSVP.all(promises).then(res => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        let value = res.join('\n');
        Ember.set(this, 'value', value);

        if (value) {
          if (this.fileChosen) {
            this.fileChosen();
          }
        }
      }).finally(() => {
        input.value = '';
      });
    },

    tabReplacement(cm) {
      if (cm.somethingSelected()) {
        var sel = cm.doc.getSelection('\n'); // Indent only if there are multiple lines selected, or if the selection spans a full line

        if (sel.length > 0 && (sel.indexOf('\n') > -1 || sel.length === cm.getLine(cm.getCursor().line).length)) {
          cm.indentSelection('add');
          return;
        }
      }

      if (cm.options.indentWithTabs) {
        cm.execCommand('insertTab');
      } else {
        cm.execCommand('insertSoftTab');
      }
    },

    shiftTabReplacement(cm) {
      cm.indentSelection('subtract');
    }

  });

  _exports.default = _default;
});