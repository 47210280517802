define("shared/components/schema/input-storageclass/component", ["exports", "shared/components/schema/input-storageclass/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allStorageClasses: Ember.inject.service(),
    layout: _template.default,
    field: null,
    value: null,
    selected: null,
    disabled: false,
    storageClassesOptions: Ember.computed.alias('allStorageClasses.list'),

    init() {
      this._super(...arguments);

      const def = Ember.get(this, 'value') || Ember.get(this, 'field.default');

      if (def && !Ember.get(this, 'selected')) {
        const exact = Ember.get(this, 'storageClassesOptions').findBy('id', def);
        Ember.run.next(() => {
          if (exact) {
            Ember.set(this, 'selected', Ember.get(exact, 'id') || null);
          } else {
            Ember.set(this, 'selected', null);
          }
        });
      }
    },

    selectedChanged: Ember.observer('selected', function () {
      let id = Ember.get(this, 'selected');
      let str = null;

      if (id) {
        const storageClass = Ember.get(this, 'storageClassesOptions').findBy('id', id);

        if (storageClass) {
          str = Ember.get(storageClass, 'id');
        }
      }

      Ember.set(this, 'value', str);
    })
  });

  _exports.default = _default;
});