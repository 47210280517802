define("global-admin/components/form-basic-policy/component", ["exports", "global-admin/components/form-basic-policy/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const policies = _constants.default.BASIC_POD_SECURITY_POLICIES;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    readOnly: false,
    basicPolicies: null,
    statusClass: null,
    status: null,

    init() {
      this._super(...arguments);

      const basicPolicies = [];

      for (let i = 0; i < policies.length / 3; i++) {
        basicPolicies.push(policies.slice(i * 3, i * 3 + 3));
      }

      this.set('basicPolicies', basicPolicies);
    },

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    }

  });

  _exports.default = _default;
});