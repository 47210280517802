define("shared/components/form-user-labels/component", ["exports", "shared/mixins/manage-labels", "shared/components/accordion-list-item/component", "shared/components/form-user-labels/template", "jquery"], function (_exports, _manageLabels, _component, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    detailKey: 'formUserLabels.detail',
    expandAll: null,
    readonlyLabels: null,
    // Inputs
    initialLabels: null,
    statusClass: null,

    init() {
      this._super(...arguments);

      this.initLabels(this.get('initialLabels'), 'user', null, this.get('readonlyLabels'));
      this.labelsChanged();
    },

    didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    actions: {
      addUserLabel() {
        this._super();

        Ember.run.next(() => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          (0, _jquery.default)('INPUT.key').last()[0].focus();
        });
      }

    },
    status: Ember.computed('userLabelArray.@each.key', function () {
      let k = _component.STATUS.NONE;
      let count = this.get('userLabelArray').filterBy('key').get('length') || 0;

      if (count) {
        k = _component.STATUS.COUNTCONFIGURED;
      }

      this.set('statusClass', (0, _component.classForStatus)(k));
      return this.get('intl').t(`${_component.STATUS_INTL_KEY}.${k}`, {
        count
      });
    }),

    updateLabels(labels) {
      if (this.setLabels) {
        this.setLabels(labels);
      }

      this.validate();
    },

    validate() {
      let errors = []; //    (this.get('labelArray')||[]).forEach((obj) => {
      //    });

      Ember.set(this, 'errors', errors);
    }

  });

  _exports.default = _default;
});