define("shared/grafana/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GRAFANA_LINKS = [{
    id: 'etcd',
    title: 'Etcd'
  }, {
    id: 'scheduler',
    title: 'Kubernetes Components'
  }, {
    id: 'controller',
    title: 'Kubernetes Components'
  }, {
    id: 'nodes',
    title: 'Nodes'
  }, {
    id: 'k8s',
    title: 'Kubernetes Components'
  }, {
    id: 'rancher',
    title: 'Rancher Components'
  }, {
    id: 'istio',
    title: 'istio'
  }];

  var _default = Ember.Service.extend({
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    grafanaLinks: GRAFANA_LINKS,
    dashboards: null,

    updateLinks() {
      (Ember.get(this, 'grafanaLinks') || []).forEach(link => {
        const dashboards = Ember.get(this, 'dashboards') || [];
        const target = dashboards.findBy('title', Ember.get(link, 'title'));

        if (target) {
          const grafanaUrl = `${Ember.get(this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint')}${Ember.get(target, 'url')}`;
          Ember.set(this, `${Ember.get(link, 'id')}Url`, grafanaUrl);
        } else {
          Ember.set(this, `${Ember.get(link, 'id')}Url`, null);
        }
      });
    },

    monitoringStatusDidChange: Ember.on('init', Ember.observer('scope.currentCluster.id', 'scope.currentProject.id', 'scope.currentCluster.isMonitoringReady', 'scope.currentProject.isMonitoringReady', function () {
      Ember.set(this, 'dashboards', []);
      this.updateLinks();
      const isProjectReady = Ember.get(this, 'scope.currentProject.isMonitoringReady');
      const systemProject = Ember.get(this, 'globalStore').all('project').findBy('isSystemProject', true);

      if (isProjectReady) {
        const rootUrl = Ember.get(this, 'scope.currentProject.monitoringStatus.grafanaEndpoint');
        Ember.get(this, 'globalStore').rawRequest({
          url: `${rootUrl}api/search`,
          method: 'GET'
        }).then(xhr => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          const dashboards = xhr.body || [];
          Ember.set(this, 'dashboards', dashboards);
        });
      } else if (systemProject) {
        const isClusterReady = Ember.get(this, 'scope.currentCluster.isMonitoringReady');

        if (isClusterReady) {
          const rootUrl = Ember.get(this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint');
          Ember.get(this, 'globalStore').rawRequest({
            url: `${rootUrl}api/search`,
            method: 'GET'
          }).then(xhr => {
            if (this.isDestroyed || this.isDestroying) {
              return;
            }

            const dashboards = xhr.body || [];
            Ember.set(this, 'dashboards', dashboards);
            this.updateLinks();
          });
        } else {
          Ember.set(this, 'dashboards', []);
          this.updateLinks();
        }
      }
    }))
  });

  _exports.default = _default;
});