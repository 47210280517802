define("shared/oauth/service", ["exports", "shared/utils/util", "shared/utils/constants"], function (_exports, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const googleOauthScope = 'openid profile email';
  const githubOauthScope = 'read:org';

  var _default = Ember.Service.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),
    intl: Ember.inject.service(),
    authType: '',

    generateState(provider = '') {
      const state = JSON.stringify({
        to: 'ember',
        provider,
        nonce: Math.random(),
        test: true
      });
      return Ember.set(this, 'session.oauthState', state);
    },

    generateLoginStateKey(provider) {
      const state = JSON.stringify({
        to: 'ember',
        provider,
        nonce: Math.random(),
        test: false
      });
      return Ember.set(this, 'session.oauthState', state);
    },

    encodeState(state) {
      const m = {
        '+': '-',
        '/': '_'
      };
      return AWS.util.base64.encode(state).replace(/[+/]|=+$/g, char => m[char] || '');
    },

    decodeState(state) {
      return AWS.util.base64.decode(`${state.replace(/[-_]/g, char => char === '-' ? '+' : '/')}=`).toString();
    },

    stateMatches(actual) {
      const state = Ember.get(this, 'session.oauthState');

      try {
        const parsedState = JSON.parse(state);
        return actual && actual === parsedState.nonce;
      } catch {
        return false;
      }
    },

    testConfig(config) {
      return config.doAction('configureTest', config);
    },

    saveConfig(config, opt) {
      return config.doAction('testAndApply', opt);
    },

    authorize(auth, state) {
      const isGithub = auth.type.includes('github');
      let url = null;

      if (isGithub) {
        url = (0, _util.addQueryParams)(Ember.get(auth, 'redirectUrl'), {
          scope: githubOauthScope,
          redirect_uri: `${window.location.origin}/verify-auth`,
          authProvider: 'github',
          state
        });
      } else {
        url = (0, _util.addQueryParams)(Ember.get(auth, 'redirectUrl'), {
          scope: googleOauthScope,
          redirect_uri: `${window.location.origin}/verify-auth`,
          state
        });
      }

      return window.location.href = url;
    },

    login(authType, forwardUrl) {
      const provider = Ember.get(this, 'access.providers').findBy('id', authType);
      const authRedirect = Ember.get(provider, 'redirectUrl');
      let redirect = `${window.location.origin}/verify-auth`;

      if (forwardUrl) {
        redirect = (0, _util.addQueryParam)(redirect, 'forward', forwardUrl);
      }

      let url = (0, _util.addQueryParams)(authRedirect, {
        scope: authType === 'github' ? githubOauthScope : googleOauthScope,
        state: this.encodeState(this.generateLoginStateKey(authType)),
        redirect_uri: redirect
      });
      window.location.href = url;
    },

    test(config, cb) {
      let responded = false;
      let configName = config.name;

      window.onAuthTest = (err, code) => {
        if (!responded && !err) {
          let ghConfig = config;
          responded = true;
          this.finishTest(ghConfig, code, cb);
        }
      };

      Ember.set(this, 'state', this.encodeState(this.generateState(config.name)));
      let url = (0, _util.addQueryParams)(`${window.location.origin}/verify`, {
        config: configName
      });
      const popup = window.open(url, 'rancherAuth', (0, _util.popupWindowOptions)());
      const intl = Ember.get(this, 'intl');
      let timer = setInterval(() => {
        if (popup && popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t(`authPage.${configName}.testAuth.authError`)
            });
          }
        } else if (popup === null || typeof popup === 'undefined') {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t(`authPage.${configName}.testAuth.popupError`)
            });
          }
        }
      }, 500);
    },

    finishTest(config, code, cb) {
      const currentConfig = config;
      let out = null;
      Ember.set(currentConfig, 'enabled', true);

      if (config.id === 'googleoauth') {
        out = {
          code,
          enabled: true,
          googleOauthConfig: currentConfig,
          description: _constants.default.SESSION.DESCRIPTION,
          ttl: _constants.default.SESSION.TTL
        };
      } else {
        out = {
          code,
          enabled: true,
          githubConfig: currentConfig,
          description: _constants.default.SESSION.DESCRIPTION,
          ttl: _constants.default.SESSION.TTL
        };
      }

      const allowedPrincipalIds = Ember.get(config, 'allowedPrincipalIds') || [];
      return this.saveConfig(config, out).then(() => {
        let found = false;
        const myPIds = Ember.get(this, 'access.me.principalIds');
        myPIds.forEach(id => {
          if (allowedPrincipalIds.indexOf(id) >= 0) {
            found = true;
          }
        });

        if (!found && !allowedPrincipalIds.length) {
          allowedPrincipalIds.pushObject(Ember.get(this, 'access.principal.id'));
        }

        return currentConfig.save().then(() => {
          window.location.href = window.location.href; // eslint-disable-line no-self-assign
        });
      }).catch(err => {
        cb(err);
      });
    }

  });

  _exports.default = _default;
});