define("shared/components/modal-edit-catalog/component", ["exports", "shared/mixins/modal-base", "shared/mixins/new-or-edit", "shared/components/modal-edit-catalog/template", "ui/utils/constants"], function (_exports, _modalBase, _newOrEdit, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    HELM_VERSION_2: helmV2,
    HELM_VERSION_3: helmV3
  } = _constants.default.CATALOG;
  const kindChoices = [{
    translationKey: 'catalogSettings.more.kind.helm',
    value: 'helm'
  }];
  const scopeChoices = [{
    scope: 'global'
  }, {
    scope: 'cluster'
  }, {
    scope: 'project'
  }];

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scopeService: Ember.inject.service('scope'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    model: null,
    allNamespaces: null,
    allProjects: null,
    selectedScope: null,
    allScopes: null,
    helmVersions: null,
    isPrivate: false,
    kindChoices,
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    editing: Ember.computed.notEmpty('originalModel.id'),
    scope: Ember.computed.alias('modalService.modalOpts.scope'),

    init() {
      this._super(...arguments);

      const orig = this.get('originalModel');
      const clone = orig.clone();

      this._initScope();

      this.initHelmVerions();
      Ember.set(clone, 'kind', 'helm');

      if (Ember.isEmpty(clone.helmVersion)) {
        Ember.set(clone, 'helmVersion', helmV2);
      }

      Ember.setProperties(this, {
        model: clone,
        username: Ember.get(clone, 'username'),
        isPrivate: Ember.get(clone, 'username') ? true : false
      });
    },

    watchScope: Ember.observer('selectedScope', function () {
      Ember.set(this, 'model', this._initModel(Ember.get(this, 'selectedScope')));
    }),

    willSave() {
      const isPrivate = Ember.get(this, 'isPrivate');
      const pr = Ember.get(this, 'primaryResource');

      if (isPrivate) {
        Ember.setProperties(pr, {
          username: Ember.get(this, 'username'),
          password: Ember.get(this, 'password')
        });
      } else {
        Ember.setProperties(pr, {
          username: null,
          password: null
        });
      }

      return this._super(...arguments);
    },

    validate() {
      this._super(...arguments);

      const errors = Ember.get(this, 'errors') || [];
      const isPrivate = Ember.get(this, 'isPrivate');

      if (isPrivate) {
        if (!Ember.get(this, 'username')) {
          errors.push(Ember.get(this, 'intl').t('catalogSettings.more.username.required'));
        }

        if (!Ember.get(this, 'password')) {
          errors.push(Ember.get(this, 'intl').t('catalogSettings.more.password.required'));
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },

    doneSaving() {
      this.send('cancel');
    },

    _initScope() {
      const {
        scope
      } = this;
      let choices = scopeChoices;

      switch (scope) {
        case 'cluster':
          choices = scopeChoices.filter(s => s.scope !== 'project');
          break;

        case 'global':
          choices = scopeChoices.filter(s => s.scope === 'global');
          break;

        case 'project':
        default:
          break;
      }

      Ember.setProperties(this, {
        allScopes: choices,
        selectedScope: scope
      });
    },

    _initModel(scope = 'global') {
      const current = Ember.get(this, 'model');
      const record = {
        type: 'catalog',
        kind: 'helm',
        branch: 'master'
      };

      switch (scope) {
        case 'cluster':
          Ember.setProperties(record, {
            type: 'clusterCatalog',
            clusterId: Ember.get(this, 'scopeService.currentCluster.id')
          });
          break;

        case 'project':
          Ember.setProperties(record, {
            type: 'projectCatalog',
            projectId: Ember.get(this, 'scopeService.currentProject.id')
          });
          break;

        case 'global':
        default:
          break;
      }

      if (current) {
        Ember.setProperties(record, {
          branch: Ember.get(current, 'branch'),
          url: Ember.get(current, 'url'),
          name: Ember.get(current, 'name'),
          username: Ember.get(current, 'username')
        });
      }

      if (Ember.get(current, 'username')) {
        Ember.set(this, 'isPrivate', true);
      } else {
        Ember.set(this, 'isPrivate', false);
      }

      return Ember.get(this, 'globalStore').createRecord(record);
    },

    initHelmVerions() {
      const mappedVerions = [{
        label: 'Helm v2',
        value: helmV2
      }, {
        label: 'Helm v3',
        value: helmV3
      }];
      Ember.set(this, 'helmVersions', mappedVerions);
    }

  });

  _exports.default = _default;
});