define("global-admin/components/policy-row/component", ["exports", "global-admin/components/policy-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    policies: null,
    tagName: 'TR',
    classNames: 'main-row',
    actions: {
      remove() {
        this.remove(this.model);
      }

    },

    remove() {
      throw new Error('remove action is required!');
    }

  });

  _exports.default = _default;
});