define("global-admin/security/authentication/localauth/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,
    adminName: '',
    adminPublicValue: '',
    adminSecretValue: '',
    adminSecretValue2: '',
    actions: {
      test() {
        if (!Ember.get(this, 'adminPublicValue')) {
          return void this.send('showError', 'Login username is required');
        }

        if (!Ember.get(this, 'adminSecretValue')) {
          return void this.send('showError', 'Password is required');
        }

        if (Ember.get(this, 'adminSecretValue') !== Ember.get(this, 'adminSecretValue2')) {
          return void this.send('showError', 'Passwords do not match');
        }

        this.send('clearError');
        Ember.set(this, 'testing', true);
        const model = Ember.get(this, 'model');
        const am = Ember.get(model, 'accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          name: Ember.get(this, 'adminName'),
          accessMode: am,
          username: Ember.get(this, 'adminPublicValue'),
          password: Ember.get(this, 'adminSecretValue'),
          enabled: false
        });
        model.save().then(() => {
          // Wait a bit for the new config to take effect...
          setTimeout(() => {
            this.send('authenticate');
          }, 1000);
        }).catch(err => {
          this.send('gotError', err);
        });
      },

      authenticate() {
        this.send('clearError');
        var code = `${Ember.get(this, 'adminPublicValue')}:${Ember.get(this, 'adminSecretValue')}`;
        Ember.get(this, 'access').login(code).then(res => {
          this.send('authenticationSucceeded', res.body);
        }).catch(err => {
          this.send('gotError', err);
        });
      },

      authenticationSucceeded()
      /* auth*/
      {
        this.send('clearError'); // Set this to true so the token will be sent with the request

        Ember.set(this, 'access.enabled', true);
        const model = Ember.get(this, 'model');
        Ember.setProperties(model, {
          enabled: true
        });
        model.save().then(() => {
          this.send('waitAndRefresh');
        }).catch(err => {
          Ember.set(this, 'access.enabled', false);
          this.send('gotError', err);
        });
      },

      waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line

        setTimeout(() => {
          window.location.href = url || window.location.href;
        }, 1000);
      },

      promptDisable() {
        Ember.set(this, 'confirmDisable', true);
        Ember.run.later(this, function () {
          Ember.set(this, 'confirmDisable', false);
        }, 10000);
      },

      gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? `(${err.detail})` : ''));
        } else {
          this.send('showError', `Error (${err.status} - ${err.code})`);
        }

        Ember.set(this, 'testing', false);
        Ember.set(this, 'saving', false);
      },

      showError(msg) {
        Ember.set(this, 'errors', [msg]);
        window.scrollY = 0;
      },

      clearError() {
        Ember.set(this, 'errors', null);
      },

      disable() {
        this.send('clearError');
        var model = Ember.get(this, 'model');
        Ember.setProperties(model, {
          enabled: false,
          username: '',
          password: ''
        });
        model.save().then(() => {
          this.send('waitAndRefresh');
        }).catch(err => {
          this.send('gotError', err);
        }).finally(() => {
          Ember.set(this, 'confirmDisable', false);
        });
      }

    },
    createDisabled: Ember.computed('adminPublicValue.length', 'adminSecretValue.length', 'adminSecretValue2', function () {
      var ok = Ember.get(this, 'adminPublicValue.length') && Ember.get(this, 'adminSecretValue.length') && Ember.get(this, 'adminSecretValue') === Ember.get(this, 'adminSecretValue2');
      return !ok;
    }),
    validateDescription: Ember.computed(function () {
      return Ember.get(this, 'settings').get(_constants.default.SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    })
  });

  _exports.default = _default;
});