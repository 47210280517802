define("ember-asset-loader/errors/bundle-load", ["exports", "ember-asset-loader/errors/load", "ember-asset-loader/services/asset-loader"], function (_exports, _load, _assetLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents an error, or errors, that occurred while trying to load a bundle
   * of assets.
   *
   * @class BundleLoadError
   * @extends LoadError
   */
  class BundleLoadError extends _load.default {
    /**
     * Constructs a new BundleLoadError from the original error, or errors, and
     * the name of the bundle that was attempting to load.
     *
     * @param {AssetLoader} assetLoader
     * @param {Asset} asset
     * @param {Error} error
     */
    constructor(assetLoader, bundleName, errors) {
      super(`The bundle "${bundleName}" failed to load.`, assetLoader);
      this.name = 'BundleLoadError';
      this.bundleName = bundleName;
      this.errors = errors;
    }

    retryLoad() {
      return this._invokeAndCache('loadBundle', this.bundleName, _assetLoader.RETRY_LOAD_SECRET);
    }

  }

  _exports.default = BundleLoadError;
});