define("shared/components/accordion-list/component", ["exports", "shared/components/accordion-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showExpandAll: true,
    expandAll: false,
    actions: {
      expandAll() {
        this.toggleProperty('expandAll');
      }

    },

    expand(item) {
      item.toggleProperty('expanded');
    }

  });

  _exports.default = _default;
});