define("shared/components/cluster-ribbon-nav/component", ["exports", "shared/components/cluster-ribbon-nav/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    tagName: 'section',
    classNames: ['recent-clusters'],
    activeClusterProvider: null,
    allActiveClusterProviders: null,

    init() {
      this._super(...arguments);

      this.initRecentClusters();
    },

    actions: {
      removeRecent(clusterName) {
        let recentClusters = Ember.get(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`) || [];
        recentClusters.removeObject(clusterName);
        Ember.set(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`, recentClusters);
      }

    },
    recentClusterProviders: Ember.computed(`prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`, function () {
      let recentProviders = Ember.get(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`) || [];
      let {
        allActiveClusterProviders
      } = this;
      let out = recentProviders.map(provider => {
        return allActiveClusterProviders.findBy('name', provider);
      });
      return out;
    }),

    clusterDriverError() {
      throw new Error('clusterDriverError action is required!');
    },

    initRecentClusters() {
      let {
        allActiveClusterProviders
      } = this;
      let recentClusters = Ember.get(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`) ? Ember.get(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`).slice() : [];
      let activeClusterProvider = Ember.get(this, 'activeClusterProvider');
      let providerName = activeClusterProvider ? Ember.get(activeClusterProvider, 'name') : null;
      let activeRecentClusters = recentClusters.filter(rc => {
        if (allActiveClusterProviders.findBy('name', rc)) {
          return true;
        }

        return false;
      });

      if (providerName) {
        if (activeRecentClusters.includes(providerName)) {
          activeRecentClusters = activeRecentClusters.removeObject(providerName);
          activeRecentClusters.unshiftObject(providerName);
        } else {
          if (activeRecentClusters.length === 5) {
            activeRecentClusters.pop();
            activeRecentClusters.unshiftObject(providerName);
          } else {
            activeRecentClusters.unshiftObject(providerName);
          }
        }
      } else {
        this.clusterDriverError();
      }

      if (activeRecentClusters.uniq().length > 5) {
        activeRecentClusters = activeRecentClusters.slice(0, 5);
      }

      Ember.set(this, `prefs.${_constants.default.PREFS.RECENT_CLUSTERS}`, activeRecentClusters.uniq());
    }

  });

  _exports.default = _default;
});