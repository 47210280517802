define("shared/components/modal-delete-eks-cluster/component", ["exports", "shared/components/modal-delete-eks-cluster/template", "shared/mixins/new-or-edit", "shared/mixins/modal-base"], function (_exports, _template, _newOrEdit, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    classNames: ['medium-modal'],
    clone: null,
    errors: null,
    updateKeys: true,
    layout: _template.default,
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    primaryResource: Ember.computed.alias('originalModel'),
    actions: {
      confirmKeys(cb) {
        const errors = [];

        if (this.isValid()) {
          this.canListEksClusters().then(() => {
            Ember.get(this, 'primaryResource').save().then(() => {
              Ember.get(this, 'primaryResource').delete().then(() => {
                cb();
                this.send('cancel');
              }).catch(e => {
                errors.push(e);
                Ember.set(this, 'errors', errors);
                cb(false, e);
              });
            }).catch(e => {
              errors.push(e);
              Ember.set(this, 'errors', errors);
              cb(false, e);
            });
          }).catch(e => {
            errors.push(e);
            Ember.set(this, 'errors', errors);
            cb(false, e);
          });
        } else {
          cb(false);
        }
      }

    },

    canListEksClusters() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const eks = new AWS.EKS(this.authCreds());
        eks.listClusters({}, (err, clusters) => {
          if (err) {
            return reject(`${Ember.get(err, 'statusCode')} ${Ember.get(err, 'code')}: ${Ember.get(err, 'message')}`);
          }

          return resolve({
            clusters: clusters.clusters
          });
        });
      });
    },

    isValid() {
      const config = Ember.get(this, 'primaryResource.amazonElasticContainerServiceConfig');
      const errors = [];
      let {
        accessKey,
        secretKey,
        sessionToken
      } = config;

      if (!accessKey) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.accessKey'));
      }

      if (!secretKey) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.secretKey'));
      }

      if (!sessionToken) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.sessionToken'));
      }

      Ember.set(this, 'errors', errors);
      return errors.length > 0 ? false : true;
    },

    authCreds() {
      const config = Ember.get(this, 'primaryResource.amazonElasticContainerServiceConfig');
      let {
        accessKey,
        secretKey,
        region,
        sessionToken
      } = config;
      accessKey = accessKey.trim();
      secretKey = secretKey.trim();
      Ember.setProperties(config, {
        accessKey,
        secretKey
      });
      const auth = {
        region,
        accessKeyId: accessKey,
        secretAccessKey: secretKey
      };

      if (sessionToken) {
        Ember.set(auth, 'sessionToken', sessionToken);
      }

      return auth;
    }

  });

  _exports.default = _default;
});